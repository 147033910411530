import React from "react";
import styles from "../../general.module.scss";
import CreateAccount from "./CreateAccount/CreateAccount";
import SEO from "../../components/SEO/SEO";

const Account = () => {
  return (
    <>
      <SEO
        title="Contul meu"
        description="Doresti sa te inregistrezi pe RomStock.ro? Nimic mai simplu. Introdu adresa de mail, parola, cateva detalii si esti pregatit de cumparaturi."
        robots="index, follow"
        canonical="https://romstock.ro/my-account"
        openGraph={{
          url: "https://www.romstock.ro/my-account",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "Intra in contul tau RomStock",
        }}
      />
      <div className={`${styles.container} ${styles.account_container}`}>
        <CreateAccount />
      </div>
    </>
  );
};

export default Account;
