import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "../../general.module.scss";
import "./Confirmation.scss";
import { Link } from "react-router-dom";
import {
  getRambursPaymentRequest,
  getOrdersDetailsRequest,
} from "../../redux/order/order.actions";
import SEO from "../../components/SEO/SEO";
import { pixelPurchaseEvent } from "../../utilities/marketing-events";

const ConfirmationRamburs = ({
  error,
  location,
  getOrdersDetailsRequest,
  details,
}) => {
  const [searchedNumber] = useState("");

  useEffect(() => {
    const locationSplit = location.search.split("=");
    if (
      "?orderNumber=" + searchedNumber !== location.search &&
      locationSplit[0] === "?orderNumber"
    ) {
      if (localStorage.getItem("token")) {
        getOrdersDetailsRequest({
          orderNumber: locationSplit[1].split("&")[0],
          isLogged: localStorage.getItem("token"),
        });
      } else {
        getOrdersDetailsRequest({
          orderNumber: locationSplit[1].split("&")[0],
          isLogged: null,
        });
      }
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (details && !error) {
      var script = document.createElement("script");
      var script2 = document.createElement("script");
      script.type = "text/javascript";
      script2.type = "text/javascript";

      const totalWithoutShipping = (
        Number(details.revenue) - Number(details.shipping)
      ).toFixed(2);

      var code = `gtag('event', 'conversion', { 'send_to': 'AW-466907200/d89qCObamuwBEMDg0d4B', 'value': ${totalWithoutShipping}, 'currency': 'RON', 'transaction_id': ${details.id} }); `;

      var productArray = details.products.map((p) => ({
        name: p.name,
        id: p.id,
        price: p.price,
        brand: p.brand,
        category: p.category,
        variant: p.variant,
        quantity: p.quantity,
      }));

      pixelPurchaseEvent(totalWithoutShipping, productArray)

      var code2 = `var dataLayer  = window.dataLayer || [];
                          dataLayer.push({
                              'event': 'transaction',
                              'ecommerce': {
                                  'purchase': {
                                      'actionField': {
                                          'id': '${details.id}',
                                          'affiliation': '${
                                            details.affiliation
                                          }',
                                          'revenue': '${details.revenue}',
                                          'Tax': '${details.tax}',
                                          'shipping': '${details.shipping}',
                                          'coupon': '${details.coupon}'
                                      },
                                      'products': ${JSON.stringify(
                                        productArray
                                      )}
                                  }
                              }
                          });`;
      try {
        if (!error) {
          script.appendChild(document.createTextNode(code));
          document.body.appendChild(script);
          script2.appendChild(document.createTextNode(code2));
          document.body.appendChild(script2);
        }
      } catch (e) {
        if (!error) {
          script.text = code;
          document.body.appendChild(script);
          script2.text = code2;
          document.body.appendChild(script2);
        }
      }
    }
  }, [details]);

  return (
    <>
      <SEO
        title="Confirmare comanda ramburs"
        description=""
        robots="noindex, follow"
        canonical="https://romstock.ro/confirmation"
        openGraph={{
          url: "https://www.romstock.ro/confirmation-ramburs",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "Confirmarea plasarii comenzii ramburs",
        }}
      />
      <div className={styles.container}>
        {error && (
          <div className="container col-md-8 col-12 confirmation_container">
            <h1>{error}</h1>
          </div>
        )}
        {!error && (
          <div className="container col-md-8 col-12 confirmation_container">
            <h1>Comanda ta a fost înregistrată.</h1>
            <h3>Vei primi un email cu detaliile.</h3>
            <Link to="/" style={{ color: "white", textDecoration: "none" }}>
              <button className="button">Continuă cumpărăturile</button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.order.error,
  details: state.order.details,
  user: state.registerAccount.user,
});
const mapDispatchToProps = (dispatch) => ({
  getRambursPaymentRequest: (payload) =>
    dispatch(getRambursPaymentRequest(payload)),
  getOrdersDetailsRequest: (payload) =>
    dispatch(getOrdersDetailsRequest(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationRamburs);
