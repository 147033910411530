import React from "react";
import styles from "../../general.module.scss";
import "./Confirmation.scss";
import Timer from "./Timer";

const Message = ({ response }) => {
  return (
    <div className={styles.container}>
      <div className="container col-md-8 col-12 confirmation_container">
        {response && response.status === 200 && (
          <>
            <h3>
              {" "}
              Vă mulțumim că v-ați creat cont pe RomStock. Veți primi un link de
              confirmare pe email (verificați și secțiunea Spam).{" "}
            </h3>
            <Timer />
          </>
        )}
        {response && response.status === 400 && (
          <>
            <h3> Crearea contului a eșuat, încercați din nou. </h3>
          </>
        )}
      </div>
    </div>
  );
};

export default Message;
