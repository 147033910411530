import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import { connect } from "react-redux";
import "./BrandsPage.scss";

import { getBrandsRequest } from "../../redux/brands/brands.actions";
import { Link as RouterLink } from "react-router-dom";
import SEO from "../../components/SEO/SEO";

const BrandsPage = ({ getBrandsRequest, brands }) => {
  useEffect(() => {
    getBrandsRequest();
  }, []);

  if (brands)
    return (
      <>
        <SEO
          title="Branduri"
          description={
            "Branduri Romstock. Comanda online din peste cele " +
            brands.length +
            " de branduri din gama de produse disponibila."
          }
          robots="index, follow"
          canonical="https://romstock.ro/brands"
          openGraph={{
            url: "https://www.romstock.ro/brands",
            type: "website",
            image: "https://romstock.ro/logo192.png",
            description:
              "Vizualizeaza toate brandurile comercializate pe RomStock",
          }}
        />

        <div className="brands-container">
          <Row className="title-style">
            <h1>Branduri</h1>
          </Row>
          <Row className="letters-row">
            <ol>
              {brands.map(function (brand, index) {
                return (
                  <li>
                    <Col>
                      <Link
                        activeClass="active"
                        to={"litera" + index}
                        spy={true}
                        smooth={true}
                        offset={-180}
                        duration={500}
                      >
                        {brand.letter}
                      </Link>
                    </Col>
                  </li>
                );
              })}
            </ol>
          </Row>
          <Row className="brands-row">
            <ol>
              {brands.map(function (brand, index) {
                return (
                  <li>
                    <Row className="letter">
                      {" "}
                      <Col id={"litera" + index}>{brand.letter}</Col>{" "}
                    </Row>
                    <Row>
                      {brand.brands.map(function (item) {
                        return (
                          <Col md={2}>
                            <label className="label-brand">
                              <RouterLink to={`/products/all?brand=${item}`}>
                                {item}
                              </RouterLink>
                            </label>
                          </Col>
                        );
                      })}
                    </Row>
                  </li>
                );
              })}
            </ol>
          </Row>
        </div>
      </>
    );
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  getBrandsRequest: (payload) => dispatch(getBrandsRequest(payload)),
});

const mapStateToProps = (state) => ({
  brands: state.brands.brands,
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandsPage);
