import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
} from "./search.constants";

export function* searchProducts({ payload }) {
  const { searchedString, currentPageNumber } = payload;
  const {
    data: { products, numberOfPages },
  } = yield call(apiService.searchProducts, searchedString, currentPageNumber);
  yield put({
    type: SEARCH_PRODUCTS_SUCCESS,
    payload: { products, numberOfPages },
  });
}

export function* searchProductsWatcher() {
  yield takeLatest(SEARCH_PRODUCTS_REQUEST, searchProducts);
}

export function* searchSaga() {
  yield all([call(searchProductsWatcher)]);
}
