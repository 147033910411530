import React, { useState } from "react";
import Menu from "./Menu/Menu";
import "./MyAccountPage.scss";
import SEO from "../../components/SEO/SEO";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MyAccountPage = ({ location }) => {
  const [linkString, setLinkString] = useState("");
  return (
    <>
      <SEO
        title="Contul meu"
        description="Vizualizeaza contul tau RomStock. Poti edita datele personale sau sa iti vizualizezi comenzile."
        robots="index, follow"
        canonical="https://romstock.ro/my-account"
        openGraph={{
          url: "https://www.romstock.ro/my-account",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description:
            "Vizualizeaza contul tau RomStock. Poti edita datele personale sau sa iti vizualizezi comenzile.",
        }}
      />
      <div className="my-account-page-container">
        {linkString ? (
          <>
            <Row
              className="row-links-my-account"
              id="products-list"
              itemScope
              itemType="https://schema.org/BreadcrumbList"
            >
              <Link
                itemProp="itemListElement"
                itemscope
                itemtype="https://schema.org/ListItem"
                to={linkString[2] ? `/my-account/${linkString[2]}` : ""}
                className="first-part-link-style"
              >
                <span itemProp="name">
                  {linkString[2]
                    ? (
                        linkString[2].charAt(0).toUpperCase() +
                        linkString[2].slice(1)
                      ).replace(/%2F/g, "/") + " "
                    : ""}
                </span>
              </Link>
              <Link
                itemProp="itemListElement"
                itemscope
                itemtype="https://schema.org/ListItem"
                to={
                  linkString[3]
                    ? `/my-account/${linkString[2]}/${linkString[3]}/${linkString[4]}`
                    : ""
                }
                style={{ color: "#707070", textDecoration: "none" }}
              >
                <div
                  itemProp="item"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "auto",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ marginBottom: "0%" }}>
                    {linkString[3] ? (
                      <i className="fas fa-angle-double-right double_arrow"></i>
                    ) : (
                      ""
                    )}
                    <span itemProp="name">
                      {linkString[3]
                        ? " " +
                          (
                            linkString[3].charAt(0).toUpperCase() +
                            linkString[3].slice(1)
                          ).replace(/%2F/g, "/") +
                          " "
                        : ""}
                    </span>
                    {linkString[4] ? (
                      <i className="fas fa-angle-double-right double_arrow"></i>
                    ) : (
                      ""
                    )}
                    <span itemProp="name">
                      {linkString[4]
                        ? " " + linkString[4].replace(/%2F/g, "/")
                        : ""}
                    </span>
                  </p>
                </div>
              </Link>
            </Row>
          </>
        ) : (
          <>
            <td className="empty-row"></td>
          </>
        )}
        <Menu location={location} setLinkString={setLinkString}></Menu>
      </div>
    </>
  );
};

export default MyAccountPage;
