import { connect } from "react-redux";
import React, { useEffect } from "react";
import styles from "../../general.module.scss";
import "./Confirmation.scss";
import { Link } from "react-router-dom";
import {
  getBTStatusRequest,
  getOrdersDetailsRequest,
} from "../../redux/order/order.actions";
import SEO from "../../components/SEO/SEO";
import { pixelPurchaseEvent } from "../../utilities/marketing-events";

const Confirmation = ({
  location,
  user,
  status,
  getBTStatusRequest,
  getOrdersDetailsRequest,
  details,
}) => {
  useEffect(() => {
    const locationSplit = location.search.split("&");
    if (localStorage.getItem("token")) {
      getOrdersDetailsRequest({
        orderNumber: locationSplit[0].split("=")[1],
        isLogged: localStorage.getItem("token"),
      });
      getBTStatusRequest({
        orderId: locationSplit[1].split("=")[1],
        isLogged: localStorage.getItem("token"),
      });
    } else {
      getOrdersDetailsRequest({
        orderNumber: locationSplit[0].split("=")[1],
        isLogged: null,
      });
      getBTStatusRequest({
        orderId: locationSplit[1].split("=")[1],
        isLogged: null,
      });
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (details && status) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      var script2 = document.createElement("script");
      script2.type = "text/javascript";

      var productArray = details.products.map((p) => ({
        name: p.name,
        id: p.id,
        price: p.price,
        brand: p.brand,
        category: p.category,
        variant: p.variant,
        quantity: p.quantity,
      }));

      
      const totalWithoutShipping = (
        Number(details.revenue) - Number(details.shipping)
      ).toFixed(2);
        
      pixelPurchaseEvent(totalWithoutShipping, productArray)
      var code = `gtag('event', 'conversion', { 'send_to': 'AW-466907200/d89qCObamuwBEMDg0d4B', 'value': ${totalWithoutShipping}, 'currency': 'RON', 'transaction_id': ${details.id} }); `;

      var code2 = `var dataLayer  = window.dataLayer || [];
                          dataLayer.push({
                              'event': 'transaction',
                              'ecommerce': {
                                  'purchase': {
                                      'actionField': {
                                          'id': '${details.id}',
                                          'affiliation': '${
                                            details.affiliation
                                          }',
                                          'revenue': '${details.revenue}',
                                          'Tax': '${details.tax}',
                                          'shipping': '${details.shipping}',
                                          'coupon': '${details.coupon}'
                                      },
                                      'products': ${JSON.stringify(
                                        productArray
                                      )}
                                  }
                              }
                          });`;

      try {
        const user = localStorage.getItem("token");
        if (
          user &&
          (status.data.paymentAmountInfo.paymentState === "CREATED" ||
            status.data.paymentAmountInfo.paymentState === "DEPOSITED")
        ) {
          script.appendChild(document.createTextNode(code));
          document.body.appendChild(script);
          script2.appendChild(document.createTextNode(code2));
          document.body.appendChild(script2);
        } else if (
          !user &&
          (status.data === "CREATED" || status.data === "DEPOSITED")
        ) {
          script.appendChild(document.createTextNode(code));
          document.body.appendChild(script);
          script2.appendChild(document.createTextNode(code2));
          document.body.appendChild(script2);
        }
      } catch (e) {
        const user = localStorage.getItem("token");
        if (
          user &&
          (status.data.paymentAmountInfo.paymentState === "CREATED" ||
            status.data.paymentAmountInfo.paymentState === "DEPOSITED")
        ) {
          script.text = code;
          script2.text = code2;
          document.body.appendChild(script);
          document.body.appendChild(script2);
        } else if (
          !user &&
          (status.data === "CREATED" || status.data === "DEPOSITED")
        ) {
          script.text = code;
          script2.text = code2;
          document.body.appendChild(script);
          document.body.appendChild(script2);
        }
      }
    }
  }, [status]);

  return (
    <>
      <SEO
        title="Confirmare comanda BT"
        description=""
        robots="noindex, follow"
        canonical="https://romstock.ro/confirmation"
        openGraph={{
          url: "https://www.romstock.ro/confirmation",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "Confirmarea platii cu cardul",
        }}
      />
      <div className={styles.container}>
        {status && localStorage.getItem("token") && (
          <div className="container col-md-8 col-12 confirmation_container">
            <h1>
              {status.data.paymentAmountInfo.paymentState === "CREATED" ||
              status.data.paymentAmountInfo.paymentState === "DEPOSITED"
                ? "Comanda dumneavoastră a fost înregistrată. "
                : status.data.paymentAmountInfo.paymentState === "DECLINED"
                ? "Plata dumneavoastră pentru comanda dorită a fost respinsă. Motivul: " +
                  status.errorMessage
                : ""}
            </h1>
            <h1>
              {status.data.paymentAmountInfo.paymentState === "CREATED" ||
              status.data.paymentAmountInfo.paymentState === "DEPOSITED"
                ? "Vă mulțumim că ați ales Romstock!"
                : ""}
            </h1>
            <h3>
              {status.data.paymentAmountInfo.paymentState === "CREATED" ||
              status.data.paymentAmountInfo.paymentState === "DEPOSITED"
                ? "Veți primi un email cu detaliile."
                : ""}
            </h3>
            {status.data.paymentAmountInfo.paymentState === "CREATED" ||
            status.data.paymentAmountInfo.paymentState === "DEPOSITED" ? (
              <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                <button className="button">Continuă cumpărăturile</button>
              </Link>
            ) : (
              <></>
            )}
          </div>
        )}
        {status && !user && (
          <div className="container col-md-8 col-12 confirmation_container">
            <h1>
              {status.data === "CREATED" || status.data === "DEPOSITED"
                ? "Comanda dumneavoastră a fost înregistrată. "
                : status.data === "DECLINED"
                ? "Plata dumneavoastră pentru comanda dorită a fost respinsă. "
                : ""}
            </h1>
            <h1>
              {status.data === "CREATED" || status.data === "DEPOSITED"
                ? "Vă mulțumim că ați ales Romstock!"
                : ""}
            </h1>
            <h3>
              {status.data === "CREATED" || status.data === "DEPOSITED"
                ? "Veți primi un email cu detaliile."
                : ""}
            </h3>
            {status.data === "CREATED" || status.data === "DEPOSITED" ? (
              <Link to="/" style={{ color: "white", textDecoration: "none" }}>
                <button className="button">Continuă cumpărăturile</button>
              </Link>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  response: state.order.response,
  status: state.order.status,
  user: state.registerAccount.user,
  details: state.order.details,
});
const mapDispatchToProps = (dispatch) => ({
  getBTStatusRequest: (payload) => dispatch(getBTStatusRequest(payload)),
  getOrdersDetailsRequest: (payload) =>
    dispatch(getOrdersDetailsRequest(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
