import {
  GET_PROMOTION_PRODUCTS_REQUEST,
  SET_FIRST_PAGE_PROMOTION,
  SET_LAST_PAGE_PROMOTION,
  SET_NEXT_PAGE_PROMOTION,
  SET_PREV_PAGE_PROMOTION,
  NEXT_PAGE_PROMOTION,
  PREV_PAGE_PROMOTION,
  GET_PROMOTION_CATEGORIES_REQUEST,
} from "./promotions.constants";

export const getPromotionProductsRequest = (payload) => {
  return {
    type: GET_PROMOTION_PRODUCTS_REQUEST,
    payload,
  };
};

export const getPromotionCategoriesRequest = (payload) => {
  return {
    type: GET_PROMOTION_CATEGORIES_REQUEST,
    payload,
  };
};

export const nextPage = () => {
  return {
    type: NEXT_PAGE_PROMOTION,
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE_PROMOTION,
  };
};

export const setNextPage = () => {
  return {
    type: SET_NEXT_PAGE_PROMOTION,
  };
};

export const setPrevPage = () => {
  return {
    type: SET_PREV_PAGE_PROMOTION,
  };
};

export const setFirstPage = () => {
  return {
    type: SET_FIRST_PAGE_PROMOTION,
  };
};

export const setLastPage = () => {
  return {
    type: SET_LAST_PAGE_PROMOTION,
  };
};
