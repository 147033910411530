import { combineReducers } from "redux";
import { cartReducer } from "./cart/cart.reducer";
import { filtersReducer } from "./filters/filters.reducer";
import { productReducer } from "./product/product.reducer";
import { registerReducer } from "./register/register.reducer";
import { commentsReducer } from "./comments/comments.reducer";
import { menuReducer } from "./menu/menu.reducer";
import { brandsReducer } from "./brands/brands.reducer";
import { accountReducer } from "./account/account.reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { orderReducer } from "./order/order.reducer";
import { homeReducer } from "./home/home.reducer";
import { adminReducer } from "./admin/admin.reducer";
import { searchReducer } from "./search/search.reducer";
import { promotionReducer } from "./promotions/promotions.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["cart"],
};

const rootReducer = combineReducers({
  filters: filtersReducer,
  cart: cartReducer,
  product: productReducer,
  registerAccount: registerReducer,
  comments: commentsReducer,
  menu: menuReducer,
  brands: brandsReducer,
  account: accountReducer,
  order: orderReducer,
  home: homeReducer,
  admin: adminReducer,
  search: searchReducer,
  promotions: promotionReducer,
});

let toExport = rootReducer;
if (!localStorage.getItem("token"))
  toExport = persistReducer(persistConfig, rootReducer);

export default toExport;
