import React from "react";
import "./PoliticaCookie.scss";

const PoliticaCookie = () => {
  return (
    <>
      <div className="container-politica-cookie">
        <h1 style={{ textAlign: "center" }}>POLITICA PRIVIND COOKIES</h1>
        <div>
          <h5>
            <strong>Cine suntem noi?</strong>
          </h5>
          <table>
            <tr>
              <td>
                <p>Denumirea operatorului de date</p>
              </td>
              <td>
                <p>
                  <strong>UNICIMOB CONSTRUCT SRL</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Sediul operatorului de date</p>
              </td>
              <td>
                <p>Oradea, str. Sovata, nr.40, bl.Q4, ap.9</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  Informații de contact ale Operatorului de date, precum adresa
                  de e-mail folosită regulat pentru a contacta utilizatorii
                </p>
              </td>
              <td>
                <p style={{ color: "#70737B" }}>suport@romstock.ro</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Nr. Registrul comerțului</p>
              </td>
              <td>
                <p>
                  <strong>J5/1878/2018</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Cod fiscal</p>
              </td>
              <td>
                <p>
                  <strong>39704532</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Denumirea autorității de înregistrare</p>
              </td>
              <td>
                <p>Oficiul Registrul Comerțului pe lângă Tribunalul Bihor</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>Pagină web</p>
              </td>
              <td>
                <a href="www.romstock.ro">www.romstock.ro</a>
              </td>
            </tr>
            <tr>
              <td>
                <p>Limba de redactare a contractului</p>
              </td>
              <td>
                <p>română</p>
              </td>
            </tr>
          </table>

          <h5>
            <strong>Ce este un modul cookie?</strong>
          </h5>
          <p>
            Un „Internet Cookie” (termen cunoscut şi ca „browser cookie”, „HTTP
            cookie” sau pur şi simplu „cookie”) este un fişier de mici
            dimensiuni, format din litere şi cifre, care va fi stocat pe
            computerul, terminalul mobil sau alte echipamente ale unui
            utilizator de pe care se accesează Internetul. Cookie-ul este
            instalat prin solicitarea emisă de către un web-server unui browser
            (ex: Internet Explorer, Chrome) şi este complet „pasiv” (nu contine
            programe software, virusi sau spyware si nu poate accesa
            informatiile de pe hard driveul utilizatorului).
          </p>

          <h5>
            <strong>Scopul și utilizarea modulelor cookie.</strong>
          </h5>
          <p>
            ROMSTOCK.RO caută constant metode prin care să îmbunătățească
            site-ul, experiența utilizatorului precum și serviciile oferite
            cumpărătorilor.{" "}
          </p>
          <p>
            „Cookie”-urile joacă un rol important în facilitarea accesului şi
            livrării multiplelor servicii de care utilizatorul se bucură pe
            Internet, cum ar fi:
            <li style={{ marginLeft: "1%" }}>
              Personalizarea anumitor setări precum:
              <p style={{ margin: "0% 1%" }}>
                – limba în care este vizualizat un site;
              </p>
              <p style={{ margin: "0% 1%" }}>
                – moneda în care se exprimă anumite preţuri sau tarife;
              </p>
              <p style={{ margin: "0% 1%" }}>
                – păstrarea opţiunilor pentru diverse produse (măsuri, alte
                detalii etc.) în coşul de cumpărături (şi memorarea acestor
                opţiuni) – generându-se astfel flexibilitatea „coşului de
                cumpărături”;
              </p>
              <p style={{ margin: "0% 1%" }}>
                – accesarea preferinţelor vechi prin accesarea butonului
                „Înainte” şi „Înapoi”.
              </p>
            </li>
            <li style={{ marginLeft: "1%", marginTop: "0.5%" }}>
              Cookie-urile oferă deţinatorilor de site-uri feedback asupra
              modului cum sunt utilizate site-urile lor de către utilizatori,
              astfel încât să le poată face şi mai eficiente şi mai accesibile
              pentru utilizatori;
            </li>
            <li style={{ marginLeft: "1%", marginTop: "0.5%" }}>
              Permit aplicaţiilor multimedia sau de alt tip de pe alte site-uri
              să fie incluse într-un anumit site pentru a crea o experienţă de
              navigare mai valoroasă, mai utilă şi mai placută;
            </li>
            <li style={{ marginLeft: "1%", marginTop: "0.5%" }}>
              Îmbunătăţesc eficienţa publicităţii online
            </li>
          </p>
          <p>
            Cookie-urile pe care utilizatorul le permite a fi utilizate pot fi
            selectate prin intermediul unui ”cookie” bar regăsit în partea de
            jos a site-ului, tipurile de cookie și datele pe care acestea le
            colectează aceste module fiind cât se poate de transparent și
            accesibil utilizatorului.
          </p>

          <p>
            Dacă preferați, aveți posibilitatea de a bloca toate modulele cookie
            sau doar pe unele dintre acestea ori chiar de a elimina module
            cookie care au fost instalate pe terminalul dumneavoastră. Fiți însă
            conștient de faptul că riscați să nu puteți utiliza anumite funcții.
            Pentru a activa acest blocaj, trebuie să modificați parametrii de
            confidențialitate ai browserului. Unii operatori terți au elaborat
            instrumente ale căror module permit să se dezactiveze culegerea și
            utilizarea datelor. Dezactivarea şi refuzul de a primi cookie-uri
            pot face anumite site-uri impracticabile sau dificil de vizitat şi
            folosit.
          </p>

          <p>
            De asemenea, refuzul de a accepta cookie-uri nu înseamnă că
            utilizatorul nu va mai primi / vedea publicitate online. Este
            posibilă reglarea din browser pentru ca aceste cookie-uri să nu mai
            fie acceptate, sau se poate regla browserul să accepte cookie-uri de
            la un anumit website. Dar, de exemplu, daca un utilizator nu este
            înregistat folosind cookie-urile, nu va putea lăsa comentarii. Toate
            browserele moderne oferă posibilitatea de a schimba reglajele
            cookie-urilor.
          </p>

          <p>
            Aceste reglaje se găsesc, de regulă, în meniurile (butoanele)
            „Opţiuni”, „Reglaje” sau „Preferinţe” ale browserului.
          </p>

          <h5>
            <strong>Care este durata de viaţă a unui cookie?</strong>
          </h5>
          <p>
            Cookie-urile sunt administrate de webservere. Durata de viaţă a unui
            cookie poate varia semnificativ, depinzând de scopul pentru care
            este plasat. Unele cookie-uri sunt folosite exclusiv pentru o
            singură sesiune (session cookies) şi nu mai sunt reţinute, odată ce
            utilizatorul a părăsit website-ul, în timp ce alte cookie-uri sunt
            reţinute şi refolosite de fiecare dată când utilizatorul revine pe
            acel website (‘cookie-uri persistente’). Cu toate acestea,
            cookie-urile pot fi şterse de un utilizator în orice moment prin
            intermediul setărilor browserului.
          </p>

          <h5>
            <strong>Cookie-urile și securitatea datelor.</strong>
          </h5>
          <p>
            Cookie-urile NU sunt viruşi! Ele folosesc formate tip plain text. Nu
            sunt alcătuite din bucăţi de cod, aşa că nu pot fi executate, nici
            nu pot rula în mod automat. În consecinţă, nu se pot duplica sau
            replica pe alte reţele pentru a rula sau a se replica din nou.
            Deoarece nu pot îndeplini aceste funcţii, nu pot fi considerate
            viruşi.{" "}
          </p>
          <p>
            Cookie-urile pot fi, totuşi, folosite pentru scopuri negative.
            Deoarece stochează informaţii despre preferinţele şi istoricul de
            navigare al utilizatorilor, atât pe un anume website cât şi pe alte
            website-uri, cookie-urile pot fi folosite ca o formă de Spyware.
            Multe produse anti-spyware sunt conştiente de acest fapt şi, în mod
            constant, marchează cookie-urile pentru a fi şterse, în cadrul
            procedurilor de ştergere/scanare anti-virus/anti-spyware. În
            general, browserele au integrate reglaje de confidenţialitate, care
            furnizează diferite nivele de acceptare a cookie-urilor, perioada de
            valabilitate şi ştergere automată după ce utilizatorul a vizitat un
            anumit site.
          </p>

          <h5>
            <strong>Ce tipuri de cookies utilizează ROMSTOCK.RO?</strong>
          </h5>
          <p>
            Modulele cookie pot să fie temporare, caz în care se mențin numai pe
            durata sesiunii de navigare pe site sau permanente, care se mențin
            mai mult timp, în funcție de intervalul alocat și parametrii
            browserului. Există numeroase tipuri de module cookie, împărțite în
            cele patru categorii de mai jos.
          </p>
          <h5 style={{ margin: "2%" }}>
            <strong>• Modulele cookie strict necesare</strong>
          </h5>
          <p style={{ marginLeft: "2%" }}>
            Modulele cookie strict necesare sunt indispensabile, funcționarea
            site-ului fiind imposibilă fără acestea. Dezactivarea modulelor
            cookie poate să ducă la apariția dificultăților grave la utilizarea
            site-ului sau chiar la imposibilitatea utilizării serviciilor sale.
            Modulele cookie strict necesare nu stochează informații după
            părăsirea site-ului. Acestea permit, de exemplu, identificarea
            dispozitivelor pentru realizarea comunicării, numerotarea
            „pachetelor” de date pentru trimiterea acestora în ordinea necesară
            și detectarea erorilor de transmisie sau a pierderilor de date.
          </p>

          <h5 style={{ margin: "2%" }}>
            <strong>• Modulele cookie funcționale</strong>
          </h5>
          <p style={{ marginLeft: "2%" }}>
            Modulele cookie funcționale sunt folosite exclusiv pentru a pune la
            dispoziția utilizatorului, la solicitarea acestuia, a unui serviciu
            electronic de comunicare. Dezinstalarea acestor module cookie duce
            la imposibilitatea punerii la dispoziție a serviciului respectiv.
            Aceste informații sunt stocate și după încheierea sesiunii de
            navigare pe site și pot să fie transmise partenerilor, cu unicul
            scop ca aceștia să ia măsurile necesare pentru punerea la dispoziție
            a serviciilor. De exemplu, poate să fie vorba despre modulele cookie
            care salvează preferințele utilizatorului, precum limba utilizată,
            cele care salvează o „sesiune a utilizatorului” sau cele necesare
            pentru „coșul de cumpărături virtual”.
          </p>

          <h5 style={{ margin: "2%" }}>
            <strong>
              • Modulele cookie pentru analiză și statistici de audiență
            </strong>
          </h5>
          <p style={{ marginLeft: "2%" }}>
            Modulele cookie pentru măsurarea audienței permit recunoașterea
            vizitatorilor site-ului web la următoarea vizită. Acestea stochează
            numai numele de utilizator (specific fiecărui modul cookie) și nu
            colectează date cu caracter personal ale vizitatorului. Acestea
            salvează paginile vizitate, durata fiecărei vizite și mesajele de
            eroare afișate; toate acestea ajutând la îmbunătățirea performanței
            site-ului nostru. Modulele cookie pentru analiză pot să fie
            instalate și gestionate de parteneri; cu toate acestea, noi impunem
            utilizarea acestora strict în scopul obținerii statisticilor
            analitice necesare.
          </p>

          <h5 style={{ margin: "2%" }}>
            <strong>
              • Modulele cookie de urmărire și cele generate de plugin-urile
              rețelelor sociale
            </strong>
          </h5>
          <p style={{ marginLeft: "2%" }}>
            Modulele cookie de urmărire permit terților să furnizeze servicii,
            în primul rând de publicitate și să își sporească eficiența. Aceste
            module cookie pot să rețină paginile și site-urile web vizitate și
            să colecteze date cu caracter personal, în primul rând adresa IP a
            dispozitivului folosit pentru navigarea pe internet. Informațiile
            colectate pot să fie distribuite și terților.
          </p>
          <p style={{ marginLeft: "2%" }}>
            Acesta este cazul, de exemplu, al modulelor cookie care permit
            companiilor de publicitate să propună reclame personalizate adaptate
            centrelor dumneavoastră de interes în funcție de istoricul navigării
            pe site-ul ROMSTOCK.RO sau care limitează repetarea acestora. Pentru
            aceste module cookie este nevoie să vă dați acordul. În acest caz,
            pe prima pagină a site-ului va apărea un banner în care vi se va
            cere acordul pentru instalarea acestora.
          </p>
          <p style={{ marginLeft: "2%" }}>
            ROMSTOCK.RO folosește și ceea ce numim plugin-uri sociale care iau
            forma instrumentelor de partajare, asociind informații prin rețele
            sociale precum Facebook, Linkedin sau YouTube. În timpul vizitei
            dumneavoastră pe site-ul nostru web, aceste plugin-uri sociale pot
            să fie dezactivate implicit, adică nu transmit informații rețelelor
            sociale relevante fără o intervenție din partea dumneavoastră.
            Înainte de a folosi aceste plugin-uri, trebuie să le activați cu un
            clic. Aceste plugin-uri rămân activate numai până când le
            dezactivați din nou sau ștergeți modulele cookie relevante. Veți
            găsi informații mai ample despre modul de dezactivare a acestor
            plugin-uri sau de ștergere a modulelor cookie în secțiunile de mai
            sus ale acestei politici. După activare, sistemul stabilește o
            legătură directă cu rețeaua socială aleasă pentru partajarea
            conținutului relevant. Conținutul partajat prin plugin este apoi
            transferat direct din rețeaua socială pe browserul dumneavoastră de
            internet care îl integrează în site-ul web vizitat.
          </p>
          <p style={{ marginLeft: "2%" }}>
            După ce ați activat plugin-ul, rețeaua socială relevantă poate să
            colecteze date, indiferent dacă folosiți sau nu efectiv acest
            plugin. Dacă sunteți conectat la o rețea socială, este în măsură să
            asocieze vizita dumneavoastră cu site-ul web prezent și contul de
            utilizator din rețea. Vizitarea altor site-uri web nu va fi asociată
            prezentei vizite și contului dumneavoastră de utilizator din rețeaua
            socială atât timp cât nu ați activat plugin-ul corespunzător pe
            aceste site-uri.{" "}
          </p>
          <p style={{ marginLeft: "2%" }}>
            Dacă sunteți membru al unei rețele sociale și nu doriți ca această
            rețea să stabilească o legătură între datele colectate în timpul
            vizitei și informațiile salvate în timpul înregistrării, este
            necesar să deconectați sesiunea de utilizator de la rețea înainte de
            a activa plugin-urile.
          </p>
          <p style={{ marginLeft: "2%" }}>
            Nu putem să influențăm sau să decidem în legătură cu volumul și
            natura datelor colectate de rețeaua socială prin aceste plugin-uri.
            Pentru a înțelege volumul și natura datelor procesate și finalitatea
            procesării, precum și mijloacele de procesare și folosirea datelor
            de către rețeaua socială, precum și drepturile și opțiunile de
            control aflate la dispoziția dumneavoastră pentru a vă proteja viața
            privată, citiți mențiunile și informațiile juridice legate de
            protecția datelor cu caracter personal publicate de rețelele sociale
            relevante.
          </p>

          <p style={{ marginTop: "3%" }}>
            Site-urile noastre web tind să folosească plugin-urile următoarelor
            rețele sociale:
          </p>
          <li>
            facebook.com, generat de Facebook Inc., 1601 S. California Ave, Palo
            Alto, CA 94304, SUA („Facebook”). Veți găsi o listă și o descriere a
            plugin-urilor puse la dispoziție de Facebook la următoarea adresă:
            <a href="https://developers.facebook.com/docs/pluginsPentru">
              {" "}
              https://developers.facebook.com/docs/plugins
            </a>
            . Pentru mai multe informații privind politica Facebook referitoare
            la protecția datelor cu caracter personal, vizitați:{" "}
            <a href="http://www.facebook.com/policy.php">
              http://www.facebook.com/policy.php
            </a>
            . De asemenea, puteți să blocați aceste plugin-uri cu add-on-urile
            specifice browserului dumneavoastră, precum „Facebook Blocker”.{" "}
          </li>

          <li style={{ marginTop: "1.5%" }}>
            Youtube, generat de Google Inc., cu sediul in 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, SUA. Pentru mai multe informații
            privind protecția datelor cu caracter personal, consultati:{" "}
            <a href="https://www.youtube.com/static?template=privacy_guidelines">
              {" "}
              https://www.youtube.com/static?template=privacy_guidelines
            </a>{" "}
          </li>

          <li style={{ marginTop: "1.5%", marginBottom: "3%" }}>
            Google + generat de Google Inc., cu sediul in 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA. Pentru mai multe informatii
            privind protectia datelor cu caracter personal, consultati:
            <a href="https://www.google.ro/intl/ro/policies/privacy/">
              {" "}
              https://www.google.ro/intl/ro/policies/privacy/
            </a>
          </li>
          <h5>
            <strong>Cum pot fi oprite cookie-urile?</strong>
          </h5>
          <p>
            Dezactivarea și refuzul de a primi cookie-uri pot face Pagina de
            Internet dificil de vizitat, atrăgând după sine limitări ale
            posibilităților de utilizare ale acesteia.
          </p>
          <p>
            Utilizatorii își pot configura browserul să respingă fișierele
            cookie sau să fie acceptate cookie-uri de la o pagină de internet
            anume.
          </p>
          <p>
            Toate browserele moderne oferă posibilitatea de a schimba setările
            cookie-urilor. Aceste setări pot fi accesate, ca regulă, în
            secțiunea „opțiuni” sau în meniul de „preferințe” al browserului
            tău.
          </p>
          <p>
            De asemenea, utilizatorii au posibilitatea să dezactiveze fișierele
            cookie (cu excepția celor strict necesare pentru funcționarea
            Paginii de Internet) prin intermediul serviciului OneTrust astfel:
          </p>
          <ol>
            <li style={{ marginLeft: "5%" }}>
              prin accesarea opțiunii „Configurare cookies” inclusă în bannerul
              prin care este semnalată utilizarea fișierelor cookie, afișat la
              prima accesare a Paginii de Internet
            </li>
            <li style={{ marginLeft: "5%" }}>
              prin accesarea opțiunii „Configurare cookies” disponibilă în josul
              Paginii de Internet.
            </li>
          </ol>
          <p>
            Totuși, refuzarea sau dezactivarea cookie-urilor nu înseamnă că nu
            veți mai primi publicitate online – ci doar ca aceasta nu va fi
            adaptată preferințelor și interesele dumneavoastră, evidențiate prin
            comportamentul de navigare.
          </p>
          <p>
            Din perspectiva înțelegerii acestor setări, următoarele linkuri pot
            fi folositoare:
          </p>
          <p>
            <a
              href="https://support.microsoft.com/ro-ro/topic/ștergerea-și-gestionarea-modulelor-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Internet Explorer
            </a>
          </p>
          <p>
            <a
              href="https://answers.microsoft.com/en-us/windows/forum/apps_windows_10-msedge/edge-how-do-i-enable-cookies/275b58e3-d741-4b21-8042-3059b1902d0e?auth=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Microsoft Edge
            </a>
          </p>
          <p>
            <a
              href="https://support.mozilla.org/ro/kb/activarea-si-dezactivarea-cookie-urilor"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Firefox
            </a>
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=ro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Chrome pentru Desktop
            </a>
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=ro"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Chrome pentru Android
            </a>
          </p>
          <p>
            <a
              href="https://support.apple.com/ro-ro/HT201265"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Safari pe iPhone, iPod Touch sau iPad
            </a>
          </p>
          <p>
            <a
              href="https://support.apple.com/ro-ro/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noopener noreferrer"
            >
              Setări cookie în Safari pe MacBook
            </a>
          </p>
          <p>
            Monitorizarea respectării cerințelor legale în acest domeniu este
            asigurată inclusiv de către responsabilul cu protecția datelor
            desemnat în cadrul RomStock şi care poate fi contactat la următoarea
            adresă de email: suport@romstock.ro
          </p>
        </div>
      </div>
    </>
  );
};

export default PoliticaCookie;
