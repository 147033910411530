import React from "react"
import { Pagination } from "react-bootstrap"
import { connect } from "react-redux"
import { nextPageA, prevPageA, setNextPageA, setLastPageA, setPrevPageA, setFirstPageA } from "../../../redux/search/search.actions"

const AdminProductsPagination = ({ currentPageNumber, numberOfPages, nextPageA, prevPageA, setNextPageA, setLastPageA, setPrevPageA, setFirstPageA }) => {
    return (
        <Pagination>
            {currentPageNumber > 2 ? <Pagination.First onClick={() => { if (currentPageNumber !== 1) { setFirstPageA(); window.scrollTo(0, 0) } }} /> : <></>}
            {currentPageNumber !== 1 ? <Pagination.Prev onClick={() => { if (currentPageNumber !== 1) { prevPageA(); window.scrollTo(0, 0) } }} /> : <></>}
            {currentPageNumber > 4 ? <Pagination.Ellipsis className="ellipsis" /> : <></>}
            {currentPageNumber > 2 ? <Pagination.Item onClick={() => { setPrevPageA(); window.scrollTo(0, 0) }}>{currentPageNumber - 2}</Pagination.Item> : <></>}
            {currentPageNumber > 1 ? <Pagination.Item onClick={() => { prevPageA(); window.scrollTo(0, 0) }}>{currentPageNumber - 1}</Pagination.Item> : <></>}
            <Pagination.Item active>{currentPageNumber}</Pagination.Item>
            {currentPageNumber !== numberOfPages ? <Pagination.Item onClick={() => { nextPageA(); window.scrollTo(0, 0) }}>{currentPageNumber + 1}</Pagination.Item> : <></>}
            {currentPageNumber !== numberOfPages && currentPageNumber !== numberOfPages - 1 ? <Pagination.Item onClick={() => { setNextPageA(); window.scrollTo(0, 0) }}>{currentPageNumber + 2}</Pagination.Item> : <></>}
            {currentPageNumber < numberOfPages - 3 ? <Pagination.Ellipsis className="ellipsis" /> : <></>}
            {currentPageNumber !== numberOfPages ? <Pagination.Next onClick={() => { if (currentPageNumber !== numberOfPages) { nextPageA(); window.scrollTo(0, 0) } }} /> : <></>}
            {currentPageNumber < numberOfPages - 1 ? <Pagination.Last onClick={() => { if (currentPageNumber !== numberOfPages) { setLastPageA(); window.scrollTo(0, 0) } }} /> : <></>}
        </Pagination>
    )
}
const mapDispatchToProps = dispatch => ({
    nextPageA: () => dispatch(nextPageA()),
    prevPageA: () => dispatch(prevPageA()),
    setNextPageA: () => dispatch(setNextPageA()),
    setPrevPageA: () => dispatch(setPrevPageA()),
    setFirstPageA: () => dispatch(setFirstPageA()),
    setLastPageA: () => dispatch(setLastPageA())
})

const mapStateToProps = state => ({
    currentPageNumber: state.search.currentPageNumber,
    numberOfPages: state.search.numberOfPages
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminProductsPagination)