import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Promotions.scss";
import ProductCard from "../../components/productCard/ProductCard";
import {
  getPromotionProductsRequest,
  getPromotionCategoriesRequest,
  setFirstPage,
} from "../../redux/promotions/promotions.actions";
import { connect } from "react-redux";
import SEO from "../../components/SEO/SEO";
import {
  encodeProductCode,
  replaceNameSingleProduct,
} from "../../utilities/helpers";
import PromotionsPagination from "./PromotionsPagination";
import { useState } from "react";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import { usePromotionsHelper } from "./usePromotionsHelper";
import { Spinner } from "react-bootstrap";

const Promotions = ({
  getPromotionProductsRequest,
  getPromotionCategoriesRequest,
  products,
  categories,
  currentPage,
  resetPage,
  isLoading,
}) => {
  const { getCategoryImage } = usePromotionsHelper();
  const [selectedCategory, setSelectedCategory] = useState();
  const { search } = useLocation();

  useEffect(() => {
    getPromotionCategoriesRequest();
  }, []);

  useEffect(() => {
    if (categories && categories.length) {
      const params = new URLSearchParams(search);
      const category = decodeURIComponent(params.get("category"));
      if (!category || category === "null") {
        window.location.search = `?category=${categories[0].split("::")[0]}`;
      } else {
        for (const c of categories) {
          const split = c.split(category);
          if (split.length > 1 && split[0] === "") {
            setSelectedCategory(category);
            break;
          }
        }
      }
    }
  }, [categories, search]);

  useEffect(() => {
    if (selectedCategory) {
      getPromotionProductsRequest({ currentPage, category: selectedCategory });
    }
  }, [selectedCategory, currentPage]);

  return (
    <>
      <SEO
        title="Promotions"
        description="Peste 12.000 de produse in stoc. Casa si gradina, electronice si echipamente de protectie. O gama variata la preturi mici, accesibile."
        robots="index, follow"
        canonical="https://romstock.ro/promotions"
        openGraph={{
          url: "https://www.romstock.ro/promotions",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description:
            "Peste 12.000 de produse in stoc. Casa si gradina, electronice si echipamente de protectie. O gama variata la preturi mici, accesibile.",
        }}
      />

      <div className="main-div-promotions">
        <h1 className="label-title">Promoțiile lunii</h1>

        {/* Categories */}
        <div style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
          {categories
            .map((c) => c.split("::")?.[0])
            .filter((c, idx, list) => list.indexOf(c) === idx)
            .map((category, idx) => (
              <Link
                key={idx}
                to={`promotions?category=${encodeURIComponent(category)}`}
                style={{
                  outline: "none",
                  border: "none",
                  textDecoration: "none",
                  color: "black",
                  backgroundColor: "transparent",
                  textTransform: "capitalize",
                  borderBottom:
                    category === selectedCategory
                      ? "2px solid #b20b0b"
                      : "none",
                }}
                onClick={() => {
                  setSelectedCategory(category);
                  resetPage();
                }}
              >
                {category}
              </Link>
            ))}
        </div>

        {/* Products for a category */}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <>
            {/* Category cards */}
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                marginTop: "10px",
              }}
            >
              {categories
                .filter((c) => c.includes(selectedCategory))
                .map(
                  (c) =>
                    c
                      ?.split(selectedCategory)?.[1]
                      ?.replace("::", "")
                      ?.split("::")?.[0]
                )
                .filter((c) => !!c)
                .filter((c, idx, list) => list.indexOf(c) === idx) // removes duplicates
                .map((c) => (
                  <Link
                    to={`/promotions?category=${encodeURIComponent(
                      `${decodeURIComponent(
                        window.location.search.replace("?category=", "")
                      )}::${c}`
                    )}`}
                    onClick={() => resetPage()}
                  >
                    <CategoryCard
                      text={c}
                      image={getCategoryImage(
                        decodeURIComponent(
                          `${window.location.search.replace(
                            "?category=",
                            ""
                          )}::${c}`
                        )
                      )}
                    />
                  </Link>
                ))}
            </div>

            <div className="products-list">
              {products.map((r, index) => {
                return (
                  <Link
                    key={index}
                    to={
                      "/" +
                      replaceNameSingleProduct(r.name) +
                      "/" +
                      encodeProductCode(r.code)
                    }
                  >
                    <ProductCard
                      key={r.code}
                      index={index}
                      isInWishlist={r.isInWishlist}
                      currentPrice={r.currentPrice}
                      labelType={
                        r.stockStatus === "REDUCED_STOCK"
                          ? "stoc redus"
                          : r.stockStatus === "IN_STOC"
                          ? "în stoc"
                          : "indisponibil"
                      }
                      product={r.imageUrl}
                      code={r.code}
                      oldPrice={String(r.oldPrice).split(/[\s.]+/)[0]}
                      oldPriceSup={
                        String(
                          (Math.round(r.oldPrice * 100) / 100).toFixed(2)
                        ).split(/[\s.]+/)[1]
                      }
                      oldType={"RON"}
                      price={String(r.currentPrice).split(/[\s.]+/)[0]}
                      priceSup={
                        String(
                          (Math.round(r.currentPrice * 100) / 100).toFixed(2)
                        ).split(/[\s.]+/)[1]
                      }
                      type={"RON"}
                      text={r.name}
                      numberOfStars1={r.numberOfStars1}
                      numberOfStars2={r.numberOfStars2}
                      numberOfStars3={r.numberOfStars3}
                      numberOfStars4={r.numberOfStars4}
                      numberOfStars5={r.numberOfStars5}
                      discount={r.discount}
                      legalGuarantee={12}
                      commercialGuarantee={24}
                      brand={r.brand}
                      weight={r.weight}
                    />
                  </Link>
                );
              })}
            </div>
            {products.length > 0 && (
              <div
                className="products-pagination"
                style={{ width: "100%", marginTop: "2%" }}
              >
                <PromotionsPagination />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPromotionProductsRequest: (payload) =>
    dispatch(getPromotionProductsRequest(payload)),
  getPromotionCategoriesRequest: (payload) =>
    dispatch(getPromotionCategoriesRequest(payload)),
  resetPage: () => dispatch(setFirstPage()),
});
const mapStateToProps = (state) => ({
  categories: state.promotions.categories,
  products: state.promotions.products,
  currentPage: state.promotions.currentPage,
  isLoading: state.promotions.isLoading,
});
export default connect(mapStateToProps, mapDispatchToProps)(Promotions);
