export const includesObject = (array, nameToFind) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i].value === nameToFind) return true;
  }
  return false;
};

export const replaceNameSingleProduct = (name) => {
  return name
    .replace(/[\/%\s><&]+/g, "-")
    .replace(/[",().=+*$;@#]/g, "")
    .replace(/---/g, "-")
    .replace(/--/g, "-")
    .toLowerCase();
};

export const encodeProductCode = (code) => {
  return code.replace(/[\/]+/g, "_").replace(/[\s]+/g, "!").toLowerCase();
};

export const decodeProductCode = (code) => {
  return code.replace(/_/g, "/").replace(/!/g, " ").toUpperCase();
};

export const replaceCategory = (name) => {
  return name.replace(/[\s]+/g, "-");
};

export const decodeCategory = (name) => {
  return name.replace(/-/g, " ");
};
