export const GET_PROMOTION_PRODUCTS_REQUEST = "GET_PROMOTION_PRODUCTS_REQUEST";
export const GET_PROMOTION_CATEGORIES_REQUEST =
  "GET_PROMOTION_CATEGORIES_REQUEST";
export const GET_PROMOTION_PRODUCTS_SUCCESS = "GET_PROMOTION_PRODUCTS_SUCCESS";
export const GET_PROMOTION_CATEGORIES_SUCCESS =
  "GET_PROMOTION_CATEGORIES_SUCCESS";

export const SET_NEXT_PAGE_PROMOTION = "SET_NEXT_PAGE_PROMOTION";
export const SET_PREV_PAGE_PROMOTION = "SET_PREV_PAGE_PROMOTION";
export const SET_FIRST_PAGE_PROMOTION = "SET_FIRST_PAGE_PROMOTION";
export const SET_LAST_PAGE_PROMOTION = "SET_LAST_PAGE_PROMOTION";
export const NEXT_PAGE_PROMOTION = "NEXT_PAGE_PROMOTION";
export const PREV_PAGE_PROMOTION = "PREV_PAGE_PROMOTION";
