import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./account.module.scss";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getRegisterRequest } from "../../../redux/register/register.actions";
import Message from "../../Confirmation/Message";

const CreateAccount = ({ getRegisterRequest, errorRegister, response }) => {
  const { register, errors, handleSubmit, watch, reset } = useForm({});
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [checkNewsletter, setCheckNewsletter] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordConfirmationShown, setPasswordConfirmationShown] =
    useState(false);
  const [messageRequest, setMessageRequest] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordConfirmationVisiblity = () => {
    setPasswordConfirmationShown(passwordConfirmationShown ? false : true);
  };

  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = (data) => {
    getRegisterRequest({
      password: String(data.password),
      firstName: String(data.nume),
      lastName: String(data.prenume),
      email: String(data.email),
    });
    reset();
    setMessageRequest(true);
  };

  useEffect(() => {}, onSubmit);

  return (
    <>
      {messageRequest === false && (
        <div id={styles.container_form} className="container">
          <div className={"form-box " + styles.form_box}>
            <h1 className={styles.title_style}>Creare cont</h1>
            <Row
              style={{
                justifyContent: "center",
                color: "#E04C35",
                marginBottom: "4%",
              }}
            >
              <div
                className="div-line"
                style={{
                  marginTop: "1%",
                  width: "10%",
                  height: "2px",
                  backgroundColor: "#E04C35",
                }}
              ></div>
            </Row>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className={styles.center_block}>
                <Row>
                  <div
                    className={
                      "form-group col-lg-8 col-md-9 col-sm-12 " +
                      styles.row_input_form
                    }
                  >
                    <Col sm={3} xs={3} className="label-group">
                      <label>Nume</label>
                    </Col>
                    <div className="col-sm-10 col-xs-12">
                      <input
                        name="nume"
                        type="text"
                        className={"form-control " + styles.input_style}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      />
                      {errors.nume && (
                        <p className={"form-group " + styles.warning_message}>
                          {errors.nume.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Row>

                <Row>
                  <div
                    className={
                      "form-group col-lg-8 col-md-9 col-sm-12 " +
                      styles.row_input_form
                    }
                  >
                    <Col sm={3} xs={3} className="label-group">
                      <label>Prenume</label>
                    </Col>
                    <div className="col-sm-10 col-xs-12">
                      <input
                        name="prenume"
                        type="text"
                        className={"form-control " + styles.input_style}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      />
                      {errors.prenume && (
                        <p className={"form-group " + styles.warning_message}>
                          {errors.prenume.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Row>

                <Row>
                  <div
                    className={
                      "form-group col-lg-8 col-md-9 col-sm-12 " +
                      styles.row_input_form
                    }
                  >
                    <Col sm={3} xs={3} className="label-group">
                      <label>Email</label>
                    </Col>
                    <div className="col-sm-10 col-xs-12">
                      <input
                        name="email"
                        type="email"
                        className={"form-control " + styles.input_style}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Emailul introdus nu este valid",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className={"form-group " + styles.warning_message}>
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Row>

                <Row>
                  <div
                    className={
                      "form-group col-lg-8 col-md-9 col-sm-12 " +
                      styles.row_input_form
                    }
                  >
                    <Col sm={3} xs={6} className="label-group">
                      <label>Parolă</label>
                    </Col>
                    <div className="col-sm-10 col-xs-12">
                      <input
                        name="password"
                        type={passwordShown ? "text" : "password"}
                        className={"form-control " + styles.input_style}
                        style={{ position: "relative" }}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                          minLength: {
                            value: 6,
                            message:
                              "Parola trebuie să conțină cel puțin 6 caractere",
                          },
                        })}
                      />
                      <span className={styles.span_eye}>
                        {passwordShown ? (
                          <i
                            className="fa fa-eye"
                            aria-hidden="true"
                            onClick={togglePasswordVisiblity}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash"
                            aria-hidden="true"
                            onClick={togglePasswordVisiblity}
                          ></i>
                        )}
                      </span>
                      {errors.password && (
                        <p className={"form-group " + styles.warning_message}>
                          {errors.password.message}
                        </p>
                      )}
                    </div>
                  </div>
                </Row>
                <Row>
                  <div
                    className={
                      "form-group col-lg-8 col-md-9 col-sm-12 " +
                      styles.row_input_form
                    }
                  >
                    <Col sm={3} xs={6} className="label-group">
                      <label>Confirmare parolă</label>
                    </Col>
                    <div className="col-sm-10 col-xs-12">
                      <input
                        name="password_repeat"
                        type={passwordConfirmationShown ? "text" : "password"}
                        className={"form-control " + styles.input_style}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                          validate: (value) =>
                            value === password.current ||
                            "Parolele nu se potrivesc",
                        })}
                      />
                      <span className={styles.span_eye}>
                        {passwordConfirmationShown ? (
                          <i
                            className="fa fa-eye"
                            aria-hidden="true"
                            onClick={togglePasswordConfirmationVisiblity}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-eye-slash"
                            aria-hidden="true"
                            onClick={togglePasswordConfirmationVisiblity}
                          ></i>
                        )}
                      </span>
                      {errors.password_repeat && (
                        <p className={"form-group " + styles.warning_message}>
                          {errors.password_repeat.message}
                        </p>
                      )}
                      {errorRegister && (
                        <p className={"form-group " + styles.warning_message}>
                          {errorRegister}
                        </p>
                      )}
                    </div>
                  </div>
                </Row>
              </div>
              <Row
                style={{ width: "50%", margin: "auto" }}
                className={styles.terms}
              >
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="defaultUncheckedTerms"
                    name="defaultExampleRadios"
                    style={{ width: "0.5rem" }}
                    onClick={() => setCheckTerms(!checkTerms)}
                  />
                  <label
                    className={
                      "custom-control-label " +
                      styles.radio_label +
                      " " +
                      styles.terms_label
                    }
                    for="defaultUncheckedTerms"
                  >
                    Sunt de acord cu
                  </label>
                  <Link
                    style={{ color: "#2593E7" }}
                    className={styles.terms_label}
                    to="/terms"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    Termenii și condițiile
                  </Link>
                </div>
              </Row>
              <Row
                style={{ width: "50%", margin: "auto" }}
                className={styles.terms}
              >
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="defaultUncheckedPolicy"
                    name="defaultExampleRadios"
                    style={{ width: "0.5rem" }}
                    onClick={() => setCheckPolicy(!checkPolicy)}
                  />
                  <label
                    className={
                      "custom-control-label " +
                      styles.radio_label +
                      " " +
                      styles.terms_label
                    }
                    for="defaultUncheckedPolicy"
                  >
                    Sunt de acord cu
                  </label>
                  <Link
                    style={{ color: "#2593E7" }}
                    className={styles.terms_label}
                    to="/privacy-policy"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {" "}
                    Politica de confidențialitate
                  </Link>
                </div>
              </Row>
              <Row
                style={{ width: "50%", margin: "auto" }}
                className={styles.terms}
              >
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="defaultUncheckedNews"
                    name="defaultExampleRadios"
                    style={{ width: "0.5rem" }}
                    onClick={() => setCheckNewsletter(!checkNewsletter)}
                  />
                  <label
                    className={
                      "custom-control-label " +
                      styles.radio_label +
                      " " +
                      styles.terms_label
                    }
                    for="defaultUncheckedNews"
                  >
                    Vreau să mă abonez la newsletter
                  </label>
                </div>
              </Row>
              <Row>
                <span className={styles.connect}>
                  <p style={{ color: "#484B51" }}>Ai deja cont? </p>
                  <Link
                    to="/login"
                    style={{ color: "#2593E7", marginLeft: "5px" }}
                  >
                    Conectează-te
                  </Link>
                </span>
              </Row>

              <Row className={styles.button_row}>
                <Button
                  type="submit"
                  className={styles.button_style}
                  disabled={
                    true
                      ? (!checkTerms && !checkPolicy) ||
                        !checkTerms ||
                        !checkPolicy
                      : false
                  }
                  onClick={handleSubmit(onSubmit)}
                >
                  Crează cont
                </Button>
              </Row>
            </form>
          </div>
        </div>
      )}
      {messageRequest === true && <Message response={response} />}
    </>
  );
};

const mapStateToProps = (state) => ({
  errorRegister: state.registerAccount.errorRegister,
  isLoading: state.registerAccount.isLoading,
  user: state.registerAccount.user,
  response: state.registerAccount.response,
});

const mapDispatchToProps = (dispatch) => ({
  getRegisterRequest: (payload) => dispatch(getRegisterRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
