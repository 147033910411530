import React, { useMemo, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Button, CloseButton, Spinner } from "react-bootstrap";
import "./MiniCart.scss";
import { Link } from "react-router-dom";
import {
  removeFromCart,
  updateCartQuantityRequest,
} from "../../redux/cart/cart.actions";
import {
  replaceNameSingleProduct,
  encodeProductCode,
} from "../../utilities/helpers";

const MiniCart = ({
  cartProducts,
  removeFromCart,
  setShowCart,
  user,
  updateCartQuantityRequest,
  isLoading,
  showCart,
}) => {
  const modalRef = useRef(null);

  const totalPrice = useMemo(() => {
    const productsSum = cartProducts.reduce((acc, current) => {
      const price =
        (current.price || current.price === 0) && !current.currentPrice
          ? (Number(current.price) +
              Number(current.priceSup) /
                Math.pow(10, parseFloat(String(current.priceSup).length))) *
            current.quantity
          : Number(current.currentPrice) * current.quantity;
      const sumAcc = Number(acc) + Number(price);
      return sumAcc.toFixed(2);
    }, 0);

    return productsSum;
  }, [cartProducts]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (!("path" in MouseEvent.prototype))
        Object.defineProperty(MouseEvent.prototype, "path", {
          get: function () {
            var path = [];
            var currentElem = this.target;
            while (currentElem) {
              path.push(currentElem);
              currentElem = currentElem.parentElement;
            }
            if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
              path.push(document);
            if (path.indexOf(window) === -1) path.push(window);
            return path;
          },
        });
      if (showCart) {
        if (modalRef.current) {
          const classNames = event.path.map((path) => path.className);
          if (!classNames.includes(modalRef.current.className)) {
            setShowCart(!showCart);
          }
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCart]);

  return (
    <div ref={modalRef} className="my-minicart">
      <h1>Coșul meu</h1>
      {!cartProducts.length ? (
        <div className="empty-minicart">Coșul este gol!</div>
      ) : (
        <div className="my-minicart_container">
          <div className="my-minicart_products">
            <div className="my-minicart_product my-minicart_product-header">
              <span>Produs</span>
              <span className="my-minicart_product-quantity my-minicart_product-header-quantity">
                Cantitate
              </span>
              <span className="my-minicart_product-price">Preț</span>
              <span className="my-minicart_product-space"></span>
            </div>
            {cartProducts.map((product) => {
              if (
                isLoading === product.code ||
                isLoading === product.productCode
              )
                return (
                  <div className="mini-cart-spinner">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                );

              return (
                <div className="my-minicart_product">
                  <img
                    src={product.image || product.productImage}
                    alt={product.name || product.productName + "-icon"}
                  />
                  <Link
                    to={
                      "/" +
                      (product.name
                        ? replaceNameSingleProduct(product.name)
                        : replaceNameSingleProduct(product.productName)) +
                      "/" +
                      (product.code
                        ? encodeProductCode(product.code)
                        : encodeProductCode(product.productCode))
                    }
                  >
                    <h5
                      className="name-product"
                      onClick={() => setShowCart(false)}
                    >
                      {product.name || product.productName}
                    </h5>
                  </Link>
                  <span className="my-minicart_product-quantity">
                    {product.quantity}
                  </span>
                  <span className="my-minicart_product-price">
                    {(product.price || product.price === 0) &&
                    !product.currentPrice
                      ? (
                          (Number(product.price) +
                            Number(product.priceSup) /
                              Math.pow(
                                10,
                                parseFloat(String(product.priceSup).length)
                              )) *
                          product.quantity
                        ).toFixed(2)
                      : (
                          product.currentPrice ||
                          product.productPrice * product.quantity
                        ).toFixed(2)}
                    <span> {product.type} </span>
                  </span>

                  <CloseButton
                    onClick={() => {
                      if (user)
                        return updateCartQuantityRequest({
                          product: { ...product },
                          quantity: 0,
                        });
                      removeFromCart({
                        ...product,
                        permanent: true,
                        location: "cartProducts",
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div className="my-minicart_cos">
            <div className="my-minicart_product my-minicart_product-footer">
              <span>Subtotal</span>
              <span className="my-minicart_product-quantity">
                {cartProducts.reduce(
                  (acc, current) => acc + current.quantity,
                  0
                )}
              </span>
              <span className="my-minicart_product-price">
                {totalPrice} RON
              </span>
            </div>
            <Link to="/my-cart">
              <Button
                className="my-minicart_cos-btn"
                onClick={() => {
                  setShowCart(false);
                  window.scrollTo(0, 0);
                }}
              >
                Coș
              </Button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  user: state.registerAccount.user,
  isLoading: state.cart.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (payload) => dispatch(removeFromCart(payload)),
  updateCartQuantityRequest: (payload) =>
    dispatch(updateCartQuantityRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniCart);
