import React, { useState, useEffect } from "react"
// import "./AdminProducts.scss"
import { Row, Button, Modal, Spinner } from "react-bootstrap"
import { useForm } from "react-hook-form"
import ApiService from "../../../service/apiService"

const AdminProducts = () => {
    const { register, errors, handleSubmit, reset } = useForm({});

    const [id, setId] = useState("")
    const [fromPrice, setFromPrice] = useState("")
    const [toPrice, setToPrice] = useState("")
    const [multiplier, setMultiplier] = useState("")
    const [provider, setProvider] = useState(undefined)
    const [priceRanges, setPriceRanges] = useState([])
    
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const handleClose = () => {
        setShowEditModal(false);
        setShowAddModal(false);
    }

    const handleShow = (priceRangeToUpdate) => {
        setId(priceRangeToUpdate.id)
        setFromPrice(priceRangeToUpdate.fromPrice)
        setToPrice(priceRangeToUpdate.toPrice)
        setMultiplier(priceRangeToUpdate.multiplier)
        setProvider(priceRangeToUpdate.provider)
        setShowEditModal(true)
    }

    const onSave = async data => {
        const response = await ApiService.savePriceRange({
            fromPrice: parseFloat(fromPrice),
            toPrice: parseFloat(toPrice),
            multiplier: parseInt(multiplier, 10),
            provider
        });
        
        if (response && response.status === 200) {
            reset();
            setShowAddModal(false);
        }
    };

    const onEdit = async data => {
        const response = await ApiService.updatePriceRange(id, {
            fromPrice,
            toPrice,
            multiplier,
            provider
        });

        if (response && response.status === 200) {
            reset();
            setShowEditModal(false);
        }
    };

    useEffect(async () => {
        const response = await ApiService.getPriceRanges()
        if (response && response.status === 200) {
            setPriceRanges(response.data);
        }
    }, [])

    const getToUpdateProduct = (index) => {
        const priceRange = priceRanges[index]
        handleShow(priceRange)
    };

    const onDelete = async id => {
        const response = await ApiService.deletePriceRange(id);
        if (response && response.status === 200) {
            const aux = priceRanges.filter(el => el.id !== id)
            setPriceRanges(aux);
        }
    };

    return (
        <>
            <div className="admin_products_container">
                {/* ADD MODAL */}
                <Modal show={showAddModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Adauga un nou interval</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <div className="form-group col-md-4">
                                    <label htmlFor="fromPrice">De la</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="fromPrice"
                                        className="form-control"
                                        value={fromPrice}
                                        onChange={event => setFromPrice(event.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="toPrice">Pana la</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="toPrice"
                                        className="form-control"
                                        value={toPrice}
                                        onChange={event => setToPrice(event.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="multiplier">Adaos Comercial</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="multiplier"
                                        className="form-control"
                                        value={multiplier}
                                        onChange={event => setMultiplier(event.target.value)}
                                        ref={register({
                                            required: "Acest câmp este obligatoriu",
                                        })}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="form-group col-md-4">
                                    <label htmlFor="provider">Importator</label>
                                    <select name="provider" onChange={event => setProvider(event.target.value)} value={provider || 'Alegeti furnizor'}>
                                        <option selected disabled>Alegeti furnizor</option>
                                        <option value="ROMPROFIX">Romprofix</option>
                                        <option value="LECHPOL">Lechpol</option>
                                        <option value="ELECTRONIC_PLUS">Electronic Plus</option>
                                        <option value="SHOPEXPERT">ShopExpert</option>
                                    </select>
                                </div>
                            </Row>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Închide
                    </Button>
                        <Button className="saveBtn" type="submit"
                            onClick={handleSubmit(onSave)}>
                            Salvează modificările
                     </Button>
                    </Modal.Footer>
                </Modal>

                {/* EDIT MODAL */}
                <Modal show={showEditModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editează intervalul</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <div className="form-group col-md-4">
                                    <label htmlFor="fromPrice">De la</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="fromPrice"
                                        className="form-control"
                                        value={fromPrice}
                                        onChange={event => setFromPrice(event.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="toPrice">Pana la</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="toPrice"
                                        className="form-control"
                                        value={toPrice}
                                        onChange={event => setToPrice(event.target.value)}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="multiplier">Adaos Comercial</label>
                                    <input
                                        type="number"
                                        min="0"
                                        name="multiplier"
                                        className="form-control"
                                        value={multiplier}
                                        onChange={event => setMultiplier(event.target.value)}
                                        ref={register({
                                            required: "Acest câmp este obligatoriu",
                                        })}
                                    />
                                </div>
                            </Row>
                            <Row>
                                <div className="form-group col-md-4">
                                    <label htmlFor="provider">Importator</label>
                                    <select name="provider" onChange={event => setProvider(event.target.value)} value={provider || 'Alegeti furnizor'}>
                                        <option selected disabled>Alegeti furnizor</option>
                                        <option value="ROMPROFIX">Romprofix</option>
                                        <option value="LECHPOL">Lechpol</option>
                                        <option value="ELECTRONIC_PLUS">Electronic Plus</option>
                                        <option value="SHOPEXPERT">ShopExpert</option>
                                    </select>
                                </div>
                            </Row>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Închide
                    </Button>
                        <Button className="saveBtn" type="submit"
                            onClick={handleSubmit(onEdit)}>
                            Salvează modificările
                     </Button>
                    </Modal.Footer>
                </Modal>

                {/* TABLE */}
                {
                    (priceRanges.length === 0) ?
                        <>
                            <div className="col" className="no-results">Nu există rezultate pentru această căutare.</div>
                        </>
                        :
                        <>
                            <Button className="btn-edit" onClick={() => { setShowAddModal(true) }}>Adauga</Button>
                            <Row style={{ overflowX: "auto" }}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>De la</th>
                                            <th>Pana la</th>
                                            <th>Adaos comercial</th>
                                            <th>Importator</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            priceRanges.map((p, index) => {
                                                return <tr key={p.id} index={index}>
                                                    <td>{p.fromPrice}</td>
                                                    <td>{p.toPrice}</td>
                                                    <td>{p.multiplier}%</td>
                                                    <td>{p.provider}</td>
                                                    <td>
                                                        <Button className="btn-edit" onClick={() => { getToUpdateProduct(index) }}>Editează</Button>
                                                    </td>
                                                    <td>
                                                        <Button className="btn-edit" onClick={() => { onDelete(p.id) }}>Sterge</Button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Row>
                        </>
                }
            </div>
        </>
    )
}

export default React.memo(AdminProducts);