import React from "react";
import styles from "./DynamicTable.module.scss";
import {
  replaceNameSingleProduct,
  encodeProductCode,
} from "../../utilities/helpers";

const DynamicTable = ({ products, currentCode }) => {
  return (
    <div className={styles.container}>
      <table className={styles.tableContainer}>
        <tr className={styles.head}>
          <th>
            <h6>Cod</h6>
          </th>
          {products[0].tableHeader.map((header, index) => (
            <th key={index}>
              <h6>{header}</h6>
            </th>
          ))}
          <th>
            <h6>Preț</h6>
          </th>
        </tr>
        {products.map((product, index) => (
          <tr
            className={
              product.code === currentCode ? styles.row_active : styles.row
            }
            onClick={() =>
              window.location.assign(
                "/" +
                  replaceNameSingleProduct(product.name) +
                  "/" +
                  encodeProductCode(product.code)
              )
            }
            key={index}
          >
            <td>{product.code}</td>
            {product.tableValues.map((value, index) =>
              value === "x" ? (
                <td key={index}>&#10003;</td>
              ) : (
                <td key={index}>{value}</td>
              )
            )}
            <td>{product.price}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default DynamicTable;
