export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const VERIFY_TOKEN_FAILURE = "VERIFY_TOKEN_FAILURE";

export const LOGOUT = "LOGOUT";

export const SET_VERIFIED = "SET_VERIFIED";

export const REGISTER_STATUS_REQUEST = "REGISTER_STATUS_REQUEST";
export const REGISTER_STATUS_SUCCESS = "REGISTER_STATUS_SUCCESS";
export const REGISTER_STATUS_FAILURE = "REGISTER_STATUS_FAILURE";

export const RESEND_EMAIL_REQUEST = "RESEND_EMAIL_REQUEST";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";

export const SEND_EMAIL_FORGOTPASS_REQUEST = "SEND_EMAIL_FORGOTPASS_REQUEST";
export const SEND_EMAIL_FORGOTPASS_SUCCESS = "SEND_EMAIL_FORGOTPASS_SUCCESS";
export const SEND_EMAIL_FORGOTPASS_FAILURE = "SEND_EMAIL_FORGOTPASS_FAILURE";

export const SET_NEW_PASSWORD_REQUEST = "SET_NEW_PASSWORD_REQUEST";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";
