import {
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_REQUEST,
  SET_VERIFIED,
  VERIFY_TOKEN_REQUEST,
  REGISTER_STATUS_REQUEST,
  RESEND_EMAIL_REQUEST,
  SEND_EMAIL_FORGOTPASS_REQUEST,
  SET_NEW_PASSWORD_REQUEST,
} from "./register.constants";

export const getRegisterRequest = (payload) => {
  return {
    type: REGISTER_REQUEST,
    payload,
  };
};

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    payload,
  };
};

export const verifyTokenRequest = () => {
  return {
    type: VERIFY_TOKEN_REQUEST,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setVerified = () => {
  return {
    type: SET_VERIFIED,
  };
};

export const getRegisterStatusRequest = (payload) => {
  return {
    type: REGISTER_STATUS_REQUEST,
    payload,
  };
};

export const resendEmailRequest = (payload) => {
  return {
    type: RESEND_EMAIL_REQUEST,
    payload,
  };
};

export const sendEmailForgotPassRequest = (payload) => {
  return {
    type: SEND_EMAIL_FORGOTPASS_REQUEST,
    payload,
  };
};

export const setNewPasswordRequest = (payload) => {
  return {
    type: SET_NEW_PASSWORD_REQUEST,
    payload,
  };
};
