import React from "react";
import "./CloseButton.scss";
const CloseButton = ({ onButtonClick }) => {
  return (
    <div id="mdiv" onClick={onButtonClick}>
      <div className="mdiv">
        <div className="md"></div>
      </div>
    </div>
  );
};

export default CloseButton;
