import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  SET_VERIFIED,
  VERIFY_TOKEN_SUCCESS,
  REGISTER_STATUS_REQUEST,
  REGISTER_STATUS_SUCCESS,
  REGISTER_STATUS_FAILURE,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  SEND_EMAIL_FORGOTPASS_REQUEST,
  SEND_EMAIL_FORGOTPASS_SUCCESS,
  SEND_EMAIL_FORGOTPASS_FAILURE,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
} from "./register.constants";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  errorRegister: null,
  user: null,
  status: null,
  isVerified: false,
  response: null,
  account: null,
};

export const registerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        response: action.payload.response,
        account: action.payload.payload,
        errorRegister: null,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        errorRegister: action.payload,
        isLoading: false,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.payload.lastName,
        isLoading: false,
        isVerified: true,
      };
    case SET_VERIFIED:
      return {
        ...state,
        isVerified: true,
      };
    case REGISTER_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case REGISTER_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
      };
    case REGISTER_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case RESEND_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_EMAIL_FORGOTPASS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_EMAIL_FORGOTPASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SEND_EMAIL_FORGOTPASS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_NEW_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
