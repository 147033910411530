import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  REGISTER_STATUS_REQUEST,
  REGISTER_STATUS_SUCCESS,
  REGISTER_STATUS_FAILURE,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_REQUEST,
  SEND_EMAIL_FORGOTPASS_SUCCESS,
  SEND_EMAIL_FORGOTPASS_REQUEST,
  SET_NEW_PASSWORD_REQUEST,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
} from "./register.constants";
import { ADD_NOTIFICATION } from "../product/product.constants";

export function* saveUserAccount({ payload }) {
  const { password, firstName, lastName, email } = payload;

  try {
    const response = yield call(apiService.saveUserAccount, {
      password,
      firstName,
      lastName,
      email,
    });
    if (response) {
      yield put({
        type: REGISTER_SUCCESS,
        payload: { payload, response },
      });
    }
  } catch (err) {
    yield put({
      type: REGISTER_FAILURE,
      payload: "Crearea contului a eșuat, încercați din nou",
    });
  }
}

export function* getRegisterStatus({ payload }) {
  const { token } = payload;
  const status = yield call(apiService.getUserAccountStatus, token);
  try {
    yield put({
      type: REGISTER_STATUS_SUCCESS,
      payload: { status },
    });
  } catch (err) {
    yield put({
      type: REGISTER_STATUS_FAILURE,
      payload: "Contul nu a putut fi creat, încercați din nou!",
    });
  }
}

export function* login({ payload }) {
  const body = {
    grant_type: "password",
    username: payload.email,
    password: payload.password,
  };
  try {
    const response = yield call(apiService.postLogin, body);
    if (response.data.access_token) {
      if (localStorage.getItem("persist:root")) {
        localStorage.removeItem("persist:root");
      }
      localStorage.setItem("token", response.data.access_token);
      window.location.assign(`/`);
    }
  } catch (err) {
    if (
      err.response.data.error_description === "Bad credentials" &&
      err.response.status === 400
    )
      yield put({
        type: LOGIN_FAILURE,
        payload: "Email sau parolă incorectă!",
      });
    else
      yield put({
        type: LOGIN_FAILURE,
        payload: "Ceva nu a mers bine. Vă rugăm încercați mai târziu!",
      });
  }
}

export function* verifyToken() {
  try {
    const response = yield call(apiService.getVerify);
    if (response.data)
      yield put({
        type: VERIFY_TOKEN_SUCCESS,
        payload: response.data,
      });
  } catch (err) {
    localStorage.removeItem("token");
    window.location.assign("/");
  }
}

export function* logout() {
  yield call(apiService.logout);
  localStorage.removeItem("token");
  window.location.assign("/");
}

export function* resendEmail({ payload }) {
  const { email } = payload;
  const response = yield call(apiService.resendEmail, email);
  try {
    yield put({
      type: RESEND_EMAIL_SUCCESS,
      payload: { response },
    });
  } catch (err) {
    yield put({
      payload:
        "Retrimiterea emailului de confirmare a eșuat, încercați din nou!",
    });
  }
}

export function* sendEmailForgotPassword({ payload }) {
  const { email } = payload;
  const response = yield call(apiService.sendEmailForgotPassword, email, {
    email,
  });
  try {
    yield put({
      type: SEND_EMAIL_FORGOTPASS_SUCCESS,
      payload: { response },
    });
  } catch (err) {
    yield put({
      payload:
        "Trimiterea emailului de resetare a parolei a eșuat, încercați din nou!",
    });
  }
}

export function* setNewPassword({ payload }) {
  const { token, newPassword, newPasswordConfirmed } = payload;
  try {
    const response = yield call(apiService.setNewPassword, token, {
      newPassword,
      newPasswordConfirmed,
    });
    const status = response.status;
    if (response) {
      yield put({
        type: SET_NEW_PASSWORD_SUCCESS,
        payload: { response },
      });
      if (status === 200) {
        yield put({
          type: ADD_NOTIFICATION,
          payload: { boldText: "Parola a fost schimbată cu succes!" },
        });
      }
    }
  } catch (err) {
    if (err.response.status === 500) {
      yield put({
        type: SET_NEW_PASSWORD_FAILURE,
        payload: err.response.data.message,
      });
    } else
      yield put({
        payload: "Schimbarea parolei a eșuat, încercați din nou!",
      });
  }
}

export function* verifyTokenWatcher() {
  yield takeLatest(VERIFY_TOKEN_REQUEST, verifyToken);
}
export function* saveUserAccountWatcher() {
  yield takeLatest(REGISTER_REQUEST, saveUserAccount);
}
export function* getRegisterStatusWatcher() {
  yield takeLatest(REGISTER_STATUS_REQUEST, getRegisterStatus);
}
export function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, login);
}
export function* logoutWatcher() {
  yield takeLatest(LOGOUT, logout);
}
export function* resendEmailWatcher() {
  yield takeLatest(RESEND_EMAIL_REQUEST, resendEmail);
}
export function* sendEmailForgotPasswordWatcher() {
  yield takeLatest(SEND_EMAIL_FORGOTPASS_REQUEST, sendEmailForgotPassword);
}
export function* setNewPasswordWatcher() {
  yield takeLatest(SET_NEW_PASSWORD_REQUEST, setNewPassword);
}

export function* registerSaga() {
  yield all([
    call(saveUserAccountWatcher),
    call(loginWatcher),
    call(verifyTokenWatcher),
    call(logoutWatcher),
    call(getRegisterStatusWatcher),
    call(resendEmailWatcher),
    call(sendEmailForgotPasswordWatcher),
    call(setNewPasswordWatcher),
  ]);
}
