export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const SET_SHOW_CART = "SET_SHOW_CART";
export const ADD_TO_CART_USER_REQUEST = "ADD_TO_CART_USER_REQUEST";
export const ADD_TO_CART_USER_SUCCESS = "ADD_TO_CART_USER_SUCCESS";
export const ADD_TOCART_USER_FAILURE = "ADD_TO_CART_USER_FAILURE";

export const ADD_TO_WISHLIST_REQUEST = "ADD_TO_WISHLIST_REQUEST";
export const ADD_TO_WISHLIST_SOLO_SUCCESS = "ADD_TO_WISHLIST_SOLO_SUCCESS";
export const ADD_TO_WISHLIST_SOLO_CART_SUCCESS =
  "ADD_TO_WISHLIST_SOLO_CART_SUCCESS";
export const ADD_TO_WISHLIST_FAILURE = "ADD_TO_WISHLIST_FAILURE";

export const DELETE_FROM_WISHLIST_REQUEST = "DELETE_FROM_WISHLIST_REQUEST";
export const DELETE_FROM_WISHLIST_SUCCESS = "DELETE_FROM_WISHLIST_SUCCESS";
export const DELETE_FROM_WISHLIST_FAILURE = "DELETE_FROM_WISHLIST_FAILURE";

export const DELETE_FROM_WISHLIST_CART_SUCCESS =
  "DELETE_FROM_WISHLIST_CART_SUCCESS";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAILURE = "DELETE_CART_FAILURE";

export const GET_WISHLIST_REQUEST = "GET_WISHLIST_REQUEST";
export const GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS";
export const GET_WISHLIST_FAILURE = "GET_WISHLIST_FAILURe";

export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";

export const UPDATE_CART_QUANTITY_REQUEST = "UPDATE_CART_QUANTITY_REQUEST";
export const UPDATE_CART_QUANTITY_SUCCESS = "UPDATE_CART_QUANTITY_SUCCESS";
export const UPDATE_CART_QUANTITY_FAILURE = "UPDATE_CART_QUANTITY_FAILURE";

export const UPDATE_GUEST_CART_REQUEST = "UPDATE_GUEST_CART_REQUEST";
export const UPDATE_GUEST_CART_SUCCESS = "UPDATE_GUEST_CART_SUCCESS";
export const UPDATE_GUEST_CART_FAILURE = "UPDATE_GUEST_CART_FAILURE";

export const UPDATE_CART_PRICE = "UPDATE_CART_PRICE";
