import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "react-bootstrap";
import "./PasswordRecovery.scss";
import { setNewPasswordRequest } from "../../redux/register/register.actions";
import Notification from "../../components/Notification/Notification";
import { removeNotification } from "../../redux/product/product.actions";

const PasswordRecovery = ({
  location,
  setNewPasswordRequest,
  error,
  notifications,
  removeNotification,
}) => {
  const { register, errors, handleSubmit, reset, watch } = useForm({});
  const [searchedId, setSearchedId] = useState("");

  const locationSplit = location.search.split("=");
  if (
    "?token=" + searchedId !== location.search &&
    locationSplit[0] === "?token"
  ) {
    setSearchedId(locationSplit[1]);
  }
  const onSubmitForm = (data) => {
    setNewPasswordRequest({
      token: searchedId,
      newPassword: data.newPassword,
      newPasswordConfirmed: data.newPasswordConfirmed,
    });
    reset();
  };

  const password = useRef({});
  password.current = watch("newPassword", "");

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  useEffect(() => {}, onSubmitForm);

  return (
    <>
      <div className="pass-recovery">
        <div
          style={{ position: "fixed", top: "20", zIndex: "1002" }}
          className="filters_notifications"
        >
          {notifications.map((notification) => {
            return (
              <Notification
                text={notification.text}
                boldText={notification.boldText}
                onClick={() => removeNotification(notification.id)}
              />
            );
          })}
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <Row style={{ justifyContent: "center" }}>
            <Col md={5}>
              <h5 style={{ marginBottom: "1.3em" }}>Introduceți noua parolă</h5>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newPassword">Parolă nouă</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="newPassword"
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      minLength: {
                        value: 6,
                        message:
                          "Parola trebuie să conțină cel puțin 6 caractere",
                      },
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.newPassword && (
                  <p className="form-group warning-message">
                    {errors.newPassword.message}
                  </p>
                )}
              </div>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newPasswordConfirmed">Confirmare parolă</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown2 ? "text" : "password"}
                    name="newPasswordConfirmed"
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      validate: (value) =>
                        value === password.current ||
                        "Parolele nu se potrivesc",
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown2 ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.newPasswordConfirmed && (
                  <p className="form-group warning-message">
                    {errors.newPasswordConfirmed.message}
                  </p>
                )}
                {error ? (
                  <p className="form-group warning-message">{error}</p>
                ) : (
                  <></>
                )}
              </div>
              <Button type="submit" onClick={handleSubmit(onSubmitForm)}>
                Salvează
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.registerAccount.error,
  notifications: state.product.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  setNewPasswordRequest: (payload) => dispatch(setNewPasswordRequest(payload)),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery);
