import { put, call, takeLatest, all } from "redux-saga/effects";
import apiService from "../../service/apiService";
import { ADD_NOTIFICATION, RESET_ITEM } from "../product/product.constants";
import {
  ADD_TO_CART,
  ADD_TO_CART_USER_REQUEST,
  ADD_TO_CART_USER_SUCCESS,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SOLO_CART_SUCCESS,
  ADD_TO_WISHLIST_SOLO_SUCCESS,
  DELETE_CART_FAILURE,
  DELETE_CART_REQUEST,
  DELETE_FROM_WISHLIST_CART_SUCCESS,
  DELETE_FROM_WISHLIST_REQUEST,
  DELETE_FROM_WISHLIST_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_WISHLIST_FAILURE,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
  REMOVE_FROM_CART,
  UPDATE_CART_PRICE,
  UPDATE_CART_QUANTITY_REQUEST,
  UPDATE_GUEST_CART_REQUEST,
} from "./cart.constants";

export function* addWishlist({ payload }) {
  yield call(apiService.postWishlist, { productCode: payload.product.code });

  const product = payload.product;

  if (!payload.modifyQuantity)
    yield put({ type: ADD_TO_WISHLIST_SOLO_SUCCESS, payload: product });

  yield put({ type: ADD_TO_WISHLIST_SOLO_CART_SUCCESS, payload: product });
}

export function* deleteWishlist({ payload }) {
  const code = payload.product.code || payload.product.productCode;

  yield call(apiService.deleteWishlist, code);

  yield put({ type: DELETE_FROM_WISHLIST_CART_SUCCESS, payload: code });
  yield put({ type: DELETE_FROM_WISHLIST_SUCCESS, payload: payload.product });
}

export function* getCart() {
  const response = yield call(apiService.getCart);

  yield put({ type: GET_CART_SUCCESS, payload: response.data });

  const persist = JSON.parse(localStorage.getItem("persist:root"));

  if (persist) {
    const { cartProducts } = JSON.parse(persist["cart"]);

    for (let i = 0; i < cartProducts.length; i++) {
      let ok = false;
      for (let j = 0; j < response.data.length; j++)
        if (
          response.data[j].productCode === cartProducts[i].code ||
          response.data[j].productCode === cartProducts[i].productCode
        ) {
          yield put({
            type: UPDATE_CART_QUANTITY_REQUEST,
            payload: {
              quantity: cartProducts[i].quantity + 1,
              product: cartProducts[i],
            },
          });
          ok = true;
        }
      if (!ok)
        yield put({ type: ADD_TO_CART_USER_REQUEST, payload: cartProducts[i] });

      ok = false;
    }
    localStorage.removeItem("persist:root");
  }
}

export function* addToCart({ payload }) {
  try {
    const response = yield call(apiService.postToCart, {
      productId: payload.id || payload.productId,
      quantity: payload.quantityToAdd || 1,
      productCode: payload.code || payload.productCode,
    });
    if (response.data) {
      payload.cartItemId = response.data;
      yield put({ type: ADD_TO_CART_USER_SUCCESS, payload: payload });
    }
    yield put({
      type: ADD_NOTIFICATION,
      payload: {
        boldText: payload.name || payload.productName,
        text: "a fost adăugat în coș!",
      },
    });
    yield put({ type: RESET_ITEM });
  } catch (err) {
    yield put({
      type: ADD_NOTIFICATION,
      payload: {
        boldText: "S-a produs o eroare, produsul nu a fost adăugat în coș!",
      },
    });
  }
}

export function* updateCart({ payload }) {
  const { product } = payload;
  try {
    const productToUpdate = {
      id: product.id || product.productId,
      name: product.name || product.productName,
      price: product.price || product.currentPrice,
      oldPrice: product.oldPrice,
      code: product.code || product.productCode,
      quantity: product.quantity,
    };

    if (payload.quantity === 0) {
      yield call(apiService.deleteCart, productToUpdate.code);
      yield put({
        type: REMOVE_FROM_CART,
        payload: { ...productToUpdate, permanent: true },
      });
      yield put({
        type: ADD_NOTIFICATION,
        payload: {
          boldText: productToUpdate.productName || productToUpdate.name,
          text: "a fost scos din cos!",
        },
      });
    } else {
      yield call(apiService.patchCart, {
        productCode: productToUpdate.code,
        quantity: payload.quantity,
      });
      if (
        payload.quantity === payload.product.quantity + 1 ||
        !payload.product.quantity
      ) {
        yield put({ type: ADD_TO_CART, payload: productToUpdate });
      } else {
        yield put({
          type: REMOVE_FROM_CART,
          payload: { ...productToUpdate, permanent: false },
        });
      }

      yield put({
        type: ADD_NOTIFICATION,
        payload: { boldText: "Cantitatea a fost modificata cu success!" },
      });
    }
  } catch (err) {
    if (err.response.data.message === "Not enough items in stock!")
      yield put({
        type: ADD_NOTIFICATION,
        payload: { boldText: "Cantitatea dorită nu se mai găsește în stoc!" },
      });
    else
      yield put({
        type: ADD_NOTIFICATION,
        payload: {
          boldText: "S-a produs o eroare, cantiatea nu a fost modificată!",
        },
      });
  }
}

export function* getWishlist() {
  try {
    const response = yield call(apiService.getWishlist);
    yield put({ type: GET_WISHLIST_SUCCESS, payload: response.data });
  } catch (err) {
    yield put({ type: GET_WISHLIST_FAILURE });
    yield put({
      type: ADD_NOTIFICATION,
      payload: { boldText: "S-a produs o eroare" },
    });
  }
}

export function* deleteCart({ payload }) {
  const code = payload.code || payload.productCode;
  try {
    yield call(apiService.deleteCart, code);
    yield put({
      type: REMOVE_FROM_CART,
      payload: { ...payload, permanent: true },
    });
  } catch (err) {
    yield put({ type: DELETE_CART_FAILURE });
  }
}

export function* updateGuestCart({ payload }) {
  const productCodes = payload.map(
    (product) => product.productCode || product.code
  );
  const products = yield call(apiService.updateGuestCart, { productCodes });

  for (let i = 0; i < payload.length; i++) {
    let code = payload[i].code ? payload[i].code : payload[i].productCode;
    let currentProducts = products.data.filter((pr) => pr.code === code);
    currentProducts.forEach((product) => {
      payload[i].numberOfStars1 = product.numberOfStars1;
      payload[i].numberOfStars2 = product.numberOfStars2;
      payload[i].numberOfStars3 = product.numberOfStars3;
      payload[i].numberOfStars4 = product.numberOfStars4;
      payload[i].numberOfStars5 = product.numberOfStars5;
      payload[i].oldPrice = product.oldPrice;
      payload[i].price = product.currentPrice;
      payload[i].weight = product.weight;
    });

    yield put({ type: UPDATE_CART_PRICE, payload: payload[i] });
  }
}

export function* updateGuestCartWatcher() {
  yield takeLatest(UPDATE_GUEST_CART_REQUEST, updateGuestCart);
}
export function* deleteCartWatcher() {
  yield takeLatest(DELETE_CART_REQUEST, deleteCart);
}
export function* getWishlistWatcher() {
  yield takeLatest(GET_WISHLIST_REQUEST, getWishlist);
}
export function* updateCartQuantityWatcher() {
  yield takeLatest(UPDATE_CART_QUANTITY_REQUEST, updateCart);
}
export function* addToCartWatcher() {
  yield takeLatest(ADD_TO_CART_USER_REQUEST, addToCart);
}
export function* addWishlistWatcher() {
  yield takeLatest(ADD_TO_WISHLIST_REQUEST, addWishlist);
}
export function* deleteWishlistWatcher() {
  yield takeLatest(DELETE_FROM_WISHLIST_REQUEST, deleteWishlist);
}
export function* getCartWatcher() {
  yield takeLatest(GET_CART_REQUEST, getCart);
}
export function* cartSagas() {
  yield all([
    call(addWishlistWatcher),
    call(deleteWishlistWatcher),
    call(updateGuestCartWatcher),
    call(getCartWatcher),
    call(addToCartWatcher),
    call(updateCartQuantityWatcher),
    call(getWishlistWatcher),
    call(deleteCartWatcher),
  ]);
}
