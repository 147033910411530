import {
  ORDER_RAMBURS_REQUEST,
  ORDER_BT_REQUEST,
  ORDER_INFO_REQUEST,
  BT_STATUS_REQUEST,
  VOUCHER_INFO_REQUEST,
  ORDER_DETAILS_REQUEST,
  VOUCHER_INFO_GUEST_REQUEST,
} from "./order.constants";

export const getRambursPaymentRequest = (payload) => {
  return {
    type: ORDER_RAMBURS_REQUEST,
    payload,
  };
};

export const getBTPaymentRequest = (payload) => {
  return {
    type: ORDER_BT_REQUEST,
    payload,
  };
};

export const getBTStatusRequest = (payload) => {
  return {
    type: BT_STATUS_REQUEST,
    payload,
  };
};

export const getOrdersInfoRequest = (payload) => {
  return {
    type: ORDER_INFO_REQUEST,
    payload,
  };
};

export const getOrdersDetailsRequest = (payload) => {
  return {
    type: ORDER_DETAILS_REQUEST,
    payload,
  };
};

export const getVoucherInfoRequest = (payload) => {
  return {
    type: VOUCHER_INFO_REQUEST,
    payload,
  };
};

export const getVoucherInfoGuestRequest = (payload) => {
  return {
    type: VOUCHER_INFO_GUEST_REQUEST,
    payload,
  };
};
