import React from "react";
import { Image } from "react-bootstrap";
import "./Benefits.scss";
import truck from "../../../images/truck.png";
import clock from "../../../images/clock.png";
import return_icon from "../../../images/return.png";
const Benefits = () => {
  return (
    <div className="benefits_div">
      <div style={{ textAlign: "left" }} className="benefit_row">
        <Image src={truck} className="style-img-truck" alt="truck" />
        <label>Transport gratuit la comenzile de peste 400 RON</label>
      </div>
      <div style={{ textAlign: "left" }} className="benefit_row">
        <Image
          id="style-img-truck"
          src={clock}
          className="style-img"
          alt="clock"
        />
        <label>Transport rapid</label>
      </div>
      <div style={{ textAlign: "left" }} className="benefit_row">
        <Image src={return_icon} className="style-img" alt="return_icon" />
        <label>Returnare în 14 zile</label>
      </div>
    </div>
  );
};

export default Benefits;
