import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Col, Image, Navbar } from "react-bootstrap";
import "./Header.scss";
import cart from "../../images/Header/cart_icon.svg";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import MiniCart from "../MiniCart/MiniCart";
import logo from "../../images/logo_deschis.png";
import Img from "react-cool-img";
import { setShowCart } from "../../redux/cart/cart.actions";
import useWindowSize from "../../hooks/useWindowSize";

const Header = ({ cartLength, showCart, setShowCart }) => {
  const toggleRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width <= 991) {
      function handleClickOutside(event) {
        if (!toggleRef.current.contains(event.target)) {
          setExpanded(false);
        } else setExpanded(!expanded);
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [toggleRef, expanded, size]);

  return (
    <div id="navbar-div">
      <Navbar collapseOnSelect expand="lg" expanded={expanded}>
        <Col md={3} xs={3}>
          <div id="navbar-div">
            <Navbar.Brand href="/">
              <Img src={logo} alt="romstock-logo" className="principal_logo" />
            </Navbar.Brand>
          </div>
        </Col>

        <div className="icons-group__cart">
          <div style={{ paddingLeft: "1.5%", position: "relative" }}>
            <Image
              src={cart}
              alt="cos-romstock-logo"
              className="header_logo cart"
              onClick={() => {
                setShowCart(!showCart);
              }}
            />
            <div className="div-price-style">
              <div className="quantity-style">
                <p>{cartLength}</p>
              </div>
            </div>
          </div>
          {showCart && <div className="small-pointer" />}
        </div>

        {showCart && <MiniCart setShowCart={setShowCart} showCart={showCart} />}

        <Navbar.Toggle
          id="toggle"
          aria-controls="responsive-header"
          className="ml"
          style={{ outline: "none", marginRight: "2.5%" }}
          ref={toggleRef}
        />

        <Col lg={9} xs={12} style={{ padding: "0" }}>
          <Navbar.Collapse className="responsive-header" id="responsive-header">
            <HeaderDesktop />
            <HeaderMobile />
          </Navbar.Collapse>
        </Col>
      </Navbar>
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartLength: state.cart.cartLength,
  showCart: state.cart.showCart,
});
const mapDispatchToProps = (dispatch) => ({
  setShowCart: (payload) => dispatch(setShowCart(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
