import React, { useEffect, useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Navbar.scss";
import Menu from "../Menu/Menu";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { replaceCategory } from "../../utilities/helpers";
import { setFirstPage } from "../../redux/filters/filters.actions";
import { useScroll } from "../../hooks/useScroll";
import searchIcon from "../../images/Header/search_icon.svg";
import useWindowSize from "../../hooks/useWindowSize";

const NavbarComponent = ({ toolsMenu, electronicsMenu, setFirstPage }) => {
  const [searchInput, setSearchInput] = useState("");

  const modalRef = useRef(null);
  const buttonReferenceTools = useRef(null);
  const buttonReferenceElectronics = useRef(null);

  const [showElectronicsMenu, setShowElectronicsMenu] = useState(false);
  const [showToolsMenu, setShowToolsMenu] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    function handleClickOutside(event) {
      if (showElectronicsMenu) {
        if (!modalRef.current.contains(event.target)) {
          setShowElectronicsMenu(false);
          if (
            buttonReferenceTools.current &&
            buttonReferenceTools.current.contains(event.target)
          ) {
            setShowToolsMenu(true);
          }
        }
      } else if (!showToolsMenu) {
        if (
          buttonReferenceTools.current &&
          buttonReferenceTools.current.contains(event.target)
        ) {
          setShowToolsMenu(!showElectronicsMenu);
        } else if (
          buttonReferenceElectronics.current &&
          buttonReferenceElectronics.current.contains(event.target)
        ) {
          setShowElectronicsMenu(!showToolsMenu);
        }
      } else if (showToolsMenu) {
        if (!modalRef.current.contains(event.target)) {
          setShowToolsMenu(false);
          if (
            buttonReferenceElectronics.current &&
            buttonReferenceElectronics.current.contains(event.target)
          ) {
            setShowElectronicsMenu(true);
          }
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, buttonReferenceTools, showElectronicsMenu, showToolsMenu]);

  let history = useHistory();

  const navigateTo = (url) => {
    history.push(url);
    setSearchInput("");
    setFirstPage();
  };

  const { scrollDirection, scrollY, lastScrollTop } = useScroll();

  const menuToolsComponent = showToolsMenu && (
    <Menu
      menu={toolsMenu}
      modalRef={modalRef}
      shouldDismissModal={(decision) => setShowToolsMenu(!decision)}
    />
  );

  const menuElectronicsComponent = showElectronicsMenu && (
    <Menu
      menu={electronicsMenu}
      modalRef={modalRef}
      shouldDismissModal={(decision) => setShowElectronicsMenu(!decision)}
    />
  );

  const [navbarWrapperClass, setNavbarWrapperClass] = useState(
    "show-navbar-wrapper"
  );

  useEffect(() => {
    if (
      scrollDirection === "down" ||
      scrollY < 100 ||
      (scrollY < 30 && size.width > 991)
    ) {
      setNavbarWrapperClass("show-navbar-wrapper");
    }
    if (scrollDirection === "up") {
      setNavbarWrapperClass("hide-navbar-wrapper");
    }
  }, [scrollDirection, lastScrollTop, scrollY]);

  return (
    <div
      style={{ backgroundColor: "#F1F1F1" }}
      className={`${navbarWrapperClass} navbar-container`}
    >
      <Navbar id="navbar-style">
        <div className="navbar-div-links">
          <Nav.Link ref={buttonReferenceTools} className="navbar-link">
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "0%",
                  color: showToolsMenu ? "#FF4F04" : "#302F2FD6",
                }}
              >
                Unelte si Scule Profesionale
              </p>
            </span>
          </Nav.Link>
          <Nav.Link ref={buttonReferenceElectronics} className="navbar-link">
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  marginBottom: "0%",
                  color: showElectronicsMenu ? "#FF4F04" : "#302F2FD6",
                }}
              >
                Electronice si Electrocasnice
              </p>
            </span>
          </Nav.Link>
          <Link
            onClick={() => {
              navigateTo(`/promotions`);
            }}
            style={{ textDecoration: "none" }}
            className="navbar-link nav-link "
          >
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <p style={{ marginBottom: "0%", color: "#302F2FD6" }}>
                Promoțiile lunii
              </p>
            </span>
          </Link>
          {/* <Link onClick={() => {
                        navigateTo(`/products/${replaceCategory("tv, electronice si foto")}`)
                    }} style={{textDecoration: "none"}} className="navbar-link nav-link ">
                        <span style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <p style={{marginBottom: '0%', color: "#302F2FD6"}}>TV, Electronice și Foto</p>
                        </span>
                    </Link>
                    <Link onClick={() => {
                        navigateTo(`/products/${replaceCategory("aparate de bucatarie")}`)
                    }} style={{textDecoration: "none"}} className="navbar-link nav-link ">
                        <span style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <p style={{marginBottom: '0%', color: "#302F2FD6"}}>Bucătărie</p>
                        </span>
                    </Link>
                    <Link to={`/products/all?brand=ADA`} onClick={() => setFirstPage()} style={{textDecoration: "none"}}
                          className="navbar-link nav-link ">
                        <span style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <p style={{marginBottom: '0%', color: "#302F2FD6"}}>Aparate de măsură</p>
                        </span>
                    </Link> */}
          {size.width > 991 && menuToolsComponent}
          {size.width > 991 && menuElectronicsComponent}
        </div>
        {/* <Nav style={{display: 'flex', flexDirection: 'row'}} className="display_desktop">
                    <Nav.Link className="pages-menu" href="/brands">Branduri</Nav.Link>
                    <Nav.Link className="pages-menu contact-link" href="/contact">Contact</Nav.Link>
                </Nav> */}
      </Navbar>
      <div className="display_mobile">
        <form className="example">
          <input
            type="text"
            placeholder="Cauta produse, Branduri & Categorii"
            name="search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            type="submit"
            onClick={(event) => {
              event.preventDefault();
              navigateTo(
                `/products/all?search=${searchInput.replace(/\s/g, "%20")}`
              );
            }}
          >
            <img src={searchIcon} />
          </button>
        </form>
      </div>
      {size.width <= 991 && menuToolsComponent}
      {size.width <= 991 && menuElectronicsComponent}
    </div>
  );
};

const mapStateToProps = (state) => ({
  toolsMenu: state.menu.toolsMenu,
  electronicsMenu: state.menu.electronicsMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setFirstPage: () => dispatch(setFirstPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavbarComponent);
