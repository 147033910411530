import React, { useState, useEffect } from "react";
import "./AdminProducts.scss";
import { FormControl, Row, Button, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import AdminProductsPagination from "../AdminProductsPagination/AdminProductsPagination";
import {
  searchProductsRequest,
  setFirstPageA,
} from "../../../redux/search/search.actions";
import { editProductRequest } from "../../../redux/admin/admin.actions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DateRange from "../DateRange";
import apiService from "../../../service/apiService";

const AdminProducts = ({
  searchProductsRequest,
  products,
  isLoading,
  currentPageNumber,
  setFirstPageA,
  editProductRequest,
}) => {
  const [selectedItems, setSelectedItems] = useState({});

  const [searchInput, setSearchInput] = useState("");
  const { register, errors, handleSubmit, reset } = useForm({});
  const [afterSubmit, setAfterSubmit] = useState(false);

  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [brand, setBrand] = useState("");
  const [weight, setWeight] = useState(0.1);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [categoryDateRange, setCategoryDateRange] = useState("");
  const [deal, setDeal] = useState(null);
  const [categoryDeal, setCategoryDeal] = useState(null);
  const [startDateCalendar, setStartDateCalendar] = useState("");
  const [startDateCategoryCalendar, setStartDateCategoryCalendar] =
    useState("");
  const [stopDateCalendar, setStopDateCalendar] = useState("");
  const [stopDateCategoryCalendar, setStopDateCategoryCalendar] = useState("");
  const [isMonthlyPromotion, setIsMonthlyPromotion] = useState(false);
  const [isCategoryMonthlyPromotion, setIsCategoryMonthlyPromotion] =
    useState(false);
  const [categoryDiscount, setCategoryDiscount] = useState("");

  const [show, setShow] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (productToUpdate) => {
    setCode(productToUpdate.code);
    setName(productToUpdate.name);
    setBrand(productToUpdate.brand);
    setDescription(productToUpdate.description);
    setCategory(productToUpdate.category);
    setPrice(productToUpdate.price);
    setWeight(productToUpdate.weight);

    if (productToUpdate.deal !== null) {
      setDeal(productToUpdate.deal);
      setFinalPrice(productToUpdate.deal.finalPrice);
      setStartDate(productToUpdate.deal.startDate);
      setIsMonthlyPromotion(productToUpdate.deal.isMonthlyPromotion);
      setExpirationDate(productToUpdate.deal.expirationDate);
      setDateRange(
        `${productToUpdate.deal.startDate} -> ${productToUpdate.deal.expirationDate}`
      );

      var arrayStartDate = productToUpdate.deal.startDate.split("-");
      var startDateC = `"${arrayStartDate[1]}/${arrayStartDate[2]}/${arrayStartDate[0]}"`;
      setStartDateCalendar(startDateC);

      var arrayStopDate = productToUpdate.deal.expirationDate.split("-");
      var stopDateC = `"${arrayStopDate[1]}/${arrayStopDate[2]}/${arrayStopDate[0]}"`;
      setStopDateCalendar(stopDateC);
    } else {
      setDateRange("");
      setDeal(null);
      setFinalPrice("");
      setStartDate("");
      setExpirationDate("");
      setIsMonthlyPromotion(false);
      setStartDateCalendar("");
      setStopDateCalendar("");
    }

    if (productToUpdate.categoryDeal !== null) {
      setCategoryDeal(productToUpdate.categoryDeal);
      setCategoryDiscount(productToUpdate.categoryDeal.discount);
      setIsCategoryMonthlyPromotion(
        productToUpdate.categoryDeal.isMonthlyPromotion
      );
      setCategoryDateRange(
        `${productToUpdate.categoryDeal.startDate} -> ${productToUpdate.categoryDeal.expirationDate}`
      );

      var arrayStartDate = productToUpdate.categoryDeal.startDate.split("-");
      var startDateC = `"${arrayStartDate[1]}/${arrayStartDate[2]}/${arrayStartDate[0]}"`;
      setStartDateCategoryCalendar(startDateC);

      var arrayStopDate =
        productToUpdate.categoryDeal.expirationDate.split("-");
      var stopDateC = `"${arrayStopDate[1]}/${arrayStopDate[2]}/${arrayStopDate[0]}"`;
      setStopDateCategoryCalendar(stopDateC);
    } else {
      setCategoryDeal(null);
      setIsCategoryMonthlyPromotion(false);
      setStartDateCategoryCalendar("");
      setStopDateCategoryCalendar("");
    }
    setShow(true);
  };

  function handleChange(value) {
    setDescription(value);
  }

  const onSubmitForm = (data) => {
    let splitDate = dateRange.split(" -> ");
    let splitDateCategory = categoryDateRange.split(" -> ");

    if (
      finalPrice !== "" &&
      splitDate &&
      splitDate[0] !== "" &&
      splitDate[1] !== ""
    ) {
      editProductRequest({
        code: String(code),
        name: String(name),
        brand: String(brand),
        price: Number(price),
        description: String(description),
        weight: Number(weight),
        deal: {
          finalPrice: Number(finalPrice),
          startDate: splitDate[0],
          expirationDate: splitDate[1],
          isMonthlyPromotion: isMonthlyPromotion,
        },
        category,
        categoryDeal:
          splitDateCategory &&
          splitDateCategory[0] &&
          splitDateCategory[1] &&
          !!categoryDiscount
            ? {
                discount: +categoryDiscount,
                startDate: splitDateCategory[0],
                expirationDate: splitDateCategory[1],
                isMonthlyPromotion: isCategoryMonthlyPromotion,
              }
            : null,
      });
    } else {
      editProductRequest({
        code: String(code),
        name: String(name),
        brand: String(brand),
        weight: Number(weight),
        price: Number(price),
        description: String(description),
        deal: null,
        category,
        categoryDeal:
          splitDateCategory &&
          splitDateCategory[0] &&
          splitDateCategory[1] &&
          !!categoryDiscount
            ? {
                discount: +categoryDiscount,
                startDate: splitDateCategory[0],
                expirationDate: splitDateCategory[1],
                isMonthlyPromotion: isCategoryMonthlyPromotion,
              }
            : null,
      });
    }

    reset();
    setAfterSubmit(true);
    setShow(false);
  };

  const onSubmitListForm = async (data) => {
    let splitDate = dateRange.split(" -> ");
    let body;

    if (splitDate[0] !== "" && splitDate[1] !== "") {
      body = {
        items: Object.keys(selectedItems),
        isMonthlyPromotion,
        discount: finalPrice,
        startDate: splitDate[0],
        endDate: splitDate[1],
      };
    } else {
      body = {
        items: Object.keys(selectedItems),
        isMonthlyPromotion,
        discount: finalPrice,
      };
    }

    const response = await apiService.bulkUpdateProducts(body);
    if (response.status === 200) {
      setShowPriceModal(false);
      setSelectedItems({});
    }
  };

  const searchFunction = () => {
    setFirstPageA();
    searchProductsRequest({
      searchedString: encodeURIComponent(searchInput),
      currentPageNumber: 1,
    });
  };

  useEffect(() => {
    searchProductsRequest({
      searchedString: encodeURIComponent(searchInput),
      currentPageNumber,
    });
  }, [currentPageNumber]);

  const onUpdateCheckbox = (isChecked, code) => {
    if (selectedItems[code] && !isChecked) {
      const newItems = { ...selectedItems };
      delete newItems[code];
      setSelectedItems({ ...newItems });
    } else {
      setSelectedItems({
        ...selectedItems,
        [code]: isChecked,
      });
    }
  };

  const toolbarContainer = [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: [] }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ color: [] }, { background: [] }],
    ["image", "video", "link"],
    ["clean"],
  ];

  const inputPickerChange = (label) => {
    setDateRange(label);
  };

  const getToUpdateProduct = (index) => {
    let product = products[index];
    handleShow(product);
  };

  const onOpenModal = (index) => {
    if (Object.keys(selectedItems).length <= 1) {
      getToUpdateProduct(index);
    } else {
      setShowPriceModal(true);
    }
  };

  return (
    <>
      <div className="admin_products_container">
        {/* MODAL */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editează produsul</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group col-md-12">
                <label htmlFor="name">Nume Produs</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  ref={register({
                    required: "Acest câmp este obligatoriu",
                  })}
                />
                {errors.name && (
                  <p className="form-group warning-message">
                    {errors.name.message}
                  </p>
                )}
              </div>
              <Row>
                <div className="form-group col-md-4">
                  <label htmlFor="code">Cod Produs</label>
                  <input
                    type="text"
                    name="code"
                    className="form-control"
                    value={code}
                    onChange={(event) => setCode(event.target.value)}
                    readOnly
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="brand">Brand</label>
                  <input
                    type="text"
                    name="brand"
                    className="form-control"
                    value={brand}
                    onChange={(event) => setBrand(event.target.value)}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="brand">Greutate (kg)</label>
                  <input
                    type="number"
                    name="weight"
                    className="form-control"
                    value={weight}
                    min={"0.1"}
                    step={"0.1"}
                    onChange={(event) => setWeight(event.target.value)}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="oldPrice">Preț</label>
                  <input
                    type="text"
                    name="oldPrice"
                    className="form-control"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                    })}
                  />
                  {errors.oldPrice && (
                    <p className="form-group warning-message">
                      {errors.oldPrice.message}
                    </p>
                  )}
                </div>
              </Row>
              <Row>
                <div className="form-group col-md-12">
                  <label htmlFor="currentPrice">Adaugă reducere preț</label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "5px",
                    }}
                  >
                    <input
                      type="number"
                      name="currentPrice"
                      className="form-control"
                      value={finalPrice}
                      onChange={(event) => setFinalPrice(event.target.value)}
                    />
                    <span>RON</span>
                  </div>
                </div>
              </Row>
              <div className="form-group col-md-12">
                {deal === null ? (
                  <DateRange
                    label={dateRange}
                    onNewRange={inputPickerChange}
                  ></DateRange>
                ) : (
                  <DateRange
                    initialSettings={{
                      startDate: startDateCalendar,
                      endDate: stopDateCalendar,
                    }}
                    label={dateRange}
                    onNewRange={inputPickerChange}
                  ></DateRange>
                )}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="promotion">
                  Face parte din promoțiile lunii
                </label>
                <input
                  type="checkbox"
                  checked={isMonthlyPromotion}
                  onClick={() => setIsMonthlyPromotion(!isMonthlyPromotion)}
                  id="promotion"
                  style={{ marginLeft: "2%" }}
                />
              </div>
              <Row>
                <div className="form-group col-md-12">
                  <label htmlFor="categoryDiscount">
                    {`Adaugă reducere (0 -> 100%) tuturor produselor din categoria ${category}`}
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      columnGap: "5px",
                    }}
                  >
                    <input
                      type="number"
                      max="100"
                      style={{ width: "auto" }}
                      name="categoryDiscount"
                      className="form-control"
                      value={categoryDiscount}
                      onChange={(event) =>
                        setCategoryDiscount(event.target.value)
                      }
                    />
                    <span>%</span>
                  </div>
                  {categoryDeal === null ? (
                    <DateRange
                      label={categoryDateRange}
                      onNewRange={(label) => setCategoryDateRange(label)}
                    ></DateRange>
                  ) : (
                    <DateRange
                      initialSettings={{
                        startDate: startDateCategoryCalendar,
                        endDate: stopDateCategoryCalendar,
                      }}
                      label={categoryDateRange}
                      onNewRange={(label) => setCategoryDateRange(label)}
                    ></DateRange>
                  )}
                </div>
              </Row>
              <div
                className="form-group col-md-12"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "5px",
                }}
              >
                <input
                  type="checkbox"
                  checked={isCategoryMonthlyPromotion}
                  onClick={() =>
                    setIsCategoryMonthlyPromotion(!isCategoryMonthlyPromotion)
                  }
                  id="categoryPromotion"
                />
                <label htmlFor="categoryPromotion">
                  Adăugare categorie '{category}' la promoțiile lunii
                </label>
              </div>
              <div className="form-group col-md-12">
                <ReactQuill
                  autoFocus="true"
                  theme="snow"
                  value={description}
                  onChange={handleChange}
                  modules={{
                    toolbar: {
                      container: toolbarContainer,
                    },
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmitForm)}
            >
              Salvează modificările
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Second Modal */}
        <Modal show={showPriceModal} onHide={() => setShowPriceModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editează pretul</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <div className="form-group col-md-12">
                  <label htmlFor="currentPrice">Adaugă reducere preț</label>
                  <label htmlFor="currentPrice">
                    Se va adauga o reducere de pret in procente
                  </label>
                  <input
                    type="text"
                    name="currentPrice"
                    className="form-control"
                    value={finalPrice}
                    onChange={(event) => setFinalPrice(event.target.value)}
                  />
                  <label htmlFor="currentPrice">%</label>
                </div>
              </Row>
              <div className="form-group col-md-12">
                {deal === null ? (
                  <DateRange
                    label={dateRange}
                    onNewRange={inputPickerChange}
                  ></DateRange>
                ) : (
                  <DateRange
                    initialSettings={{
                      startDate: startDateCalendar,
                      endDate: stopDateCalendar,
                    }}
                    label={dateRange}
                    onNewRange={inputPickerChange}
                  ></DateRange>
                )}
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="promotion">
                  Face parte din promoțiile lunii
                </label>
                <input
                  type="checkbox"
                  checked={isMonthlyPromotion}
                  onClick={() => setIsMonthlyPromotion(!isMonthlyPromotion)}
                  id="promotion"
                  style={{ marginLeft: "2%" }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowPriceModal(false)}
            >
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmitListForm)}
            >
              Salvează modificările
            </Button>
          </Modal.Footer>
        </Modal>

        {/* SEARCH INPUT */}
        <div className="div_group_search">
          <FormControl
            type="text"
            value={searchInput}
            id="search-input-admin"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                searchFunction();
              }
            }}
          ></FormControl>
          <div
            id="products-search"
            type="submit"
            onClick={() => searchFunction()}
          >
            <i className="fa fa-search" aria-hidden="true"></i>
          </div>
        </div>

        {/* TABLE */}
        {!isLoading && products.length === 0 ? (
          <>
            <div className="col no-results">
              Nu există rezultate pentru această căutare.
            </div>
          </>
        ) : isLoading ? (
          <div className="products-spinner">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <Row style={{ overflowX: "auto" }}>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Cod Produs</th>
                  <th>Nume</th>
                  <th>Brand</th>
                  <th>Preț</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.map((p, index) => {
                  return (
                    <tr key={p.code} index={index}>
                      <td>
                        <input
                          type="checkbox"
                          onClick={(e) =>
                            onUpdateCheckbox(e.target.checked, p.code)
                          }
                        />
                      </td>
                      <td>{p.code}</td>
                      <td>{p.name}</td>
                      <td>{p.brand}</td>
                      <td>
                        {p.deal !== null
                          ? p.deal.finalPrice
                          : p.categoryDeal
                          ? p.categoryDeal.finalPrice
                          : p.price}
                      </td>
                      <td>
                        <Button
                          className="btn-edit"
                          onClick={() => onOpenModal(index)}
                        >
                          Editează
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isLoading || products.length === 0 ? (
              <></>
            ) : (
              <div className="products-pagination">
                <AdminProductsPagination></AdminProductsPagination>
              </div>
            )}
          </Row>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  searchProductsRequest: (payload) => dispatch(searchProductsRequest(payload)),
  setFirstPageA: (payload) => dispatch(setFirstPageA(payload)),
  editProductRequest: (payload) => dispatch(editProductRequest(payload)),
});

const mapStateToProps = (state) => ({
  products: state.search.products,
  isLoading: state.search.isLoading,
  currentPageNumber: state.search.currentPageNumber,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AdminProducts));
