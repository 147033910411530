import React from "react";
import { Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setNextPage,
  setLastPage,
  setPrevPage,
  setFirstPage,
  nextPage,
  prevPage,
} from "../../redux/promotions/promotions.actions";
import "./Promotions.scss";

const PaginationPromotions = ({
  currentPage,
  numberOfPages,
  setNextPage,
  setLastPage,
  setPrevPage,
  setFirstPage,
  nextPage,
  prevPage,
}) => {
  return (
    <Pagination>
      {currentPage > 2 ? (
        <Pagination.First
          onClick={() => {
            if (currentPage !== 1) {
              setFirstPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPage !== 1 ? (
        <Pagination.Prev
          onClick={() => {
            if (currentPage !== 1) {
              prevPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPage > 4 ? <Pagination.Ellipsis className="ellipsis" /> : <></>}
      {currentPage > 2 ? (
        <Pagination.Item
          onClick={() => {
            setPrevPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPage - 2}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPage > 1 ? (
        <Pagination.Item
          onClick={() => {
            prevPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPage - 1}
        </Pagination.Item>
      ) : (
        <></>
      )}
      <Pagination.Item active>{currentPage}</Pagination.Item>
      {currentPage !== numberOfPages ? (
        <Pagination.Item
          onClick={() => {
            nextPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPage + 1}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPage !== numberOfPages && currentPage !== numberOfPages - 1 ? (
        <Pagination.Item
          onClick={() => {
            setNextPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPage + 2}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPage < numberOfPages - 3 ? (
        <Pagination.Ellipsis className="ellipsis" />
      ) : (
        <></>
      )}
      {currentPage !== numberOfPages ? (
        <Pagination.Next
          onClick={() => {
            if (currentPage !== numberOfPages) {
              nextPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPage < numberOfPages - 1 ? (
        <Pagination.Last
          onClick={() => {
            if (currentPage !== numberOfPages) {
              setLastPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
    </Pagination>
  );
};
const mapStateToProps = (state) => ({
  currentPage: state.promotions.currentPage,
  numberOfPages: state.promotions.numberOfPages,
});
const mapDispatchToProps = (dispatch) => ({
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
  setNextPage: () => dispatch(setNextPage()),
  setPrevPage: () => dispatch(setPrevPage()),
  setFirstPage: () => dispatch(setFirstPage()),
  setLastPage: () => dispatch(setLastPage()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaginationPromotions);
