import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import {
  encodeProductCode,
  replaceNameSingleProduct,
} from "../../utilities/helpers";
import ProductCard from "../productCard/ProductCard";
import "./ProductsCarousel.scss";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.css";
import { sliderSettings } from "../../utilities/constants";

const ProductsCarousel = ({ products }) => {
  return (
    <div className="products-list-style">
      <Slider {...sliderSettings}>
        {products.map((r, index) => (
          <div key={r.code} className="carousel-card">
            <div className="col-card">
              <Link
                to={
                  "/" +
                  replaceNameSingleProduct(r.name) +
                  "/" +
                  encodeProductCode(r.code)
                }
              >
                <ProductCard
                  key={r.code}
                  currentPrice={r.currentPrice}
                  index={index}
                  isInWishlist={r.isInWishlist}
                  labelType={
                    r.stockStatus === "REDUCED_STOCK"
                      ? "stoc redus"
                      : r.stockStatus === "IN_STOC"
                      ? "în stoc"
                      : "indisponibil"
                  }
                  product={r.imageUrl}
                  code={r.code}
                  oldPrice={String(r.oldPrice).split(/[\s.]+/)[0]}
                  oldPriceSup={
                    String(
                      (Math.round(r.oldPrice * 100) / 100).toFixed(2)
                    ).split(/[\s.]+/)[1]
                  }
                  oldType={"RON"}
                  price={String(r.currentPrice).split(/[\s.]+/)[0]}
                  priceSup={
                    String(
                      (Math.round(r.currentPrice * 100) / 100).toFixed(2)
                    ).split(/[\s.]+/)[1]
                  }
                  type={"RON"}
                  text={r.name}
                  numberOfStars1={r.numberOfStars1}
                  numberOfStars2={r.numberOfStars2}
                  numberOfStars3={r.numberOfStars3}
                  numberOfStars4={r.numberOfStars4}
                  numberOfStars5={r.numberOfStars5}
                  discount={r.discount}
                  legalGuarantee={12}
                  commercialGuarantee={24}
                  brand={r.brand}
                  weight={r.weight}
                />
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductsCarousel;
