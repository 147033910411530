import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Avatar from "react-avatar";
import "./MyAccount.scss";
import { connect } from "react-redux";
import {
  changePasswordRequest,
  changeEmailRequest,
  editInfoRequest,
  getInfoRequest,
  deleteAccountRequest,
} from "../../../redux/account/account.actions";
import { judete } from "../../../utilities/constants";

const MyAccount = ({
  changePasswordRequest,
  changeEmailRequest,
  error,
  editInfoRequest,
  getInfoRequest,
  person,
  deleteAccountRequest,
  errorDeleteAccount,
}) => {
  useEffect(() => {
    getInfoRequest();
  }, []);

  const { register, errors, handleSubmit, reset, watch } = useForm({});

  // MODAL 1
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // MODAL 2
  const [showModalPass, setShowModalPass] = useState(false);
  const handleCloseModalPass = () => setShowModalPass(false);
  const handleShowModalPass = () => setShowModalPass(true);

  const onSubmitPassForm = (data) => {
    changePasswordRequest({
      oldPassword: String(data.oldPassword),
      newPassword: String(data.newPassword),
      newPasswordConfirmed: String(data.newPasswordConfirmed),
    });
    reset();
  };

  // MODAL 3
  const [showModalEmail, setShowModalEmail] = useState(false);
  const handleCloseModalEmail = () => setShowModalEmail(false);
  const handleShowModalEmail = () => setShowModalEmail(true);

  const onSubmitEmailForm = (data) => {
    changeEmailRequest({
      newEmail: String(data.newEmail),
      newEmailConfirmed: String(data.newEmailConfirmed),
    });
    reset();
  };

  // MODAL 4
  const [showModalDeleteAccount, setShowModalDeleteAccount] = useState(false);
  const handleCloseModalDeleteAccount = () => setShowModalDeleteAccount(false);
  const handleShowModalDeleteAccount = () => setShowModalDeleteAccount(true);

  const onSubmitDeleteAccountForm = (data) => {
    deleteAccountRequest({
      email: String(data.email),
      password: String(data.password),
    });
  };

  const [nume, setNume] = useState("");
  const [prenume, setPrenume] = useState("");
  const [adresaDeLivrare, setAdresaLivrare] = useState("");
  const [codPostal, setCodPostal] = useState("");
  const [telefon, setTelefon] = useState("");
  const [judet, setJudet] = useState("");
  const [localitate, setLocalitate] = useState("");
  const [numeD, setNumeD] = useState("");
  const [prenumeD, setPrenumeD] = useState("");
  const [adresaDeFacturare, setAdresaFacturare] = useState("");
  const [codPostalF, setCodPostalF] = useState("");
  const [telefonF, setTelefonF] = useState("");
  const [judetF, setJudetF] = useState("");
  const [judetIndexL, setJudetIndexL] = useState(0);
  const [judetIndexF, setJudetIndexF] = useState(0);
  const [localitateF, setLocalitateF] = useState("");
  const [localitateIndexF, setLocalitateIndexF] = useState(0);
  const [localitateIndex, setLocalitateIndex] = useState(0);
  const [numeF, setNumeF] = useState("");
  const [prenumeF, setPrenumeF] = useState("");
  const [deliveryAddressString, setDeliveryAddress] = useState("");
  const [billingAddressString, setBillingAddress] = useState("");
  const [deliveryAddressId, setDeliveryId] = useState(null);
  const [billingAddressId, setBillingId] = useState(null);
  const [afterSubmit, setAfterSubmit] = useState(false);

  const onSubmit = (data) => {
    editInfoRequest({
      firstName: String(data.nume),
      lastName: String(data.prenume),
      deliveryAddress: {
        id: deliveryAddressId,
        street: String(data.adresaLivrare),
        postalCode: String(data.codPostal),
        city: String(data.localitate),
        country: "Romania",
        county: JSON.parse(data.judet).simplu,
        phoneNumber: String(data.telefon),
        addressType: "DELIVERY",
        firstName: String(data.numeD),
        lastName: String(data.prenumeD),
      },
      billingAddress: {
        id: billingAddressId,
        street: String(data.adresaFacturare),
        postalCode: String(data.codPostalF),
        city: String(data.localitateF),
        country: "Romania",
        county: JSON.parse(data.judetF).simplu,
        phoneNumber: String(data.telefonF),
        addressType: "BILLING",
        firstName: String(data.numeF),
        lastName: String(data.prenumeF),
      },
    });
    reset();
    getInfoRequest();
    setAfterSubmit(true);
  };

  const password = useRef({});
  password.current = watch("newPassword", "");

  const email = useRef({});
  email.current = watch("newEmail", "");

  // Hide/Show password
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  const [passwordShown3, setPasswordShown3] = useState(false);
  const togglePasswordVisiblity3 = () => {
    setPasswordShown3(passwordShown3 ? false : true);
  };

  const [passwordShown4, setPasswordShown4] = useState(false);
  const togglePasswordVisiblity4 = () => {
    setPasswordShown4(passwordShown4 ? false : true);
  };

  let adresaFacturare = "";
  let adresaLivrare = "";

  useEffect(() => {
    if (person) {
      if (person.billingAddress) {
        setCodPostalF(person.billingAddress.postalCode);
        setTelefonF(person.billingAddress.phoneNumber);
        setJudetF(person.billingAddress.county);
        setLocalitateF(person.billingAddress.city);
        setNumeF(person.billingAddress.lastName);
        setPrenumeF(person.billingAddress.firstName);
        setAdresaFacturare(person.billingAddress.street);
        adresaFacturare =
          person.billingAddress.firstName +
          " " +
          person.billingAddress.lastName +
          " - " +
          person.billingAddress.phoneNumber +
          " jud. " +
          person.billingAddress.county +
          " loc. " +
          person.billingAddress.city +
          " " +
          person.billingAddress.street;
        setBillingAddress(adresaFacturare);
        setBillingId(person.billingAddress.id);

        for (let i = 0; i < judete.length; i++) {
          if (judete[i].simplu === person.billingAddress.county) {
            setJudetIndexF(i);
            setAutoNbF(judete[i].auto);
            break;
          }
        }
      }

      if (person.deliveryAddress) {
        setCodPostal(person.deliveryAddress.postalCode);
        setTelefon(person.deliveryAddress.phoneNumber);
        setJudet(person.deliveryAddress.county);
        setLocalitate(person.deliveryAddress.city);
        setNumeD(person.deliveryAddress.lastName);
        setPrenumeD(person.deliveryAddress.firstName);
        setAdresaLivrare(person.deliveryAddress.street);
        adresaLivrare =
          person.deliveryAddress.firstName +
          " " +
          person.deliveryAddress.lastName +
          " - " +
          person.deliveryAddress.phoneNumber +
          " jud. " +
          person.deliveryAddress.county +
          " loc. " +
          person.deliveryAddress.city +
          " " +
          person.deliveryAddress.street;
        setDeliveryAddress(adresaLivrare);
        setDeliveryId(person.deliveryAddress.id);

        for (let i = 0; i < judete.length; i++) {
          if (judete[i].simplu === person.deliveryAddress.county) {
            setJudetIndexL(i);
            setAutoNb(judete[i].auto);
            break;
          }
        }
      }
      setNume(person.firstName);
      setPrenume(person.lastName);

      if (afterSubmit) {
        window.location.reload();
      }
    }
  }, [person]);

  //////////////////////////// API ///////////////////////////////////////////
  const [localitati, setLocalitati] = useState();
  const [autoNb, setAutoNb] = useState("");
  const [localitatiF, setLocalitatiF] = useState();
  const [autoNbF, setAutoNbF] = useState("");

  const handleSelect = (e) => {
    const { target } = e;
    setAutoNb(JSON.parse(target.value).auto);
  };

  const handleSelectF = (e) => {
    const { target } = e;
    setAutoNbF(JSON.parse(target.value).auto);
  };

  useEffect(() => {
    if (autoNb.length > 0) {
      fetch(`https://roloca.coldfuse.io/orase/${autoNb}`)
        .then((res) => res.json())
        .then((result) => {
          if (person) {
            result.forEach((value, index) => {
              if (
                person.deliveryAddress.city ===
                (value.simplu ? value.simplu : value.nume)
              ) {
                setLocalitate(result[index]);
                setLocalitateIndex(index);
              }
            });
          }
          setLocalitati(result);
        });
    }
  }, [autoNb]);

  useEffect(() => {
    if (autoNbF.length > 0) {
      fetch(`https://roloca.coldfuse.io/orase/${autoNbF}`)
        .then((res) => res.json())
        .then((result) => {
          if (person) {
            result.forEach((value, index) => {
              if (
                person.billingAddress.city ===
                (value.simplu ? value.simplu : value.nume)
              ) {
                setLocalitateF(result[index]);
                setLocalitateIndexF(index);
              }
            });
          }
          setLocalitatiF(result);
        });
    }
  }, [autoNbF]);

  if (person)
    return (
      <div className="my-account-container">
        {/* MODAL EDITEAZA DETALII*/}
        <Modal show={show} onHide={handleClose} className="edit-modal">
          <Modal.Header closeButton>
            <Modal.Title>Editează detaliile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Row>
                  <label className="label-style">Date personale</label>
                </Row>
                <Row>
                  <table className="table1">
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="nume">Nume</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="nume"
                            className="form-control"
                            value={nume}
                            onChange={(event) => setNume(event.target.value)}
                            ref={register({
                              required: "Acest câmp este obligatoriu",
                            })}
                          />
                          {errors.nume && (
                            <p className="form-group warning-message">
                              {errors.nume.message}
                            </p>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label htmlFor="prenume">Prenume</label>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="prenume"
                            className="form-control"
                            value={prenume}
                            onChange={(event) => setPrenume(event.target.value)}
                            ref={register({
                              required: "Acest câmp este obligatoriu",
                            })}
                          />
                          {errors.prenume && (
                            <p className="form-group warning-message">
                              {errors.prenume.message}
                            </p>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Row>
                {/* ///////////////////////////////////////////// INFO LIVRARE ///////////////////////////////////*/}
                <Row>
                  <label htmlFor="adresaLivrare" className="label-style">
                    Adresă livrare
                  </label>
                </Row>
                <Row className="row-inputs">
                  <div
                    style={{ paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="numeD">Nume</label>
                    <input
                      type="text"
                      name="numeD"
                      className="form-control"
                      value={numeD}
                      onChange={(event) => setNumeD(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.numeD && (
                      <p className="form-group warning-message">
                        {errors.numeD.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="prenumeD">Prenume</label>
                    <input
                      type="text"
                      name="prenumeD"
                      className="form-control"
                      value={prenumeD}
                      onChange={(event) => setPrenumeD(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.prenumeD && (
                      <p className="form-group warning-message">
                        {errors.prenumeD.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="telefon">Telefon</label>
                    <input
                      type="text"
                      name="telefon"
                      className="form-control"
                      value={telefon}
                      onChange={(event) => setTelefon(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                        pattern: {
                          value: /^[0-9\b]+$/,
                          message: "Numărul introdus nu este valid",
                        },
                      })}
                    />
                    {errors.telefon && (
                      <p className="form-group warning-message">
                        {errors.telefon.message}
                      </p>
                    )}
                  </div>
                </Row>
                <Row className="row-inputs">
                  <div
                    style={{ paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="judet">Judet</label>
                    {judete && (
                      <select
                        onChange={(e) => handleSelect(e)}
                        className="form-control"
                        name="judet"
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      >
                        {judete.map((judet, idx) => (
                          <option
                            selected={judetIndexL === idx}
                            value={JSON.stringify(judet)}
                            key={idx}
                          >
                            {judet.nume}
                          </option>
                        ))}
                      </select>
                    )}
                    {!judete && (
                      <input
                        className="form-control"
                        name="judet"
                        value={judet}
                        onChange={(event) => setJudet(event.target.value)}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      ></input>
                    )}
                    {errors.judet && (
                      <p className="form-group warning-message">
                        {errors.judet.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0", paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="localitate">Localitate</label>
                    {localitati && (
                      <select
                        className="form-control"
                        name="localitate"
                        ref={register}
                      >
                        {localitati.map((loc, idx) => (
                          <option
                            selected={idx === localitateIndex}
                            value={loc.simplu ? loc.simplu : loc.nume}
                            key={idx}
                          >
                            {loc.nume}
                          </option>
                        ))}
                      </select>
                    )}
                    {!localitati && (
                      <input
                        className="form-control"
                        name="localitate"
                        value={localitate}
                        onChange={(event) => setLocalitate(event.target.value)}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      ></input>
                    )}
                    {errors.localitate && (
                      <p className="form-group warning-message">
                        {errors.localitate.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="codPostal">Cod Poștal</label>
                    <input
                      type="text"
                      name="codPostal"
                      className="form-control"
                      value={codPostal}
                      onChange={(event) => setCodPostal(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.codPostal && (
                      <p className="form-group warning-message">
                        {errors.codPostal.message}
                      </p>
                    )}
                  </div>
                </Row>
                <Row>
                  <textarea
                    name="adresaLivrare"
                    rows="3"
                    value={adresaDeLivrare}
                    onChange={(event) => setAdresaLivrare(event.target.value)}
                    style={{ resize: "none", marginTop: "1%" }}
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      maxLength: {
                        value: 500,
                        message:
                          "Adresa poate să conțină cel mult 500 de caractere",
                      },
                    })}
                  />
                  {errors.adresaLivrare && (
                    <p className="form-group warning-message">
                      {errors.adresaLivrare.message}
                    </p>
                  )}
                </Row>

                {/* ///////////////////////////////////////////// INFO FACTURARE ///////////////////////////////////*/}
                <Row style={{ marginTop: "2%" }}>
                  <label htmlFor="adresaFacturare" className="label-style">
                    Adresă facturare
                  </label>
                </Row>
                <Row className="row-inputs">
                  <div
                    style={{ paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="numeF">Nume</label>
                    <input
                      type="text"
                      name="numeF"
                      className="form-control"
                      value={numeF}
                      onChange={(event) => setNumeF(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.numeF && (
                      <p className="form-group warning-message">
                        {errors.numeF.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingLeft: "0", paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="prenumeF">Prenume</label>
                    <input
                      type="text"
                      name="prenumeF"
                      className="form-control"
                      value={prenumeF}
                      onChange={(event) => setPrenumeF(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.prenumeF && (
                      <p className="form-group warning-message">
                        {errors.prenumeF.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="telefonF">Telefon</label>
                    <input
                      type="text"
                      name="telefonF"
                      className="form-control"
                      value={telefonF}
                      onChange={(event) => setTelefonF(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                        pattern: {
                          value: /^[0-9\b]+$/,
                          message: "Numărul introdus nu este valid",
                        },
                      })}
                    />
                    {errors.telefonF && (
                      <p className="form-group warning-message">
                        {errors.telefonF.message}
                      </p>
                    )}
                  </div>
                </Row>
                <Row className="row-inputs">
                  <div
                    style={{ paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="judetF">Judet</label>
                    {judete && (
                      <select
                        onChange={(e) => handleSelectF(e)}
                        className="form-control"
                        name="judetF"
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      >
                        {judete.map((judet, idx) => (
                          <option
                            selected={idx === judetIndexF}
                            value={JSON.stringify(judet)}
                            key={judet.nume}
                          >
                            {judet.nume}
                          </option>
                        ))}
                      </select>
                    )}
                    {!judete && (
                      <input
                        className="form-control"
                        name="judetF"
                        value={judetF}
                        onChange={(event) => setJudetF(event.target.value)}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      ></input>
                    )}
                    {errors.judetF && (
                      <p className="form-group warning-message">
                        {errors.judetF.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0", paddingLeft: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="localitateF">Localitate</label>
                    {localitatiF && (
                      <select
                        className="form-control"
                        name="localitateF"
                        ref={register}
                      >
                        {localitatiF.map((loc, idx) => (
                          <option
                            selected={localitateIndexF === idx}
                            value={loc.simplu ? loc.simplu : loc.nume}
                            key={idx}
                          >
                            {loc.nume}
                          </option>
                        ))}
                      </select>
                    )}
                    {!localitatiF && (
                      <input
                        className="form-control"
                        name="localitateF"
                        value={localitateF}
                        onChange={(event) => setLocalitateF(event.target.value)}
                        ref={register({
                          required: "Acest câmp este obligatoriu",
                        })}
                      ></input>
                    )}
                    {errors.localitateF && (
                      <p className="form-group warning-message">
                        {errors.localitateF.message}
                      </p>
                    )}
                  </div>
                  <div
                    style={{ paddingRight: "0" }}
                    className="form-group col-md-4"
                  >
                    <label htmlFor="codPostalF">Cod Poștal</label>
                    <input
                      type="text"
                      name="codPostalF"
                      className="form-control"
                      value={codPostalF}
                      onChange={(event) => setCodPostalF(event.target.value)}
                      ref={register({
                        required: "Acest câmp este obligatoriu",
                      })}
                    />
                    {errors.codPostalF && (
                      <p className="form-group warning-message">
                        {errors.codPostalF.message}
                      </p>
                    )}
                  </div>
                </Row>
                <Row>
                  <textarea
                    name="adresaFacturare"
                    rows="3"
                    value={adresaDeFacturare}
                    onChange={(event) => setAdresaFacturare(event.target.value)}
                    style={{ resize: "none", marginTop: "1%" }}
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      maxLength: {
                        value: 500,
                        message:
                          "Adresa poate să conțină cel mult 500 de caractere",
                      },
                    })}
                  />
                  {errors.adresaFacturare && (
                    <p className="form-group warning-message">
                      {errors.adresaFacturare.message}
                    </p>
                  )}
                </Row>
              </Row>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Salvează modificările
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL SCHIMBARE PAROLA*/}
        <Modal show={showModalPass} onHide={handleCloseModalPass}>
          <Modal.Header closeButton>
            <Modal.Title>Schimbă parola</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="oldPassword">Parolă actuală</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="oldPassword"
                    className="form-control pass-input"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.oldPassword && (
                  <p className="form-group warning-message">
                    {errors.oldPassword.message}
                  </p>
                )}
              </div>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newPassword">Parolă nouă</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown2 ? "text" : "password"}
                    name="newPassword"
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      minLength: {
                        value: 6,
                        message:
                          "Parola trebuie să conțină cel puțin 6 caractere",
                      },
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown2 ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity2}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.newPassword && (
                  <p className="form-group warning-message">
                    {errors.newPassword.message}
                  </p>
                )}
              </div>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newPasswordConfirmed">Confirmare parolă</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown3 ? "text" : "password"}
                    name="newPasswordConfirmed"
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      validate: (value) =>
                        value === password.current ||
                        "Parolele nu se potrivesc",
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown3 ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity3}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity3}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.newPasswordConfirmed && (
                  <p className="form-group warning-message">
                    {errors.newPasswordConfirmed.message}
                  </p>
                )}
                {error && (
                  <div className="form-group warning-message">{error}</div>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalPass}>
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmitPassForm)}
            >
              Salvează parola
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL SCHIMBARE EMAIL*/}
        <Modal show={showModalEmail} onHide={handleCloseModalEmail}>
          <Modal.Header closeButton>
            <Modal.Title>Schimbă email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newEmail">Email nou</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="email"
                    name="newEmail"
                    className="form-control pass-input"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Emailul introdus nu este valid",
                      },
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    <i className="far fa-envelope"></i>
                  </span>
                </div>

                {errors.newEmail && (
                  <p className="form-group warning-message">
                    {errors.newEmail.message}
                  </p>
                )}
              </div>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="newEmailConfirmed">Confirmare email</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="email"
                    name="newEmailConfirmed"
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Emailul introdus nu este valid",
                      },
                      validate: (value) =>
                        value === email.current ||
                        "Email-urile nu se potrivesc",
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    <i className="far fa-envelope"></i>{" "}
                  </span>
                </div>
                {errors.newEmailConfirmed && (
                  <p className="form-group warning-message">
                    {errors.newEmailConfirmed.message}
                  </p>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalEmail}>
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmitEmailForm)}
            >
              Salvează email-ul
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL STERGERE CONT */}
        <Modal
          show={showModalDeleteAccount}
          onHide={handleCloseModalDeleteAccount}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmă ștergerea contului</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={(e) => e.preventDefault()}>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="email">Email</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="email"
                    name="email"
                    className="form-control pass-input"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Emailul introdus nu este valid",
                      },
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    <i className="far fa-envelope"></i>
                  </span>
                </div>

                {errors.email && (
                  <p className="form-group warning-message">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="password">Parolă</label>
                <div style={{ position: "relative" }}>
                  <input
                    type={passwordShown4 ? "text" : "password"}
                    name="password"
                    className="form-control pass-input"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    {passwordShown4 ? (
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity4}
                      ></i>
                    ) : (
                      <i
                        className="fa fa-eye-slash"
                        aria-hidden="true"
                        onClick={togglePasswordVisiblity4}
                      ></i>
                    )}
                  </span>
                </div>
                {errors.password && (
                  <p className="form-group warning-message">
                    {errors.password.message}
                  </p>
                )}
                {errorDeleteAccount && (
                  <div className="form-group warning-message">
                    {errorDeleteAccount}
                  </div>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModalDeleteAccount}>
              Închide
            </Button>
            <Button
              className="saveBtn"
              type="submit"
              onClick={handleSubmit(onSubmitDeleteAccountForm)}
            >
              Șterge contul
            </Button>
          </Modal.Footer>
        </Modal>

        {/* COMPONENTA MY ACCOUNT*/}
        <Row className="avatar-row">
          <Avatar
            name={person.firstName + " " + person.lastName}
            size="100"
            maxInitials={2}
            color="#B9BDC3"
            fgColor="#484B51"
            round="50%"
            textSizeRatio={4}
          />
        </Row>
        <Row
          className="dates-row"
          itemScope
          itemType="https://schema.org/Person"
        >
          <Col md={4} style={{ paddingLeft: "0" }}>
            <label className="label-style" style={{ marginBottom: "3%" }}>
              Date personale
            </label>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label itemProp="name" className="name-label">
                      Nume:
                    </label>
                  </td>
                  <td className="td-padding">
                    <label itemProp="givenName">{person.firstName}</label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label className="name-label">Prenume:</label>
                  </td>
                  <td className="td-padding">
                    <label itemProp="familyName">{person.lastName}</label>
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col
            md={4}
            style={{ paddingLeft: "0" }}
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            <label className="label-style" style={{ marginBottom: "3%" }}>
              Adresă livrare
            </label>
            <p
              style={{ width: "55%", marginBottom: "0.5rem" }}
              itemProp="streetAddress"
            >
              {deliveryAddressString === ""
                ? "Nu există nicio adresă de livrare salvată"
                : deliveryAddressString}
            </p>
          </Col>
          <Col
            md={4}
            style={{ paddingLeft: "0" }}
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            <label className="label-style" style={{ marginBottom: "3%" }}>
              Adresă facturare
            </label>
            <p
              style={{ width: "55%", marginBottom: "0.5rem" }}
              itemProp="streetAddress"
            >
              {billingAddressString === ""
                ? "Nu există nicio adresă de facturare salvată"
                : billingAddressString}
            </p>
          </Col>
        </Row>
        <Row className="edit-button">
          <Button onClick={handleShow}>Editează</Button>
        </Row>
        <Row className="email-row">
          <label className="label-style">Email:</label>
          <Row style={{ width: "-webkit-fill-available" }}>
            <Col style={{ marginBottom: "0.5rem" }} itemProp="email">
              {" "}
              Adresa de email folosită în prezent este: {person.email}
            </Col>
            <Col md={2}>
              <Button onClick={handleShowModalEmail}>Modifică</Button>
            </Col>
          </Row>
        </Row>
        <Row className="pass-row">
          <label className="label-style">Parolă:</label>
          <Row style={{ width: "-webkit-fill-available" }}>
            <Col style={{ marginBottom: "0.5rem" }}>
              Folosește o parolă puternică pentru contul tău
            </Col>
            <Col md={2}>
              <Button onClick={handleShowModalPass}>Modifică</Button>
            </Col>
          </Row>
        </Row>
        <Row className="row-del">
          <Button id="delete-btn" onClick={handleShowModalDeleteAccount}>
            Ștergere cont
          </Button>
        </Row>
      </div>
    );
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  changePasswordRequest: (payload) => dispatch(changePasswordRequest(payload)),
  changeEmailRequest: (payload) => dispatch(changeEmailRequest(payload)),
  editInfoRequest: (payload) => dispatch(editInfoRequest(payload)),
  getInfoRequest: (payload) => dispatch(getInfoRequest(payload)),
  deleteAccountRequest: (payload) => dispatch(deleteAccountRequest(payload)),
});

const mapStateToProps = (state) => ({
  error: state.account.error,
  person: state.account.person,
  errorDeleteAccount: state.account.errorDeleteAccount,
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
