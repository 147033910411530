import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import { VERIFY_ROLE_REQUEST } from "./admin.constants";
import {
  GET_ADMIN_ORDERS_REQUEST,
  GET_ADMIN_ORDERS_SUCCESS,
  GET_ADMIN_ORDER_DETAILS_REQUEST,
  GET_ADMIN_ORDER_DETAILS_SUCCESS,
  VERIFY_ROLE_FAILURE,
  VERIFY_ROLE_SUCCESS,
  EDIT_PRODUCT_REQUEST,
} from "./admin.constants";

export function* getOrders({ payload }) {
  const { currentPage } = payload;
  const { data } = yield call(apiService.getAdminOrders, currentPage);

  yield put({
    type: GET_ADMIN_ORDERS_SUCCESS,
    payload: { products: data.orders, numberOfPages: data.numberOfPages },
  });
}

export function* getOrdersDetails({ payload }) {
  const { index } = payload;
  const { data } = yield call(apiService.getAdminOrdersDetails, index);

  yield put({
    type: GET_ADMIN_ORDER_DETAILS_SUCCESS,
    payload: { ordersDetails: data },
  });
}

export function* verifyRole() {
  try {
    const response = yield call(apiService.verifyRole);
    if (response.data.role === "admin") {
      yield put({ type: VERIFY_ROLE_SUCCESS });
    } else {
      yield put({ type: VERIFY_ROLE_FAILURE });
    }
  } catch (err) {
    yield put({ type: VERIFY_ROLE_FAILURE });
  }
}

export function* editProduct({ payload }) {
  yield call(apiService.editProduct, payload);
  // window.location.reload();
}

export function* getAdminOrderDetailsWatcher() {
  yield takeLatest(GET_ADMIN_ORDER_DETAILS_REQUEST, getOrdersDetails);
}

export function* getAdminOrdersWatcher() {
  yield takeLatest(GET_ADMIN_ORDERS_REQUEST, getOrders);
}

export function* editProductRequestWatcher() {
  yield takeLatest(EDIT_PRODUCT_REQUEST, editProduct);
}

export function* verifyRoleWatcher() {
  yield takeLatest(VERIFY_ROLE_REQUEST, verifyRole);
}

export function* adminSaga() {
  yield all([
    call(getAdminOrdersWatcher),
    call(getAdminOrderDetailsWatcher),
    call(verifyRoleWatcher),
    call(editProductRequestWatcher),
  ]);
}
