import React, { useEffect, useState, useRef } from "react";
import Axios from "axios";
import Filters from "./Filters";
import ProductsList from "./ProductsList";
import "./ProductsPage.scss";
import SEO from "../../components/SEO/SEO";
import { Link } from "react-router-dom";
import { decodeCategory, replaceCategory } from "../../utilities/helpers";
import {
  setPriceActiveFilter,
  setStockActiveFilter,
  removeBrandActiveFilter,
  searchGuestRequest,
  setChangedFilters,
  setFirstPage,
} from "../../redux/filters/filters.actions";
import { connect } from "react-redux";
import { BRAND_TYPE, PRICE_TYPE } from "../../redux/filters/filters.constants";

const ProductsPage = ({
  currentPageProduct,
  order,
  searchGuestRequest,
  location,
  justErasedAllFilters,
  brandActiveFilters,
  stockActiveFilter,
  priceActiveFilter,
  setChangedFilters,
  setFirstPage,
  changedPage,
  changedFilterList,
}) => {
  const [searchedString, setSearchedString] = useState("");
  const [brandString, setBrandString] = useState("");
  const [categoryString, setCategoryString] = useState("");
  const [lastSearchFilter, setLastSearchFilter] = useState({ value: null });
  const [activeFiltersArray, setActiveFiltersArray] = useState([]);
  let cancelToken = useRef(null);

  useEffect(() => {
    const locationSplit = location.search.split("=");
    if (locationSplit[0] === "?search") {
      setSearchedString(locationSplit[1].split("&")[0].replace(/%20/g, " "));
      if (brandString) setBrandString("");
    } else if (locationSplit[0] === "?brand") {
      setBrandString(locationSplit[1].split("&")[0].replace(/%20/g, " "));
      setSearchedString("");
    } else {
      let category = location.pathname.split(/\//)[2];
      setCategoryString(category.split("?")[0]);
      setBrandString("");
      setSearchedString("");
    }
    setChangedFilters(false);
  }, [location.search, location.pathname]);

  useEffect(() => {
    let mergedArray = [];

    if (priceActiveFilter.value !== null) {
      mergedArray.push({
        filterType: PRICE_TYPE,
        value: `${priceActiveFilter.value.min}-${priceActiveFilter.value.max}`,
      });
    }

    if (stockActiveFilter.value !== null) {
      mergedArray.push(stockActiveFilter);
    }

    brandActiveFilters.forEach((filter) =>
      mergedArray.push({ filterType: BRAND_TYPE, value: filter })
    );

    setActiveFiltersArray(mergedArray);

    let searchFilter = null;
    if (searchedString.length > 0) {
      searchFilter = { filterType: "NAME", value: searchedString };
    } else if (brandString.length > 0) {
      searchFilter = { filterType: "BRAND", value: brandString };
    } else if (categoryString.length > 0) {
      let category = categoryString
        .split("_")
        .map((elem) => decodeCategory(elem))
        .reduce((accumulator, elem) => accumulator + "::" + elem);
      searchFilter = { filterType: "CATEGORY", value: category };
    }

    if (cancelToken.current !== null) {
      cancelToken.current.cancel("Operation canceled due to new request.");
    }

    const filters = mergedArray.concat(searchFilter);

    if (searchFilter && !justErasedAllFilters) {
      cancelToken.current = Axios.CancelToken.source();

      if (changedPage === false && changedFilterList === true) {
        setFirstPage();
      }

      setLastSearchFilter(searchFilter);
      searchGuestRequest(
        {
          sortType: order,
          filters: filters,
          currentPage: currentPageProduct,
          isLogged: localStorage.getItem("token") !== null,
          cancelToken: cancelToken ? cancelToken.current : null,
        },
        { changeFilters: lastSearchFilter.value !== searchFilter.value }
      );
    }
  }, [
    brandActiveFilters,
    priceActiveFilter,
    stockActiveFilter,
    searchedString,
    brandString,
    categoryString,
    currentPageProduct,
    order,
  ]);

  return (
    <>
      <SEO
        title={searchedString ? "Cautare " + searchedString : "Cautare produse"}
        description={
          "Alege " +
          searchedString +
          " din gama de disponibila pe Romstock. Transportul este gratuit. Intra acum si vezi si celelalte oferte"
        }
        robots="index, follow"
        canonical="https://romstock.ro"
        openGraph={{
          url: "https://www.romstock.ro/products/all?search=" + searchedString,
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "RomStock - Cautare produse",
        }}
      />
      <div className="products">
        {searchedString && (
          <div className="products_result-info">
            <h3>{`Rezultate pentru:"${searchedString}"`}</h3>
          </div>
        )}
        {brandString && (
          <div className="products_result-info brand-title">
            <h3 style={{ marginLeft: "10%" }}>{brandString}</h3>
          </div>
        )}
        {!searchedString && !brandString && categoryString && (
          <div
            id="products-list"
            itemScope
            itemType="https://schema.org/BreadcrumbList"
            style={{ display: "flex", flexDirection: "row" }}
            className="category_string"
          >
            <Link
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              to={`/products/${replaceCategory(categoryString.split("_")[0])}`}
              style={{ color: "#1B80D9", marginLeft: "1%" }}
            >
              <>
                <span itemProp="name">
                  {decodeCategory(
                    categoryString.split("_")[0].charAt(0).toUpperCase() +
                      categoryString.split("_")[0].slice(1)
                  )}
                </span>
              </>
            </Link>
            {categoryString.split("_")[1] ? (
              <i class="fas fa-angle-double-right double_arrow"></i>
            ) : (
              <></>
            )}
            <Link
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              to={
                categoryString.split("_")[1]
                  ? `/products/${replaceCategory(
                      categoryString.split("_")[0]
                    )}_${replaceCategory(categoryString.split("_")[1])}`
                  : ""
              }
              style={{ color: "#1B80D9", marginLeft: "1%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "center",
                }}
              >
                <p style={{ marginBottom: "0%" }} itemProp="item">
                  <span itemProp="name">
                    {categoryString.split("_")[1]
                      ? decodeCategory(
                          categoryString.split("_")[1].charAt(0).toUpperCase() +
                            categoryString.split("_")[1].slice(1)
                        )
                      : ""}
                  </span>
                </p>
              </div>
            </Link>
            {categoryString.split("_")[2] ? (
              <i class="fas fa-angle-double-right double_arrow"></i>
            ) : (
              <></>
            )}
            <Link
              to={
                categoryString.split("_")[2]
                  ? `/products/${replaceCategory(
                      categoryString.split("_")[0]
                    )}_${replaceCategory(
                      categoryString.split("_")[1]
                    )}_${replaceCategory(categoryString.split("_")[2])}`
                  : ""
              }
              style={{ color: "#707070", marginLeft: "1%" }}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "auto",
                  justifyContent: "center",
                }}
              >
                <p style={{ marginBottom: "0%" }} itemProp="item">
                  <span itemProp="name">
                    {categoryString.split("_")[2]
                      ? decodeCategory(
                          categoryString.split("_")[2].charAt(0).toUpperCase() +
                            categoryString.split("_")[2].slice(1)
                        )
                      : ""}
                  </span>
                </p>
              </div>
            </Link>
          </div>
        )}
        <div className="products_content">
          <Filters activeFiltersArray={activeFiltersArray} />
          <ProductsList />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  priceActiveFilter: state.filters.priceActiveFilter,
  stockActiveFilter: state.filters.stockActiveFilter,
  brandActiveFilters: state.filters.brandActiveFilters,
  currentPageProduct: state.filters.currentPageProduct,
  order: state.filters.order,
  justErasedAllFilters: state.filters.justErasedAllFilters,
  changedPage: state.filters.changedPage,
  changedFilterList: state.filters.changedFilterList,
});
const mapDispatchToProps = (dispatch) => ({
  setStockActiveFilter: (payload) => dispatch(setStockActiveFilter(payload)),
  setPriceActiveFilter: (payload) => dispatch(setPriceActiveFilter(payload)),
  removeBrandActiveFilter: (payload) =>
    dispatch(removeBrandActiveFilter(payload)),
  setChangedFilters: (payload) => dispatch(setChangedFilters(payload)),
  searchGuestRequest: (payload, options) =>
    dispatch(searchGuestRequest(payload, options)),
  setFirstPage: () => dispatch(setFirstPage()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
