import { compose } from "redux";
import { connect } from "react-redux";
import CartPage from "./CartPage";
import {
  addToCartLogged,
  deleteFromWishlist,
  updateCartQuantityRequest,
} from "../../redux/cart/cart.actions";
import {
  removeNotification,
  addNotification,
} from "../../redux/product/product.actions";

const mapStateToProps = (state) => ({
  products: state.cart.wishList,
  cartProducts: state.cart.cartProducts,
  isWishList: true,
  notifications: state.product.notifications,
  cartLoading: state.cart.cartLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addToCartLogged: (payload) => dispatch(addToCartLogged(payload)),
  updateCartQuantityRequest: (payload) =>
    dispatch(updateCartQuantityRequest(payload)),
  deleteFromWishlist: (payload) => dispatch(deleteFromWishlist(payload)),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
});

export const Wishlist = compose(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);
