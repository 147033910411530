import React from "react";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import { Row, Col } from "react-bootstrap";
import "./ProductTabs.scss";
import Reviews from "../Reviews/Reviews";
import Details from "../Details/Details";
import SpecificationsTable from "../../../components/SpecificationsTable/SpecificationsTable";

const ProductTabs = ({ product, forceDetails, setForceDetails }) => {
  return (
    <Tabs
      renderActiveTabContentOnly={true}
      selectedTab={forceDetails ? "details" : "reviews"}
      activeLinkStyle={{
        backgroundColor: "#E04C35",
        padding: "1%",
        marginLeft: "0px",
        width: "inherit",
        paddingLeft: "0",
        paddingRight: "0",
      }}
    >
      <Row id="div-tabs">
        <Col>
          <TabLink className="tab-link col-xs-6 tab-style" to="details">
            Detalii
          </TabLink>
        </Col>
        {product.productSpecifications.length > 0 && (
          <Col>
            <TabLink
              className="tab-link col-xs-6 tab-style"
              to="specifications"
            >
              Specificații
            </TabLink>
          </Col>
        )}
        <Col>
          <TabLink
            className="tab-link col-xs-6 tab-style"
            to="reviews"
            onClick={() => setForceDetails(false)}
          >
            Recenzii clienți
          </TabLink>
        </Col>
      </Row>
      <TabContent for="details">
        <Details product={product}></Details>
      </TabContent>
      {product.productSpecifications.length > 0 && (
        <TabContent for="specifications">
          <SpecificationsTable specifications={product.productSpecifications} />
        </TabContent>
      )}
      <TabContent for="reviews">
        <Reviews product={product}></Reviews>
      </TabContent>
    </Tabs>
  );
};

export default React.memo(ProductTabs);
