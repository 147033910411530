export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CHANGE_EMAIL_REQUEST = "CHANGE_EMAIL_REQUEST";
export const CHANGE_EMAIL_SUCCESS = "CHANGE_EMAIL_SUCCESS";
export const CHANGE_EMAIL_FAILURE = "CHANGE_EMAIL_FAILURE";

export const EDIT_INFO_REQUEST = "EDIT_INFO_REQUEST";
export const EDIT_INFO_SUCCESS = "EDIT_INFO_SUCCESS";
export const EDIT_INFO_FAILURE = "EDIT_INFO_FAILURE";

export const GET_INFO_REQUEST = "GET_INFO_REQUEST";
export const GET_INFO_SUCCESS = "GET_INFO_SUCCESS";
export const GET_INFO_FAILURE = "GET_INFO_FAILURE";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";

export const GET_ORDERS_DETAILS_REQUEST = "GET_ORDERS_DETAILS_REQUEST";
export const GET_ORDERS_DETAILS_SUCCESS = "GET_ORDERS_DETAILS_SUCCESS";
export const GET_ORDERS_DETAILS_FAILURE = "GET_ORDERS_DETAILS_FAILURE";

export const NEXT_PAGE_ACC = "NEXT_PAGE_ACC";
export const PREV_PAGE_ACC = "PREV_PAGE_ACC";

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const SET_NEXT_PAGE_ACC = "SET_NEXT_PAGE_ACC";
export const SET_PREV_PAGE_ACC = "SET_PREV_PAGE_ACC";
export const SET_FIRST_PAGE_ACC = "SET_FIRST_PAGE_ACC";
export const SET_LAST_PAGE_ACC = "SET_LAST_PAGE_ACC";
