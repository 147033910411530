import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import "./Reviews.scss";
import ReviewCard from "../ReviewCard/ReviewCard";
import {
  getCommentsRequest,
  saveCommentRequest,
} from "../../../redux/comments/comments.actions";
import CommentsPagination from "../CommentsPagination/CommentsPagination";
import { URL } from "../../../utilities/constants";

const Reviews = ({
  product,
  comments,
  getCommentsRequest,
  isLoading,
  currentPage,
  saveCommentRequest,
  user,
}) => {
  const [rating, setRating] = useState(0);
  const changeRating = (newRating) => {
    setRating(newRating);
  };

  useEffect(() => {
    getCommentsRequest({
      code: product.code ? product.code : product.productCode,
      currentPage,
    });
  }, [currentPage]);

  const getStars = () => {
    if (
      product.numberOfStars1 +
        product.numberOfStars2 +
        product.numberOfStars3 +
        product.numberOfStars4 +
        product.numberOfStars5 !==
      0
    ) {
      const number =
        (product.numberOfStars1 +
          product.numberOfStars2 * 2 +
          product.numberOfStars3 * 3 +
          product.numberOfStars4 * 4 +
          product.numberOfStars5 * 5) /
        (product.numberOfStars1 +
          product.numberOfStars2 +
          product.numberOfStars3 +
          product.numberOfStars4 +
          product.numberOfStars5);
      return Math.round(number * 100) / 100;
    } else return 0;
  };

  const getNumberOfReviews = () => {
    const numberReviews =
      product.numberOfStars1 +
      product.numberOfStars2 +
      product.numberOfStars3 +
      product.numberOfStars4 +
      product.numberOfStars5;
    if (numberReviews === 0 || (numberReviews > 1 && numberReviews < 20))
      return String(numberReviews + " rencezii");
    else if (numberReviews === 1) return String(numberReviews + " recenzie");
    else return String(numberReviews + " de recenzii");
  };

  const { register, errors, handleSubmit, reset } = useForm({});
  const onSubmit = async (data) => {
    if (user) {
      saveCommentRequest({
        code: product.code ? product.code : product.productCode,
        title: String(data.title),
        text: String(data.text),
        numberOfStars: rating,
      });
      reset();
      setRating(0);
    } else {
      window.location.assign(URL + "/login");
    }
  };

  if (product && comments)
    return (
      <div className="main-div-reviews">
        <Row id="row-review-style">
          Evaluările clienților:
          <label id="label-rating"> {getStars()} </label>
          din {getNumberOfReviews()}
        </Row>
        <Row className="review-container">
          <Col md={4} className="stars-col">
            <Row
              style={{
                paddingTop: "5%",
                paddingLeft: "15px",
                display: "table",
              }}
            >
              <td>
                <label style={{ verticalAlign: "middle" }}>
                  <StarRatings
                    rating={5}
                    starRatedColor="#E6B923"
                    numberOfStars={5}
                    name="rating"
                  />
                </label>
              </td>
              <td>
                <label style={{ verticalAlign: "middle" }} id="number-notes">
                  ({product.numberOfStars5})
                </label>
              </td>
            </Row>
            <Row style={{ paddingLeft: "15px", display: "table" }}>
              <td>
                <label style={{ verticalAlign: "middle" }}>
                  <StarRatings
                    rating={4}
                    starRatedColor="#E6B923"
                    numberOfStars={4}
                    name="rating"
                  />
                </label>
              </td>
              <td>
                <label style={{ verticalAlign: "middle" }} id="number-notes">
                  ({product.numberOfStars4})
                </label>
              </td>
            </Row>
            <Row style={{ paddingLeft: "15px", display: "table" }}>
              <td>
                <label style={{ verticalAlign: "middle" }}>
                  <StarRatings
                    rating={3}
                    starRatedColor="#E6B923"
                    numberOfStars={3}
                    name="rating"
                  />
                </label>
              </td>
              <td>
                <label style={{ verticalAlign: "middle" }} id="number-notes">
                  ({product.numberOfStars3})
                </label>
              </td>
            </Row>
            <Row style={{ paddingLeft: "15px", display: "table" }}>
              <td>
                <label style={{ verticalAlign: "middle" }}>
                  <StarRatings
                    rating={2}
                    starRatedColor="#E6B923"
                    numberOfStars={2}
                    name="rating"
                  />
                </label>
              </td>
              <td>
                <label style={{ verticalAlign: "middle" }} id="number-notes">
                  ({product.numberOfStars2})
                </label>
              </td>
            </Row>
            <Row style={{ paddingLeft: "15px", display: "table" }}>
              <td>
                <label style={{ verticalAlign: "middle" }}>
                  <StarRatings
                    rating={1}
                    starRatedColor="#E6B923"
                    numberOfStars={1}
                    name="rating"
                  />
                </label>
              </td>
              <td>
                <label style={{ verticalAlign: "middle" }} id="number-notes">
                  ({product.numberOfStars1})
                </label>
              </td>
            </Row>
          </Col>

          <Col md={8}>
            <form onSubmit={(e) => e.preventDefault()}>
              {/* WEB */}
              <Row id="web">
                <Col className="note-style">Oferă o notă</Col>
                <Col md={5} id="stars-note">
                  <StarRatings
                    rating={rating}
                    starRatedColor="#E6B923"
                    starEmptyColor="#DADDE1"
                    starHoverColor="#E04C35"
                    changeRating={changeRating}
                    numberOfStars={5}
                    name="rating"
                  />
                </Col>
                <Col style={{ paddingTop: "1.2%" }}>
                  <Button
                    className="button-review-style"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={rating === 0 ? true : false}
                  >
                    Postează review
                  </Button>
                </Col>
              </Row>

              {/* MOBILE */}
              <Row className="mobile">
                <div
                  className="col align-self-start"
                  style={{ display: "contents" }}
                >
                  <label className="text-get-review"> Lasă un review </label>
                </div>
                <div className="col align-self-end">
                  <StarRatings
                    rating={rating}
                    starRatedColor="#E6B923"
                    starEmptyColor="#DADDE1"
                    starHoverColor="#E04C35"
                    changeRating={changeRating}
                    numberOfStars={5}
                    name="rating"
                  />
                </div>
              </Row>

              <Row>
                <div className="col-md-12 inputs-group-form">
                  <input
                    name="title"
                    type="text"
                    placeholder="titlu"
                    className="form-control"
                    style={{ backgroundColor: "#ECECEC" }}
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      maxLength: {
                        value: 100,
                        message:
                          "Titlul poate să conțină cel mult 100 de caratere",
                      },
                    })}
                  />
                  {errors.title && (
                    <p className="form-group warning-message">
                      {errors.title.message}
                    </p>
                  )}

                  <textarea
                    name="text"
                    rows="4"
                    placeholder="experiența ta cu acest produs"
                    style={{
                      resize: "none",
                      marginTop: "1%",
                      backgroundColor: "#ECECEC",
                    }}
                    className="form-control"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      maxLength: {
                        value: 500,
                        message:
                          "Comentariul poate să conțină cel mult 500 de caratere",
                      },
                    })}
                  />
                  {errors.text && (
                    <p className="form-group warning-message">
                      {errors.text.message}
                    </p>
                  )}
                </div>

                {/* MOBILE */}
                <Row style={{ width: "-webkit-fill-available" }}>
                  <Button
                    style={{ justifyContent: "center" }}
                    className="button-review-style mobile"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Postează review
                  </Button>
                </Row>
              </Row>
            </form>
          </Col>
        </Row>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <>
            {comments.map((c, index) => {
              return (
                <ReviewCard
                  key={index}
                  index={index}
                  name={c.accountName}
                  rating={c.numberOfStars}
                  date={c.date}
                  title={c.title}
                  content={c.text}
                />
              );
            })}
          </>
        )}
        {isLoading || comments.length === 0 ? (
          <></>
        ) : (
          <div className="products-pagination">
            <CommentsPagination />
          </div>
        )}
      </div>
    );
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  getCommentsRequest: (payload) => dispatch(getCommentsRequest(payload)),
  saveCommentRequest: (payload) => dispatch(saveCommentRequest(payload)),
});

const mapStateToProps = (state) => ({
  product: state.product.product,
  comments: state.comments.comments,
  isLoading: state.comments.isLoading,
  currentPage: state.comments.currentPage,
  user: state.registerAccount.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
