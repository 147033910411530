import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./OrderCompletion.module.scss";
import { Col, Row, Spinner } from "react-bootstrap";
import OrderForm from "./OrderForm";
import visa from "../../images/visa_logo.png";
import mastercard from "../../images/mastercard_logo.png";
import maestro from "../../images/maestro_logo.png";
import btPay from "../../images/logo-bt-pay.png";
import {
  getRambursPaymentRequest,
  getBTPaymentRequest,
  getOrdersInfoRequest,
  getVoucherInfoRequest,
  getVoucherInfoGuestRequest,
} from "../../redux/order/order.actions";
import { addNotification } from "../../redux/product/product.actions";
import {
  costMax,
  DELIVERY_FEE,
  LOCAL_STORAGE_REFERRER,
} from "../../utilities/constants";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const OrderCompletion = ({
  isLoading,
  cartProducts,
  cartLength,
  getRambursPaymentRequest,
  getBTPaymentRequest,
  user,
  info,
  voucher,
  addNotification,
  getVoucherInfoRequest,
  getVoucherInfoGuestRequest,
  voucherError,
}) => {
  const { register, getValues, setValue } = useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedAccount, setIsCheckedAccount] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const [btChecked, setBtChecked] = useState(false);
  const [rambursChecked, setRambursChecked] = useState(false);
  const [fanCourierDeliveryChecked, setFanCourierDeliveryChecked] =
    useState(false);
  const [postalDeliveryChecked, setPostalDeliveryChecked] = useState(false);

  const [voucherInput, setVoucherInput] = useState(false);
  const [validated, setValidated] = useState(false);
  const [judet, setJudet] = useState("");
  const [localitateLivrare, setLocalitateLivrare] = useState("");
  const [judetFacturare, setJudetFacturare] = useState("");

  const [checkTerms, setCheckTerms] = useState(false);

  const [deliveryFee, setDeliveryFee] = useState(0);

  const handleCheckbox = () => {
    setIsCheckedAccount(false);
    setIsChecked(!isChecked);
  };

  const handleCheckboxAccount = () => {
    setIsChecked(false);
    setIsCheckedAccount(!isCheckedAccount);
  };

  useEffect(() => {
    if (postalDeliveryChecked) {
      setDeliveryFee(DELIVERY_FEE);
    } else if (
      fanCourierDeliveryChecked &&
      judet.length > 0 &&
      localitateLivrare.length > 0
    ) {
      let localitate = localitateLivrare;
      if (judet.includes("Bucuresti")) {
        localitate = "Bucuresti";
      }

      const totalWeight =
        cartProducts.reduce((prev, curr) => prev + curr.weight, 0) || 1;

      fetch(
        `${
          process.env.REACT_APP_API_URL
        }/public/orders/delivery/fee?county=${judet}&city=${localitate}&weight=${Math.max(
          totalWeight,
          1
        )}`
      )
        .then((res) => res.json())
        .then((res) => setDeliveryFee(res));
    } else {
      setDeliveryFee(DELIVERY_FEE);
    }
  }, [
    judet,
    localitateLivrare,
    postalDeliveryChecked,
    fanCourierDeliveryChecked,
  ]);

  useEffect(() => {
    if (isCheckedAccount) {
      if (user && info) {
        setValue(
          "nume_livrare",
          info.deliveryAddress.firstName ? info.deliveryAddress.firstName : ""
        );
        setValue(
          "prenume_livrare",
          info.deliveryAddress.lastName ? info.deliveryAddress.lastName : ""
        );
        setValue(
          "telefon_livrare",
          info.deliveryAddress.phone ? info.deliveryAddress.phone : ""
        );
        setValue(
          "email_livrare",
          info.deliveryAddress.email ? info.deliveryAddress.email : ""
        );
        setValue(
          "judet_livrare",
          info.deliveryAddress.county ? info.deliveryAddress.county : ""
        );
        setValue(
          "localitate_livrare",
          info.deliveryAddress.city ? info.deliveryAddress.city : ""
        );
        setValue(
          "adresa_livrare",
          info.deliveryAddress.address ? info.deliveryAddress.address : ""
        );
        setValue(
          "codPostal_livrare",
          info.deliveryAddress.postalCode ? info.deliveryAddress.postalCode : ""
        );
        setJudet(info.deliveryAddress.county);
        setLocalitateLivrare(info.deliveryAddress.city);

        setValue(
          "nume_facturare",
          info.billingAddress.firstName ? info.billingAddress.firstName : ""
        );
        setValue(
          "prenume_facturare",
          info.billingAddress.lastName ? info.billingAddress.lastName : ""
        );
        setValue(
          "telefon_facturare",
          info.billingAddress.phone ? info.billingAddress.phone : ""
        );
        setValue(
          "email_facturare",
          info.billingAddress.email ? info.billingAddress.email : ""
        );
        setValue(
          "judet_facturare",
          info.billingAddress.county ? info.billingAddress.county : ""
        );
        setValue(
          "localitate_facturare",
          info.billingAddress.city ? info.billingAddress.city : ""
        );
        setValue(
          "adresa_facturare",
          info.billingAddress.address ? info.billingAddress.address : ""
        );
        setValue(
          "codPostal_facturare",
          info.billingAddress.postalCode ? info.billingAddress.postalCode : ""
        );
        setJudetFacturare(info.billingAddress.county);
      }
    }
  }, [isCheckedAccount]);

  useEffect(() => {
    if (isChecked) {
      setValue("nume_facturare", getValues("nume_livrare"));
      setValue("prenume_facturare", getValues("prenume_livrare"));
      setValue("telefon_facturare", getValues("telefon_livrare"));
      setValue("email_facturare", getValues("email_livrare"));
      setValue("judet_facturare", getValues("judet_livrare"));
      setValue("localitate_facturare", getValues("localitate_livrare"));
      setValue("adresa_facturare", getValues("adresa_livrare"));
      setValue("codPostal_facturare", getValues("codPostal_livrare"));
    }
  }, [isChecked]);

  useEffect(() => {
    if (voucherError && voucherError.length > 0) {
      setVoucherInput(null);
      setValue("voucher", "");
      addNotification({ text: voucherError });
    }
  }, [voucherError]);

  useEffect(() => {
    if (cartProducts.length > 0) {
      const items = [];
      cartProducts.forEach((product) => {
        items.push({
          cartItemId: user ? product.cartItemId : null,
          productCode: product.code ? product.code : product.productCode,
          quantity: product.quantity,
        });
      });
      setCartItems(items);
    }
  }, [cartProducts]);

  const onSubmit = () => {
    if (
      getValues("nume_facturare") === "" ||
      getValues("nume_livrare") === "" ||
      getValues("prenume_facturare") === "" ||
      getValues("prenume_livrare") === "" ||
      getValues("telefon_facturare") === "" ||
      getValues("telefon_livrare") === "" ||
      getValues("email_facturare") === "" ||
      getValues("email_livrare") === "" ||
      getValues("judet_facturare") === "" ||
      getValues("judet_livrare") === "" ||
      getValues("localitate_facturare") === "" ||
      getValues("localitate_livrare") === "" ||
      getValues("adresa_facturare") === "" ||
      getValues("adresa_livrare") === "" ||
      getValues("codPostal_facturare") === "" ||
      getValues("codPostal_livrare") === ""
    ) {
      addNotification({
        text: "Trebuie să completați toate câmpurile obligatorii !",
      });
      return;
    }

    for (let i = 0; i < cartProducts.length; i++) {
      if (
        cartProducts[i].stockStatus === "OUT_OF_STOCK" ||
        cartProducts[i].stockStatus === "indisponibil"
      ) {
        addNotification({
          text: "Produsul " + cartProducts[i].name + " nu se afla in stock!",
        });
        return;
      }
    }

    if (rambursChecked) {
      getRambursPaymentRequest({
        voucherCode: user ? getValues("voucher") : null,
        deliveryAddressId:
          info && user && isCheckedAccount ? info.deliveryAddress.id : null,
        billingAddressId:
          user && info && isCheckedAccount ? info.billingAddress.id : null,
        currency: "RON",
        cartItems: cartItems,
        deliveryAddress: {
          firstName: getValues("nume_livrare"),
          lastName: getValues("prenume_livrare"),
          phone: getValues("telefon_livrare"),
          email: getValues("email_livrare"),
          country: "Romania",
          county: judet,
          city: getValues("localitate_livrare"),
          address: getValues("adresa_livrare"),
          postalCode: getValues("codPostal_livrare"),
          cui: null,
        },
        billingAddress: {
          firstName: getValues("nume_facturare"),
          lastName: getValues("prenume_facturare"),
          phone: getValues("telefon_facturare"),
          email: getValues("email_facturare"),
          country: "Romania",
          county: isChecked ? judet : judetFacturare,
          city: isChecked
            ? getValues("localitate_livrare")
            : getValues("localitate_facturare"),
          address: getValues("adresa_facturare"),
          postalCode: getValues("codPostal_facturare"),
          cui: getValues("cui"),
        },
        isLogged: user,
        deliveryType: fanCourierDeliveryChecked
          ? "FAN_COURIER"
          : "POSTA_ROMANA",
        referrer: JSON.parse(localStorage.getItem(LOCAL_STORAGE_REFERRER)),
      });
    } else if (btChecked) {
      getBTPaymentRequest({
        voucherCode: user ? getValues("voucher") : null,
        deliveryAddressId:
          info && user && isCheckedAccount ? info.deliveryAddress.id : null,
        billingAddressId:
          user && info && isCheckedAccount ? info.billingAddress.id : null,
        currency: "RON",
        cartItems: cartItems,
        deliveryAddress: {
          firstName: getValues("nume_livrare"),
          lastName: getValues("prenume_livrare"),
          phone: getValues("telefon_livrare"),
          email: getValues("email_livrare"),
          country: "Romania",
          county: judet,
          city: getValues("localitate_livrare"),
          address: getValues("adresa_livrare"),
          postalCode: getValues("codPostal_livrare"),
          cui: null,
        },
        billingAddress: {
          firstName: getValues("nume_facturare"),
          lastName: getValues("prenume_facturare"),
          phone: getValues("telefon_facturare"),
          email: getValues("email_facturare"),
          country: "Romania",
          county: isChecked ? judet : judetFacturare,
          city: isChecked
            ? getValues("localitate_livrare")
            : getValues("localitate_facturare"),
          address: getValues("adresa_facturare"),
          postalCode: getValues("codPostal_facturare"),
          cui: getValues("cui"),
        },
        isLogged: user,
        deliveryType: fanCourierDeliveryChecked
          ? "FAN_COURIER"
          : "POSTA_ROMANA",
        referrer: JSON.parse(localStorage.getItem(LOCAL_STORAGE_REFERRER)),
      });
    }
  };

  const handleSubmitFunc = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  const totalPrice = useMemo(() => {
    const productsSum = cartProducts.reduce((acc, current) => {
      const price =
        (current.price || current.price === 0) && !current.currentPrice
          ? (current.price +
              Number(current.priceSup) /
                Math.pow(10, parseFloat(String(current.priceSup).length))) *
            current.quantity
          : current.currentPrice * current.quantity;
      const sumAcc = Number(acc) + Number(price);
      return sumAcc;
    }, 0);

    return productsSum;
  }, [cartProducts]);

  return (
    <div className={"container col-12 " + styles.order_completion_container}>
      <Row>
        <Col xs={12}>
          <h1 className={styles.title}> Finalizare comandă</h1>
        </Col>
      </Row>

      <div className={styles.order_data_container} style={{ padding: "0%" }}>
        <Col xs={12} className={styles.sumar}>
          <Row style={{ padding: "2% 0%" }}>
            <h4 style={{ paddingLeft: "3%" }}>Sumar</h4>
          </Row>
          <Row style={{ padding: "0% 3%" }}>
            <Col sm={8} xs={8} style={{ padding: "0" }}>
              <h5>Nume</h5>
              {cartProducts.map((product) => {
                return (
                  <>
                    <li className={styles.form_label}>
                      {" "}
                      {product.name || product.productName}{" "}
                    </li>
                  </>
                );
              })}
            </Col>
            <Col sm={2} xs={2} style={{ textAlign: "center", padding: "0" }}>
              <h5>Cantitate</h5>
              {cartProducts.map((product) => {
                return (
                  <>
                    <p className={styles.form_label}>{product.quantity}</p>
                  </>
                );
              })}
            </Col>
            <Col sm={2} xs={2} style={{ textAlign: "center", padding: "0" }}>
              <h5>Preț</h5>
              {cartProducts.map((product) => {
                return (
                  <>
                    {(product.price || product.price == 0) &&
                    !product.currentPrice ? (
                      <p className={styles.form_label}>
                        {(
                          (product.price +
                            Number(product.priceSup) /
                              Math.pow(
                                10,
                                parseFloat(String(product.priceSup).length)
                              )) *
                          product.quantity
                        ).toFixed(2)}{" "}
                        RON
                      </p>
                    ) : (
                      <p className={styles.form_label}>
                        {(
                          (product.currentPrice || product.productPrice) *
                          product.quantity
                        ).toFixed(2)}{" "}
                        RON
                      </p>
                    )}
                  </>
                );
              })}
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "4%",
              backgroundColor: "#B9BDC3",
              padding: "0%",
            }}
          >
            <div className={styles.sumar_total}>
              <div>Total produse: </div>
              <p> {totalPrice.toFixed(2)} RON</p>
            </div>
          </Row>
        </Col>
      </div>

      <div className={styles.order_data_container}>
        <Col md={6} xs={12} className={styles.col_delivery}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4 style={{ marginLeft: "3%" }}>Adresă livrare</h4>
            <i className={"fas fa-truck " + styles.icon}></i>
          </Row>
          <OrderForm
            id="form"
            isDelivery={true}
            validated={validated}
            handleSubmit={handleSubmitFunc}
            register={register}
            handleCheckbox={handleCheckbox}
            isCheckedAccount={isCheckedAccount}
            isChecked={isChecked}
            handleCheckboxAccount={handleCheckboxAccount}
            nume="nume_livrare"
            prenume="prenume_livrare"
            telefon="telefon_livrare"
            email="email_livrare"
            county="judet_livrare"
            localitate="localitate_livrare"
            adresa="adresa_livrare"
            setJudetName={setJudet}
            setLocalitateName={setLocalitateLivrare}
            codPostal="codPostal_livrare"
          />
        </Col>

        <Col md={6} xs={12} className={styles.col_billing}>
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "3%",
            }}
          >
            <h4 style={{ marginLeft: "5%" }}>Adresă facturare</h4>
            <i
              className={
                "fas fa-clipboard-list " + styles.icon + " " + styles.clipboard
              }
            ></i>
          </Row>
          <OrderForm
            id="form"
            isDelivery={false}
            validated={validated}
            handleSubmit={handleSubmitFunc}
            register={register}
            isCheckedAccount={isCheckedAccount}
            isChecked={isChecked}
            nume="nume_facturare"
            prenume="prenume_facturare"
            telefon="telefon_facturare"
            email="email_facturare"
            county="judet_facturare"
            localitate="localitate_facturare"
            adresa="adresa_facturare"
            cui="cui"
            className={styles.facturare}
            setJudetName={setJudetFacturare}
            setLocalitateName={() => {}}
            codPostal="codPostal_facturare"
          />
        </Col>
      </div>

      <div className={styles.order_data_container}>
        <Col md={12} xs={12} className={styles.col_payment}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4>Plată</h4>
            <i className={"far fa-credit-card " + styles.icon}></i>
          </Row>
          <Row>
            <p>Alegeți una dintre variantele de mai jos</p>
          </Row>
          <Col>
            <Row>
              <div class="col-sm-6 col-xs-12 custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="defaultUnchecked"
                  name="defaultExampleRadios"
                  onClick={() => {
                    setBtChecked(true);
                    setRambursChecked(false);
                  }}
                />
                <label
                  className={"custom-control-label " + styles.radio_label}
                  for="defaultUnchecked"
                >
                  Online - cu card bancar
                </label>
              </div>
              <div className={"col-sm-5 col-xs-12 " + styles.images_container}>
                <img
                  src={visa}
                  alt="comanda-visa-icon"
                  className={styles.logo}
                  style={{ marginRight: "1%" }}
                />
                <img
                  src={mastercard}
                  alt="comanda-mastercard-icon"
                  className={styles.logo}
                />
                <img
                  src={maestro}
                  alt="comanda-maestro-icon"
                  className={styles.logo + " " + styles.maestro}
                />
              </div>
              <div
                class="custom-control-inline"
                style={{ margin: "0% 10% 2%" }}
              >
                <img
                  src={btPay}
                  alt="comanda-bt-pay-logo"
                  style={{ marginRight: "1%" }}
                  className={styles.logo + " " + styles.btpay}
                />
                <p className={styles.form_label}>
                  Folosind mediul securizat oferit de Banca Transilvania.
                </p>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="defaultUnchecked2"
                  name="defaultExampleRadios"
                  onClick={() => {
                    setRambursChecked(true);
                    setBtChecked(false);
                  }}
                />
                <label
                  className={"custom-control-label " + styles.radio_label}
                  for="defaultUnchecked2"
                >
                  Ramburs
                </label>
              </div>
            </Row>
          </Col>
        </Col>
      </div>

      <div className={styles.order_data_container}>
        <Col md={12} xs={12} className={styles.col_payment}>
          <Row
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <h4>Livrare</h4>
            <i className={"fas fa-truck " + styles.icon}></i>
          </Row>
          <Row>
            <p>Alegeți una dintre variantele de mai jos</p>
          </Row>
          <Col>
            <Row>
              <div class="col-sm-6 col-xs-12 custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="defaultUnchecked3"
                  name="defaultExampleRadios2"
                  onClick={() => {
                    setFanCourierDeliveryChecked(true);
                    setPostalDeliveryChecked(false);
                  }}
                />
                <label
                  className={"custom-control-label " + styles.radio_label}
                  for="defaultUnchecked3"
                >
                  FAN Courier
                </label>
              </div>
              <div
                className={"col-sm-5 col-xs-12 " + styles.images_container}
              ></div>
              <div
                class="custom-control-inline"
                style={{ margin: "0% 10% 2%" }}
              ></div>
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  class="custom-control-input"
                  id="defaultUnchecked4"
                  name="defaultExampleRadios2"
                  onClick={() => {
                    setFanCourierDeliveryChecked(false);
                    setPostalDeliveryChecked(true);
                  }}
                />
                <label
                  className={"custom-control-label " + styles.radio_label}
                  for="defaultUnchecked4"
                >
                  Poșta Română
                </label>
              </div>
            </Row>
          </Col>
        </Col>
      </div>

      <div
        className={
          " col-md-6 col-xs-12 ml-auto " +
          styles.order_data_container +
          " " +
          styles.total_container
        }
        style={{ width: "100%" }}
      >
        <Col>
          <Row
            style={{ margin: "auto", borderBottom: "1px solid black" }}
            className={styles.col_total + " " + styles.total}
          >
            <Col xs={4} style={{ paddingLeft: "0" }}>
              <h4>Total</h4>
            </Col>
            <Col xs={8}>
              <Row style={{ justifyContent: "space-between" }}>
                <p>{cartLength} x Produse</p>
                <p> {totalPrice.toFixed(2)} RON</p>
              </Row>
              <Row style={{ justifyContent: "space-between" }}>
                <p>Livrare</p>
                <p>
                  {" "}
                  {totalPrice > costMax
                    ? 0
                    : deliveryFee < 25
                    ? 25
                    : deliveryFee}{" "}
                  RON
                </p>
              </Row>
            </Col>
          </Row>

          <Row
            style={{
              margin: "auto",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={styles.col_total}
          >
            {!voucherInput && (
              <a
                className={styles.voucher}
                onClick={() => setVoucherInput(true)}
              >
                Ai un voucher?
              </a>
            )}
            {voucherInput && (
              <div style={{ width: "100%" }} className={styles.div_voucher}>
                <i className="fa fa-percent"></i>
                <input
                  className={styles.input_voucher}
                  placeholder="Cod voucher"
                  name="voucher"
                  ref={register}
                ></input>
                <button
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                      getVoucherInfoRequest(getValues("voucher"));
                    } else if (!getValues("email_livrare").includes("@")) {
                      addNotification({
                        text: "Introduceți o adresă de e-mail validă la adresa de livrare!",
                      });
                    } else {
                      getVoucherInfoGuestRequest({
                        voucherCode: getValues("voucher"),
                        email: getValues("email_livrare"),
                      });
                    }
                  }}
                >
                  Aplică voucher
                </button>
              </div>
            )}
            <p>* Reducerea se aplică raportat la totalul produselor</p>
          </Row>

          <Row
            style={{ margin: "auto", justifyContent: "space-between" }}
            className={styles.col_total}
          >
            <h4>De plată</h4>
            <div className={styles.all_total}>
              {cartProducts && (
                <h4>
                  {(
                    totalPrice +
                    (totalPrice > costMax
                      ? 0
                      : deliveryFee < 25
                      ? 25
                      : deliveryFee)
                  ).toFixed(2)}{" "}
                  RON
                </h4>
              )}
              {voucher && (
                <h3>
                  -
                  {voucher.amount < 1
                    ? voucher.amount * 100 + " %"
                    : voucher.amount + " RON"}
                </h3>
              )}
              {voucher && voucher.code === getValues("voucher") && (
                <h4 style={{ marginTop: "-5%" }}>_______________</h4>
              )}
              <h4>
                {voucher && voucher.amount < 1
                  ? (
                      totalPrice -
                      totalPrice *
                        (voucher.code === getValues("voucher") &&
                        voucher.amount > 0
                          ? voucher.amount
                          : 1) +
                      (totalPrice > costMax
                        ? 0
                        : deliveryFee < 25
                        ? 25
                        : deliveryFee)
                    ).toFixed(2) + " RON"
                  : voucher && voucher.amount >= 1
                  ? (
                      totalPrice -
                      (voucher.code === getValues("voucher") &&
                      voucher.amount < totalPrice
                        ? voucher.amount
                        : 0) +
                      (totalPrice > costMax
                        ? 0
                        : deliveryFee < 25
                        ? 25
                        : deliveryFee)
                    ).toFixed(2) + " RON"
                  : " "}
              </h4>
            </div>
          </Row>
          {!user && (
            <Row
              style={{ width: "80%", margin: "auto" }}
              className={styles.terms}
            >
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="defaultUncheckedTerms"
                  name="defaultExampleRadios"
                  style={{ width: "0.5rem" }}
                  required
                  onClick={() => setCheckTerms(!checkTerms)}
                />
                <label
                  className={
                    "custom-control-label " +
                    styles.radio_label +
                    " " +
                    styles.terms_label
                  }
                  for="defaultUncheckedTerms"
                >
                  Sunt de acord cu
                </label>
                <Link
                  style={{ color: "#2593E7" }}
                  className={styles.terms_label}
                  to="/terms"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {" "}
                  Termenii și condițiile
                </Link>
              </div>
            </Row>
          )}
          <Row
            className={styles.button_row}
            style={{
              background: "#E04C35 0% 0% no-repeat padding-box",
              boxShadow: "0px 3px 3px #00000029",
              opacity: "1",
              padding: "0",
              margin: "3% 9%",
            }}
          >
            {!user ? (
              // <button form="form" className={styles.button} onClick={onSubmit} disabled={!((checkTerms && btChecked) || (checkTerms && rambursChecked))}>
              <button
                form="form"
                className={styles.button}
                onClick={onSubmit}
                disabled={
                  !(
                    checkTerms &&
                    (btChecked || rambursChecked) &&
                    (fanCourierDeliveryChecked || postalDeliveryChecked)
                  )
                }
              >
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Următorul pas"
                )}
              </button>
            ) : (
              <button
                form="form"
                className={styles.button}
                onClick={onSubmit}
                disabled={
                  !(
                    (btChecked || rambursChecked) &&
                    (fanCourierDeliveryChecked || postalDeliveryChecked)
                  )
                }
              >
                {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Următorul pas"
                )}
              </button>
            )}
          </Row>
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cartProducts: state.cart.cartProducts,
  cartLength: state.cart.cartLength,
  order: state.order.order,
  info: state.order.info,
  user: state.registerAccount.user,
  voucher: state.order.voucher,
  isLoading: state.order.isLoading,
  voucherError: state.order.voucherError,
});

const mapDispatchToProps = (dispatch) => ({
  getBTPaymentRequest: (payload) => dispatch(getBTPaymentRequest(payload)),
  getRambursPaymentRequest: (payload) =>
    dispatch(getRambursPaymentRequest(payload)),
  getOrdersInfoRequest: (payload) => dispatch(getOrdersInfoRequest(payload)),
  getVoucherInfoRequest: (payload) => dispatch(getVoucherInfoRequest(payload)),
  getVoucherInfoGuestRequest: (payload) =>
    dispatch(getVoucherInfoGuestRequest(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderCompletion);
