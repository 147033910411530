import React, { useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setOrder } from "../../redux/filters/filters.actions";
import { ORDER_MAP } from "../../utilities/constants";

const SelectOrder = ({ order, setOrder }) => {
  const [showMenu, setShowMenu] = useState(false);

  const orderSign = useMemo(() => {
    const valueSplit = ORDER_MAP[order].split(" ");
    if (valueSplit[1] === "s")
      return (
        <span>
          {valueSplit[0]} <i class="fas fa-arrow-up"></i>{" "}
        </span>
      );
    else
      return (
        <span>
          {valueSplit[0]} <i class="fas fa-arrow-down"></i>{" "}
        </span>
      );
  }, [order]);

  return (
    <div className="filters-select filter-order">
      <Button onClick={() => setShowMenu((state) => !state)}>
        {orderSign}
        <i class="fas fa-chevron-down"></i>
      </Button>
      {showMenu && (
        <ul className="filter-order-menu">
          {Object.entries(ORDER_MAP).map((order) => {
            const valueSplit = order[1].split(" ");
            return (
              <li
                onClick={() => {
                  setOrder(order[0]);
                  setShowMenu((state) => !state);
                }}
              >
                {valueSplit[0]}{" "}
                {valueSplit[1] === "s" ? (
                  <i class="fas fa-arrow-up"></i>
                ) : (
                  <i class="fas fa-arrow-down"></i>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  order: state.filters.order,
});

const mapDispatchToProps = (dispatch) => ({
  setOrder: (payload) => dispatch(setOrder(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectOrder);
