import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import "./QuantityCard.scss";
import {
  decreaseItem,
  incrementItem,
  addNotification,
} from "../../../redux/product/product.actions";
import {
  addToCart,
  addToCartLogged,
  updateCartQuantityRequest,
} from "../../../redux/cart/cart.actions";
import { pixelAddToCartEvent } from "../../../utilities/marketing-events";

const QuantityCard = ({
  decreaseItem,
  incrementItem,
  clicks,
  product,
  addToCart,
  addToCartLogged,
  addNotification,
  updateCartRequest,
  user,
  cartProducts,
}) => {
  if (product)
    return (
      <div className="quantity-card">
        {/* WEB */}
        {product.currentPrice - product.oldPrice === 0 ? (
          <></>
        ) : (
          <Row className="old-price-row">
            <p className="old-price">
              {String(product.oldPrice).split(/[\s.]+/)[0]}
              <sup>
                {
                  String(Math.round(product.oldPrice * 100) / 100).split(
                    /[\s.]+/
                  )[1]
                }
              </sup>{" "}
              <span>{"RON"}</span>
            </p>
          </Row>
        )}

        <Row
          className={
            product.currentPrice - product.oldPrice === 0
              ? "price-row-2"
              : "price-row"
          }
        >
          <p style={{ display: "none" }} itemProp="price">
            {product.currentPrice}
          </p>
          <p className="price">
            {String(product.currentPrice).split(/[\s.]+/)[0]}
            <sup>
              {
                String(Math.round(product.currentPrice * 100) / 100).split(
                  /[\s.]+/
                )[1]
              }
            </sup>
            <span itemProp="priceCurrency">{"RON"}</span>
          </p>
        </Row>
        <Row className="label-qty">Cantitate</Row>
        <Row>
          <Button className="qty-btn">
            <Row>
              <Col md={4} xs={4} onClick={() => decreaseItem(clicks)}>
                {" "}
                - |
              </Col>
              <Col xs={4}>{clicks}</Col>
              <Col md={4} xs={4} onClick={() => incrementItem(clicks)}>
                {" "}
                | +{" "}
              </Col>
            </Row>
          </Button>
        </Row>

        {/* MOBILE */}
        <Row className="mobile-elements">
          <Col xs={6} style={{ marginLeft: "9%", paddingLeft: "0" }}>
            Cantitate
            <Row style={{ borderRight: "1px solid #707070" }}>
              <Button className="qty-btn-mobile">
                <Row>
                  <Col md={4} xs={4} onClick={() => decreaseItem(clicks)}>
                    {" "}
                    -{" "}
                  </Col>
                  <Col xs={4}>{clicks}</Col>
                  <Col md={4} xs={4} onClick={() => incrementItem(clicks)}>
                    {" "}
                    +{" "}
                  </Col>
                </Row>
              </Button>
            </Row>
          </Col>
          <Col>
            Preț
            <p className="price-mobile">
              {String(product.currentPrice).split(/[\s.]+/)[0]}
              <sup>
                {
                  String(Math.round(product.currentPrice * 100) / 100).split(
                    /[\s.]+/
                  )[1]
                }
              </sup>
              <span>{"RON"}</span>
            </p>
          </Col>
        </Row>

        <Row>
          <Button
            className="add-btn"
            onClick={() => {
              if (product.stockStatus === "OUT_OF_STOCK")
                return addNotification({
                  text: "nu este în stoc!",
                  boldText: product.name,
                });

              const currentPrice = String(product.currentPrice).split(/[\s.]+/);
              const oldPrice = String(product.oldPrice).split(/[\s.]+/);

              const productObj = {
                ...product,
                oldPrice: oldPrice[0],
                price: currentPrice[0],
                oldPriceSup: oldPrice[1] ? oldPrice[1] : "0",
                priceSup: currentPrice[1] ? currentPrice[1] : "0",
                location: "cartProducts",
                quantityToAdd: clicks,
                code: product.code || product.productCode,
              };
              let ok = false;
              if (user) {
                cartProducts.forEach((cartProduct) => {
                  if (
                    cartProduct.code === productObj.code ||
                    productObj.code === cartProduct.productCode
                  ) {
                    updateCartRequest({
                      product: { ...productObj },
                      quantity: cartProduct.quantity + 1,
                    });
                    ok = true;
                  }
                });
                if (!ok) addToCartLogged(productObj);
              } else addToCart(productObj);
              pixelAddToCartEvent(product.code || product.productCode, product.currentPrice);
              addNotification({
                text: " a fost adaugat in cos!",
                boldText: product.name,
              });
            }}
          >
            Adaugă în coș
          </Button>
        </Row>
      </div>
    );
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  decreaseItem: (payload) => dispatch(decreaseItem(payload)),
  updateCartRequest: (payload) => dispatch(updateCartQuantityRequest(payload)),
  incrementItem: (payload) => dispatch(incrementItem(payload)),
  addToCart: (payload) => dispatch(addToCart(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
  addToCartLogged: (payload) => dispatch(addToCartLogged(payload)),
});
const mapStateToProps = (state) => ({
  clicks: state.product.clicks,
  product: state.product.product,
  user: state.registerAccount.user,
  cartProducts: state.cart.cartProducts,
});
export default connect(mapStateToProps, mapDispatchToProps)(QuantityCard);
