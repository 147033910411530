import {
  ADD_BRAND_ACTIVE_FILTER,
  NEXT_PAGE_FILTERS,
  PREV_PAGE_FILTERS,
  REMOVE_BRAND_ACTIVE_FILTER,
  SEARCH_GUEST_REQUEST,
  SET_ORDER,
  SET_PRICE_ACTIVE_FILTER,
  SET_STOCK_ACTIVE_FILTER,
  SET_LAST_PAGE_FILTERS,
  SET_PREV_PAGE_FILTERS,
  SET_FIRST_PAGE_FILTERS,
  SET_NEXT_PAGE_FILTERS,
  SET_CHANGED_FILTERS,
} from "./filters.constants";

export const addBrandActiveFilter = (payload) => {
  return {
    type: ADD_BRAND_ACTIVE_FILTER,
    payload,
  };
};

export const removeBrandActiveFilter = (payload) => {
  return {
    type: REMOVE_BRAND_ACTIVE_FILTER,
    payload,
  };
};

export const setPriceActiveFilter = (payload) => {
  return {
    type: SET_PRICE_ACTIVE_FILTER,
    payload,
  };
};

export const setStockActiveFilter = (payload) => {
  return {
    type: SET_STOCK_ACTIVE_FILTER,
    payload,
  };
};

export const searchGuestRequest = (payload, options) => {
  return {
    type: SEARCH_GUEST_REQUEST,
    payload,
    options,
  };
};
export const setOrder = (payload) => {
  return {
    type: SET_ORDER,
    payload,
  };
};
export const nextPage = () => {
  return {
    type: NEXT_PAGE_FILTERS,
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE_FILTERS,
  };
};

export const setNextPage = () => {
  return {
    type: SET_NEXT_PAGE_FILTERS,
  };
};

export const setPrevPage = () => {
  return {
    type: SET_PREV_PAGE_FILTERS,
  };
};

export const setFirstPage = () => {
  return {
    type: SET_FIRST_PAGE_FILTERS,
  };
};

export const setLastPage = () => {
  return {
    type: SET_LAST_PAGE_FILTERS,
  };
};

export const setChangedFilters = (payload) => {
  return {
    type: SET_CHANGED_FILTERS,
    payload,
  };
};
