export const ORDER_RAMBURS_REQUEST = "ORDER_RAMBURS_REQUEST";
export const ORDER_RAMBURS_SUCCESS = "ORDER_RAMBURS_SUCCESS";
export const ORDER_RAMBURS_FAILURE = "ORDER_RAMBURS_FAILURE";

export const ORDER_BT_REQUEST = "ORDER_BT_REQUEST";
export const ORDER_BT_SUCCESS = "ORDER_BT_SUCCESS";
export const ORDER_BT_FAILURE = "ORDER_BT_FAILURE";

export const BT_STATUS_REQUEST = "BT_STATUS_REQUEST";
export const BT_STATUS_SUCCESS = "BT_STATUS_SUCCESS";
export const BT_STATUS_FAILURE = "BT_STATUS_FAILURE";

export const ORDER_INFO_REQUEST = "ORDER_INFO_REQUEST";
export const ORDER_INFO_SUCCESS = "ORDER_INFO_SUCCESS";
export const ORDER_INFO_FAILURE = "ORDER_INFO_FAILURE";

export const VOUCHER_INFO_REQUEST = "VOUCHER_INFO_REQUEST";
export const VOUCHER_INFO_SUCCESS = "VOUCHER_INFO_SUCCESS";
export const VOUCHER_INFO_ERROR = "VOUCHER_INFO_ERROR";

export const VOUCHER_INFO_GUEST_REQUEST = "VOUCHER_INFO_GUEST_REQUEST";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
