import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Image } from "react-bootstrap";
import heroDesktop from "../../../images/Home/hero-desktop.png";
import heroMobile from "../../../images/Home/hero-mobile.png";
// import heroDesktop from "../../../images/Home/easter-desktop.jpg"
// import heroMobile from "../../../images/Home/easter-mobile.jpg"

const CarouselHeader = () => {
  const products = [
    {
      image: heroDesktop,
    },
  ];

  const products_mobile = [
    {
      image: heroMobile,
    },
  ];

  return (
    <>
      <Carousel
        className="web-carousel"
        infiniteLoop={false}
        autoPlay={false}
        interval={4000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
      >
        {products.map((c, index) => (
            <div>
              <Image src={c.image} alt="banner" />
            </div>
        ))}
      </Carousel>

      <Carousel
        className="mobile-carousel"
        infiniteLoop={false}
        autoPlay={false}
        interval={4000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        stopOnHover={false}
      >
        {products_mobile.map((c, index) => (
        
            <div>
              <Image src={c.image} alt="brand-proline-banner-icon" />
            </div>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselHeader;
