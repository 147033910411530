import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  EDIT_INFO_REQUEST,
  EDIT_INFO_SUCCESS,
  GET_INFO_REQUEST,
  GET_INFO_SUCCESS,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_DETAILS_REQUEST,
  GET_ORDERS_DETAILS_SUCCESS,
  EDIT_INFO_FAILURE,
  CHANGE_EMAIL_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_FAILURE,
} from "./account.constants";
import { ADD_NOTIFICATION } from "../product/product.constants";

export function* changePassword({ payload }) {
  const { oldPassword, newPassword, newPasswordConfirmed } = payload;
  try {
    const response = yield call(apiService.changePassword, {
      oldPassword,
      newPassword,
      newPasswordConfirmed,
    });
    const status = response.status;
    if (response) {
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: { payload },
      });
      if (status === 200) {
        localStorage.removeItem("token");
        window.location.assign(`/login`);
        yield put({
          type: ADD_NOTIFICATION,
          payload: { boldText: "Parola a fost schimbată cu succes!" },
        });
      }
    }
  } catch (err) {
    if (
      err.response.data.message === "Old password is incorrect!" &&
      err.response.status === 400
    ) {
      yield put({
        type: CHANGE_PASSWORD_FAILURE,
        payload: "Parola actuală este incorectă!",
      });
    } else
      yield put({
        type: CHANGE_PASSWORD_FAILURE,
        payload: "Schimbarea parolei a eșuat. Vă rugăm încercați mai târziu!",
      });
  }
}

export function* changePasswordWatcher() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, changePassword);
}

export function* changePasswordSaga() {
  yield all([call(changePasswordWatcher)]);
}

export function* changeEmail({ payload }) {
  const { newEmail, newEmailConfirmed } = payload;
  try {
    const response = yield call(apiService.changeEmail, {
      newEmail,
      newEmailConfirmed,
    });
    const status = response.status;
    if (response) {
      yield put({
        type: CHANGE_EMAIL_SUCCESS,
        payload: { payload, status },
      });
      if (status === 200) {
        localStorage.removeItem("token");
        window.location.assign(`/login`);
        yield put({
          type: ADD_NOTIFICATION,
          payload: { boldText: "Email-ul a fost schimbat cu succes!" },
        });
      }
    }
  } catch (err) {
    yield put({
      type: CHANGE_EMAIL_FAILURE,
      payload: "Schimbarea email-ului a eșuat. Vă rugăm încercați mai târziu!",
    });
  }
}

export function* changeEmailWatcher() {
  yield takeLatest(CHANGE_EMAIL_REQUEST, changeEmail);
}

export function* changeEmailSaga() {
  yield all([call(changeEmailWatcher)]);
}

export function* editInfo({ payload }) {
  const { firstName, lastName, deliveryAddress, billingAddress } = payload;
  try {
    const response = yield call(apiService.editInfo, {
      firstName,
      lastName,
      deliveryAddress,
      billingAddress,
    });
    const status = response.status;
    if (response) {
      yield put({
        type: EDIT_INFO_SUCCESS,
        payload: { payload },
      });
      if (status === 200) {
        yield put({
          type: ADD_NOTIFICATION,
          payload: {
            boldText: "Informațiile editate au fost salvate cu succes!",
          },
        });
      }
    }
  } catch (err) {
    yield put({
      type: EDIT_INFO_FAILURE,
      payload: "Editarea contului a eșuat. Vă rugăm încercați mai târziu!",
    });
  }
}

export function* editInfoWatcher() {
  yield takeLatest(EDIT_INFO_REQUEST, editInfo);
}

export function* editInfoSaga() {
  yield all([call(editInfoWatcher)]);
}

export function* getInfo() {
  const { data: person } = yield call(apiService.getInfo);
  yield put({
    type: GET_INFO_SUCCESS,
    payload: { person },
  });
}

export function* getInfoWatcher() {
  yield takeLatest(GET_INFO_REQUEST, getInfo);
}

export function* getInfoSaga() {
  yield all([call(getInfoWatcher)]);
}

export function* getOrders({ payload }) {
  const { currentPage } = payload;
  const {
    data: { products, numberOfPages },
  } = yield call(apiService.getOrders, currentPage);
  yield put({
    type: GET_ORDERS_SUCCESS,
    payload: { products, numberOfPages },
  });
}

export function* getOrdersWatcher() {
  yield takeLatest(GET_ORDERS_REQUEST, getOrders);
}

export function* getOrdersSaga() {
  yield all([call(getOrdersWatcher)]);
}

export function* getOrdersDetails({ payload }) {
  const { index } = payload;
  const { data: ordersDetails } = yield call(
    apiService.getOrdersDetails,
    index
  );
  yield put({
    type: GET_ORDERS_DETAILS_SUCCESS,
    payload: { ordersDetails },
  });
}

export function* getOrdersDetailsWatcher() {
  yield takeLatest(GET_ORDERS_DETAILS_REQUEST, getOrdersDetails);
}

export function* getOrdersDetailsSaga() {
  yield all([call(getOrdersDetailsWatcher)]);
}

export function* deleteAccount({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield call(apiService.deleteAccount, { email, password });
    const status = response.status;
    if (response) {
      yield put({
        type: DELETE_ACCOUNT_SUCCESS,
        payload: { payload },
      });
      if (status === 200) {
        localStorage.removeItem("token");
        window.location.assign(`/login`);
        yield put({
          type: ADD_NOTIFICATION,
          payload: { boldText: "Contul a fost șters cu succes!" },
        });
      }
    }
  } catch (err) {
    if (
      err.response.data.message ===
        "The account email does not match your email!" &&
      err.response.status === 400
    ) {
      yield put({
        type: DELETE_ACCOUNT_FAILURE,
        payload: "Email-ul contului nu corespunde cu email-ul introdus!",
      });
    } else if (
      err.response.data.message === "Invalid password!" &&
      err.response.status === 400
    ) {
      yield put({
        type: DELETE_ACCOUNT_FAILURE,
        payload: "Parola este incorectă!",
      });
    } else
      yield put({
        type: DELETE_ACCOUNT_FAILURE,
        payload: "Ștergerea contului a eșuat. Vă rugăm încercați mai târziu!",
      });
  }
}

export function* deleteAccountWatcher() {
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteAccount);
}

export function* deleteAccountSaga() {
  yield all([call(deleteAccountWatcher)]);
}
