import {
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  NEXT_PAGE_COMMENTS,
  PREV_PAGE_COMMENTS,
  SAVE_COMMENT_REQUEST,
  SAVE_COMMENT_SUCCESS,
} from "./comments.constants";

const INITIAL_STATE = {
  comments: [],
  currentPage: 1,
  numberOfPages: null,
  isLoading: false,
};

const PAGE_SIZE = 3;

export const commentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMMENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_COMMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: action.payload.comments,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
      };
    case NEXT_PAGE_COMMENTS:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case PREV_PAGE_COMMENTS:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case SAVE_COMMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SAVE_COMMENT_SUCCESS:
      let comments = state.comments;
      let currentPage = state.currentPage;
      let numberOfPages = state.numberOfPages;

      if (comments.length === 0) {
        comments = [action.payload.comment];
        currentPage = 1;
      } else if (comments.length % PAGE_SIZE === 0) {
        comments = [
          action.payload.comment,
          state.comments[0],
          state.comments[1],
        ];
        currentPage = 1;
      } else {
        comments = [action.payload.comment].concat(state.comments);
      }

      return {
        ...state,
        comments: comments,
        currentPage: currentPage,
        numberOfPages: numberOfPages,
        isLoading: false,
      };
    default:
      return state;
  }
};
