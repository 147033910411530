import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  EDIT_INFO_REQUEST,
  EDIT_INFO_SUCCESS,
  GET_INFO_REQUEST,
  GET_INFO_SUCCESS,
  GET_ORDERS_REQUEST,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_DETAILS_REQUEST,
  GET_ORDERS_DETAILS_SUCCESS,
  NEXT_PAGE_ACC,
  PREV_PAGE_ACC,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  SET_PREV_PAGE_ACC,
  SET_NEXT_PAGE_ACC,
  SET_LAST_PAGE_ACC,
  SET_FIRST_PAGE_ACC,
} from "./account.constants";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  person: null,
  products: [],
  ordersDetails: null,
  currentPage: 1,
  numberOfPages: null,
  errorDeleteAccount: null,
};

export const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EDIT_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_INFO_SUCCESS:
      return {
        ...state,
        person: action.payload.person,
        isLoading: false,
      };
    case GET_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INFO_SUCCESS:
      return {
        ...state,
        person: action.payload.person,
        isLoading: false,
      };
    case GET_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
        isLoading: false,
      };
    case GET_ORDERS_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORDERS_DETAILS_SUCCESS:
      return {
        ...state,
        ordersDetails: action.payload.ordersDetails,
        isLoading: false,
      };
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        errorDeleteAccount: action.payload,
      };
    case NEXT_PAGE_ACC:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case PREV_PAGE_ACC:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case SET_NEXT_PAGE_ACC:
      return {
        ...state,
        currentPage: state.currentPage + 2,
      };
    case SET_PREV_PAGE_ACC:
      return {
        ...state,
        currentPage: state.currentPage - 2,
      };
    case SET_FIRST_PAGE_ACC:
      return {
        ...state,
        currentPage: 1,
      };
    case SET_LAST_PAGE_ACC:
      return {
        ...state,
        currentPage: state.numberOfPages,
      };
    default:
      return state;
  }
};
