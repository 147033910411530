import React from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import "./Details.scss";

const Details = ({ product }) => {
  const isRichText =
    product.description[0] === "<" || product.description.includes("<br/>");

  if (product)
    return (
      <div className="main-div-details" itemProp="description">
        <Row>
          <Col md={12}>
            <p className="paragraph-style">
              {String(product.description) === "" ||
              product.description === "<p><br></p>" ? (
                "Acest produs nu are descriere"
              ) : isRichText ? (
                <div
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></div>
              ) : (
                String(product.description)
                  .split("\n")
                  .map((item, key) => {
                    return item === "" ? (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    ) : (
                      <span key={key}>
                        &bull; {item} <br />
                      </span>
                    );
                  })
              )}
            </p>
          </Col>
        </Row>
      </div>
    );
  return null;
};

const mapStateToProps = (state) => ({
  product: state.product.product,
});

export default connect(mapStateToProps, null)(Details);
