import { connect } from "react-redux";
import { compose } from "redux";
import {
  getOrdersDetailsRequest,
  getOrdersRequest,
} from "../../../redux/account/account.actions";
import MyOrders from "./MyOrders";

const mapDispatchToProps = (dispatch) => ({
  getOrdersRequest: (payload) => dispatch(getOrdersRequest(payload)),
  getOrdersDetailsRequest: (payload) =>
    dispatch(getOrdersDetailsRequest(payload)),
});

const mapStateToProps = (state) => ({
  products: state.account.products,
  ordersDetails: state.account.ordersDetails,
  currentPage: state.account.currentPage,
  numberOfPages: state.account.numberOfPages,
  isLoading: state.account.isLoading,
});

export const AccountOrders = compose(
  connect(mapStateToProps, mapDispatchToProps)(MyOrders)
);
