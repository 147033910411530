import React from "react";
import styles from "../../general.module.scss";
import OrderCompletion from "./OrderCompletion";
import SEO from "../../components/SEO/SEO";

const OrderCompletionPage = () => {
  return (
    <>
      <SEO
        title="Date livrare"
        description="Finalizeaza comanda pe RomStock"
        robots="noindex, follow"
        canonical="https://romstock.ro/order-completion"
        openGraph={{
          url: "https://www.romstock.ro/order-completion",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "Finalizeaza comanda pe RomStock",
        }}
      />
      <div className={styles.container}>
        <OrderCompletion />
      </div>
    </>
  );
};

export default OrderCompletionPage;
