import React from "react"
import styles from '../../general.module.scss'
import Home from "./Home"
import Helmet from "react-helmet"

const HomePage = () => {
  return (
    <div className={`${styles.container}`}>
      <Helmet>
        <meta name="facebook-domain-verification" content="35gihiv0plo3flt7bcfv6elipkt4up" />
      </Helmet>
      <Home />
    </div>
  )
}

export default HomePage