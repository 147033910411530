import React from "react";
import "./PoliticaConfidentialitate.scss";

const PoliticaConfidentialitate = () => {
  return (
    <>
      <div className="container-politica-confidentialitate">
        <h1>
          Politică de Confidențialitate si de prelucrare a datelor cu caracter
          personal{" "}
        </h1>
        <p>
          Considerăm asigurarea dreptului la protecția datelor cu caracter
          personal ca un angajament fundamental al ROMSTOCK.RO, prin urmare vom
          dedica toate resursele și eforturile necesare pentru a prelucra datele
          dumneavoastră în deplină concordanță cu Regulamentul (UE) 2016/679{" "}
          <strong>(“Regulamentul general privind protecția datelor”</strong> sau{" "}
          <strong>“GDPR”</strong>), precum și cu orice altă legislație
          aplicabilă pe teritoriul Romaniei. Întrucât unul dintre principiile
          esențiale ale acestui cadrul legal îl reprezintă transparența, am
          pregătit aceast document prin care dorim să vă informăm despre modul
          în care colectăm, utilizăm, transferăm și protejăm datele dvs cu
          caracter personal atunci când interacționați cu noi în legătură cu
          produsele și serviciile noastre, inclusiv prin site-ul nostru web sau
          prin aplicațiile disponibile pe telefonul mobil.
        </p>

        <p>
          Ne rezervăm dreptul de a actualiza și modifica periodic această
          Politică de Confidențialitate, pentru a reflecta orice modificări ale
          modului în care prelucrăm datele dumneavoastră cu caracter personal
          sau orice modificări ale cerințelor legale. În cazul oricărei astfel
          de modificări, vom afișa pe website-ul nostru versiunea modificată a
          Politicii de Confidențialitate, motiv pentru care va rugam sa
          verificati periodic continutul acestei Politici de Confidentialitate.
        </p>

        <h5>
          <strong>PREAMBUL</strong>
        </h5>
        <p style={{ marginBottom: "0%" }}>
          ROMSTOCK.RO (în continuare: „Operator de date”) este o companie
          încorporată și care operează în România, al cărei domeniu principal de
          activitate este comercializarea de produse prin intermediul
          website-ului ROMSTOCK.RO.{" "}
        </p>
        <p style={{ marginBottom: "0%" }}>
          În această Politică de confidențialitate, termenii ”compania”, „noi”,
          „ai noștri/ale noastre” etc. reprezintă întotdeauna operatorul de
          date.{" "}
        </p>
        <p style={{ marginBottom: "0%" }}>
          Termenii ”dumneavoastră”, ”utilizator”, „client” se referă la persoana
          care fie accesează ROMSTOCK.RO, fie își crează un cont sau
          achiziționează produsele comercializate de companie.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Utilizatorul este informat cu privire la dispozițiile acestei Politici
          de confidențialitate la momentul creării contului pe ROMSTOCK.RO și la
          momentul solicitării informațiilor necesare pentru validarea contului.
          Utilizatorul va confirma că a fost informat cu privire la Politica de
          confidențialitate prin bifarea unei rubrici existente pe site, la
          rubrica creării contului. Odată cu crearea contului, Utilizatorul își
          va acorda consimțământul pentru utilizarea datelor în scop de
          marketing, consimțământul acordat în acest sens putând fi retras în
          orice moment prin intermediul platformei sau prin mijlocul prin care
          sunt transmise informațiile către acesta.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Operatorul de date este responsabil pentru elaborarea prezentei
          Politici de confidențialitate, pentru respectarea deplină a
          persoanelor din sfera sa de aplicare și pentru monitorizarea și
          efectuarea oricăror modificări necesare. Operatorul de date poate
          modifica unilateral și în orice moment această Politică de
          confidențialitate. Unele procese de afaceri și îmbunătățiri fac
          esențial ca unele dintre obiectivele de gestionare a datelor să se
          extindă ulterior. Operatorul de date se angajează să respecte
          directivele GDPR în fiecare caz care necesită prelucrarea datelor cu
          caracter personal. Politica de confidențialitate intră în vigoare
          odată cu publicarea sa.{" "}
        </p>
        <p style={{ marginBottom: "0%" }}>
          Operatorul de date acordă fiecărei persoane interesate dreptul de a
          cunoaște, în special, scopul prelucrării datelor cu caracter personal
          și, dacă este posibil, durata prelucrării datelor cu caracter
          personal, destinatarii datelor cu caracter personal și logica
          prelucrării automate a datelor cu caracter personal, precum și
          consecințele pe care le poate avea gestionarea datelor și informațiile
          pe care le poate primi Persoana Vizată. Acest drept nu ar trebui să
          afecteze în mod negativ drepturile și libertățile altora, inclusiv
          secretele comerciale sau proprietatea intelectuală, în special în ceea
          ce privește protecția software-ului. Cu toate acestea, aceste
          considerente nu ar trebui să conducă la refuzul tuturor informațiilor
          de către Operatorul de date față de Persoana Vizată.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Acceptarea expresă a acestei Politici de confidențialitate prin
          mijloace electronice este voluntară, specifică și informativă ,
          dumneavoastră confirmând astfel faptul că ați luat la cunoștință
          prevederile acestei politice de confidențialitate. Consimțământul este
          dat de către Utilizator bifând caseta corespunzătoare pe pagina web a
          Operatorului de date, la înregistrare, acesta fiind liber exprimat și
          existând posibilitatea de retragere, în orice moment, prin mijloacele
          puse la dispoziție de către Operator.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Operatorul de date se angajează să protejeze datele personale ale
          Utilizatorilor acestea fiind gestionate confidențial și fiind luate
          toate măsurile, tehnice și organizatorice pentru a asigura securitatea
          datelor.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Operatorul de date prelucrează datele cu caracter personal în mod
          legal și corect și într-o manieră transparentă pentru Persoana Vizată
          și eventuala colectare poate avea loc doar pentru un scop specific,
          explicit și legitim, adecvat și relevant pentru scopul său și limitat
          la ceea ce este necesar. Atunci când prelucrează date cu caracter
          personal, Operatorul de date se străduiește să se asigure că acestea
          sunt corecte și actualizate și va lua toate măsurile rezonabile pentru
          a șterge sau rectifica cu promptitudine orice date cu caracter
          personal inexacte.
        </p>
        <p style={{ marginBottom: "0%" }}>
          Având în vedere că Operatorul de date are sediul în România, protecția
          oferită în legătură cu prelucrarea datelor cu caracter personal
          reglementată de această Politică de confidențialitate este garantată
          de autoritatea națională de supraveghere competentă, Autoritatea
          Națională de Supraveghere a prelucrării datelor cu caracter personal
          (ANSPDCP).
        </p>

        <h5>
          <strong>DATE REFERITOARE LA OPERATOR</strong>
        </h5>
        <table>
          <tr>
            <td>
              <p>Denumirea operatorului de date</p>
            </td>
            <td>
              <p>
                <strong>UNICIMOB CONSTRUCT SRL</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Sediul operatorului de date</p>
            </td>
            <td>
              <p>Oradea, str. Sovata, nr.40, bl.Q4, ap.9</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Informații de contact ale Operatorului de date, precum adresa de
                e-mail folosită regulat pentru a contacta utilizatorii
              </p>
            </td>
            <td>
              <p style={{ color: "#70737B" }}>suport@romstock.ro</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Nr. Registrul comerțului</p>
            </td>
            <td>
              <p>
                <strong>J5/1878/2018</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Cod fiscal</p>
            </td>
            <td>
              <p>
                <strong>39704532</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Denumirea autorității de înregistrare</p>
            </td>
            <td>
              <p>Oficiul Registrul Comerțului pe lângă Tribunalul Bihor</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Pagină web</p>
            </td>
            <td>
              <a href="www.romstock.ro">www.romstock.ro</a>
            </td>
          </tr>
          <tr>
            <td>
              <p>Limba de redactare a contractului</p>
            </td>
            <td>
              <p>română</p>
            </td>
          </tr>
        </table>

        <p>
          Pentru exercitarea drepturilor dumneavoastră și pentru a obșine
          informații suplimentare cu privire la prelucrarea datelor cu caracter
          personal aveți posibilitatea să contactați Responsabilul cu protecția
          datelor la adresa de e-mail
          <a href="mailto:suport@romstock.ro.">suport@romstock.ro. </a>
        </p>

        <h5>
          <strong>LEGISLAȚIE APLICABILĂ</strong>
        </h5>
        <p>
          Operatorul de date declară că, în orice moment, va trata datele cu
          caracter personal ale oricărui subiect cu care nu este, încă în
          relație contractuală, precum a oricărui Utilizator contractat, în
          conformitate cu legislația aplicabilă, în special cu:
          <p>
            - Regulamentul Uniunii Europene: Regulamentul general privind
            protecția datelor al Uniunii Europene 2016/679/UE („GDPR”)
          </p>
          <p style={{ marginBottom: "0%" }}>Constituția României</p>
          <p style={{ marginBottom: "0%" }}>
            - Noul Cod Civil actualizat 2019 - Legea 287/2009
          </p>
          <p style={{ marginBottom: "0%" }}>
            - Legea nr. 365/2002 privind comerțul electronic
          </p>
          <p style={{ marginBottom: "0%" }}>
            - Legea nr. 363/2007 privind combaterea practicilor incorecte ale
            comercianţilor în relaţia cu consumatorii şi armonizarea
            reglementărilor cu legislaţia europeană privind protecţia
            consumatorilor
          </p>
          <p>
            - Legea nr.506/2004 privind prelucrarea datelor cu caracter personal
            și protecția vieții private în sectorul comunicațiilor electronice,
            cu modificările și completările ulterioare)
          </p>
        </p>
        <h5>
          <strong>CE CATEGORII DE DATE PRELUCRĂM </strong>
        </h5>
        <p>
          Datele personale sunt colectate în mod direct prin intermediul
          ROMSTOCK.RO, colectarea fiind realizată în diferite etape, precum:
        </p>
        <p>
          Când vă creați un cont ROMSTOCK.RO, ne transmiteți:{" "}
          <strong>adresa de e-mail, numele și prenumele</strong>;
        </p>
        <p>
          În cadrul paginii dvs. personale (Contul meu) din platforma
          ROMSTOCK.RO aveți posibilitatea să adăugați informații suplimentare,
          precum:{" "}
          <strong>
            număr telefon mobil, număr telefon fix, adrese de livrare, adresa
            alternativa de e-mail, datele cardului bancar
          </strong>
          ;
        </p>
        <p>
          Când plasați o <strong>comandă</strong>, ne furnizați informații
          precum:{" "}
          <strong>
            produsul dorit, numele si prenumele, adresa de livrare, detalii de
            facturare, metoda de plată, număr de telefon, datele cardului bancar{" "}
          </strong>{" "}
          etc.
        </p>
        <p>
          Vă oferim și posibilitatea de a vă înregistra în platforma ROMSTOCK.RO
          prin contul dvs Facebook sau Google. Dacă optați pentru una dintre
          aceste variante, veți fi direcționați către o pagină administrată de
          Facebook Inc / Google LLC, unde aceștia vă vor informa cu privire la
          transferul datelor dvs. către ROMSTOCK.RO. Puteți consulta politicile
          de confidențialitate Facebook, respectiv Google, folosind următoarele
          link-uri:
        </p>
        <p style={{ marginBottom: "0" }}>
          <a href="https://www.facebook.com/about/privacy">
            {" "}
            https://www.facebook.com/about/privacy
          </a>
        </p>
        <p>
          <a href="https://policies.google.com/privacy">
            {" "}
            https://policies.google.com/privacy
          </a>
        </p>
        <p>
          Putem, de asemenea, să colectăm și să prelucrăm ulterior anumite
          informații cu privire la comportamentul dvs în timpul vizitării
          site-ului nostru web sau a folosirii aplicației smartphone, pentru a
          vă personaliza experiența online și a vă pune la dispoziție oferte
          adaptate profilului dvs. Vă invităm să aflați mai multe detalii în
          acest sens prin consultarea secțiunii privitoare la scopurile
          prelucrării de mai jos.
        </p>
        <p>
          Pe site-ul nostru web și în aplicația smartphone putem stoca și
          colecta informații in cookie-uri și tehnologii similare, conform
          Politicii de cookie-uri.
        </p>
        <p>
          Nu colectăm și nu prelucrăm în alt mod date sensibile, incluse de
          Regulamentul general privind protecția datelor în categorii speciale
          de date cu caracter personal. De asemenea, nu dorim să colectăm sau să
          prelucrăm date ale minorilor care nu au împlinit varsta de 16 ani.
        </p>

        <h5>
          <strong>
            TIPUL DE DATE COLECTATE, SCOPURILE ȘI TEMEIURILE PRELUCRĂRII
          </strong>
        </h5>
        <h6>
          <strong>1. Pentru oferirea serviciilor ROMSTOCK.RO</strong>
        </h6>
        <table className="table_2">
          <tr>
            <th>
              <p>TIPURI DE DATE</p>
            </th>
            <th>
              <p>SCOPUL COLECTĂRII</p>
            </th>
            <th>
              <p>TEMEIUL LEGAL</p>
            </th>
          </tr>
          <tr>
            <td>
              <li>nume, prenume</li>
              <li>număr telefon</li>
              <li>adresă livrare/ facturare</li>
              <li>date facturare</li>
              <li>date privind plata produselor</li>
              <li>e-mail</li>
            </td>
            <td>
              <li>Crearea și administrarea contului ROMSTOCK.RO</li>
              <li>Facturare, prelucrare comenzi și transmiterea acestora</li>
              <li>
                Returnarea produselor și soluționarea anulărilor comenzilor
              </li>
              <li>Pentru îndeplinirea obligațiilor legale ale Companiei</li>
              <li>Livrarea produselor</li>
              <li>Marketing direct dacă există consimțământ expres</li>
            </td>
            <td>
              <li>
                <strong>Încheierea și executarea unui contract</strong> - art.6
                lit.f) GDPR
              </li>
              <li>
                <strong>Obligație legală</strong> - art.6 lit.c) GDPR
              </li>
              <li>
                <strong>Consimțământ</strong> - art.6 lit.a) GDPR - în cazul
                marketingului direct
              </li>
            </td>
          </tr>
        </table>

        <h6>
          <strong>2. Pentru îmbunătățirea serviciilor noastre</strong>
        </h6>
        <p>
          Putem colecta și utiliza anumite{" "}
          <strong>
            informații în legătură cu comportamentul dvs. de Cumpărăror, vă
            putem invita să completați chestionare de satisfacție subsecvent
            finalizării unei comenzi sau putem desfășura, direct sau cu ajutorul
            unor parteneri, studii și cercetări de piață.
          </strong>
        </p>
        <table className="table_2">
          <tr>
            <th>
              <p>TIPURI DE DATE</p>
            </th>
            <th>
              <p>SCOPUL COLECTĂRII</p>
            </th>
            <th>
              <p>TEMEIUL LEGAL</p>
            </th>
          </tr>
          <tr>
            <td>
              <li>e-mail</li>
              <li>număr telefon</li>
            </td>
            <td>
              <li>Optimizarea și adaptarea experienței pe ROMSTOCK.RO</li>
            </td>
            <td>
              <li>
                <strong>Interesul legitim</strong> - art.6 lit.f) GDPR
              </li>
            </td>
          </tr>
        </table>

        <h6>
          <strong>3. Pentru activități de marketing</strong>
        </h6>
        <p>
          Pentru promovarea produselor comercializate, extinderea vizibilității
          ROMSTOCK.RO și informarea dumneavoastră cu privire la campaniile de
          marketing, colectăm și utilizăm următoarele tipuri de date.
        </p>
        <table className="table_2">
          <tr>
            <th>
              <p>TIPURI DE DATE</p>
            </th>
            <th>
              <p>SCOPUL COLECTĂRII</p>
            </th>
            <th>
              <p>TEMEIUL LEGAL</p>
            </th>
          </tr>
          <tr>
            <td>
              <li>e-mail</li>
              <li>număr telefon</li>
            </td>
            <td>
              <li>
                Informații cu privire la produse similare, oferte, promoții,
                recomandări produse complementare
              </li>
              <li>Acordarea de vouchere pentru cumpărături</li>
              <li>Informații privind campanii promoționale</li>
            </td>
            <td>
              <li>
                <strong>Consimțământ</strong> - art.6 lit.a) GDPR - obținut în
                prealabil și cu posibilitatea retragerii în orice moment
              </li>
            </td>
          </tr>
        </table>

        <p>
          Vă puteți răzgândi și retrage consimțământul în orice moment, prin:
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          - Accesarea link-ului de dezabonare afișat în cadrul mesajelor pe care
          le primiți de la noi; sau prin
        </p>
        <p>
          - Contactarea ROMSTOCK.RO folosind detaliile de contact descrise mai
          sus.
        </p>
        <p>
          Ne{" "}
          <strong>
            puteți solicita oricând, prin mijloacele descrise mai sus, să oprim
            prelucrarea datelor dvs.
          </strong>{" "}
          cu caracter personal în scop de marketing, urmând să dăm curs cererii
          dvs.
        </p>
        <p>
          <strong>
            Vom solicita consimțământul dvs. înainte de a vă prelucra datele
            personale în scopuri de marketing direct.
          </strong>{" "}
          În acest caz, vă aducem la cunoștință că{" "}
          <strong>veți putea retrage în orice moment consimțământul</strong>{" "}
          pentru prelucrarea în scop de marketing, caz în care nu veți mai primi
          nicio comunicare de marketing din partea noastră. Vom include un link
          de dezabonare pe care îl puteți utiliza dacă nu doriți să vă mai
          trimitem mesaje.
        </p>

        <h6>
          <strong>
            4. Pentru apărarea intereselor și respectarea prevederilor legale
          </strong>
        </h6>
        <p>
          Pot exista situații în care vom folosi sau transmite informații pentru
          a ne proteja drepturile și activitatea comercială. Acestea pot
          include:
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – Măsuri de protecție a site-ului web și a utilizatorilor platformei
          ROMSTOCK.RO față de atacuri cibernetice;
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – Măsuri de prevenire și detectare a tentativelor de fraudare,
          inclusiv transmiterea unor informații către autoritățile publice
          competente;
        </p>
        <p>– Măsuri de gestionare a diverselor altor riscuri.</p>
        <p>
          Temeiul general al acestor tipuri de prelucrări este{" "}
          <strong>
            interesul nostru legitim de a ne apăra activitatea comercială precum
            și în vederea îndeplinirii unor obligații legale ce ne revin conform
            legislației în vigoare
          </strong>
          , fiind înțeles că ne asigurăm că toate măsurile pe care le luăm
          garantează un echilibru între interesele noastre și drepturile și
          libertățile dvs. fundamentale.
        </p>

        <h6>
          <strong>Cât timp păstrăm datele dvs. cu caracter personal</strong>
        </h6>
        <p>
          Ca regulă generală, vom stoca datele dvs. cu caracter personal
          furnizate pentru crearea și utilizarea contului ROMSTOCK.RO (e-mail,
          nume, prenume, număr telefon, adresă livrare){" "}
          <strong>
            pentru o perioadă de 3 ani de la ultima comandă plasată
          </strong>
          . Puteți să ne solicitați oricând ștergerea anumitor informații sau
          închiderea contului și vom da curs acestor solicitări, sub rezerva
          păstrării anumitor informații inclusiv ulterior închiderii contului,
          în situațiile în care legislația aplicabilă sau interesele noastre
          legitime o impun.Această excepție poate deveni aplicabilă în situația
          în care informațiile sunt necesare pentru apărarea drepturilor noastre
          în instanță sau în fața autorităților abilitate ori în alte cazuri
          care presupun protejarea intereselor noastre. În cazul acestei
          excepții, datele sunt stocate până la finalizarea definitivă a
          acțiunilor judiciare.
        </p>
        <p>
          Revizuim constant necesitatea păstrării datelor dvs. personale, iar în
          măsura în care prelucrarea nu mai este necesară și nu există
          obligativitatea impusă de lege de a păstra datele , vom distruge
          datele dvs. personale cât mai curând posibil și într-un mod în care să
          nu mai poată fi recuperate sau reconstituite (spre exemplu, vom
          șterge/distruge toate datele persoanelor care nu au fost angajate în
          urma interviurilor, dacă nu există perspectiva serioasă de angajare în
          viitor). Dacă informațiile personale sunt imprimate pe suport de
          hârtie, acestea vor fi distruse într-un mod sigur care să asigure
          imposibilitatea de reconstituire, iar, dacă acestea sunt salvate pe
          suporturi electronice, vor fi șterse prin mijloace tehnice pentru a
          asigura faptul că informațiile nu mai pot fi recuperate sau
          reconstituite ulterior.
        </p>
        <p>
          Datele necesare facturării (adresa, nume client, etc) și păstrării
          evidențelor contabile sunt păstrate pe o perioadă de 10 ani conform
          legislației aplicabile, fiind o obligație legală a Companiei.
        </p>
        <p>
          Prelucrarea și stocarea altor tipuri de date cu caracter personal nu
          va depăși 3 ani.
        </p>

        <h5>
          <strong>TRANSMITEREA DATELOR CU CARACTER PERSONAL</strong>
        </h5>
        <p>
          În vederea desfășurării activității comerciale și îndeplinirii
          scopurilor descries anterior, după caz, putem transmite sau oferi
          acces la anumite date cu caracter personal ale dvs. următoarelor
          categorii de destinatari:
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – societăților din cadrul aceluiași grup de societăți ca ROMSTOCK.RO;
        </p>
        <p style={{ marginBottom: "0.5%" }}>– partenerilor ROMSTOCK.RO;</p>
        <p style={{ marginBottom: "0.5%" }}>
          – furnizorilor de servicii de curierat;
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – furnizorilor de servicii de plată/bancare;
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – furnizorilor de servicii de marketing / telemarketing;
        </p>
        <p style={{ marginBottom: "0.5%" }}>
          – furnizorilor de servicii de cercetare de piață;
        </p>
        <p style={{ marginBottom: "0.5%" }}>– furnizorilor de servicii IT;</p>
        <p>
          – altor societăți cu care putem dezvolta programe comune de ofertare
          pe piață a bunurilor si serviciilor noastre.
        </p>

        <p>
          În cazul în care ne revine o obligație legală sau dacă este necesar
          pentru a ne apăra un interes legitim, putem de asemenea divulga
          anumite date cu caracter personal unor autorități publice.
        </p>
        <p>
          Ne asigurăm că accesul la datele dvs. de către terții persoane
          juridice de drept privat se realizeaza în conformitate cu prevederile
          legale privind protecția datelor si confidențialitatea informațiilor,
          în baza unor contracte încheiate cu aceștia.
        </p>
        <p>
          Confidențialitatea datelor dvs. este importantă pentru noi, motiv
          pentru care,{" "}
          <strong>
            acolo unde este posibil, transmiterea de date personale în
            conformitate cu cele de mai sus se realizează numai în temeiul unui
            angajament de confidențialitate din partea destinatarilor
          </strong>
          , prin care garantează că aceste date sunt păstrate în siguranță și că
          furnizarea acestor informații se face conform legislației în vigoare
          și politicilor aplicabile. În orice caz, de fiecare dată vom transmite
          către destinatari doar informațiile strict necesare pentru realizarea
          scopului respectiv.
        </p>

        <h5>
          <strong>TTRANSMITEREA DATELOR CĂTRE ALTE STATE</strong>
        </h5>
        <p>
          În prezent, stocăm și prelucrăm datele dvs. cu caracter personal pe
          teritoriul României.
        </p>
        <p>
          Cu toate acestea, este posibil să transferăm anumite date ale dvs. cu
          caracter personal unor entități localizate în Uniunea Europeană sau
          Spațiul Economic European.
        </p>
        <p>
          Vom lua întotdeauna măsuri pentru a ne asigura că orice transfer
          internațional de date cu caracter personal este gestionat cu atenție
          cu scopul de a vă proteja drepturile și interesele. Transferurile
          către furnizorii de servicii și alte părți terțe vor fi întotdeauna
          protejate prin angajamente contractuale și, după caz, prin alte
          garanții, cum ar fi clauzele contractuale standard emise de Comisia
          Europeană sau schemele de certificare, precum Scutul de
          confidențialitate pentru protecția datelor cu caracter personal
          transferate din interiorul UE către Statele Unite ale Americii.
        </p>
        <p>
          Ne puteți contacta oricând, folosind detaliile de contact expuse mai
          sus, pentru a afla mai multe informații despre țările în care
          transferăm datele dvs., perecum și garanțiile pe care le-am pus în
          aplicare cu privire la aceste transferuri.
        </p>

        <h5>
          <strong>SECURITATEA DATELOR CU CARACTER PERSONAL</strong>
        </h5>
        <p>
          Ne angajăm să asigurăm securitatea datelor cu caracter personal prin
          implementarea unor măsuri tehnice și organizatorice adecvate,prevăzute
          în politicile interne de confidențialitate și conforme standardelor
          industriei.
        </p>
        <p>
          Transmisia datelor dvs. cu caracter personal se face folosind
          algoritmi de criptare de ultimă generație si le stocam pe servere
          securizate, asigurând in acelasi timp redundanța datelor.
        </p>
        <p>
          Pentru efectuarea plăţilor folosim serviciile procesatorului de plăţi.
          Orice informaţii privitoare la plăţi sunt criptate, folosind
          tehnologia HTTPS cu criptare TSL 1.2.
        </p>
        <p>
          În ciuda măsurilor luate pentru a proteja datele dvs. cu caracter
          personal, vă atragem atenţia că transmiterea informaţiilor prin
          Internet, în general, sau prin intermediul altor reţele publice, nu
          este complet sigură, existând riscul ca datele să fie văzute şi
          utilizate de către terţe părţi neautorizate. Nu putem fi responsabili
          pentru astfel de vulnerabilități ale unor sisteme care nu sunt sub
          controlul nostru.
        </p>

        <h5>
          <strong>
            DREPTURILE DUMNEAVOASTRĂ CU PRIVIRE LA PRELUCRAREA DATELOR
          </strong>
        </h5>
        <p>
          Regulamentul general privind protecția datelor vă recunoaște o serie
          drepturi în legătură cu datele dvs. cu caracter personal. Conform
          Regulamentului, aveți următoarele drepturi
        </p>

        <p style={{ marginTop: "4%" }}>
          <strong>
            (a) Dreptul de a fi informat cu privire la prelucrarea datelor dvs.
          </strong>
        </p>
        <p>
          <strong>(b) Dreptul de acces asupra datelor. </strong>Aveți dreptul de
          a obține din partea noastră o confirmare că se prelucrează sau nu date
          cu caracter personal care vă privesc și, în caz afirmativ, acces la
          datele respective și la informațiile prevăzute de art. 15 alin. (1)
          din GDPR.
        </p>
        <p>
          <strong>
            (c) Dreptul de a rectifica datele inexacte sau incomplete.{" "}
          </strong>
          Aveți dreptul de a obține, din partea noastră, fără întârzieri
          nejustificate, rectificarea datelor cu caracter personal inexacte care
          vă privesc.
        </p>
        <p>
          <strong>(d) Dreptul la ștergere („dreptul de a fi uitat”). </strong>În
          situațiile prevăzute la art. 17 din GDPR, aveți dreptul de a solicita
          și obține ștergerea datelor cu caracter personal.
        </p>
        <p>
          <strong>(e) Dreptul la restricționarea prelucrării. </strong>În
          cazurile prevăzute la art. 18 din GDPR, aveți dreptul de a solicita și
          obține restricționarea prelucrării.
        </p>
        <p>
          <strong>
            (f) Dreptul de a transmite datele pe care le avem despre dvs. către
            alt operator („dreptul la portabilitate”).{" "}
          </strong>
          Dreptul de a transmite datele pe care le avem despre dvs. către alt
          operator („dreptul la portabilitate”)
        </p>
        <p>
          <strong>(g) Dreptul de a vă opune prelucrării datelor. </strong>În
          cazurile prevăzute la art. 21 din GDPR, aveți dreptul de a vă opune
          prelucării datelor.
        </p>
        <p>
          <strong>
            (h) Dreptul de a nu face obiectul unei decizii bazate exclusiv pe
            prelucrarea automată, inclusiv crearea de profiluri cu efecte
            juridice sau cu efecte semnificative similare asupra asupra dvs.
          </strong>
        </p>
        <p>
          <strong>
            (i) Dreptul de a vă adresa justiției pentru apărarea drepturilor și
            intereselor dvs.
          </strong>
        </p>
        <p>
          <strong>
            (j) Dreptul de o plângere în fața unei Autorități de Supraveghere,
            datele de contact fiind regăsite în cele ce urmează:
          </strong>
        </p>

        <table>
          <tr>
            <th colSpan="2" style={{ textAlign: "center" }}>
              <p>
                Autoritatea Națională de Supraveghere a Prelucrării Datelor cu
                Caracter Personal
              </p>
            </th>
          </tr>
          <tr>
            <td style={{ width: "30%" }}>
              <p>
                <strong>Adresă</strong>
              </p>
            </td>
            <td style={{ width: "70%" }}>
              <p>
                B-dul G-ral. Gheorghe Magheru nr. 28-30, Sector 1, cod poștal
                010336, București, România
              </p>
            </td>
          </tr>
          <tr>
            <td style={{ width: "30%" }}>
              <p>
                <strong>e-mail</strong>
              </p>
            </td>
            <td style={{ width: "70%" }}>
              <p style={{ color: "#70737B" }}>anspdcp@dataprotection.ro</p>
            </td>
          </tr>
        </table>

        <p>
          Pentru a vă putea exercita drepturile, ne puteți contacta folosind
          detaliile de contact, respective <strong>suport@romstock.ro</strong>{" "}
          sau la sediul societății. Solicitările bazate pe exercitarea
          drepturilor prevăzute la art. 15-21 din GDPR vor fi formulate,
          obligatoriu, în scris, în așa fel încât să se asigure verificarea
          respectării prevederilor din GDPR.
        </p>
        <p>
          Vă rugăm să rețineți următoarele aspecte dacă doriți să vă exercitați
          aceste drepturi:
        </p>
        <p>
          <strong>Identitate. </strong>Luăm în serios confidențialitatea tuturor
          înregistrărilor care conțin date cu caracter personal. Din acest
          motiv, va rugam sa ne transmiteti cererile dvs. cu privire la astfel
          de înregistrări folosind adresa de e-mail aferenta contului
          ROMSTOCK.RO. In caz contrar, ne rezervăm dreptul de a vă verifica
          identitatea prin solicitarea de informatii suplimentare care au ca
          scop confirmarea identitatii dvs.
        </p>
        <p>
          <strong>Onorarii. </strong>Nu vom solicita o taxă pentru a vă exercita
          vreun drept în ceea ce privește datele dvs. cu caracter personal, cu
          excepția cazului în care solicitarea dvs. de acces la informații este
          nefondată, respectiv repetitivă sau excesivă, caz în care vom percepe
          o sumă rezonabilă în asemenea circumstanțe. Vă vom informa despre
          orice onorarii aplicate înainte de a vă soluționa cererea.
        </p>
        <p>
          <strong>Durata de răspuns. </strong>Ne propunem să răspundem la orice
          solicitări valide în termen de maximum o lună, cu excepția cazului în
          care acest lucru este deosebit de complicat sau dacă ați făcut mai
          multe solicitări, caz în care urmează să răspundem în termen de
          maximum doua luni. Vă vom anunța dacă vom avea nevoie de mai mult de o
          lună. S-ar putea să vă întrebăm dacă puteți să ne spuneți exact ce
          doriți să primiți sau ce anume vă îngrijorează. Acest lucru ne va
          ajuta să acționăm mai repede si sa scurtam timpul de raspuns la
          solicitarea dvs.
        </p>
        <p style={{ marginBottom: "3%" }}>
          <strong>Drepturile terțelor părți. </strong>Nu trebuie să respectăm o
          cerere în cazul în care ar afecta în mod negativ drepturile și
          libertățile altor persoane vizate.
        </p>
      </div>
    </>
  );
};

export default PoliticaConfidentialitate;
