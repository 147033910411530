import React, { useState, useEffect } from "react";
import styles from "./OrderCompletion.module.scss";
import { Form } from "react-bootstrap";
import Check from "./Check";
import { connect } from "react-redux";
import { getOrdersInfoRequest } from "../../redux/order/order.actions";
import { judete } from "../../utilities/constants";

const OrderForm = ({
  id,
  cui,
  isDelivery,
  handleSubmit,
  register,
  validated,
  handleCheckbox,
  handleCheckboxAccount,
  isChecked,
  isCheckedAccount,
  nume,
  prenume,
  telefon,
  email,
  county,
  localitate,
  adresa,
  setJudetName,
  setLocalitateName,
  codPostal,
  user,
  info,
  getOrdersInfoRequest,
}) => {
  const [localitati, setLocalitati] = useState();
  const [autoNb, setAutoNb] = useState("");

  const [judetIndex, setJudetIndex] = useState();
  const [localitateIndex, setLocalitateIndex] = useState();

  const handleSelect = (e) => {
    const { target } = e;
    setAutoNb(judete[target.value].auto);
    setJudetName(judete[target.value].simplu);
  };

  const handleSelectLocalitate = (e) => {
    const { target } = e;
    setLocalitateName(target.value);
  };

  useEffect(() => {
    if (autoNb.length > 0) {
      fetch(`https://roloca.coldfuse.io/orase/${autoNb}`)
        .then((res) => res.json())
        .then((result) => {
          if ((info && isCheckedAccount) || (info && isChecked)) {
            for (let i = 0; i < result.length; i++) {
              const city = isDelivery
                ? info.deliveryAddress.city
                : info.billingAddress.city;
              if (
                city === (result[i].simplu ? result[i].simplu : result[i].nume)
              ) {
                setLocalitateIndex(i);
                break;
              }
            }
          }
          setLocalitati(result);
        });
    }
  }, [autoNb]);

  useEffect(() => {
    if (user) {
      getOrdersInfoRequest();
    }
  }, [user]);

  useEffect(() => {
    if (info && isCheckedAccount) {
      for (let i = 0; i < judete.length; i++) {
        const county = isDelivery
          ? info.deliveryAddress.county
          : info.billingAddress.county;
        if (judete[i].simplu === county) {
          setJudetIndex(i);
          setAutoNb(judete[i].auto);
          break;
        }
      }
    }
  }, [info, isCheckedAccount, isChecked]);

  return (
    <Form
      id={id}
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className={styles.form_container}
      method="POST"
    >
      {user &&
        info &&
        info.deliveryAddress !== null &&
        info.billingAddress !== null && (
          <Form.Row
            className={
              isDelivery ? styles.form_row : styles.display_transparent
            }
            style={{ marginTop: "4%" }}
          >
            <Form.Group className={"col-12 " + styles.checkbox_mobile}>
              <Check
                handleCheckbox={handleCheckboxAccount}
                text="Folosește datele salvate în Contul meu"
                custom="check1"
                isChecked={isCheckedAccount}
              />
            </Form.Group>
          </Form.Row>
        )}
      <Form.Row className={styles.form_row}>
        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Nume*</Form.Label>
          <Form.Control
            required
            type="text"
            className={styles.form_control}
            name={nume}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Prenume*</Form.Label>
          <Form.Control
            required
            type="text"
            className={styles.form_control}
            name={prenume}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row className={styles.form_row}>
        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Telefon*</Form.Label>
          <Form.Control
            required
            type="tel"
            pattern="[0-9]{10}"
            className={styles.form_control}
            name={telefon}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Numărul de telefon este invalid
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Email*</Form.Label>
          <Form.Control
            required
            type="email"
            className={styles.form_control}
            name={email}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Emailul este invalid
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row className={styles.form_row}>
        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Județ*</Form.Label>
          {judete ? (
            <Form.Control
              required
              as="select"
              onChange={(e) => handleSelect(e)}
              className={styles.form_control}
              name={county}
              ref={register}
            >
              <option disabled selected></option>
              {judete.map((judet, idx) => (
                <option
                  selected={idx === judetIndex}
                  value={idx}
                  key={judet.nume}
                >
                  {judet.nume}
                </option>
              ))}
            </Form.Control>
          ) : (
            <Form.Control
              required
              type="text"
              className={styles.form_control}
              name={county}
              ref={register}
            ></Form.Control>
          )}
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className={"col-6"}>
          <Form.Label className={styles.form_label}>Localitate*</Form.Label>
          {localitati && (
            <Form.Control
              required
              as="select"
              onChange={(e) => handleSelectLocalitate(e)}
              className={styles.form_control}
              name={localitate}
              ref={register}
            >
              <option disabled selected></option>
              {localitati.map((loc, idx) => (
                <option
                  selected={idx === localitateIndex}
                  value={loc.simplu ? loc.simplu : loc.nume}
                  key={idx}
                >
                  {loc.nume}
                </option>
              ))}
            </Form.Control>
          )}
          {!localitati && (
            <Form.Control
              required
              type="text"
              className={styles.form_control}
              name={localitate}
              ref={register}
            ></Form.Control>
          )}
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>

      <Form.Row className={styles.form_row}>
        <Form.Group className={"col-12"}>
          <Form.Label className={styles.form_label}>Adresa*</Form.Label>
          <Form.Control
            required
            type="text"
            className={styles.form_control + " " + styles.last}
            name={adresa}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row className={styles.form_row}>
        <Form.Group className={`col-6`}>
          <Form.Label className={styles.form_label}>Cod poștal*</Form.Label>
          <Form.Control
            required
            type="text"
            className={styles.form_control}
            name={codPostal}
            ref={register}
          />
          <Form.Control.Feedback
            className={styles.form_feedback}
            type="invalid"
          >
            Acest câmp este obligatoriu
          </Form.Control.Feedback>
        </Form.Group>

        {isDelivery ? (
          <Form.Group className={`col-6`}></Form.Group>
        ) : (
          <>
            <Form.Group className={"col-6"}>
              <Form.Label className={styles.form_label}>CUI</Form.Label>
              <Form.Control
                type="text"
                className={styles.form_control}
                name={cui}
                ref={register}
              />
            </Form.Group>
          </>
        )}
      </Form.Row>

      <Form.Row
        className={isDelivery ? styles.form_row : styles.not_display}
        style={{ marginTop: "4%" }}
      >
        <Form.Group className={"col-12 " + styles.checkbox_mobile}>
          <Check
            handleCheckbox={handleCheckbox}
            text="Folosește aceleași date și pentru adresa de facturare"
            custom="check2"
            isChecked={isChecked}
          />
        </Form.Group>
      </Form.Row>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
  info: state.order.info,
});

const mapDispatchToProps = (dispatch) => ({
  getOrdersInfoRequest: (payload) => dispatch(getOrdersInfoRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
