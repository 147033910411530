import { GET_MENU_ITEM_REQUEST, GET_MENU_ITEM_SUCCESS } from "./menu.constants";

const INITIAL_STATE = {
  toolsMenu: [],
  electronicsMenu: [],
  isLoading: false,
};

export const menuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MENU_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_MENU_ITEM_SUCCESS:
      return {
        ...state,
        toolsMenu: action.payload.toolsMenu,
        electronicsMenu: action.payload.electronicsMenu,
        isLoading: false,
      };
    default:
      return state;
  }
};
