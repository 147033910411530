import React, { useState, useEffect } from "react";
import ProductDetails from "./ProductDetails/ProductDetails";
import ProductTabs from "./ProductTabs/ProductTabs";
import styles from "../../general.module.scss";
import { getProductRequest } from "../../redux/product/product.actions";
import { connect } from "react-redux";
import { decodeProductCode } from "../../utilities/helpers";
import DynamicTable from "../../components/Table/DynamicTable";
import { pixelViewContentEvent } from "../../utilities/marketing-events"
// import { replaceNameSingleProduct, encodeProductCode } from "../../utilities/helpers"
// import CarouselCards from "./CarouselCards/CarouselCards"

const SingleProductPage = ({
  product,
  getProductRequest,
  match: {
    params: { name, productCode },
  },
}) => {
  const [forceDetails, setForceDetails] = useState(true);
  const decodedCode = decodeProductCode(productCode);

  useEffect(() => {
    getProductRequest(encodeURIComponent(decodeProductCode(productCode)));
  }, []);

  useEffect(() => {
    if (product) {
      var script = document.createElement("script");
      script.type = "text/javascript";

      var code = `var dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'product view',       
                            'content_ids': '${
                              product.code || product.productCode
                            }',
                            'value': '${product.currentPrice}',
                            'content_name': '${product.name.toLowerCase()}',
                            'content_category': '${product.category}'
                        });
                        `;
      try {
        script.appendChild(document.createTextNode(code));
        document.head.appendChild(script);
      } catch (e) {
        script.text = code;
        document.head.appendChild(script);
      }

      pixelViewContentEvent(product.code, product.currentPrice)  
    }
  }, [product]);

  if (product)
    if (product.productCode === decodedCode || product.code === decodedCode)
      return (
        <div
          className={styles.container}
          itemScope
          itemType="https://schema.org/Product"
        >
          <ProductDetails
            product={product}
            clickShowDetails={setForceDetails}
          />
          {/* TO DO: produse similare */}
          {/* <CarouselCards /> */}
          {product.similarProducts.length > 1 && (
            <DynamicTable
              products={product.similarProducts}
              currentCode={product.code || product.productCode}
            />
          )}
          <ProductTabs
            product={product}
            forceDetails={forceDetails}
            setForceDetails={setForceDetails}
          />
        </div>
      );
  return <div style={{ flexGrow: 1 }}></div>;
};

const mapDispatchToProps = (dispatch) => ({
  getProductRequest: (payload) => dispatch(getProductRequest(payload)),
});

const mapStateToProps = (state) => ({
  product: state.product.product,
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductPage);
