import React from "react";
import styles from "./OrderCompletion.module.scss";

const Check = ({ handleCheckbox, text, custom, isChecked }) => {
  return (
    <div
      class="custom-radio custom-control-inline"
      className={styles.checkbox_adress}
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id={custom}
        onChange={handleCheckbox}
        checked={isChecked}
      />
      <label
        class={"custom-control-label " + styles.form_label + " " + styles.radio}
        for={custom}
      >
        {text}
      </label>
    </div>
  );
};

export default Check;
