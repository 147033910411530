import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  SAVE_COMMENT_REQUEST,
  SAVE_COMMENT_SUCCESS,
} from "./comments.constants";

export function* getComments({ payload }) {
  const { code, currentPage } = payload;
  const {
    data: { comments, numberOfPages },
  } = yield call(apiService.getComments, code, currentPage);
  yield put({
    type: GET_COMMENTS_SUCCESS,
    payload: { comments, numberOfPages },
  });
}

export function* getCommentsWatcher() {
  yield takeLatest(GET_COMMENTS_REQUEST, getComments);
}

export function* commentsSaga() {
  yield all([call(getCommentsWatcher)]);
}

export function* saveComment({ payload }) {
  const { code, title, text, numberOfStars } = payload;
  const { data: comment } = yield call(apiService.saveComment, code, {
    title,
    text,
    numberOfStars,
  });
  yield put({
    type: SAVE_COMMENT_SUCCESS,
    payload: { comment },
  });
}

export function* saveCommentWatcher() {
  yield takeLatest(SAVE_COMMENT_REQUEST, saveComment);
}

export function* saveCommentSaga() {
  yield all([call(saveCommentWatcher)]);
}
