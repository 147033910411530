import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm/LoginForm";
import styles from "../../general.module.scss";
import Notification from "../../components/Notification/Notification";
import { removeNotification } from "../../redux/product/product.actions";

const Login = ({ user, notifications, removeNotification }) => {
  if (user) return <Redirect to={"/"} />;
  return (
    <div className={`${styles.container}`}>
      <div
        style={{ position: "fixed", top: "20", zIndex: "1002" }}
        className="filters_notifications"
      >
        {notifications.map((notification) => {
          return (
            <Notification
              text={notification.text}
              boldText={notification.boldText}
              onClick={() => removeNotification(notification.id)}
            />
          );
        })}
      </div>
      <LoginForm />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  removeNotification: (payload) => dispatch(removeNotification(payload)),
});

const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
  notifications: state.product.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
