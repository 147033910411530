import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import { GET_BRANDS_REQUEST, GET_BRANDS_SUCCESS } from "./brands.constants";

export function* getBrands({ payload }) {
  const { data: brands } = yield call(apiService.getBrands, payload);
  yield put({
    type: GET_BRANDS_SUCCESS,
    payload: { brands },
  });
}

export function* getBrandsWatcher() {
  yield takeLatest(GET_BRANDS_REQUEST, getBrands);
}

export function* brandsSaga() {
  yield all([call(getBrandsWatcher)]);
}
