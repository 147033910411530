import {
  GET_PROMOTION_PRODUCTS_REQUEST,
  GET_PROMOTION_PRODUCTS_SUCCESS,
  GET_PROMOTION_CATEGORIES_SUCCESS,
  GET_PROMOTION_CATEGORIES_REQUEST,
  SET_FIRST_PAGE_PROMOTION,
  SET_LAST_PAGE_PROMOTION,
  SET_NEXT_PAGE_PROMOTION,
  SET_PREV_PAGE_PROMOTION,
  NEXT_PAGE_PROMOTION,
  PREV_PAGE_PROMOTION,
} from "./promotions.constants";

const INITIAL_STATE = {
  isLoading: false,
  products: [],
  categories: [],
  currentPage: 1,
  numberOfPages: null,
};

export const promotionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROMOTION_CATEGORIES_REQUEST:
    case GET_PROMOTION_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROMOTION_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload.products,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
      };
    case GET_PROMOTION_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.payload.categories,
      };
    case NEXT_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case PREV_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case SET_NEXT_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: state.currentPage + 2,
      };
    case SET_PREV_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: state.currentPage - 2,
      };
    case SET_FIRST_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: 1,
      };
    case SET_LAST_PAGE_PROMOTION:
      return {
        ...state,
        currentPage: state.numberOfPages,
      };
    default:
      return state;
  }
};
