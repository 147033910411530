export const GET_ADMIN_ORDERS_REQUEST = "GET_ADMIN_ORDERS_REQUEST";
export const GET_ADMIN_ORDERS_SUCCESS = "GET_ADMIN_ORDERS_SUCCESS";
export const GET_ADMIN_ORDERS_FAILURE = "GET_ADMIN_ORDERS_FAILURE";
export const GET_ADMIN_ORDER_DETAILS_REQUEST =
  "GET_ADMIN_ORDER_DETAILS_REQUEST";
export const GET_ADMIN_ORDER_DETAILS_SUCCESS =
  "GET_ADMIN_ORDER_DETAILS_SUCCESS";
export const GET_ADMIN_ORDER_DETAILS_FAILURE =
  "GET_ADMIN_ORDER_DETAILS_FAILURE";

export const EDIT_PRODUCT_REQUEST = "EDIT_PRODUCT_REQUEST";

export const RESET_ORDER_DETAILS = "RESET_ORDER_DETAILS";
export const ADD_ADMIN_ORDER = "ADD_ADMIN_ORDER";

export const VERIFY_ROLE_REQUEST = "VERIFY_ROLE_REQUEST";
export const VERIFY_ROLE_SUCCESS = "VERIFY_ROLE_SUCCESS";
export const VERIFY_ROLE_FAILURE = "VERIFY_ROLE_FAILURE";

export const SET_NEXT_PAGE_ADMIN = "SET_NEXT_PAGE_ADMIN";
export const SET_PREV_PAGE_ADMIN = "SET_PREV_PAGE_ADMIN";
export const SET_FIRST_PAGE_ADMIN = "SET_FIRST_PAGE_ADMIN";
export const SET_LAST_PAGE_ADMIN = "SET_LAST_PAGE_ADMIN";

export const NEXT_PAGE_ADMIN = "NEXT_PAGE_ADMIN";
export const PREV_PAGE_ADMIN = "PREV_PAGE_ADMIN";
