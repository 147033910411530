import React, { useState } from "react";
import "./Menu.scss";
import { useHistory } from "react-router-dom";
import { replaceCategory } from "../../utilities/helpers";
import Img from "react-cool-img";
import { connect } from "react-redux";
import {
  setPriceActiveFilter,
  setStockActiveFilter,
  removeBrandActiveFilter,
} from "../../redux/filters/filters.actions";
import { useEffect } from "react";

const Menu = ({
  menu,
  modalRef,
  shouldDismissModal,
  brandActiveFilters,
  setStockActiveFilter,
  setPriceActiveFilter,
  removeBrandActiveFilter,
}) => {
  const [secondIndex, setSecondIndex] = useState(0);
  const [isThirdMenuVisible, setThirdMenuVisible] = useState(false);
  const [isSecondMenuVisible, setSecondMenuVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [isImageLoading, setIsImageLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsImageLoading(false);
    }, 100);
  }, [isImageLoading]);

  let history = useHistory();

  const navigateTo = (url) => {
    history.push(url);
    shouldDismissModal(true);
  };

  if (menu) {
    return (
      <div ref={modalRef} className="menu_container">
        <div style={{ width: "31%" }} className="meniu_principal">
          {menu.map((item, idx) => (
            <div
              className={index === idx ? "menu_option_active" : "menu_option"}
              onClick={() => {
                setIndex(idx);
                setIsImageLoading(true);
                if (item.children === null) {
                  navigateTo(`/products/${replaceCategory(item.name)}`);
                }
              }}
            >
              <p
                onClick={() =>
                  navigateTo(`/products/${replaceCategory(item.name)}`)
                }
              >
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </p>
              {item.children !== null && <i className="fas fa-angle-right" />}
            </div>
          ))}
        </div>
        <div
          style={{ width: "20%" }}
          className={
            isSecondMenuVisible ? "meniu_principal_mobile" : "menu_slide_in"
          }
        >
          {menu.map((item, idx) => (
            <div
              className="menu_option"
              onClick={() => {
                setIndex(idx);
                setSecondMenuVisible(true);
              }}
            >
              <p
                onClick={(e) => {
                  e.preventDefault();
                  navigateTo(`/products/${replaceCategory(item.name)}`);
                }}
              >
                {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
              </p>
              {item.children !== null && <i className="fas fa-angle-right" />}
            </div>
          ))}
        </div>
        <div style={{ width: "85%" }} className="meniu_secundar">
          {menu[index].children !== null &&
            menu[index].children.map((i) => (
              <div
                className={
                  menu[index].children.length <= 3
                    ? "subcategory_part_max_three"
                    : "subcategory_part"
                }
              >
                <p
                  className="subcategory_title"
                  onClick={() =>
                    navigateTo(
                      `/products/${replaceCategory(
                        menu[index].name
                      )}_${replaceCategory(i.name)}`
                    )
                  }
                >
                  {i.name.charAt(0).toUpperCase() + i.name.slice(1)}
                </p>
                <div className="menu_links_and_image">
                  <div className="menu_img_category">
                    <Img
                      src={i.imageUrl}
                      className="submenu_category_image"
                      style={isImageLoading ? { visibility: "hidden" } : {}}
                      alt={`category-${i.name}-logo`}
                      cache
                      lazy={false}
                    />
                  </div>
                  {i.children !== null && (
                    <div>
                      {i.children.map((cName) => (
                        <p
                          className="p_link"
                          onClick={() =>
                            navigateTo(
                              `/products/${replaceCategory(
                                menu[index].name
                              )}_${replaceCategory(i.name)}_${replaceCategory(
                                cName.name
                              )}`
                            )
                          }
                        >
                          {cName.name.charAt(0).toUpperCase() +
                            cName.name.slice(1)}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        {isSecondMenuVisible && (
          <div style={{ width: "60%" }} className="meniu_secundar_mobile">
            {menu[index].children !== null && (
              <div>
                <div
                  className="back-to-category"
                  onClick={() => {
                    setSecondMenuVisible(false);
                  }}
                >
                  <i className="fas fa-angle-left" />
                  <p>
                    {menu[index].name.charAt(0).toUpperCase() +
                      menu[index].name.slice(1)}
                  </p>
                </div>
                {menu[index].children.map((i, ind) => (
                  <div
                    className="subcategory_part"
                    onClick={() =>
                      setThirdMenuVisible(
                        menu[index].children[ind].children !== null
                      )
                    }
                  >
                    <p
                      className="subcategory_title"
                      onClick={(e) => {
                        e.preventDefault();
                        setSecondIndex(ind);
                        navigateTo(
                          `/products/${replaceCategory(
                            menu[index].name
                          )}_${replaceCategory(i.name)}`
                        );
                      }}
                    >
                      {i.name.charAt(0).toUpperCase() + i.name.slice(1)}
                    </p>
                    {menu[index].children[ind].children !== null && (
                      <i className="fas fa-angle-right" />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {isSecondMenuVisible && isThirdMenuVisible && (
          <div style={{ width: "60%" }} className="meniu_secundar_mobile">
            {menu[index].children[secondIndex] !== null && (
              <div>
                <div
                  className="back-to-category"
                  onClick={() => {
                    setThirdMenuVisible(false);
                  }}
                >
                  <i className="fas fa-angle-left" />
                  <p>
                    {menu[index].children[secondIndex].name
                      .charAt(0)
                      .toUpperCase() +
                      menu[index].children[secondIndex].name.slice(1)}
                  </p>
                </div>
                {menu[index].children[secondIndex].children.map((i) => (
                  <div className="subcategory_part">
                    <p
                      className="subcategory_title"
                      onClick={() =>
                        navigateTo(
                          `/products/${replaceCategory(
                            menu[index].name
                          )}_${replaceCategory(
                            menu[index].children[secondIndex].name
                          )}_${replaceCategory(i.name)}`
                        )
                      }
                    >
                      {i.name.charAt(0).toUpperCase() + i.name.slice(1)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => ({
  brandActiveFilters: state.filters.brandActiveFilters,
});
const mapDispatchToProps = (dispatch) => ({
  setStockActiveFilter: (payload) => dispatch(setStockActiveFilter(payload)),
  setPriceActiveFilter: (payload) => dispatch(setPriceActiveFilter(payload)),
  removeBrandActiveFilter: (payload) =>
    dispatch(removeBrandActiveFilter(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
