import axios from "axios";
class ApiClient {
  constructor() {
    this.apiClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
    this.apiClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.apiClient.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response.status === 404) {
          window.location.assign(`/404`);
        } else if (err.response.status === 400) {
          window.alert(err.response.data.message);
        }
      }
    );

    return this.apiClient;
  }
}

export default new ApiClient();
