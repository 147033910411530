import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./App.scss";
import Login from "./pages/Login/Login";
import SingleProductPage from "./pages/SingleProductPage/SingleProductPage";
import Footer from "./components/Footer/Footer";
import ProductsPage from "./pages/ProductsPage/ProductsPage";
import Header from "./components/header/Header";
import Navbar from "./components/navbar/Navbar";
import Account from "./pages/Account/Account";
import { MyCart } from "./pages/CartPage/MyCart";
import { Wishlist } from "./pages/CartPage/Wishlist";
import OrderCompletionPage from "./pages/OrderCompletion/OrderCompletionPage";
import Confirmation from "./pages/Confirmation/Confirmation";
import { connect } from "react-redux";
import {
  getCart,
  getWishlistRequest,
  updateGuestCartRequest,
} from "./redux/cart/cart.actions";
import {
  setVerified,
  verifyTokenRequest,
} from "./redux/register/register.actions";
import BrandsPage from "./pages/BrandsPage/BrandsPage";
import MyAccountPage from "./pages/MyAccountPage/MyAccountPage";
import ConfirmationRamburs from "./pages/Confirmation/ConfirmationRamburs";
import AdminPage from "./pages/AdminPage/AdminPage";
import CreateAccountConfirmation from "./pages/Confirmation/CreateAccountConfirmation";
import Message from "./pages/Confirmation/Message";
import HomePage from "./pages/Home/HomePage";
import OrderRambursError from "./pages/Confirmation/OrderRambursError";
import Contact from "./pages/Contact/Contact";
import ForgotPasswordPage from "./pages/ForgotPassword/ForgotPasswordPage";
import { Spinner } from "react-bootstrap";
import PasswordRecovery from "./pages/PasswordRecovery/PasswordRecovery";
import Notification from "./components/Notification/Notification";
import { removeNotification } from "./redux/product/product.actions";
import { getMenuItems, setMenuItems } from "./redux/menu/menu.actions";
import TermeniSiConditii from "./pages/TermeniSiConditii/TermeniSiConditii";
import PoliticaConfidentialitate from "./pages/PoliticaConfidentialitate/PoliticaConfidentialitate";
import PoliticaCookie from "./pages/PoliticaCookie/PoliticaCookie";
import Cookies from "./components/Cookies/Cookies";
import Page404 from "./pages/Page404/Page404";
import Promotions from "./pages/Promotions/Promotions";
import { LOCAL_STORAGE_REFERRER } from "./utilities/constants";

function App({
  getCart,
  verifyTokenRequest,
  user,
  getWishlistRequest,
  setVerified,
  notifications,
  removeNotification,
  getMenuItems,
  setMenuItems,
  updateGuestCartRequest,
}) {
  useEffect(() => {
    if (user) {
      getCart();
      getWishlistRequest();
    }
  }, [user]);

  useEffect(() => {
    if (localStorage.getItem("token") && !user) verifyTokenRequest();

    if (!localStorage.getItem("token")) {
      const stringifiedPersistedRoot = localStorage.getItem("persist:root");
      const persistedRoot = stringifiedPersistedRoot
        ? JSON.parse(stringifiedPersistedRoot)
        : null;
      const cart = persistedRoot ? JSON.parse(persistedRoot.cart) : null;

      if (cart && cart.cartProducts.includes(null)) {
        localStorage.removeItem("persist:root");
        window.location.assign("/");
      } else {
        setVerified();
        if (cart && cart.cartProducts)
          updateGuestCartRequest(cart.cartProducts);
      }
    }
  }, []);

  useEffect(() => {
    let categories = JSON.parse(sessionStorage.getItem("categories_TOOLS"));
    if (!categories) {
      getMenuItems();
    } else {
      setMenuItems({
        electronicsMenu: JSON.parse(
          sessionStorage.getItem("categories_ELECTRONICS")
        ),
        toolsMenu: JSON.parse(sessionStorage.getItem("categories_TOOLS")),
      });
    }
  }, []);

  const [isCookie, setIsCookie] = useState(false);
  useEffect(() => {
    let cookies = JSON.parse(localStorage.getItem("cookie"));
    if (cookies) {
      setIsCookie(true);
      let dateCookie = cookies.data;
      const currentDate = new Date();
      const lastDate = new Date(dateCookie);
      const diffTime = Math.abs(currentDate - lastDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 30) {
        setIsCookie(false);
      }
    }
  }, []);

  useEffect(() => {
    let savedReferrer = localStorage.getItem(LOCAL_STORAGE_REFERRER);
    let referrer = document.referrer;

    let today = new Date();
    let timestamp =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();

    let object = {
      date: timestamp,
      from: referrer.length > 1 ? referrer : "direct",
      to: window.location.href,
    };

    if (savedReferrer) {
      let list = JSON.parse(savedReferrer);
      let lastElement = list[list.length - 1];

      if (
        referrer === "" ||
        lastElement.to.split("/")[2] !== referrer.split("/")[2]
      ) {
        list.push(object);
        if (list.length > 10) {
          list.splice(0, 1);
        }
        localStorage.setItem(LOCAL_STORAGE_REFERRER, JSON.stringify(list));
      }
    } else {
      if (
        object.from === "direct" ||
        object.from.split("/")[2] !== object.to.split("/")[2]
      ) {
        localStorage.setItem(LOCAL_STORAGE_REFERRER, JSON.stringify([object]));
      }
    }
  }, []);

  return (
    <>
      <div className="header_sticky">
        <Header />
        <div
          style={{ position: "fixed", top: "20", zIndex: "1002" }}
          className="filters_notifications"
        >
          {notifications.map((notification) => {
            return (
              <Notification
                text={notification.text}
                boldText={notification.boldText}
                onClick={() => removeNotification(notification.id)}
              />
            );
          })}
        </div>
        <Navbar />
      </div>
      {false ? (
        <div className="general-spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/login" component={Login} />
          <Route
            path="/account-confirmation"
            component={CreateAccountConfirmation}
          />
          <Route exact path="/products/:category" component={ProductsPage} />
          <Route path="/register" component={Account} />
          <Route path="/message" component={Message} />
          <Route path="/order-completion" component={OrderCompletionPage} />
          <Route path="/confirmation" component={Confirmation} />
          <Route path="/confirmation-ramburs" component={ConfirmationRamburs} />
          <Route path="/ramburs-error" component={OrderRambursError} />
          <Route path="/my-cart" component={MyCart} />
          <Route path="/wishlist" component={Wishlist} />
          <Route path="/brands" component={BrandsPage} />
          <Route path="/my-account" component={MyAccountPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/contact" component={Contact} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/password-recovery" component={PasswordRecovery} />
          <Route path="/terms" component={TermeniSiConditii} />
          <Route path="/privacy-policy" component={PoliticaConfidentialitate} />
          <Route path="/cookie" component={PoliticaCookie} />
          <Route path="/promotions" component={Promotions} />
          <Route path="/:name/:productCode" component={SingleProductPage} />
          <Route path="/404" component={Page404} />
          <Redirect to="/404" />
        </Switch>
      )}
      {!isCookie && <Cookies></Cookies>}
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
  isVerified: state.registerAccount.isVerified,
  notifications: state.product.notifications,
  menu: state.menu.menu,
});

const mapDispatchToProps = (dispatch) => ({
  getCart: () => dispatch(getCart()),
  verifyTokenRequest: () => dispatch(verifyTokenRequest()),
  getWishlistRequest: () => dispatch(getWishlistRequest()),
  setVerified: () => dispatch(setVerified()),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
  getMenuItems: (payload) => dispatch(getMenuItems(payload)),
  setMenuItems: (payload) => dispatch(setMenuItems(payload)),
  updateGuestCartRequest: (payload) =>
    dispatch(updateGuestCartRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
