import React, { useEffect, useState } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { Link } from "react-router-dom";
import "react-web-tabs/dist/react-web-tabs.css";
import MyAccount from "../MyAccount/MyAccount";
import "./Menu.scss";
import { AccountOrders } from "../MyOrders/AccountOrders";
import { logout } from "../../../redux/register/register.actions";
import { connect } from "react-redux";
import Notification from "../../../components/Notification/Notification";
import { removeNotification } from "../../../redux/product/product.actions";

const Menu = ({
  logout,
  user,
  isVerified,
  location,
  notifications,
  removeNotification,
  setLinkString,
}) => {
  const [tab, setTab] = useState("vertical-tab-one");

  useEffect(() => {
    if (location.pathname === "/my-account") {
      setTab("vertical-tab-one");
      setLinkString("");
    } else if (location.pathname.includes("orders")) {
      setTab("vertical-tab-two");
    }
  }, [location.pathname, tab]);

  if (!user && isVerified) window.location.assign("/");

  return (
    <div className="main-content">
      <div className="filters_notifications sticky">
        {notifications.map((notification) => {
          return (
            <Notification
              text={notification.text}
              boldText={notification.boldText}
              onClick={() => removeNotification(notification.id)}
            />
          );
        })}
      </div>
      <Tabs className="menu-content" defaultTab={tab} vertical>
        <TabList>
          <Link to="/my-account">
            <Tab
              tabFor="vertical-tab-one"
              onClick={() => setTab("vertical-tab-one")}
            >
              Contul meu
            </Tab>
          </Link>
          <Link to="/my-account/orders">
            <Tab
              tabFor="vertical-tab-two"
              onClick={() => setTab("vertical-tab-two")}
            >
              Comenzi
            </Tab>
          </Link>
          <Link to="/wishlist">
            <Tab tabFor="vertical-tab-three">Wishlist</Tab>
          </Link>
          <Tab
            style={{ color: "#E04C35" }}
            tabFor="vertical-tab-four"
            onClick={() => logout()}
          >
            Deloghează-te
          </Tab>
        </TabList>

        {tab === "vertical-tab-one" && (
          <TabPanel tabId="vertical-tab-one">
            <MyAccount></MyAccount>
          </TabPanel>
        )}
        {tab === "vertical-tab-two" && (
          <TabPanel tabId="vertical-tab-two">
            <AccountOrders
              location={location}
              setLinkString={setLinkString}
            ></AccountOrders>
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
});
const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
  isVerified: state.registerAccount.isVerified,
  notifications: state.product.notifications,
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
