import { GET_MENU_ITEM_REQUEST, GET_MENU_ITEM_SUCCESS } from "./menu.constants";

export const getMenuItems = (payload) => {
  return {
    type: GET_MENU_ITEM_REQUEST,
    payload,
  };
};

export const setMenuItems = (payload) => {
  return {
    type: GET_MENU_ITEM_SUCCESS,
    payload,
  };
};
