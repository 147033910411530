import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_EMAIL_REQUEST,
  EDIT_INFO_REQUEST,
  GET_INFO_REQUEST,
  GET_ORDERS_REQUEST,
  GET_ORDERS_DETAILS_REQUEST,
  NEXT_PAGE_ACC,
  PREV_PAGE_ACC,
  DELETE_ACCOUNT_REQUEST,
  SET_FIRST_PAGE_ACC,
  SET_LAST_PAGE_ACC,
  SET_NEXT_PAGE_ACC,
  SET_PREV_PAGE_ACC,
} from "./account.constants";

export const changePasswordRequest = (payload) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload,
  };
};

export const changeEmailRequest = (payload) => {
  return {
    type: CHANGE_EMAIL_REQUEST,
    payload,
  };
};

export const editInfoRequest = (payload) => {
  return {
    type: EDIT_INFO_REQUEST,
    payload,
  };
};

export const getInfoRequest = (payload) => {
  return {
    type: GET_INFO_REQUEST,
    payload,
  };
};

export const getOrdersRequest = (payload) => {
  return {
    type: GET_ORDERS_REQUEST,
    payload,
  };
};

export const getOrdersDetailsRequest = (payload) => {
  return {
    type: GET_ORDERS_DETAILS_REQUEST,
    payload,
  };
};

export const deleteAccountRequest = (payload) => {
  return {
    type: DELETE_ACCOUNT_REQUEST,
    payload,
  };
};

export const nextPage = () => {
  return {
    type: NEXT_PAGE_ACC,
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE_ACC,
  };
};

export const setNextPage = () => {
  return {
    type: SET_NEXT_PAGE_ACC,
  };
};

export const setPrevPage = () => {
  return {
    type: SET_PREV_PAGE_ACC,
  };
};

export const setFirstPage = () => {
  return {
    type: SET_FIRST_PAGE_ACC,
  };
};

export const setLastPage = () => {
  return {
    type: SET_LAST_PAGE_ACC,
  };
};
