import React from "react";
import { Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import { nextPage, prevPage } from "../../../redux/comments/comments.actions";

const CommentsPagination = ({
  currentPage,
  nextPage,
  prevPage,
  numberOfPages,
}) => {
  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => {
          if (currentPage !== 1) prevPage();
        }}
      >
        Pagina anterioară
      </Pagination.Prev>

      <div>
        <span>{currentPage}</span>
        <span> din {numberOfPages}</span>
      </div>
      <Pagination.Next
        onClick={() => {
          if (currentPage !== numberOfPages) nextPage();
        }}
      >
        Pagina următoare
      </Pagination.Next>
    </Pagination>
  );
};
const mapStateToProps = (state) => ({
  currentPage: state.comments.currentPage,
  numberOfPages: state.comments.numberOfPages,
});
const mapDispatchToProps = (dispatch) => ({
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
});
export default connect(mapStateToProps, mapDispatchToProps)(CommentsPagination);
