import React from "react";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import "./Header.scss";
import person from "../../images/Header/account_icon.svg";
import heart from "../../images/Header/wishlist_icon.svg";

const HeaderMobile = ({ user }) => {
  return (
    <div className="header_mobile">
      <div
        className="icons-group mobile"
        onClick={() => window.location.assign("/brands")}
      >
        <p>Branduri</p>
        <i className="fas fa-angle-right" />
      </div>
      <div
        className="icons-group mobile"
        onClick={() => window.location.assign("/contact")}
      >
        <p>Contact</p>
        <i className="fas fa-angle-right" />
      </div>
      <div
        className="icons-group mobile mobile-fav"
        style={{ color: "#DDDADA" }}
        onClick={() => window.location.assign("/wishlist")}
      >
        <p>Favorite</p>
        <Image src={heart} alt="heart logo" className="header_logo" />
      </div>
      <div
        className="icons-group mobile mobile-cont"
        style={{ color: "#DDDADA" }}
        onClick={() => {
          user
            ? window.location.assign("/my-account")
            : window.location.assign("/login");
        }}
      >
        <p>{user || "Cont"}</p>
        <Image src={person} alt="person logo" className="header_logo" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
});

export default connect(mapStateToProps)(HeaderMobile);
