export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";

export const NEXT_PAGE_COMMENTS = "NEXT_PAGE_COMMENTS";
export const PREV_PAGE_COMMENTS = "PREV_PAGE_COMMENTS";

export const SAVE_COMMENT_REQUEST = "SAVE_COMMENT_REQUEST";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";
