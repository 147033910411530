export const SEARCH_PRODUCTS_REQUEST = "SEARCH_PRODUCTS_REQUEST";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE";

export const NEXT_PAGE_A = "NEXT_PAGE_A";
export const PREV_PAGE_A = "PREV_PAGE_A";

export const SET_NEXT_PAGE_A = "SET_NEXT_PAGE_A";
export const SET_PREV_PAGE_A = "SET_PREV_PAGE_A";
export const SET_FIRST_PAGE_A = "SET_FIRST_PAGE_A";
export const SET_LAST_PAGE_A = "SET_LAST_PAGE_A";
