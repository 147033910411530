import React, { useState, useEffect } from "react";
import { Button, Row } from "react-bootstrap";
import "./Cookies.scss";

const Cookies = () => {
  const [cookie, setCookie] = useState(true);
  const [cookieLocation, setCookieLocation] = useState(false);

  useEffect(() => {
    if (cookie) {
      if (window.location.pathname === "/cookie") {
        setCookieLocation(true);
      }
    }
  }, [window.location.pathname]);

  const saveCookies = () => {
    var cookieData = {
      data: new Date(),
      cookies: "true",
    };
    localStorage.setItem("cookie", JSON.stringify(cookieData));
    setCookie(false);
  };

  return (
    <>
      <div
        className={
          cookie === true
            ? cookieLocation === true
              ? "div-cookie-page-container"
              : "div-cookie-container"
            : "hide-div-cookie-container"
        }
      >
        <Row className="body-cookie">
          <div className="text">
            Acest site foloseste cookies. Continuarea navigarii presupune ca
            esti de acord cu utilizarea cookie-urilor.&nbsp;
          </div>
          <a
            href="/cookie"
            style={{ textDecoration: "none" }}
            className="details"
            onClick={() => window.scrollTo(0, 0)}
          >
            Afla detalii
          </a>
          <Button
            className="btn-style btn-success"
            onClick={() => saveCookies()}
          >
            Am inteles
          </Button>
        </Row>
      </div>
    </>
  );
};

export default Cookies;
