import { GET_BRANDS_REQUEST, GET_BRANDS_SUCCESS } from "./brands.constants";

const INITIAL_STATE = {
  brands: [],
};

export const brandsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS_REQUEST:
      return {
        ...state,
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: action.payload.brands,
      };
    default:
      return state;
  }
};
