const pixelAddToCartEvent = (id, price) => {
  window.fbq("track", "AddToCart", {
    value: price,
    currency: "RON",
    content_type: "product",
    content_ids: id,
  });
};

const pixelViewContentEvent = (id, price) => {
  window.fbq("track", "ViewContent", {
    value: price,
    currency: "RON",
    content_type: "product",
    content_ids: id,
  });
};

const pixelPurchaseEvent = (totalPrice, products) => {
  const formattedProducts = products.map((p) => ({
    id: p.id,
    quantity: p.quantity,
  }));

  window.fbq("track", "Purchase", {
    value: totalPrice,
    currency: "RON",
    content_type: "product",
    contents: formattedProducts,
  });
};

export { pixelAddToCartEvent, pixelViewContentEvent, pixelPurchaseEvent };
