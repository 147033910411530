import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import styles from "../../general.module.scss";
import "./Confirmation.scss";
import { Link } from "react-router-dom";
import { getRegisterStatusRequest } from "../../redux/register/register.actions";
import SEO from "../../components/SEO/SEO";

const ConfirmationAccount = ({
  location,
  status,
  getRegisterStatusRequest,
}) => {
  const [searchedId, setSearchedId] = useState("");

  useEffect(() => {
    const locationSplit = location.search.split("=");
    if (
      "?token=" + searchedId !== location.search &&
      locationSplit[0] === "?token"
    ) {
      setSearchedId(locationSplit[1]);
    }
  }, [location.search]);

  useEffect(() => {
    if (searchedId !== "") {
      getRegisterStatusRequest({
        token: searchedId,
      });
    }
  }, [searchedId]);

  return (
    <>
      <SEO
        title="Confirmarea crearii contului"
        description=""
        robots="noindex, follow"
        canonical="https://romstock.ro/account-confirmation"
        openGraph={{
          url: "https://www.romstock.ro/account-confirmation",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description:
            "Felicitari pentru crearea contului RomStock! Pune prima comanda acum",
        }}
      />
      <div className={styles.container}>
        {status && (
          <div className="container col-md-8 col-12 confirmation_container">
            <h3>
              {status.data === 0
                ? "Contul dumneavoastră a fost creat. "
                : "Eroare: Contul dumneavoastră nu a putut fi creat. "}
            </h3>

            {status.data === 0 ? (
              <Link
                to="/login"
                style={{ color: "white", textDecoration: "none" }}
              >
                <button className="button">Login</button>
              </Link>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  status: state.registerAccount.status,
});
const mapDispatchToProps = (dispatch) => ({
  getRegisterStatusRequest: (payload) =>
    dispatch(getRegisterStatusRequest(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationAccount);
