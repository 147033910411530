import React, { useState } from "react";
import { Button } from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import { connect } from "react-redux";
import CloseButton from "../../components/CloseButton/CloseButton";
import Notification from "../../components/Notification/Notification";
import {
  addBrandActiveFilter,
  removeBrandActiveFilter,
  setPriceActiveFilter,
  setStockActiveFilter,
} from "../../redux/filters/filters.actions";
import { removeNotification } from "../../redux/product/product.actions";
import { PRICE_TYPE, STOCK_TYPE } from "../../redux/filters/filters.constants";

const Filters = ({
  activeFiltersArray,
  brandFilters,
  priceFilter,
  addBrandActiveFilter,
  setStockActiveFilter,
  setPriceActiveFilter,
  removeBrandActiveFilter,
  notifications,
  removeNotification,
  priceActiveFilter,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const deleteFilter = (index) => {
    activeFiltersArray.forEach((filter, i) => {
      if (i === index) {
        if (filter.filterType === PRICE_TYPE) {
          setPriceActiveFilter(null);
        } else if (filter.filterType === STOCK_TYPE) {
          setStockActiveFilter(null);
        } else {
          removeBrandActiveFilter(filter.value);
        }
      }
    });
  };

  return (
    <>
      <div className="filters-mobile filters-select">
        <Button onClick={() => setShowFilters((state) => !state)}>
          Selectează filtre
          <i className="fas fa-chevron-down"></i>
        </Button>
      </div>
      <div className="filters">
        <div className="filters_notifications sticky">
          {notifications.map((notification) => {
            return (
              <Notification
                text={notification.text}
                boldText={notification.boldText}
                onClick={() => removeNotification(notification.id)}
              />
            );
          })}
        </div>
        <div className="filter">
          <h3>Filtre</h3>
          <div className="filter_content">
            {activeFiltersArray.length === 0 ? (
              <p>Niciun filtru selectat</p>
            ) : (
              activeFiltersArray.map((filter, index) => (
                <p>
                  {filter.value === "IN_STOCK"
                    ? "În stoc"
                    : filter.value === "REDUCED_STOCK"
                    ? "Stoc limitat"
                    : filter.value === "OUT_OF_STOCK"
                    ? "Stoc epuizat"
                    : filter.value}
                  <span>
                    <CloseButton onButtonClick={() => deleteFilter(index)} />
                  </span>
                </p>
              ))
            )}
          </div>
        </div>
        <div
          className={
            showFilters ? "filter-groups filter-groups-show" : "filter-groups"
          }
        >
          <div className="filter">
            <h3>Brand</h3>
            <div className="filter_content">
              {brandFilters.map((filter) => (
                <p
                  className={
                    activeFiltersArray
                      .map((filter) => filter.value)
                      .includes(filter.brand)
                      ? "f-bold"
                      : ""
                  }
                  onClick={() => addBrandActiveFilter(filter.brand)}
                >
                  {filter.brand} <span>{filter.brandCount}</span>
                </p>
              ))}
            </div>
          </div>

          <div className="filter">
            <h3>Preț</h3>
            <div className="filter_content">
              <InputRange
                maxValue={priceFilter.max}
                minValue={priceFilter.min}
                value={priceActiveFilter.value || { min: 0, max: 0 }}
                onChange={(obj) => {
                  setPriceActiveFilter({ min: obj.min, max: obj.max });
                }}
              />
            </div>
          </div>

          <div className="filter">
            <h3>Stoc</h3>
            <div className="filter_content">
              {["În stoc", "Stoc limitat", "Stoc epuizat"].map(
                (stock, index) => {
                  const values = ["IN_STOCK", "REDUCED_STOCK", "OUT_OF_STOCK"];
                  return (
                    <p
                      className={
                        activeFiltersArray
                          .map((filter) => filter.value)
                          .includes(values[index])
                          ? "f-bold"
                          : ""
                      }
                      onClick={() => setStockActiveFilter(values[index])}
                    >
                      {stock}
                    </p>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  priceActiveFilter: state.filters.priceActiveFilter,
  brandFilters: state.filters.brandFilters,
  priceFilter: state.filters.priceFilter,
  notifications: state.product.notifications,
});

const mapDispatchToProps = (dispatch) => ({
  addBrandActiveFilter: (payload) => dispatch(addBrandActiveFilter(payload)),
  removeBrandActiveFilter: (payload) =>
    dispatch(removeBrandActiveFilter(payload)),
  setPriceActiveFilter: (payload) => dispatch(setPriceActiveFilter(payload)),
  setStockActiveFilter: (payload) => dispatch(setStockActiveFilter(payload)),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
