import React from "react";
import { Row } from "react-bootstrap";
import "./CategoryCard.scss";
import Img from "react-cool-img";

const CategoryCard = ({ text, image }) => {
  return (
    <div className="main-div-category-card">
      <Row className="center-style img-row-card">
        <Img className="image-style" src={image} alt={text + "-icon"} cache />
      </Row>

      <div className="text_row">
        <label style={{ marginBottom: "0%" }} className="text-clamp">
          {text}
        </label>
      </div>
    </div>
  );
};

export default CategoryCard;
