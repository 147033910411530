import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import ProductCard from "../../components/productCard/ProductCard";
import { Spinner } from "react-bootstrap";
import ProductsPagination from "./ProductsPagination";
import SelectOrder from "./SelectOrder";
import { Link } from "react-router-dom";
import CategoryCard from "../../components/CategoryCard/CategoryCard";
import {
  replaceNameSingleProduct,
  encodeProductCode,
  decodeCategory,
  replaceCategory,
} from "../../utilities/helpers";

const ProductsList = ({ products, isLoading, toolsMenu, electronicsMenu }) => {
  const path = window.location.pathname.split(/\//)[2];
  const [persistentMenu, setPersistentMenu] = useState("");

  useEffect(() => {
    let toolsMenu = JSON.parse(sessionStorage.getItem("categories_TOOLS"));
    let electronicsMenu = JSON.parse(
      sessionStorage.getItem("categories_ELECTRONICS")
    );

    if (toolsMenu && electronicsMenu) {
      let categories = [toolsMenu, electronicsMenu];
      setPersistentMenu(categories);
    }
  }, [toolsMenu, electronicsMenu]);

  if (persistentMenu)
    return (
      <>
        <div className="products-container">
          <SelectOrder />
          {!isLoading && products.length === 0 ? (
            <h5 style={{ marginLeft: "2.5%", marginTop: "2%" }}>
              {" "}
              Nu există niciun rezultat pentru această căutare.
            </h5>
          ) : isLoading ? (
            <div className="products-spinner">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              {path !== "" && path !== "all" && (
                <div
                  className="category-list"
                  style={{
                    display: path.split("_")[2] ? "none" : "grid",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {persistentMenu ? (
                    persistentMenu.map((p) =>
                      p.children !== null ? (
                        !path.split("_")[1] ? (
                          p.map((i) =>
                            i.name === decodeCategory(path.split("_")[0]) ? (
                              i.children !== null &&
                              i.children.map((ic) => (
                                <Link
                                  className="category_a"
                                  style={{ textDecoration: "none" }}
                                  to={`/products/${replaceCategory(
                                    i.name
                                  )}_${replaceCategory(ic.name)}`}
                                >
                                  <CategoryCard
                                    text={
                                      ic.name.charAt(0).toUpperCase() +
                                      ic.name.slice(1)
                                    }
                                    image={ic.imageUrl}
                                  />
                                </Link>
                              ))
                            ) : (
                              <></>
                            )
                          )
                        ) : (
                          p.map((i) =>
                            i.name === decodeCategory(path.split("_")[0]) ? (
                              i.children.map((icn) =>
                                icn.name ===
                                decodeCategory(path.split("_")[1]) ? (
                                  icn.children !== null ? (
                                    icn.children.map((icnc) => (
                                      <Link
                                        className="category_a"
                                        style={{ textDecoration: "none" }}
                                        to={`/products/${replaceCategory(
                                          i.name
                                        )}_${replaceCategory(
                                          icn.name
                                        )}_${replaceCategory(icnc.name)}`}
                                      >
                                        <CategoryCard
                                          text={
                                            icnc.name.charAt(0).toUpperCase() +
                                            icnc.name.slice(1)
                                          }
                                          image={icnc.imageUrl}
                                        />
                                      </Link>
                                    ))
                                  ) : (
                                    <></>
                                  )
                                ) : (
                                  <></>
                                )
                              )
                            ) : (
                              <></>
                            )
                          )
                        )
                      ) : (
                        <></>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </div>
              )}

              <div className="products-list">
                {products.map((c, index) => {
                  return (
                    <Link
                      to={
                        "/" +
                        replaceNameSingleProduct(c.name) +
                        "/" +
                        encodeProductCode(c.code)
                      }
                    >
                      <ProductCard
                        key={c.id}
                        index={index}
                        isInWishlist={c.isInWishlist}
                        currentPrice={c.currentPrice}
                        labelType={
                          c.stockStatus === "REDUCED_STOCK"
                            ? "stoc redus"
                            : c.stockStatus === "IN_STOCK"
                            ? "în stoc"
                            : "indisponibil"
                        }
                        product={c.image}
                        code={c.code}
                        oldPrice={String(c.oldPrice).split(/[\s.]+/)[0]}
                        oldPriceSup={
                          String(
                            (Math.round(c.oldPrice * 100) / 100).toFixed(2)
                          ).split(/[\s.]+/)[1]
                        }
                        oldType={"RON"}
                        price={String(c.currentPrice).split(/[\s.]+/)[0]}
                        priceSup={
                          String(
                            (Math.round(c.currentPrice * 100) / 100).toFixed(2)
                          ).split(/[\s.]+/)[1]
                        }
                        type={"RON"}
                        text={c.name}
                        numberOfStars1={c.numberOfStars1}
                        numberOfStars2={c.numberOfStars2}
                        numberOfStars3={c.numberOfStars3}
                        numberOfStars4={c.numberOfStars4}
                        numberOfStars5={c.numberOfStars5}
                        discount={c.discount}
                        legalGuarantee={12}
                        commercialGuarantee={24}
                        brand={c.brand}
                        weight={c.weight}
                      />
                    </Link>
                  );
                })}
              </div>
            </>
          )}
          {isLoading || products.length === 0 ? (
            <></>
          ) : (
            <div className="products-pagination">
              <ProductsPagination />
            </div>
          )}
        </div>
      </>
    );
  return <div style={{ flexGrow: 1 }}></div>;
};

const mapStateToProps = (state) => ({
  products: state.filters.products,
  isLoading: state.filters.isLoading,
  toolsMenu: state.menu.toolsMenu,
  electronicsMenu: state.menu.electronicsMenu,
});

export default connect(mapStateToProps, null)(React.memo(ProductsList));
