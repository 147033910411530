import { connect } from "react-redux"
import { compose } from "redux"
import { getAdminOrderDetailsRequest, getAdminOrdersRequest } from "../../redux/admin/admin.actions"
import MyOrders from "../MyAccountPage/MyOrders/MyOrders"

const mapDispatchToProps = dispatch => ({
    getOrdersRequest: payload => dispatch(getAdminOrdersRequest(payload)),
    getOrdersDetailsRequest: payload => dispatch(getAdminOrderDetailsRequest(payload))
})

const mapStateToProps = state => ({
    products: state.admin.products,
    ordersDetails: state.admin.ordersDetails,
    numberOfPages: state.admin.numberOfPages,
    currentPage: state.admin.currentPage,
    isAdmin: true,
    isLoading: state.admin.isLoading
})

export const AdminOrders = compose(connect(mapStateToProps, mapDispatchToProps)(MyOrders))