import apiClient from "./apiClient";
import qs from "qs";
import axios from "axios";

class apiService {
  getGuestProducts(body, cancelToken, page) {
    return apiClient.post(
      `/public/products/search?pageNumber=${page - 1}`,
      body,
      cancelToken ? { cancelToken: cancelToken.token } : null
    );
  }
  getLoggedProducts(body, cancelToken, page) {
    return apiClient.post(
      `/products/search?pageNumber=${page - 1}`,
      body,
      cancelToken ? { cancelToken: cancelToken.token } : null
    );
  }
  getProduct(productCode) {
    return apiClient.get(`/public/products?code=${productCode}`);
  }
  postWishlist(body) {
    return apiClient.post(`/wishlist/items`, body);
  }
  deleteWishlist(productCode) {
    return apiClient.delete(
      `/wishlist/items?code=${encodeURIComponent(productCode)}`
    );
  }
  saveUserAccount(body) {
    return apiClient.post(`/register`, body);
  }
  getUserAccountStatus(token) {
    return apiClient.get(`/register/verify?token=${token}`);
  }
  resendEmail(email) {
    return apiClient.get(`/register/resend?email=${email}`);
  }

  postLogin(body) {
    const token = Buffer.from(`client:password`, "utf8").toString("base64");
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/oauth/token`,
      data: qs.stringify(body),
      mode: "no-cors",
      credentials: "same-origin",
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        Authorization: `Basic ${token}`,
      },
    });
  }
  postToCart(body) {
    return apiClient.post("/cart/items", body);
  }
  patchCart(body) {
    return apiClient.patch("/cart/items", body);
  }
  deleteCart(productCode) {
    return apiClient.delete(
      `/cart/items?code=${encodeURIComponent(productCode)}`
    );
  }
  getVerify() {
    return apiClient.get(`/account/name`);
  }
  getComments(productCode, page) {
    return apiClient.get(
      `/public/products/comments?code=${productCode}&&pageNumber=${page - 1}`
    );
  }

  saveComment(productCode, body) {
    return apiClient.post(`/products/comments?code=${productCode}`, body);
  }

  getMenu(category) {
    return apiClient.get(`/public/categories?type=${category}`);
  }
  getBrands() {
    return apiClient.get(`/public/brands`);
  }
  getCart() {
    return apiClient.get(`/cart/items/detailed`);
  }
  getWishlist() {
    return apiClient.get(`/wishlist/items`);
  }

  ////// Payment
  postBTPayment(body) {
    return apiClient.post(`/public/orders/bt`, body);
  }
  postRambursPayment(body) {
    return apiClient.post(`/public/orders/ramburs`, body);
  }
  postLoggedBTPayment(body) {
    return apiClient.post(`/orders/bt`, body);
  }
  postLoggedRambursPayment(body) {
    return apiClient.post(`/orders/ramburs`, body);
  }
  getStatusBTPayment(orderId) {
    return apiClient.get(`/public/orders/status?orderId=${orderId}`);
  }
  getLoggedStatusBTPayment(orderId) {
    return apiClient.get(`/orders/status?orderId=${orderId}`);
  }
  getOrdersInfo() {
    return apiClient.get(`/orders/payment/info`);
  }
  getOrderDetails(orderNumber) {
    return apiClient.get(
      `/public/orders/status/analytics?orderNumber=${orderNumber}`
    );
  }
  getLoggedOrderDetails(orderNumber) {
    return apiClient.get(`/orders/status/analytics?orderNumber=${orderNumber}`);
  }
  getVoucher(voucherCode) {
    return apiClient.get(`/voucher/information?code=${voucherCode}`);
  }
  getVoucherGuest(voucherCode, email) {
    return apiClient.get(
      `/public/voucher/information/?code=${voucherCode}&email=${email}`
    );
  }
  /////

  logout() {
    return apiClient.post(`/oauth/logout`);
  }

  changePassword(body) {
    return apiClient.post(`/account/change/password`, body);
  }

  changeEmail(body) {
    return apiClient.post(`/account/change/email`, body);
  }

  editInfo(body) {
    return apiClient.post(`/account/change/info`, body);
  }

  getInfo() {
    return apiClient.get(`/account/info`);
  }

  getOrders(page) {
    return apiClient.get(`/account/orders?pageNumber=${page - 1}`);
  }
  verifyRole() {
    return apiClient.get(`/account/name`);
  }
  getOrdersDetails(orderNumber) {
    return apiClient.get(`/account/orders/${orderNumber}`);
  }
  getAdminOrders(page) {
    return apiClient.get(`/admin/orders?pageNumber=${page - 1}`);
  }
  getAdminOrdersDetails(orderNumber) {
    return apiClient.get(`/admin/orders/${orderNumber}`);
  }
  saveProducts(body) {
    return apiClient.post(`/public/products/frontpage`, body);
  }
  getRandomProducts() {
    return apiClient.get(`/public/products/frontpage`);
  }
  editProduct(body) {
    return apiClient.put("/admin/products", body);
  }

  sendEmailForgotPassword(email, body) {
    return apiClient.post(
      `/public/account/password/reset?email=${email}`,
      body
    );
  }

  setNewPassword(token, body) {
    return apiClient.post(
      `/public/account/password/reset/ok?token=${token}`,
      body
    );
  }

  deleteAccount(body) {
    return apiClient.post(`/account/delete`, body);
  }
  updateGuestCart(body) {
    return apiClient.put("/public/cart/items/update", body);
  }
  searchProducts(searchedString, currentPageNumber) {
    return apiClient.get(
      `/admin/products?string=${searchedString}&pageNumber=${
        currentPageNumber - 1
      }`
    );
  }
  getPromotionProducts(currentPage, category) {
    return apiClient.get(
      `/public/monthly/promotions?pageNumber=${
        currentPage - 1
      }&category=${encodeURIComponent(category)}`
    );
  }
  getPromotionCategories() {
    return apiClient.get(`/public/monthly/promotions/categories`);
  }
  getPriceRanges() {
    return apiClient.get(`/price-range`);
  }
  savePriceRange(body) {
    return apiClient.post(`/price-range`, body);
  }
  updatePriceRange(id, body) {
    return apiClient.put(`/price-range/${id}`, body);
  }
  deletePriceRange(id) {
    return apiClient.delete(`/price-range/${id}`);
  }
  bulkUpdateProducts(body) {
    return apiClient.put(`/admin/bulk-products`, body);
  }
}

export default new apiService();
