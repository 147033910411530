export const usePromotionsHelper = () => {
  const getCategoryImage = (category) => {
    const menu = [
      ...JSON.parse(sessionStorage.getItem("categories_TOOLS")),
      ...JSON.parse(sessionStorage.getItem("categories_ELECTRONICS")),
    ];

    let itemsToSearch = menu;
    let image;
    for (const el of category.split("::")) {
      const menuItem = itemsToSearch?.find((m) => m.name === el);
      if (menuItem) {
        itemsToSearch = menuItem.children;
        image = menuItem.imageUrl;
      }
    }

    return image ?? "https://www.romprofix.ro/assets/images/not-available.png";
  };

  return {
    getCategoryImage,
  };
};
