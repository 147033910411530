import React from "react";
import { Pagination } from "react-bootstrap";
import { connect } from "react-redux";
import {
  nextPage,
  prevPage,
  setNextPage,
  setLastPage,
  setPrevPage,
  setFirstPage,
} from "../../redux/filters/filters.actions";

const ProductsPagination = ({
  currentPageProduct,
  nextPage,
  prevPage,
  numberOfPages,
  setNextPage,
  setLastPage,
  setPrevPage,
  setFirstPage,
}) => {
  return (
    <Pagination>
      {currentPageProduct > 2 ? (
        <Pagination.First
          onClick={() => {
            if (currentPageProduct !== 1) {
              setFirstPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPageProduct !== 1 ? (
        <Pagination.Prev
          onClick={() => {
            if (currentPageProduct !== 1) {
              prevPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPageProduct > 4 ? (
        <Pagination.Ellipsis className="ellipsis" />
      ) : (
        <></>
      )}
      {currentPageProduct > 2 ? (
        <Pagination.Item
          onClick={() => {
            setPrevPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPageProduct - 2}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPageProduct > 1 ? (
        <Pagination.Item
          onClick={() => {
            prevPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPageProduct - 1}
        </Pagination.Item>
      ) : (
        <></>
      )}
      <Pagination.Item active>{currentPageProduct}</Pagination.Item>
      {currentPageProduct !== numberOfPages ? (
        <Pagination.Item
          onClick={() => {
            nextPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPageProduct + 1}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPageProduct !== numberOfPages &&
      currentPageProduct !== numberOfPages - 1 ? (
        <Pagination.Item
          onClick={() => {
            setNextPage();
            window.scrollTo(0, 0);
          }}
        >
          {currentPageProduct + 2}
        </Pagination.Item>
      ) : (
        <></>
      )}
      {currentPageProduct < numberOfPages - 3 ? (
        <Pagination.Ellipsis className="ellipsis" />
      ) : (
        <></>
      )}
      {currentPageProduct !== numberOfPages ? (
        <Pagination.Next
          onClick={() => {
            if (currentPageProduct !== numberOfPages) {
              nextPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
      {currentPageProduct < numberOfPages - 1 ? (
        <Pagination.Last
          onClick={() => {
            if (currentPageProduct !== numberOfPages) {
              setLastPage();
              window.scrollTo(0, 0);
            }
          }}
        />
      ) : (
        <></>
      )}
    </Pagination>
  );
};
const mapStateToProps = (state) => ({
  currentPageProduct: state.filters.currentPageProduct,
  numberOfPages: state.filters.numberOfPages,
});

const mapDispatchToProps = (dispatch) => ({
  nextPage: () => dispatch(nextPage()),
  prevPage: () => dispatch(prevPage()),
  setNextPage: () => dispatch(setNextPage()),
  setPrevPage: () => dispatch(setPrevPage()),
  setFirstPage: () => dispatch(setFirstPage()),
  setLastPage: () => dispatch(setLastPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPagination);
