import { all, call } from "redux-saga/effects";
import { commentsSaga, saveCommentSaga } from "./comments/comments.saga";
import { cartSagas } from "./cart/cart.saga";
import { filtersSaga } from "./filters/filters.saga";
import { productSaga } from "./product/product.saga";
import { registerSaga } from "./register/register.saga";
import { menuSaga } from "./menu/menu.saga";
import { brandsSaga } from "./brands/brands.saga";
import {
  changeEmailSaga,
  changePasswordSaga,
  deleteAccountSaga,
  editInfoSaga,
  getInfoSaga,
  getOrdersDetailsSaga,
  getOrdersSaga,
} from "./account/account.saga";
import { orderSaga } from "./order/order.saga";
import { saveProductsSaga } from "./home/home.saga";
import { adminSaga } from "./admin/admin.saga";
import { searchSaga } from "./search/search.saga";
import { promotionSaga } from "./promotions/promotions.saga";

export default function* rootSaga() {
  yield all([
    call(filtersSaga),
    call(productSaga),
    call(registerSaga),
    call(commentsSaga),
    call(saveCommentSaga),
    call(menuSaga),
    call(brandsSaga),
    call(cartSagas),
    call(changePasswordSaga),
    call(changeEmailSaga),
    call(editInfoSaga),
    call(getInfoSaga),
    call(orderSaga),
    call(getOrdersSaga),
    call(getOrdersDetailsSaga),
    call(saveProductsSaga),
    call(deleteAccountSaga),
    call(adminSaga),
    call(searchSaga),
    call(promotionSaga),
  ]);
}
