export const IN_STOC = "In Stoc";
export const STOC_LIMITAT = "Stoc limitat";
export const STOC_EPUIZAT = "Stoc epuizat";

export const ORDER_MAP = {
  PRICE_ASCENDING: "Preț s",
  PRICE_DESCENDING: "Preț j",
  DISCOUNT: "Discount",
  NAME_ASCENDING: "Nume s",
  NAME_DESCENDING: "Nume j",
  RELEVANCE: "Relevanță",
};

export const costMax = 400;
export const DELIVERY_FEE = 30.0;

export const URL = "";

export const judete = [
  { auto: "AB", nume: "Alba", simplu: "Alba" },
  { auto: "AR", nume: "Arad", simplu: "Arad" },
  { auto: "AG", nume: "Argeş", simplu: "Arges" },
  { auto: "BC", nume: "Bacău", simplu: "Bacau" },
  { auto: "BH", nume: "Bihor", simplu: "Bihor" },
  { auto: "BN", nume: "Bistriţa-Năsăud", simplu: "Bistrita-Nasaud" },
  { auto: "BT", nume: "Botoşani", simplu: "Botosani" },
  { auto: "BR", nume: "Brăila", simplu: "Braila" },
  { auto: "BV", nume: "Braşov", simplu: "Brasov" },
  { auto: "B", nume: "Bucureşti", simplu: "Bucuresti" },
  { auto: "BZ", nume: "Buzău", simplu: "Buzau" },
  { auto: "CL", nume: "Călăraşi", simplu: "Calarasi" },
  { auto: "CS", nume: "Caraş-Severin", simplu: "Caras-Severin" },
  { auto: "CJ", nume: "Cluj", simplu: "Cluj" },
  { auto: "CT", nume: "Constanţa", simplu: "Constanta" },
  { auto: "CV", nume: "Covasna", simplu: "Covasna" },
  { auto: "DB", nume: "Dâmboviţa", simplu: "Dambovita" },
  { auto: "DJ", nume: "Dolj", simplu: "Dolj" },
  { auto: "GL", nume: "Galaţi", simplu: "Galati" },
  { auto: "GR", nume: "Giurgiu", simplu: "Giurgiu" },
  { auto: "GJ", nume: "Gorj", simplu: "Gorj" },
  { auto: "HR", nume: "Harghita", simplu: "Harghita" },
  { auto: "HD", nume: "Hunedoara", simplu: "Hunedoara" },
  { auto: "IL", nume: "Ialomiţa", simplu: "Ialomita" },
  { auto: "IS", nume: "Iaşi", simplu: "Iasi" },
  { auto: "IF", nume: "Ilfov", simplu: "Ilfov" },
  { auto: "MM", nume: "Maramureş", simplu: "Maramures" },
  { auto: "MH", nume: "Mehedinţi", simplu: "Mehedinti" },
  { auto: "MS", nume: "Mureş", simplu: "Mures" },
  { auto: "NT", nume: "Neamţ", simplu: "Neamt" },
  { auto: "OT", nume: "Olt", simplu: "Olt" },
  { auto: "PH", nume: "Prahova", simplu: "Prahova" },
  { auto: "SJ", nume: "Sălaj", simplu: "Salaj" },
  { auto: "SM", nume: "Satu Mare", simplu: "Satu Mare" },
  { auto: "SB", nume: "Sibiu", simplu: "Sibiu" },
  { auto: "SV", nume: "Suceava", simplu: "Suceava" },
  { auto: "TR", nume: "Teleorman", simplu: "Teleorman" },
  { auto: "TM", nume: "Timiş", simplu: "Timis" },
  { auto: "TL", nume: "Tulcea", simplu: "Tulcea" },
  { auto: "VL", nume: "Vâlcea", simplu: "Valcea" },
  { auto: "VS", nume: "Vaslui", simplu: "Vaslui" },
  { auto: "VN", nume: "Vrancea", simplu: "Vrancea" },
];

export const LOCAL_STORAGE_REFERRER = "referrer";

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  centerMode: false,
  centerPadding: "0px",
  className: "center",
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "60px",
        arrows: false,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "40px",
        centerMode: true,
        arrows: false,
      },
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "20px",
      },
    },
  ],
};
