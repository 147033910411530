import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import { GET_MENU_ITEM_REQUEST, GET_MENU_ITEM_SUCCESS } from "./menu.constants";

export function* getMenu() {
  const { data: toolsMenu } = yield call(apiService.getMenu, "TOOLS");
  const { data: electronicsMenu } = yield call(
    apiService.getMenu,
    "ELECTRONICS"
  );

  if (toolsMenu !== []) {
    sessionStorage.setItem("categories_TOOLS", JSON.stringify(toolsMenu));
  }

  if (electronicsMenu !== []) {
    sessionStorage.setItem(
      "categories_ELECTRONICS",
      JSON.stringify(electronicsMenu)
    );
  }

  yield put({
    type: GET_MENU_ITEM_SUCCESS,
    payload: { toolsMenu: toolsMenu, electronicsMenu: electronicsMenu },
  });
}

export function* getMenuWatcher() {
  yield takeLatest(GET_MENU_ITEM_REQUEST, getMenu);
}

export function* menuSaga() {
  yield all([call(getMenuWatcher)]);
}
