import React from "react";
import "./TermeniSiConditii.scss";

const TermeniSiConditii = () => {
  return (
    <>
      <div className="container-livrare-returnare">
        <h1 className="title-style">Termeni și condiții ROMSTOCK.RO</h1>
        <div>
          <h5>
            <strong>Preambul</strong>
          </h5>
          <p>
            Acești Termeni și condiții generale (în continuare: TCG)
            „ROMSTOCK.RO” includ termenii și condițiile de accesare și utilizare
            a website-ului ROMSTOCK.RO. Platforma ROMSTOCK.RO este deținută și
            operată de SC UNICIMOB CONSTRUCT SRL, cu sediul denumită în
            continuare”Compania”,”noi”
          </p>
          <p>
            Acest TCG este un contract-cadru încheiat între ROMSTOCK.RO și
            dumneavoastră în calitate de Client, pentru o perioadă
            nedeterminată, la înregistrarea efectuată prin intermediul
            platformei online ROMSTOCK.RO, intrând în vigoare prin acceptarea
            acestuia ca element obligatoriu de înregistrare. Acceptarea TCG este
            posibilă numai pe cale electronică. Odată cu înregistrarea efectuată
            cu succes, este încheiat pe cale electronică un contract între
            părți, pentru utilizarea platformei ROMSTOCK.RO. Contractul nu se
            califică drept contract scris, prin urmare nu va fi înregistrat,
            deci nu va putea fi accesat ulterior. Limba guvernantă a
            contractului este limba româna.{" "}
          </p>
          <p>
            TCG se aplică oricărui raport juridic dintre Client și Companie,
            prin înregistrarea în sistemul ROMSTOCK.RO, în vederea utilizării
            serviciilor oferite, și se extinde asupra fiecărui contract cu un
            partener sau contract individual de servicii și determină drepturile
            și obligațiile părților și alte circumstanțe cu privire la
            utilizarea platformei ROMSTOCK.RO. TCG este o parte expresă și
            integrantă a relației juridice dintre Companie și clienții săi, cu
            excepția cazului în care părțile exclud în mod expres
            aplicabilitatea acestui TCG sau a oricărei prevederi, în scris, în
            timpul stabilirii raportului juridic sau pe durata acestuia, cu
            excepția cazului în care legea aplicabilă interzice acest aspect. În
            cazul în care anumite dispoziții nu sunt aplicabile, dispozițiile
            rămase din TCG fac parte din raportul juridic al părților. În cazul
            unui conflict între contractul individual și TCG, prevederile
            contractului individual vor prevala.
          </p>
          <p>
            Părțile vor acționa în strânsă cooperare între ele pentru punerea în
            aplicare efectivă a obiectului prezentului TCG.
          </p>
          <p>
            Părțile se pot baza exclusiv pe propria lor responsabilitate în ceea
            ce privește informațiile disponibile pe ROMSTOCK.RO și este
            responsabilitatea lor exclusivă de a monitoriza orice modificări ale
            acestora.
          </p>
          <p>
            Părțile sunt obligate să utilizeze ROMSTOCK.RO în scopuri licite și
            legale, și într-o manieră conformă cu legea.
          </p>
          <p>
            Este sarcina și responsabilitatea părților să ofere mijloacele de
            acces la internet și la echipamentele necesare în acest sens
            (hardware și software, precum setările corespunzătoare alea
            acestora) pentru a accesa platforma ROMSTOCK.RO. Clientul care
            utilizează platforma online este singurul responsabil pentru
            disponibilitatea și utilizarea corectă a instrumentelor IT.
          </p>
          <p>
            Părțile sunt de acord asupra faptului că ROMSTOCK.RO nu are nici o
            influență asupra și nu este responsabilă pentru politicile și
            practicile din Android Market, App Store sau ale altor terțe părți.
          </p>

          <h5>
            <strong>DEFINIȚII</strong>
          </h5>
          <p>
            În cuprinsul prezentului document cât și în relația dintre Companie
            și Client, interpretarea noțiunilor și termenilor utilizați va fi
            următoarea:
          </p>
          <p>
            <strong>Platformă/aplicație ROMSTOCK.RO</strong> – este modalitatea
            prin care compania pune la dispoziția utilizatorului serviciile și
            produsele sale și se permite achiziționarea acestora . În cuprinsul
            prezentului document ne vom referi la acesta și prin utilizarea
            sintagmei ” platformă/aplicație”
          </p>
          <p>
            <strong>Site</strong> - se referă la website-ul{" "}
            <a href="www.romstock.ro">www.romstock.ro</a> și este modalitatea
            prin care Compania comercializează produse, asigură prezentarea
            acestora și se oferă utilizatorului posibilitatea de a plasa o
            comandă pentru achiziția produselor
          </p>
          <p>
            <strong>
              Client-denumit în continuare ”utilizator”, ”client”, ”cumpărător”
              sau ”dumneavoastră”
            </strong>{" "}
            - o persoană fizică având vârsta peste 18 ani, care urmărește
            achiziția de produse comercilizate de companie
          </p>
          <p>
            <strong>Cont</strong> – secțiunea din platforma/aplicația
            ROMSTOCK.RO formată dintr-o adresă de e-mail și o parola care
            permite utilizatorului să acceseze platforma, să vizualizeze și să
            plaseze comenzi. Utilizatorul este responsabil și se va asigura că
            toate informațiile introduse la crearea Contului sunt corecte,
            complete și actualizate.{" "}
          </p>
          <p>
            <strong>Produse</strong> – orice produs listat pe Site,
            platformă/aplicație disponibil pentru comandă
          </p>

          <h5>
            <strong>1. EFECTUAREA UNEI COMENZI</strong>
          </h5>
          <p>
            Prin inregistrarea unei Comenzi pe Site, Cumparatorul este de acord
            cu forma de comunicare (telefonic sau e-mail) prin care Vanzatorul
            isi deruleaza operatiunile comerciale.
          </p>
          <p>
            Notificarea primita de catre Cumparator, dupa efectuarea Comenzii
            are rol de informare si nu reprezinta acceptarea Comenzii. Aceasta
            notificare se face electronic (e-mail) sau telefonic.
          </p>
          <p>
            Pentru motive justificate, Vanzatorul isi rezerva dreptul de a
            modifica cantitatea Bunurilor si/sau Serviciilor din Comanda. Daca
            modifica cantitatea de Bunuri si/sau Servicii din Comanda va anunta
            Cumparatorul la adresa de e-mail sau la numarul de telefon puse la
            dispozitia Vanzatorului la efectuarea Comenzii si va returna suma
            achitata.
          </p>
          <p>
            Contractul se considera incheiat intre Vanzator si Cumparator in
            momentul primirii de catre Cumparator de la Vanzator, prin
            intermediul postei electronice si/sau SMS a notificarii de expediere
            a Comenzii.
          </p>
          <p>
            Documentul si informatiile puse la dispozitie de catre Vanzator pe
            Site vor sta la baza Contractului, in completarea acestuia fiind,
            dacă este cazul,certificatul de garantie emis de catre Vanzator sau
            un furnizor al acestuia pentru Bunurile achizitionate.
          </p>

          <h5>
            <strong>2. PROCEDURA DE VÂNZARE</strong>
          </h5>
          <p>
            Accesul in vederea efectuarii unei Comenzii ii este permis oricarui
            Utilizator/Cumparator.
          </p>
          <p>
            Pentru motive justificate ROMSTOCK.RO isi rezerva dreptul de a
            restrictiona accesul Utilizatorului/Cumparatorului in vederea
            efectuarii unei Comenzi si/sau la unele din modalitatile de plata
            acceptate, in cazul in care considera ca in baza conduitei sau a
            activitatii Utilizatorului/Cumparatorului pe Site, actiunile
            acestuia ar putea prejudicia in vreun fel ROMSTOCK.RO. In oricare
            dintre aceste cazuri, Utilizatorul/Cumparatorul se poate adresa
            Departamentului de Relatii cu Clientii al ROMSTOCK.RO, pentru a fi
            informat cu privire la motivele care au condus la aplicarea
            masurilor sus mentionate.
          </p>
          <p>
            Comunicarea cu Vanzatorul se poate realiza prin interactiunea
            directa cu acesta sau prin adresele mentionate la sectiunea
            “contact” din Site. Vanzatorul are libertatea de a gestiona
            informatiile primite fara a fi nevoit sa aduca justificari pentru
            aceasta.
          </p>
          <p>
            In cazul unui volum de trafic neobisnuit de mare provenit din partea
            unei retele de internet, ROMSTOCK.RO isi rezerva dreptul de a cere
            Utilizatorilor/Cumparatorilor introducerea manuala a codurilor de
            validare de tip captcha, in vedere protejarii informatiei din cadrul
            Site-ului.
          </p>
          <p>
            ROMSTOCK.RO poate publica pe Site informatii despre Bunuri si/sau
            promotii practicate de catre acesta sau de catre oricare alt tert cu
            care ROMSTOCK.RO are incheiate contracte de parteneriat, intr-o
            anumita perioada de timp si in limita stocului disponibil.
          </p>
          <p>
            Toate tarifele aferente Bunurilor si/sau Serviciilor prezentate pe
            Site sunt exprimate in lei (RON) si includ T.V.A.
          </p>
          <p>
            In cazul platilor online, Vanzatorul nu este/nu poate fi facut
            responsabil pentru niciun alt cost suplimentar suportat de
            Cumparator, incluzand dar nelimitandu-se la comisioane de conversie
            valutara aplicate de catre banca emitenta a cardului acestuia, in
            cazul in care moneda de emitere a acestuia difera de RON.
            Responsabilitatea pentru aceasta actiune o poarta numai
            Cumparatorul.
          </p>
          <p>
            Toate informatiile folosite pentru descrierea Bunurilor si/sau a
            Serviciilor disponibile pe Site (imagini statice / dinamice /
            prezentari multimedia / etc.) nu reprezinta o obligatie contractuala
            din partea Vanzatorului, acestea fiind utilizate exclusiv cu titlu
            de prezentare.
          </p>
          <p>
            Dupa 14 (paisprezece) zile de la achizitionarea unui Bun,
            Cumparatorului i se va putea solicita inscrierea unui Review legat
            de Bunul achiziționat. Solicitarea va fi transmisa catre adresa de
            email inscrisa de Cumparator in Cont. In acest fel, Cumparatorul
            contribuie la informarea altor posibili Utilizatori/ Clienti/
            Cumparatori de pe Site si se implica activ in dezvoltarea de noi
            Servicii si in detalierea cat mai completa a caracteristicilor
            Bunurilor. Transmiterea acestor solicitări precum și
            răspunsurile/recenziile vor fi realizate în conformitate cu
            prevederile cuprinse în politica de confidențialitate și prelucrare
            a datelor cu caracter personal ce poate fi regăsită pe site.
          </p>
          <p>
            Vanzatorul poate cesiona si/sau subcontracta o terta parte pentru
            Servicii ce tin de onorarea Comenzii, cu informarea Cumparatorului,
            nefiind necesar acordul acestuia. Vanzatorul va fi intotdeauna
            responsabil fata de Cumparator pentru toate obligatiile
            contractuale.
          </p>
          <p>
            Vânzătorul nu este si nu poate fi făcut responsabil pentru
            prejudiciile create de erorile, neacuratetea sau neactualizarea
            informațiilor publicate sau menținute pe platforma ROMSTOCK, care nu
            se datorează culpei sale.{" "}
          </p>
          <p>
            In cazul in care preturile sau alte detalii referitoare la
            produse/promoții au fost afișate greșit, inclusiv din cauza faptului
            ca au fost introduse greșit in baza de date, ne alocam dreptul de a
            anula livrarea respectivului produs si de a anunța clientul
            telefonic/e-mail in cel mai scurt timp, despre eroarea apăruta, daca
            produsul nu s-a livrat încă, fără obligația de plată a unor
            despăgubiri.
          </p>
          <p>
            Caracteristicile produselor prezentate pe site sunt preluate / puse
            la dispoziție de producători si/ furnizori si vânzătorul nu își
            asuma răspunderea pentru corectitudinea acestor informații.
          </p>
          <p>
            Preturile produselor de pe acest site sunt informative si pot suferi
            modificări neanunțate. Promoțiile prezentate pe site sunt valabile
            in perioada de timp menționata.
          </p>
          <p>
            In cazul in care nu se menționează o perioada de timp, acestea sunt
            valabile in limita stocurilor disponibile
          </p>

          <h5>
            <strong>
              3. DREPTUL DE PROPRIETATE INTELECTUALA SI INDUSTRIALA
            </strong>
          </h5>
          <p>
            Continutul, astfel cum este definit in preambul, incluzand dar
            nelimitandu-se la logo-uri, reprezentari stilizate, simboluri
            comerciale, imagini statice, imagini dinamice, text si/sau continut
            multimedia prezentate pe Site, sunt proprietatea exclusiva a
            ROMSTOCK.RO, acestuia fiindu-i rezervate toate drepturile obtinute
            in acest sens in mod direct sau indirect (prin licente de utilizare
            si/sau publicare).{" "}
          </p>
          <p>
            De asemenea, logo-uri, reprezentari stilizate, simboluri comerciale,
            imagini statice, imagini dinamice, text si/sau continut multimedia
            prezentate ce aparțin producătorilor produselor comercializate
            aparțin acestora și nu acordă dreptul utilizatorului de a le
            prelua/copia sau utiliza în scopuri care pot genera prejudicii
            producătorilor sau vânzătorului.
          </p>
          <p>
            Clientului/Cumparatorului/Utilizatorului nu ii este permisa
            copierea, distribuirea, publicarea, transferul catre terte parti,
            modificarea si/sau altfel alterarea, utilizarea, legarea la,
            expunerea, includerea oricarui Continut in orice alt context decat
            cel original intentionat de ROMSTOCK.RO, includerea oricarui
            Continut in afara Site-ului, indepartarea insemnelor care semnifica
            dreptul de autor al ROMSTOCK.RO asupra Continutului precum si
            participarea la transferul, vanzarea, distributia unor materiale
            realizate prin reproducerea, modificarea sau afisarea Continutului,
            decat cu acordul scris expres al ROMSTOCK.RO.
          </p>
          <p>
            Orice Continut la care Clientul/Cumparatorul/Utilizatorul are si/sau
            obtine acces prin orice mijloc, se afla sub incidenta Documentului,
            in cazul in care Continutul nu este insotit de un acord de utilizare
            specific si valid incheiat intre ROMSTOCK.RO si acesta, si fara
            nicio garantie implicit sau expres formulata din partea ROMSTOCK.RO
            cu referire la acel Continut.
          </p>
          <p>
            Clientul/Cumparatorul/Utilizatorul poate copia, transfera si/sau
            utiliza Continut numai in scopuri personale sau non-comerciale,
            numai in cazul in care acestea nu intra in conflict cu prevederile
            prezentelor termeni și condiții.
          </p>
          <p>
            In cazul in care ROMSTOCK.RO confera
            Clientului/Cumparatorului/Utilizatorului dreptul de a utiliza sub
            forma descrisa intr-un acord de utilizare distinct, un anumit
            continut, la care Clientul/Cumparatorul/Utilizatorul are sau obtine
            acces in urma acestui acord, acest drept se extinde numai asupra
            acelui sau acelor continuturi definite in acord, numai pe perioada
            existentei acestuia sau acestor continuturi pe site sau a perioadei
            definite in acord, conform conditiilor definite, in cazul in care
            acestea exista si nu reprezinta un angajament contractual din partea
            ROMSTOCK.RO pentru respectivul Client/Cumparator/Utilizator sau
            oricare alt tert care are/obtine acces la acest continut transferat,
            prin orice mijloc si care ar putea fi sau este prejudiciat in orice
            mod de pe urma acestui continut, in timpul sau dupa expirarea
            acordului de utilizare.
          </p>
          <p>
            Niciun Continut transmis catre Client, Utilizator sau Cumparator,
            prin orice mijloc de comunicare (electronic, telefonic, etc) sau
            dobandit de acesta prin accesare, vizitare si/sau vizualizare nu
            constituie o obligatie contractuala din partea ROMSTOCK.RO si/sau al
            angajatului/prepusului ROMSTOCK.RO care a mijlocit transferul de
            Continut, in cazul in care aceasta exista, fata de respectivul
            continut.
          </p>
          <p>
            Este interzisa orice utilizare a Continutului in alte scopuri decat
            cele permise expres prin prezentul Document sau de acordul de
            utilizare care il insoteste, in cazul in care acesta exista.
          </p>

          <h5>
            <strong>4. REALIZAREA COMENZII</strong>
          </h5>
          <p>
            Clientul/Cumparatorul poate efectua Comenzi pe Site, prin adaugarea
            Bunurilor/serviciilor dorite in cosul de cumparaturi, urmand a
            finaliza Comanda efectuand plata prin una dintre modalitatile
            indicate expres. Odata adaugat in cosul de cumparaturi, un Bun
            si/sau un Serviciu este disponibil pentru achizitie in masura in
            care exista stoc disponibil pentru aceasta. Adaugarea unui
            Bun/Serviciu in cosul de cumparaturi, in lipsa finalizarii Comenzii,
            nu atrage dupa sine inregistrarea unei comenzi, implicit nici
            rezervarea automata a Bunului/Serviciului.
          </p>
          <p>
            Cumpărătorul va putea plasa o comandă fără a fi necesar un cont pe
            platforma ROMSTOCK.RO însă prin crearea unui cont cumpărătorul poate
            beneficia de reduceri/promoții sau vouchere acordare de către
            Vânzător. Voucherele/promoțiile/reducerile pot fi acordate de
            vânzător doar persoanelor care ți-au creat un cont fără a exista
            obligația vânzătorului de a acorda aceste beneficii și clienților
            care nu optează pentru crearea unui cont. Prin acceptarea termenilor
            și condițiilor, cumpărătorul acceptă expres această prevedere.
          </p>
          <p>
            Prin finalizarea Comenzii Cumparatorul consimte ca toate datele
            furnizate de acesta, necesare procesului de cumparare, sunt corecte,
            complete si adevarate la data plasarii Comenzii.
          </p>
          <p>
            Prin finalizarea Comenzii, Cumparatorul consimte ca Vanzatorul poate
            sa il contacteze, prin orice mijloc disponibil / agreat de Vanzator,
            in orice situatie in care este necesara contactarea Cumparatorului.
          </p>
          <p>
            Vanzatorul poate anula Comanda efectuata de catre Cumparator, in
            urma unei notificari prealabile adresate Cumparatorului, fara nicio
            obligatie ulterioara a vreunei parti fata de cealalta sau fara ca
            vreo parte sa poata sa pretinda celeilalte daune-interese in
            urmatoarele cazuri:
            <ul className="list-style">
              <li>
                {" "}
                - neacceptarea de catre banca emitenta a cardului
                Cumparatorului, a tranzactiei, in cazul platii online;
              </li>
              <li>
                {" "}
                - invalidarea tranzactiei de catre procesatorul de carduri
                agreat de ROMSTOCK.RO, in cazul platii online;
              </li>
              <li>
                {" "}
                - datele furnizate de catre Client/Cumparator, pe site sunt
                incomplete si/sau incorecte;
              </li>
            </ul>
          </p>

          <h5>
            <strong>5. DREPTUL DE RETRAGERE.EXCEPȚII</strong>
          </h5>
          <p>
            Cumparatorul are dreptul sa se retraga din Contract, respectiv sa
            returneze un Bun, în termen de 14 zile calendaristice, fara
            invocarea niciunui motiv si fara a suporta alte costuri decât cele
            de livrare, bunul trebuind să fie neutilizat/desfăcut, să aibă
            ambalajul deteriorat și să fie returnat în aceleași condiții în care
            a fost livrat(ambalarea să permită asigurarea integrității
            produsului pe perioada transportului ). Astfel, potrivit OUG nr
            34/2014, perioada de returnare a unui Bun sau renunțare la un
            Serviciu expiră în termen de 14 zile de la:
            <ul className="list-style">
              <li>
                – ziua în care Cumparatorul intră în posesia fizică a ultimului
                Bun – în cazul în care Cumparatorul comandă printr-o singură
                comandă produse multiple care vor fi livrate separat
              </li>
              <li>
                – ziua în care Cumparatorul intră în posesia fizică a ultimului
                Bun sau a ultimei piese – în cazul livrării unui produs care
                constă din mai multe loturi sau piese
              </li>
            </ul>
          </p>
          <p>
            In cazul in care Cumparatorul decide sa se retraga din Contract,
            acesta va putea completa online formularul de retur
          </p>
          <p>
            In cazul in care Clientul/Cumparatorul solicita retragerea din
            Contract in cadrul termenului legal de retragere din contract,
            trebuie sa returneze si eventualele cadouri care au insotit produsul
            respectiv.{" "}
          </p>
          <p>
            In cazul in care Comanda este achitata, Vanzatorul va rambursa suma
            in maxim 14 (paisprezece) zile de la data informarii Vanzatorului de
            catre Cumparator asupra deciziei sale de retragere din Contract.
            Suma va fi returnata dupa cum urmeaza:
            <ul>
              <li className="list-style-li">
                pentru Comenzile achitate cu card online -&gt; prin restituire
                in contul din care a fost efectuata plata;
              </li>
              <li className="list-style-li">
                pentru Comenzile achitate cu Op/ramburs/iTransfer/ Card bancar
                -&gt; prin virament bancar;
              </li>
            </ul>
          </p>
          <p>
            Vanzatorul va putea amana rambursarea sumei pana la primirea
            Bunurilor vandute sau pana la primirea unei dovezi conform careia
            acestea au fost expediate, in cazul in care nu s-a oferit sa
            recupereze el insusi Bunurile (se va lua data cea mai recenta).
          </p>
          <p>
            In cazul in care un Bun si/sau Serviciu comandat de catre
            Cumparator, nu poate fi livrat de catre Vanzator, acesta din urma va
            informa Clientul/Cumparatorul asupra acestui fapt si va returna in
            contul Cumparatorului contravaloarea Bunului si/sau Serviciului, in
            termen de maxim 7 (sapte) zile de la data la care Vanzatorul a luat
            la cunostinta acest fapt sau de la data la care Cumparatorul si-a
            exprimat in mod expres intentia de reziliere a Contractului.
          </p>
          <p>
            Disponibilitatea unui Bun va fi afisata in Site dupa cum urmeaza:
            <ul className="list-style">
              <li>
                <strong>“in stoc” </strong> – avem mai mult de 3 bucati in
                stocul ROMSTOCK.RO
              </li>
              <li>
                <strong>“indisponibil” </strong> - Bunul nu mai este disponibil
                in stocul ROMSTOCK.RO
              </li>
            </ul>
          </p>
          <p>
            Sunt exceptate de la dreptul de retragere din Contract următoarele:
            <ul>
              <li className="list-style-li">
                furnizarea de Bunuri confecţionate după specificaţiile
                prezentate de Cumparator sau personalizate în mod clar;
              </li>
              <li className="list-style-li">
                furnizarea de Bunuri care sunt susceptibile a se deteriora sau a
                expira rapid;
              </li>
              <li className="list-style-li">
                furnizarea de Bunuri sigilate care nu pot fi returnate din
                motive de protecţie a sănătăţii sau din motive de igienă şi care
                au fost desigilate de Cumparator;
              </li>
              <li className="list-style-li">
                furnizarea de Bunuri care sunt, după livrare, potrivit naturii
                acestora, inseparabil amestecate cu alte elemente;
              </li>
              <li className="list-style-li">
                bunurile al căror ambalaj este deteriorat sau a fost
                desigilat/desfăcut.
              </li>
              <li className="list-style-li">
                Bunurile care au prevăzută o perioadă de garanție mai redusă
                față de perioada de retragere, respective 14 zile{" "}
              </li>
            </ul>
          </p>

          <h5>
            <strong>6. CONFIDENTIALITATE</strong>
          </h5>
          <p>
            ROMSTOCK.RO va pastra confidentialitatea informatiilor de orice
            natura pe care le furnizati. Dezvaluirea informatiilor furnizate se
            va putea face doar in conditiile mentionate in prezentul Document și
            politica de confidențialitate și de prelucrare a datelor cu caracter
            personal.
          </p>
          <p>
            Nicio declaratie publica, promovare, comunicat de presa sau orice
            alt mod de dezvaluire catre terte parti nu va fi facuta de
            Cumparator/Client cu privire la Comanda/Contract fara consimtamantul
            prealabil scris al Vanzatorului.
          </p>
          <p>
            Prin transmiterea de informatii înțelegeți și acceptați termenii și
            condițiile prevăzute în Politica de confidențialitate si de
            prelucrare a datelor cu caracter personal.
          </p>

          <h5>
            <strong>7. COMUNICAREA ÎNTRE CLIENT ȘI VÂNZĂTOR</strong>
          </h5>
          <p>
            Cumparatorul/Utilizatorul/Clientul isi poate modifica in orice
            moment optiunea cu privire la acordul dat Vanzatorului pentru
            Comunicarile Comerciale continand informatii generale si tematice
            inclusiv informatii cu privire la oferte sau promotii, dupa cum
            urmeaza:
            <ul>
              <li className="list-style-li">
                prin modificarea setarilor din Cont la sectiunea “Abonarile
                mele”;
              </li>
              <li className="list-style-li">
                prin accesarea link-ului de dezabonare afisat in Comunicarile
                Comerciale primite de la Vanzator; sau
              </li>
              <li className="list-style-li">prin contactarea Vanzatorului.</li>
            </ul>
          </p>
          <p>Prin adaugarea de Bunuri in sectiunea din Cont:</p>
          <p>
            <strong>“Cosul meu”</strong>, Vanzatorul va transmite
            Cumparatorului/Utilizatorului Comunicari Comerciale cu privire:
            <ul className="list-style">
              <li>
                – la modificarea pretului Bunurilor sau Serviciilor adaugate in
                sectiunea “Cosul meu”,
              </li>
              <li>
                – la recomandari de Bunuri sau Serviciilor similare cu cele
                adaugate in sectiunea “Cosul meu”,
              </li>
              <li>
                – la existenta Bunurilor sau Serviciilor in sectiunea “Cosul
                meu”, si
              </li>
              <li>
                – disponibilitate stoc Bunuri sau Serviciilor adaugate in
                sectiunea “Cosul meu”.
              </li>
            </ul>
          </p>
          <p>
            <strong>“Favorite”</strong>, Vanzatorul va transmite
            Cumparatorului/Utilizatorului Comunicari Comerciale cu privire:
            <ul className="list-style">
              <li>
                – la modificarea pretului Bunurilor sau Serviciilor adaugate in
                sectiunea “Favorite”,
              </li>
              <li>
                – la recomandari de Bunuri sau Servicii similar cu cele adaugate
                in sectiunea “Favorite”, si
              </li>
              <li>
                – disponibilitate stoc sau Serviciilor Bunuri adaugate in
                sectiunea “Favorite”.
              </li>
            </ul>
          </p>
          <p>
            In urma achizitionarii unui Bun sau Serviciu, Vanzatorul va
            transmite Cumparatorului/Utilizatorului Comunicari Comerciale cu
            privire la:
            <ul className="list-style">
              <li>
                – sugestii de Bunuri sau Servicii recomandate a fi utilizate
                impreuna cu Bunul sau Serviciul achizitionat.
              </li>
            </ul>
          </p>
          <p>
            Clientul/Utilizatorul se poate dezabona, in orice moment, de la
            Comunicarile Comerciale mentionate la punctul de mai sus prin
            accesarea link-ului de dezabonare afisat in mesajele comerciale
            primite de la ROMSTOCK.RO sau prin contactarea ROMSTOCK.RO in acest
            sens.
          </p>
          <p>
            De asemenea, pentru imbunatatirea ofertei de Bunuri si Servicii si a
            experientei de cumparare, vom folosi datele dvs. pentru efectuarea
            de cercetari de piata si sondaje de opinie. Informatiile obtinute in
            urma acestor cercetari de piata si a sondajelor de opinie nu vor fi
            utilizate in scopuri publicitare ci doar in cele mentionate mai sus.
            Raspunsurile dvs. la cercetarile de piata si sondajele de opinie nu
            vor fi asociate cu identitatea dvs. si nici transmise catre terți și
            nici nu vor fi publicate. Puteti obiecta la utilizarea datelor in
            scopuri de cercetare de piata si sondaje de opinie in orice moment,
            prin accesarea link-ului de dezabonare afisat in mesaj sau prin
            contactarea ROMSTOCK.RO.
          </p>

          <h5>
            <strong>8. MODALITĂȚILE DE PLATĂ</strong>
          </h5>
          <p>
            Preturile Bunurilor afisate in cadrul site-ului ROMSTOCK.RO includ
            T.V.A. conform legislatiei in vigoare.
          </p>
          <p>
            Pretul, modalitatea de plata si termenul de plata sunt specificate
            in fiecare Comanda. Vanzatorul va emite catre Cumparator o factura
            pentru Bunurile si Serviciile livrate, obligatia Cumparatorului
            fiind sa furnizeze toate informatiile necesare emiterii facturii
            conform cu legislatia in vigoare.
          </p>
          <p>
            Vanzatorul va transmite Cumparatorului factura aferenta Comenzii ce
            contine Bunuri si/sau Servicii vandute de ROMSTOCK.RO, precum şi
            pentru orice alte plati aferente Comenzii, exclusiv in format
            electronic, prin adaugarea facturii in Contul Cumparatorului sau
            prin posta electronica, la adresa de e-mail mentionata de Cumparator
            in Contul sau. Pentru o corecta comunicare a facturii aferente
            Comenzii, Cumparatorului ii revin obligatiile de a-si actualiza ori
            de cate ori este cazul datele din Contul sau si de a accesa
            informatiile si documentele aferente fiecarei Comenzi, existente in
            Cont.
          </p>
          <p>
            Prin aceasta modalitate de comunicare Cumparatorul, accesand Contul
            sau, va detine o evidenta a facturilor emise de ROMSTOCK.RO,
            putandu-le salva si arhiva la randul sau in orice moment si in orice
            modalitate pe care o doreste.
          </p>
          <p>
            Prin trimiterea Comenzii, Cumparatorul isi exprima acordul sa
            primeasca facturile in format electronic prin adaugarea acestora de
            catre ROMSTOCK.RO in Contul sau prin intermediul postei electronice,
            la adresa de e-mail mentionata in Contul sau.
          </p>
          <p>
            Datele cardului de plata ale Utilizatorului/ Cumparatorului nu vor
            fi accesibile pentru ROMSTOCK.RO si nici nu vor fi stocate de catre
            ROMSTOCK.RO sau de catre procesatorul de plati integrat in Site, ci
            doar de catre institutia de autorizare a Tranzactiei sau o alta
            entitate autorizata sa presteze servicii de stocare date de
            identificare a cardului, despre a carei identitate Utilizatorul/
            Cumparatorul va fi informat, anterior introducerii datelor.
            Prelucrarea acestor date se realizează în conformitate cu Politica
            de confidențialitate precum și în concordanță cu politica de
            prelucrare a datelor de către operatorul de plăți, vânzătorul
            nefiind responsabil pentru prelucrarea/stocarea/transmiterea acestor
            tipuri de date.
          </p>
          <p>
            In anumite cazuri, pentru mentinerea securitatii Tranzactiilor, la
            inregistrarea Comenzii, Cumparatorului i se va solicita sa
            autorizeze plata prin reintroducerea parolei aferente Contului sau
            utilizarea amprentei digitale in cazul terminalelor mobile care au
            aceasta facilitate.
          </p>
          <p>
            Pentru motive de securitate a Tranzactiilor Utilizatorul/
            Cumparatorul este sfatuit sa nu ramana logat pe Site si sa nu seteze
            optiunea de logare automata pe dispozitivele mobile. Divulgarea
            parolei de acces in cont nu este permisa si se recomanda folosirea
            unei parole cu caracter puternic de securitate (de ex.: sa contina
            cel putin opt caractere, incluzand litere mari, litere mici, cifre
            si caractere speciale).
          </p>

          <h5>
            <strong>9. LIVRAREA BUNURILOR ȘI GARANȚIILE</strong>
          </h5>
          <p>
            Conditiile de livrare a Bunurilor vandute de catre ROMSTOCK.RO
            asigura si garantia bunurilor, acolo unde este cazul. Pot exista
            situații în care garanția să fie asigurată în mod direct de către
            Producătorul/distribuitorul produselor comercializate de
            ROMSTOCK.RO, caz în care cumpărătorul se va adresa direct către
            aceștia. Vânzătorul nu este responsabil pentru respectarea
            termenelor de reparații sau de calitatea reparațiilor în cazul în
            care garanția este asigurată de producător/distribuitor.
          </p>
          <p>
            Vanzatorul va asigura ambalarea corespunzatoare a Bunurilor si va
            asigura transmiterea documentelor insotitoare.
          </p>
          <p>
            Vanzatorul va efectua livrarea Bunurilor pe teritoriul Romaniei si
            al uniunii europene, costurile acestei operațiuni îi revin
            cumpărătorului, fiind evidențiate distinct față de costul
            produselor/serviciilor comandate.
          </p>

          <h5>
            <strong>
              10. ELIMINAREA DEȘEURILOR DE ECHIPAMENTE ELECTRICE ȘI ELECTRONICE
            </strong>
          </h5>
          <p>
            În conformitate cu prevederile OUG 195/2005 privind protecția
            mediului, cumpărătorul are obligatia de a nu elimina deseurile de
            echipamente electrice si electronice (DEEE) ca deseuri municipale
            nesortate si de a colecta separat aceste DEEE.
          </p>
          <p>
            Colectarea acestor DEEE se va efectua prin serviciul public de
            colectare a DEEE, direct de catre vânzator si prin centre de
            colectare organizate de operatori economici autorizati pentru
            colectarea DEEE.
          </p>
          <p>
            La furnizarea unui produs nou, la solicitarea cumparatorului,
            distribuitorii sunt obligati sa preia DEEE in sistem "unul la unul",
            in mod gratuit, in aceleasi conditii precum cele de livrare a
            produsului nou, daca echipamentul este de tip echivalent si a
            indeplinit aceleasi functii ca echipamentul nou furnizat si sa
            informeze cumparatorul despre aceasta posibilitate inainte de
            achizitionarea produsului.{" "}
          </p>
          <p>
            Potrivit OUG nr.5/2015, Anexa 5, lit.f), DEEE de dimensiuni foarte
            mici sunt deseurile de echipamente electrice si electronice la care
            nicio dimensiune exterioara nu este mai mare de 25 cm.
          </p>
          <p>
            Simbolul care indica faptul ca echipamentele electrice si
            electronice fac obiectul unei colectari separate reprezinta o pubela
            cu roti barata cu o cruce insotita de marcajul negru.
          </p>

          <h5>
            <strong>11. RĂSPUNDEREA CONTRACTUALĂ</strong>
          </h5>
          <p>
            Vanzatorul nu poate fi responsabil pentru daune de orice fel pe care
            Cumparatorul sau oricare terta parte o poate suferi ca rezultat al
            indeplinirii de catre Vanzator a oricarei din obligatiile sale
            conform Comenzii si pentru daune care rezulta din utilizarea
            Bunurilor dupa livrare si in special pentru pierderea acestora.
          </p>
          <p>
            Prin crearea si utilizarea Contului, Utilizatorul/ Cumparatorul isi
            asuma raspunderea pentru mentinerea confidentialitatii datelor de
            Cont (user si parola) si pentru gestionarea accesarii Contului, si,
            in masura permisa de legislatia in vigoare, este responsabil de
            activitatea derulata prin intermediul Contului sau.
          </p>
          <p>
            Prin crearea Contului si/sau utilizarea Continutului si/sau plasarea
            Comenzilor, Clientul / Utilizatorul/ Cumparatorul accepta in mod
            expres si fara echivoc Termenele si Conditiile Site-ului in ultima
            versiune actualizata care este comunicata in cadrul Site-ului,
            existenta la data crearii Contului si/sau utilizarii continutului
            si/sau la data plasarii Comenzii.
          </p>
          <p>
            Vanzatorul isi rezervă dreptul de a actualiza și modifica periodic
            Termenele si Conditiile Site-ului pentru a reflecta orice modificări
            ale modului si conditiilor de functionare a Site-ului sau orice
            modificări ale cerințelor legale. Documentul este opozabili
            Clientilor / Utilizatorilor/ Cumparatorilor de la momentul afisarii
            in Site. În cazul oricărei astfel de modificări, vom afișa pe Site
            versiunea modificată a Documentului, motiv pentru care va rugam sa
            verificati periodic continutul acestui Document.
          </p>
          <p>
            Vânzătorul va putea modifica/completa/șterge orice informatie
            regăsită pe site sau cuprinsul acestuia, fără notificarea prealabilă
            a cumpărătorului/utilizatorului.
          </p>

          <h5>
            <strong>12. FORȚA MAJORĂ</strong>
          </h5>
          <p>
            Nici una dintre Părţi nu răspunde de neexecutarea la termen sau/şi
            de executarea în mod necorespunzător - total sau parţial - a
            oricărei obligaţii care îi revine în baza prezentului contract, dacă
            neexecutarea sau executarea necorespunzătoare a obligaţiei
            respective a fost cauzată de forţa majoră.
          </p>
          <p>
            Forța majora este orice eveniment extern vointei partilor, aparut
            dupa incheierea Contractului, imprevizibil, absolut invincibil si
            inevitabil, precum război, epidemie, pandemie, evenimente meteo,
            stare de alertă/stare de urgență națională declarată de către
            instituțiile abilitate.
          </p>
          <p>
            Partea care invocă forţa majoră este obligată să notifice celeilalte
            Părţi, în termen de 3 zile, producerea evenimentului şi să ia toate
            măsurile posibile în vederea limitării consecinţelor lui. Partea
            care invoca forta majora va comunica, în termen de cel mult 5 zile
            de la data survenirii cazului de forta majora, documente
            doveditoare, emise de camera de comert si industrie teritoriala sau
            de o institutie publica cu atribuțuni in domeniul afectat de forta
            majora.
          </p>
          <p>
            Dacă în termen de 30 de zile de la producere, evenimentul respectiv
            nu încetează, oricare Parte are dreptul să notifice celeilate Parti
            încetarea prezentului contract, fără ca vreuna dintre Parti sa fie
            indreptatita să pretindă repararea prejudiciului cauzat de
            survenirea cazului de forță majoră.
          </p>

          <h5>
            <strong>13. LITIGII</strong>
          </h5>
          <p>
            Interpretarea clauzelor contractuale, drepturile și obligațiile
            părților precum și efectele juridice produse de prezentul contract
            vor fi guvernate de legea română.
          </p>
          <p>
            În cazul unor neînțelegeri legate de executarea prezentului
            contract, părților vor urmări soluționarea amiabilă a diferendului.
            Dacă părțile nu ajung la o rezolvare amiabilă, soluționarea oricărui
            litigiu izvorât din executarea sau interpretarea prezentului
            contract va reveni instanțelor judecătorești competente din
            circumscripția Municipiului Oradea.
          </p>

          <h5>
            <strong>14. DISPOZIȚII FINALE</strong>
          </h5>
          <p>
            Prezentul document intitulat Termeni și Condiții Generale se
            interpretează împreună cu Politica de confidențialitate privind
            prelucrarea datelor cu caracter personal.
          </p>
          <p>
            Cumpărătorul se va putea adresa Autorității Naționale pentru
            Protecția Consumatorilor în conformitate cu prevederile{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default TermeniSiConditii;
