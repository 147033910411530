import React from "react";
import { Col, Image, Row, Spinner } from "react-bootstrap";
import {
  addToCart,
  addToCartLogged,
  addToWishlist,
  deleteFromWishlist,
  updateCartQuantityRequest,
} from "../../redux/cart/cart.actions";
import { connect } from "react-redux";
import "./ProductCard.scss";
import cart from "../../images/add_to_cart_icon.svg";
import cartMobile from "../../images/add_to_cart_icon.svg";
import heart from "../../images/add_to_wishlist_icon.svg";
import heartMobile from "../../images/add_to_wishlist_icon.svg";
import { addNotification } from "../../redux/product/product.actions";
import Img from "react-cool-img";
import { pixelAddToCartEvent } from '../../utilities/marketing-events'

const ProductCard = ({
  addToCart,
  labelType,
  product,
  currentPrice,
  oldPrice,
  oldPriceSup,
  priceSup,
  price,
  type,
  code,
  oldType,
  id,
  text,
  weight,
  isInWishlist,
  numberOfStars1,
  numberOfStars2,
  numberOfStars3,
  numberOfStars4,
  numberOfStars5,
  addNotification,
  addToWishlist,
  deleteFromWishlist,
  reviewStars,
  addToCartLogged,
  user,
  cartProducts,
  updateCartRequest,
  isCartLoading,
  discount,
  commercialGuarantee,
  legalGuarantee,
  brand,
}) => {
  const handleBuy = () => {
    if (labelType === "OUT_OF_STOCK" || labelType === "indisponibil")
      return addNotification({ text: "nu este in stoc!", boldText: text });

    const productObj = {
      stockStatus: labelType,
      reviewStars,
      image: product,
      name: text,
      oldPrice,
      price,
      oldPriceSup,
      priceSup,
      location: "cartProducts",
      id,
      code,
      numberOfStars1,
      numberOfStars2,
      numberOfStars3,
      numberOfStars4,
      numberOfStars5,
      commercialGuarantee,
      legalGuarantee,
      weight
    };

    let ok = false;
    if (user) {
      cartProducts.forEach((cartProduct) => {
        if (
          cartProduct.code === productObj.code ||
          productObj.code === cartProduct.productCode
        ) {
          updateCartRequest({
            product: { ...productObj },
            quantity: cartProduct.quantity + 1,
          });
          ok = true;
        }
      });
      if (!ok) addToCartLogged(productObj);
    } else {
      addToCart(productObj);
      addNotification({ text: " a fost adaugat in cos!", boldText: text });
    }
    pixelAddToCartEvent(code, currentPrice);
  };

  const getDiscount = () => {
    if (discount < 1 && discount > 0) {
      return "-" + (discount * 100).toFixed(0) + "%";
    } else if (discount > 1) {
      return "-" + discount + " ";
    }
  };

  return (
    <div className="main-div-product-card">
      <Row className="first-row">
        {discount === null || discount === 0 ? (
          <></>
        ) : (
          <>
            {discount < 1 && discount > 0 ? (
              <>
                <div id="triangle-topleft" />
                <h6 className="label-discount">{getDiscount()}</h6>
              </>
            ) : (
              <>
                <div id="triangle-topleft" />
                <h6 className="label-discount-2">
                  {getDiscount()}
                  <span>RON</span>
                </h6>
              </>
            )}
          </>
        )}
        <Col
          className={
            discount === null || discount === 0
              ? "null-discount"
              : discount < 1 && discount > 0
              ? "first-col"
              : "first-col-2"
          }
        >
          <label
            itemProp="availability"
            className={
              labelType === "indisponibil"
                ? "out-of-stock"
                : labelType === "stoc redus"
                ? "reduced-stock"
                : "availability-card"
            }
          >
            {labelType}
          </label>
        </Col>
        <Col
          md={{ span: 1, offset: 1 }}
          xs={{ span: 1, offset: 1 }}
          className="heart-row"
        >
          {!isInWishlist ? (
            <>
              <Image
                id="heart-web"
                src={heart}
                alt="adauga-la-favorite-icon"
                onClick={(e) => {
                  if (user) {
                    addToWishlist({
                      product: {
                        stockStatus: labelType,
                        reviewStars,
                        image: product,
                        name: text,
                        oldPrice,
                        price,
                        oldPriceSup,
                        priceSup,
                        id,
                        code,
                        numberOfStars1,
                        numberOfStars2,
                        numberOfStars3,
                        numberOfStars4,
                        numberOfStars5,
                      },
                    });
                    addNotification({
                      text: "a fost adăugat în Wishlist!",
                      boldText: text,
                    });
                  } else {
                    addNotification({
                      text: "Pentru a putea adăuga produse în Wishlist, te rugăm să te autentifici!",
                    });
                  }
                  e.preventDefault();
                }}
              />
              <Image
                id="heart-mobile"
                src={heartMobile}
                alt="adauga-la-favorite-mobile-icon"
                onClick={(e) => {
                  user
                    ? addToWishlist({
                        product: {
                          stockStatus: labelType,
                          reviewStars,
                          image: product,
                          name: text,
                          oldPrice,
                          price,
                          oldPriceSup,
                          priceSup,
                          id,
                          code,
                          numberOfStars1,
                          numberOfStars2,
                          numberOfStars3,
                          numberOfStars4,
                          numberOfStars5,
                        },
                      })
                    : addNotification({
                        text: "Pentru a putea adăuga produse în Wishlist, te rugăm să te autentifici!",
                      });

                  e.preventDefault();
                }}
              />
            </>
          ) : (
            <i
              class="filled-heart fas fa-heart"
              onClick={(e) => {
                e.stopPropagation();
                deleteFromWishlist({
                  product: {
                    stockStatus: labelType,
                    image: product,
                    name: text,
                    oldPrice,
                    price,
                    oldPriceSup,
                    priceSup,
                    id,
                    code,
                  },
                });
                e.preventDefault();
              }}
            />
          )}
        </Col>
      </Row>
      <Row className="center-style img-row-card">
        <span style={{ display: "none" }} itemProp="image">
          {product}
        </span>
        <Img
          className="image-style"
          src={product}
          alt={text + "-icon"}
          cache={true}
          decode={true}
          lazy={false}
        />
      </Row>
      <Row style={{ marginTop: "4%" }} className="price-cart-row">
        <Col md={8} xs={8} style={{ paddingLeft: "10%" }}>
          {(oldPrice !== price ||
            (oldPrice === price && oldPriceSup !== priceSup)) && (
            <Row style={{ justifyContent: "flex-start" }}>
              <p className="old-price">
                {oldPrice}
                <sup>{oldPriceSup}</sup>
                <span>{oldType}</span>
              </p>
            </Row>
          )}
          <Row style={{ justifyContent: "flex-start" }}>
            <p style={{ display: "none" }} itemProp="price">
              {Number(price) + Number(priceSup) / Math.pow(10, priceSup.length)}
            </p>
            <p className="new-price">
              {price}
              <sup>{priceSup}</sup>
              <span itemProp="priceCurrency">{type}</span>
            </p>
          </Row>
        </Col>
        <Col md={2} xs={2} className="cart-col">
          {isCartLoading === code ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <>
              <Image
                id="cart-web"
                src={cart}
                alt="adaugare-in-cos-icon"
                onClick={(e) => {
                  handleBuy();
                  e.preventDefault();
                }}
              />
              <Image
                id="cart-mobile"
                src={cartMobile}
                alt="adaugare-in-cos-mobile-icon"
                onClick={(e) => {
                  handleBuy();
                  e.preventDefault();
                }}
              />
            </>
          )}
        </Col>
      </Row>
      <div className="text_row">
        <label
          style={{ marginBottom: "0%" }}
          className="text-clamp"
          itemProp="name"
        >
          {brand && brand !== "-" ? brand + " -" : null} {text}
        </label>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
  addToCartLogged: (payload) => dispatch(addToCartLogged(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
  addToWishlist: (payload) => dispatch(addToWishlist(payload)),
  deleteFromWishlist: (payload) => dispatch(deleteFromWishlist(payload)),
  updateCartRequest: (payload) => dispatch(updateCartQuantityRequest(payload)),
});

const mapStateToProps = (state) => ({
  user: state.registerAccount.user,
  cartProducts: state.cart.cartProducts,
  isCartLoading: state.cart.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
