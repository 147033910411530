import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Col, Row } from "react-bootstrap";
import "./ForgotPasswordPage.scss";
import { sendEmailForgotPassRequest } from "../../redux/register/register.actions";

const ForgotPasswordPage = ({ sendEmailForgotPassRequest, error }) => {
  const { register, errors, handleSubmit, reset } = useForm({});
  const onSubmitForm = (data) => {
    sendEmailForgotPassRequest({ email: data.email });
    reset();
  };

  return (
    <>
      <div className="forgot-pass-container">
        <form onSubmit={(e) => e.preventDefault()}>
          <Row style={{ justifyContent: "center" }}>
            <Col md={5}>
              <h3>Ați uitat parola?</h3>
              <h5 style={{ marginBottom: "1.3em" }}>
                Introduceți adresa de email pentru resetarea parolei
              </h5>
              <div style={{ paddingLeft: "0" }} className="form-group">
                <label htmlFor="email">Email</label>
                <div style={{ position: "relative" }}>
                  <input
                    type="email"
                    name="email"
                    className="form-control pass-input"
                    ref={register({
                      required: "Acest câmp este obligatoriu",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Emailul introdus nu este valid",
                      },
                    })}
                  />
                  <span
                    style={{ position: "absolute", left: "93%", top: "6px" }}
                  >
                    <i className="far fa-envelope"></i>
                  </span>
                </div>

                {errors.email && (
                  <p className="form-group warning-message">
                    {errors.email.message}
                  </p>
                )}
                {error ? (
                  <p className="form-group warning-message">{error}</p>
                ) : (
                  <></>
                )}
              </div>

              <Button type="submit" onClick={handleSubmit(onSubmitForm)}>
                Trimite
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.registerAccount.error,
});

const mapDispatchToProps = (dispatch) => ({
  sendEmailForgotPassRequest: (payload) =>
    dispatch(sendEmailForgotPassRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
