import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import StompClient from "react-stomp-client"
import { TabList, Tab, Tabs, TabPanel } from "react-web-tabs"
import { addAdminOrder, resetOrderDetails, verifyRole } from "../../redux/admin/admin.actions"
import { logout } from "../../redux/register/register.actions"
import "../MyAccountPage/MyAccountPage.scss"
import { AdminOrders } from "./AdminOrders"
import { Spinner } from "react-bootstrap"
import AdminProducts from "./AdminProducts/AdminProducts"
import AdminPriceRange from "./AdminPriceRange/AdminPriceRange"

const AdminPage = ({ resetOrderDetails, history, verifyRole, location, showPage, logout }) => {
    useEffect(() => {
        if (!localStorage.getItem('token'))
            return history.replace("/")
        else
            verifyRole()
    }, [])

    const [tab, setTab] = useState('vertical-tab-one')

    useEffect(() => {
        if (location.pathname === '/admin') {
            setTab("vertical-tab-one")
        } else if (location.pathname.includes('products')) {
            setTab("vertical-tab-two")
        } else if (location.pathname.includes('price')) {
            setTab("vertical-tab-three")
        }
    }, [location.pathname, tab])

    if (showPage == true)
        return (
            <div className="my-account-page-container">
                <div className="main-content">
                    <Tabs className="menu-content" defaultTab={tab} vertical>
                        <TabList>
                            <Link to="/admin" onClick={() => { setTab('vertical-tab-one'); resetOrderDetails() }}>
                                <Tab tabFor="vertical-tab-one" >Panou comenzi</Tab>
                            </Link>
                            <Link to="/admin/products" onClick={() => setTab('vertical-tab-two')}>
                                <Tab tabFor="vertical-tab-two">Panou produse</Tab>
                            </Link>
                            <Link to="/admin/price-range" onClick={() => setTab('vertical-tab-three')}>
                                <Tab tabFor="vertical-tab-three">Grila de preturi</Tab>
                            </Link>
                            <Tab style={{ color: "#E04C35" }} tabFor="vertical-tab-four" onClick={logout}>Deloghează-te</Tab>
                        </TabList>

                        {
                            tab === 'vertical-tab-one' &&
                            <TabPanel tabId="vertical-tab-one">
                                <AdminOrders location={location}></AdminOrders>
                            </TabPanel>
                        }
                        {
                            tab === 'vertical-tab-two' &&
                            <TabPanel tabId="vertical-tab-two">
                                <AdminProducts></AdminProducts>
                            </TabPanel>
                        }
                        {
                            tab === 'vertical-tab-three' &&
                            <TabPanel tabId="vertical-tab-three">
                                <AdminPriceRange></AdminPriceRange>
                            </TabPanel>
                        }
                    </Tabs>
                    {/* <StompClient
                        endpoint={`wss://api.romstock.ro/api/websocket?access_token=${localStorage.getItem("token")}`}
                        headers={customHeaders}
                        topic="topic/events"
                        onMessage={handleWS}
                    >
                        <div></div>
                    </StompClient> */}
                </div >
            </div>
        )
    else if (showPage == false)
        return <Redirect to="/" />
    else
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
}

const mapStateToProps = state => ({
    showPage: state.admin.showPage
})

const mapDispatchToProps = dispatch => ({
    resetOrderDetails: () => dispatch(resetOrderDetails()),
    verifyRole: () => dispatch(verifyRole()),
    logout: () => dispatch(logout())
})
export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)