import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Confirmation.scss";
import { resendEmailRequest } from "../../redux/register/register.actions";

const Timer = ({ account, resendEmailRequest }) => {
  const [timeLeft, setTimeLeft] = useState(30);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, account]);

  return (
    <div style={{ marginTop: "3%" }}>
      {timeLeft === 0 ? (
        account && (
          <button
            className="button"
            onClick={() => {
              resendEmailRequest({ email: account.email });
            }}
          >
            Retrimite email
          </button>
        )
      ) : (
        <h2 style={{ color: "#E04C35" }}>
          Retrimitere email: 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
        </h2>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.registerAccount.account,
});

const mapDispatchToProps = (dispatch) => ({
  resendEmailRequest: (payload) => dispatch(resendEmailRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
