import React from "react";
import { Col, Row } from "react-bootstrap";
import styles from "../../general.module.scss";
import "./Contact.scss";

const Contact = () => {
  return (
    <>
      <div className={`${styles.container} contact-container`}>
        <h1 className="title">Contact</h1>
        <Row>
          <Col style={{ marginBottom: "1.5rem" }} md={6}>
            <label className="label-title-style">Program de lucru</label>
            <p className="label-content-style">Luni - Vineri: 9:00 - 16:30</p>
          </Col>
          <Col style={{ marginBottom: "1.5rem" }} md={{ span: 5, offset: 1 }}>
            <label className="label-title-style">Departament vânzări</label>
            <p className="label-content-style">
              Email:{" "}
              <a
                style={{ textDecoration: "none" }}
                href="mailto:vanzari@romstock.ro"
              >
                vanzari@romstock.ro
              </a>
            </p>
            <p className="label-content-style">
              Telefon:{" "}
              <a
                style={{ textDecoration: "none", color: "#737475" }}
                href="tel:0758 258 888"
              >
                (0758) 258 888
              </a>
            </p>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1.5rem" }}>
          <Col style={{ marginBottom: "1.5rem" }} md={6}>
            <label className="label-title-style">Date firmă</label>
            <p
              style={{ marginBottom: "0.5rem" }}
              className="label-content-style"
            >
              S.C. UNICIMOB CONSTRUCT S.R.L CUI: 39704532 Nr. Înmatriculare
              J5/1878/02.08.2018
            </p>
            <p className="label-content-style">
              Sediu social: Municipiu Oradea, Strada SOVATA, Nr. 40, bloc Q4 ,
              Etaj 4, Ap. 9, județ Bihor
            </p>
          </Col>
          <Col style={{ marginBottom: "1.5rem" }} md={{ span: 5, offset: 1 }}>
            <label className="label-title-style">Reclamații</label>
            <p className="label-content-style">
              Email:{" "}
              <a
                style={{ textDecoration: "none" }}
                href="mailto:reclamatii@romstock.ro"
              >
                reclamatii@romstock.ro
              </a>
            </p>
            <p className="label-content-style">
              Telefon:{" "}
              <a
                style={{ textDecoration: "none", color: "#737475" }}
                href="tel:0758 258 888"
              >
                (0758) 258 888
              </a>
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Contact;
