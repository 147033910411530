import React, { useState, useEffect } from "react";
import "./MyOrders.scss";
import { Row, Col, Button, Image, Spinner } from "react-bootstrap";
import box from "../../../images/open-box.png";
import { Link } from "react-router-dom";
import OrdersPagination from "../OrdersPagination/OrdersPagination";
import {
  replaceNameSingleProduct,
  encodeProductCode,
} from "../../../utilities/helpers";
import OrderPaginationsAdmin from "../OrdersPagination/OrderPaginationsAdmin";

const MyOrders = ({
  getOrdersRequest,
  products,
  getOrdersDetailsRequest,
  ordersDetails,
  currentPage,
  location,
  isAdmin,
  numberOfPages,
  isLoading,
  setLinkString,
}) => {
  useEffect(() => {
    getOrdersRequest({ currentPage });
  }, [currentPage]);

  const [visible, setVisible] = useState(true);

  const getData = (index) => {
    setVisible(false);
    if (index !== null) {
      getOrdersDetailsRequest({ index });
    }
  };

  useEffect(() => {
    if (location.pathname.includes("details")) {
      setVisible(false);
      let orderNumber = location.pathname.split("/")[4];
      getData(orderNumber);
      if (!isAdmin) setLinkString(location.pathname.split(/\//));
    } else {
      setVisible(true);
      if (!isAdmin) setLinkString(location.pathname.split(/\//));
    }
  }, [location.pathname, visible]);

  if (isLoading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (products)
    return (
      <div className="my-orders-container">
        {visible ? (
          <div className="my-orders-tab">
            <Row style={{ alignItems: "center" }}>
              <Col>
                <h1 className="comenzi-label">Comenzi recente</h1>
              </Col>
              <Col md={2} xs={3} style={{ marginRight: "5%" }}>
                <Image
                  className="box-img"
                  src={box}
                  alt="comenzile-mele-recente-icon"
                />
              </Col>
            </Row>
            {products.length === 0 ? (
              <>
                <div
                  className="col"
                  style={{ paddingBottom: "3%", fontStyle: "italic" }}
                >
                  Nu există comenzi recente.
                </div>
              </>
            ) : (
              <Row style={{ overflowX: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Număr comandă</th>
                      <th>Data</th>
                      <th style={{ textAlign: "center" }}>Număr produse</th>
                      <th style={{ textAlign: "center" }}>Total</th>
                      {isAdmin && (
                        <th style={{ textAlign: "center" }}>Plata</th>
                      )}
                      <th style={{ textAlign: "center" }}>Livrare</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((order, index) => {
                      return (
                        <tr key={order.orderNumber} index={index}>
                          <td>{order.orderNumber}</td>
                          <td>{order.date}</td>
                          <td style={{ textAlign: "center" }}>
                            {order.numberOfProducts}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {order.total + " RON"}
                          </td>
                          {isAdmin && (
                            <td style={{ textAlign: "center" }}>
                              {order.paymentType}
                            </td>
                          )}
                          {order.shippingMethod === "POSTA_ROMANA" ? (
                            <td style={{ textAlign: "center" }}>
                              Poșta Română
                            </td>
                          ) : (
                            <td style={{ textAlign: "center" }}>FAN Courier</td>
                          )}
                          <td>
                            <Link
                              to={
                                isAdmin
                                  ? "/admin/orders/details/" + order.orderNumber
                                  : "/my-account/orders/details/" +
                                    order.orderNumber
                              }
                            >
                              <Button
                                className="orders-button"
                                onClick={() => getData(order.orderNumber)}
                              >
                                Detalii
                              </Button>
                            </Link>
                            {isAdmin && (
                              <Button
                                style={{ marginLeft: "1em;" }}
                                variant="danger"
                              >
                                Exportă comanda
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="products-pagination">
                  {isAdmin ? (
                    <OrderPaginationsAdmin
                      currentPage={currentPage}
                      numberOfPages={numberOfPages}
                    ></OrderPaginationsAdmin>
                  ) : (
                    <OrdersPagination
                      currentPage={currentPage}
                      numberOfPages={numberOfPages}
                    ></OrdersPagination>
                  )}
                </div>
              </Row>
            )}
          </div>
        ) : ordersDetails ? (
          <div className="order">
            <Row>
              <Col style={{ paddingTop: "2%" }}>
                <label className="comanda-label">
                  Comanda: {ordersDetails.orderNumber}{" "}
                </label>
                <ul>
                  <li>
                    <span className="name-label">Data: </span>
                    <span>{ordersDetails.date}</span>
                  </li>
                  <li>
                    <span className="name-label">Număr produse: </span>
                    <span>{ordersDetails.numberOfProducts}</span>
                  </li>
                  <li>
                    <span className="name-label">Total: </span>
                    <span>{ordersDetails.total}</span>
                    <span> RON </span>
                  </li>
                  <li>
                    <span className="name-label">Modalitate de livrare: </span>
                    {ordersDetails.shippingMethod === "POSTA_ROMANA" ? (
                      <span>Poșta Română</span>
                    ) : (
                      <span>FAN Courier</span>
                    )}
                  </li>
                  <li>
                    <span className="name-label">Taxa livrare: </span>
                    <span>{ordersDetails.shippingCost + " RON"}</span>
                  </li>
                </ul>
              </Col>
              <Col md={2} xs={3} style={{ marginRight: "5%" }}>
                <Image
                  className="box-img"
                  src={box}
                  alt="comenzile-mele-box-icon"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%" }}>
              <Col md={4} xs={6} style={{ paddingLeft: "0" }}>
                <label className="label-style" style={{ marginBottom: "3%" }}>
                  Adresă livrare
                </label>
                <p>
                  {ordersDetails.deliveryAddress === ""
                    ? "Nu există nicio adresă de livrare salvată"
                    : ordersDetails.deliveryAddress.firstName +
                      " " +
                      ordersDetails.deliveryAddress.lastName +
                      " - " +
                      ordersDetails.deliveryAddress.phone +
                      ", " +
                      ordersDetails.deliveryAddress.email +
                      ", jud. " +
                      ordersDetails.deliveryAddress.county +
                      ", loc. " +
                      ordersDetails.deliveryAddress.city +
                      ", cod poștal " +
                      ordersDetails.deliveryAddress.postalCode +
                      ", " +
                      ordersDetails.deliveryAddress.address}
                </p>
              </Col>
              <Col md={4} xs={6} style={{ paddingLeft: "0" }}>
                <label className="label-style" style={{ marginBottom: "3%" }}>
                  Adresă facturare
                </label>
                <p>
                  {ordersDetails.billingAddress === ""
                    ? "Nu există nicio adresă de facturare salvată"
                    : ordersDetails.billingAddress.firstName +
                      " " +
                      ordersDetails.billingAddress.lastName +
                      " - " +
                      ordersDetails.billingAddress.phone +
                      ", " +
                      ordersDetails.billingAddress.email +
                      ", jud. " +
                      ordersDetails.billingAddress.county +
                      ", loc. " +
                      ordersDetails.billingAddress.city +
                      ", cod poștal " +
                      ordersDetails.billingAddress.postalCode +
                      ", " +
                      ordersDetails.billingAddress.address +
                      ", CUI: " + 
                      (ordersDetails.billingAddress.cui || '-')}
                </p>
              </Col>
            </Row>
            <Row style={{ overflowX: "auto" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Produs</th>
                    <th>Cantitate</th>
                    <th>Preț</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersDetails.products.map((product, index) => {
                    return (
                      <tr index={index}>
                        <td>
                          <Row>
                            <Col md={2} xs={12}>
                              <Image
                                className="img-table"
                                src={product.imageUrl}
                                alt={product.name + "-icon"}
                              />
                            </Col>
                            <Col className="td-product">
                              <label className="product-name">
                                {product.name}
                              </label>
                              <label className="product-code">
                                Cod produs: {product.code}
                              </label>
                              <Button
                                className="details-btn"
                                href={
                                  "/" +
                                  replaceNameSingleProduct(product.name) +
                                  "/" +
                                  encodeProductCode(product.code)
                                }
                              >
                                Detalii
                              </Button>
                            </Col>
                          </Row>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <label>{product.quantity}</label>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          <label>{product.price}</label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Row>
            <Row>
              {ordersDetails.referrer && ordersDetails.referrer.length > 0 && (
                <>
                  <h5>Traseul utilizatorului</h5>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordersDetails.referrer.map((ref, index) => {
                        return (
                          <tr index={index}>
                            <td
                              style={{
                                textAlign: "left",
                                verticalAlign: "middle",
                              }}
                            >
                              <label>{ref.from}</label>
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                verticalAlign: "middle",
                                wordBreak: "break-all",
                              }}
                            >
                              <label>{ref.to}</label>
                            </td>
                            <td
                              style={{
                                textAlign: "left",
                                verticalAlign: "middle",
                              }}
                            >
                              <label>{ref.date}</label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </Row>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  return null;
};

export default MyOrders;
