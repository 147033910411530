import React from "react";
import "./notification.scss";

const Notification = ({ text, boldText, onClick }) => {
  setTimeout(() => {
    onClick();
  }, 3500);
  return (
    <div
      className="alert alert-warning alert-dismissible fade show notification"
      role="alert"
    >
      <strong>{boldText}</strong> {text}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default Notification;
