import React, { useMemo } from "react";
import { Row, Button, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { connect } from "react-redux";
import "./DetailsCard.scss";
import { ScrollTo } from "react-scroll-to";
import {
  addToWishlist,
  deleteFromWishlist,
} from "../../../redux/cart/cart.actions";
import { addNotification } from "../../../redux/product/product.actions";

const DetailsCard = ({
  product,
  clickShowDetails,
  addToWishlist,
  wishList,
  deleteFromWishlist,
  user,
  addNotification,
  isWishlistLoading,
}) => {
  const isInWishlist = useMemo(() => {
    const productName = product.name || product.productName;
    return wishList.some((listItem) => {
      if (listItem.name) return listItem.name === productName;
      else return listItem.productName === productName;
    });
  }, [wishList.length]);

  const getStars = () => {
    if (
      product.numberOfStars1 +
        product.numberOfStars2 +
        product.numberOfStars3 +
        product.numberOfStars4 +
        product.numberOfStars5 !==
      0
    ) {
      const number =
        (product.numberOfStars1 +
          product.numberOfStars2 * 2 +
          product.numberOfStars3 * 3 +
          product.numberOfStars4 * 4 +
          product.numberOfStars5 * 5) /
        (product.numberOfStars1 +
          product.numberOfStars2 +
          product.numberOfStars3 +
          product.numberOfStars4 +
          product.numberOfStars5);
      return Math.round(number * 100) / 100;
    } else return 0;
  };

  const getNumberOfReviews = () => {
    const numberReviews =
      product.numberOfStars1 +
      product.numberOfStars2 +
      product.numberOfStars3 +
      product.numberOfStars4 +
      product.numberOfStars5;
    if (numberReviews === 0 || (numberReviews > 1 && numberReviews < 20))
      return String(numberReviews + " evaluări");
    else if (numberReviews === 1) return String(numberReviews + " evaluare");
    else return String(numberReviews + " de evaluări");
  };

  const getBrand = () => {
    if (product.brand !== null) {
      return product.brand;
    } else return "";
  };

  if (product)
    return (
      <div className="details-card-container">
        <div>
          <Row className="web-evaluate">
            <td>
              <label style={{ verticalAlign: "middle" }}>
                <StarRatings
                  rating={getStars()}
                  starRatedColor="#E6B923"
                  numberOfStars={5}
                  name="rating"
                />
              </label>
            </td>
            <td style={{ paddingLeft: "5%" }}>
              <label
                className="label-style"
                style={{ verticalAlign: "middle" }}
              >
                (<span>{getStars()}</span>)
              </label>
            </td>
          </Row>
          <Row className="label-evaluari">{getNumberOfReviews()}</Row>
        </div>
        <Row className="line"></Row>
        <Row className="list-row">
          <ul className="list-style">
            <li className="mobile-li">
              <span> Cod produs: </span>
              <span itemProp="sku">{product.code}</span>
            </li>
            <li>
              <span className="name-details"> Livrare: </span>
              <span className="value-details">FAN Courier: 1-3 zile</span>
            </li>
            <li>
              <span className="name-details"> Retur: </span>
              <span className="value-details">Maxim 14 zile</span>
            </li>
            <li>
              <span className="name-details"> Brand: </span>
              <span
                style={{ color: "#12AFF2" }}
                className="value-details"
                itemProp="brand"
              >
                {getBrand()}
              </span>
            </li>
            <li>
              <span className="name-details"> Garanție: </span>
              <span className="value-details">
                <ul
                  style={{
                    display: "inline-grid",
                    fontWeight: "normal",
                    color: "#848484",
                  }}
                >
                  <li style={{ marginLeft: "11%", width: "max-content" }}>
                    {product.commercialGuarantee} luni persoane fizice
                  </li>
                  <li style={{ marginLeft: "11%", width: "max-content" }}>
                    {product.legalGuarantee} luni persoane juridice
                  </li>
                </ul>
              </span>
            </li>
            <li className="code">
              <span> Cod produs: </span>
              {product.code}
            </li>
          </ul>
        </Row>
        <Row>
          <ScrollTo>
            {({ scroll }) => (
              <Button
                className="details-btn"
                onClick={() => {
                  scroll({ y: 1000, smooth: true });
                  clickShowDetails(true);
                }}
              >
                Detalii produs
              </Button>
            )}
          </ScrollTo>
        </Row>
        <Row>
          {isWishlistLoading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : (
            <Button
              className="wishlist-btn"
              onClick={() => {
                if (!isInWishlist) {
                  if (user) {
                    addToWishlist({ product });
                    addNotification({
                      text: "a fost adăugat în Wishlist!",
                      boldText: product.name,
                    });
                  } else {
                    addNotification({
                      text: "Pentru a putea adăuga produse în Wishlist, te rugăm să te autentifici!",
                    });
                  }
                } else {
                  deleteFromWishlist({ product });
                  addNotification({
                    text: "a fost scos din Wishlist!",
                    boldText: product.name,
                  });
                }
              }}
            >
              <i className="fa fa-heart-o" aria-hidden="true"></i>
              {!isInWishlist ? "Adaugă în wishlist" : "Scoate din wishlist"}
            </Button>
          )}
        </Row>
        {/* TO DO: share product on insta/facebook*/}
        {/* <Row className="icons-row">
                    Share:
                    <Img className="logo-style" src={facebook} cache></Img>
                    <Img className="logo-style" src={instagram} cache></Img>
                </Row> */}
      </div>
    );
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  addToWishlist: (payload) => dispatch(addToWishlist(payload)),
  deleteFromWishlist: (payload) => dispatch(deleteFromWishlist(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
});

const mapStateToProps = (state) => ({
  product: state.product.product,
  wishList: state.cart.wishList,
  user: state.registerAccount.user,
  isWishlistLoading: state.cart.isLoading,
});
export default connect(mapStateToProps, mapDispatchToProps)(DetailsCard);
