import {
  GET_ADMIN_ORDERS_SUCCESS,
  GET_ADMIN_ORDERS_REQUEST,
  GET_ADMIN_ORDER_DETAILS_REQUEST,
  GET_ADMIN_ORDER_DETAILS_SUCCESS,
  RESET_ORDER_DETAILS,
  ADD_ADMIN_ORDER,
  VERIFY_ROLE_SUCCESS,
  VERIFY_ROLE_FAILURE,
  SET_NEXT_PAGE_ADMIN,
  SET_PREV_PAGE_ADMIN,
  SET_LAST_PAGE_ADMIN,
  SET_FIRST_PAGE_ADMIN,
  NEXT_PAGE_ADMIN,
  PREV_PAGE_ADMIN,
} from "./admin.constants";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  person: null,
  products: [],
  ordersDetails: null,
  currentPage: 1,
  numberOfPages: null,
  showPage: null,
};

export const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADMIN_ORDERS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
        isLoading: false,
      };
    case ADD_ADMIN_ORDER:
      return {
        ...state,
        products: [action.payload, ...state.products],
      };
    case GET_ADMIN_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADMIN_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        ordersDetails: action.payload.ordersDetails,
        isLoading: false,
      };
    case RESET_ORDER_DETAILS:
      return {
        ...state,
        ordersDetails: null,
      };
    case NEXT_PAGE_ADMIN:
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case PREV_PAGE_ADMIN:
      return {
        ...state,
        currentPage: state.currentPage - 1,
      };
    case SET_NEXT_PAGE_ADMIN:
      return {
        ...state,
        currentPage: state.currentPage + 2,
      };
    case SET_PREV_PAGE_ADMIN:
      return {
        ...state,
        currentPage: state.currentPage - 2,
      };
    case SET_FIRST_PAGE_ADMIN:
      return {
        ...state,
        currentPage: 1,
      };
    case SET_LAST_PAGE_ADMIN:
      return {
        ...state,
        currentPage: state.numberOfPages,
      };
    case VERIFY_ROLE_SUCCESS:
      return {
        ...state,
        showPage: true,
      };
    case VERIFY_ROLE_FAILURE:
      return {
        ...state,
        showPage: false,
      };
    default:
      return state;
  }
};
