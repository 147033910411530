import React, { useState } from "react";
import { FormControl, Image } from "react-bootstrap";
import "./Header.scss";
import { Link } from "react-router-dom";
import person from "../../images/Header/account_icon.svg";
import heart from "../../images/Header/wishlist_icon.svg";
import cart from "../../images/Header/cart_icon.svg";
import searchIcon from "../../images/Header/search_icon.svg";
import { connect } from "react-redux";
import { setShowCart } from "../../redux/cart/cart.actions";

const HeaderDesktop = ({ cartLength, user, showCart, setShowCart }) => {
  const [searchInput, setSearchInput] = useState("");

  return (
    <div className="header_desktop">
      <div className="search_input">
        <FormControl
          type="text"
          value={searchInput}
          id="search-input-form"
          placeholder="Cauta produse, Branduri & Categorii"
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              window.location.assign(`/products/all?search=${e.target.value}`);
            }
          }}
        />
        <Link
          to={{
            pathname: "/products/all",
            search: "?search=" + searchInput,
          }}
          id="products-search"
        >
          <div type="submit">
            <img src={searchIcon} alt="search-icon" />
          </div>
        </Link>
      </div>

      <div className="header-icons-group">
        <Link to="/wishlist" className="icons-group">
          <div className="icons-group">
            <Image src={heart} alt="heart logo" className="header_logo" />
          </div>
        </Link>

        <Link to={user ? "/my-account" : "/login"} className="icons-group">
          <div className="icons-group">
            <Image src={person} alt="person logo" className="header_logo" />
            {/*<p>{user ? user.split(/[\s-]+/)[0] : "Cont"}</p>*/}
          </div>
        </Link>

        <div className="icons-group">
          <div style={{ position: "relative" }}>
            <Image
              src={cart}
              alt="cart logo"
              className="header_logo cart"
              onClick={() => {
                setShowCart(!showCart);
              }}
            />
            <div className="div-price-style">
              <div className="quantity-style">
                <p>{cartLength}</p>
              </div>
            </div>
            {showCart && <div className="small-pointer" />}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartLength: state.cart.cartLength,
  user: state.registerAccount.user,
  showCart: state.cart.showCart,
});

const mapDispatchToProps = (dispatch) => ({
  setShowCart: (payload) => dispatch(setShowCart(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HeaderDesktop);
