import React from "react";
import { Image } from "react-bootstrap";
import "./FooterIcons.scss";
import truck from "../../images/truck.png";
import clock from "../../images/clock.png";
import return_icon from "../../images/return.png";

const FooterIcons = () => {
  return (
    <div className="footer-icons-container">
      <div style={{ textAlign: "center" }}>
        <Image src={clock} className="style-img" alt="clock" />
        <label>Transport rapid</label>
      </div>
      <div style={{ textAlign: "center" }}>
        <Image
          id="style-img-truck"
          src={truck}
          className="style-img-truck"
          alt="truck"
        />
        <label>Livrare gratuită la comenzi de peste 400 RON</label>
      </div>
      <div style={{ textAlign: "center" }}>
        <Image src={return_icon} className="style-img" alt="return_icon" />
        <label>Returnare în 14 zile</label>
      </div>
    </div>
  );
};

export default React.memo(FooterIcons);
