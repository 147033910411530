import React from "react"
import DatetimeRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import { Button } from 'react-bootstrap'
import "./DateRangePicker.scss"

const DateRange = ({ label, onNewRange, initialSettings }) => {
    const handleApply = (event, picker) => {
        let start = picker.startDate.format('YYYY-MM-DD');
        let end = picker.endDate.format('YYYY-MM-DD');
        let labelUpdated = start + ' -> ' + end;
        onNewRange(labelUpdated);
    }

    return (
        <div className="form-group">
            <DatetimeRangePicker onApply={handleApply} initialSettings={initialSettings} >
                <div className="input-group">
                    <input type="text" className="form-control" value={label} />
                    <span className="input-group-btn">
                        <Button className="default date-range-toggle btn-range-picker">
                            <i className="fa fa-calendar" />
                        </Button>
                    </span>
                </div>
            </DatetimeRangePicker>
        </div>
    );

}
export default DateRange