import {
  SAVE_PRODUCTS_REQUEST,
  GET_PRODUCTS_RANDOM_REQUEST,
} from "./home.constants";

export const saveProductsRequest = (payload) => {
  return {
    type: SAVE_PRODUCTS_REQUEST,
    payload,
  };
};

export const randomProductsRequest = (payload) => {
  return {
    type: GET_PRODUCTS_RANDOM_REQUEST,
    payload,
  };
};
