import {
  SAVE_PRODUCTS_REQUEST,
  SAVE_PRODUCTS_SUCCESS,
  GET_PRODUCTS_RANDOM_REQUEST,
  GET_PRODUCTS_RANDOM_SUCCESS,
} from "./home.constants";
import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";

export function* saveProducts({ payload }) {
  const { toolsCodes, electronicsCodes, weeklyPromotionsCodes } = payload;
  const response = yield call(apiService.saveProducts, {
    toolsCodes,
    electronicsCodes,
    weeklyPromotionsCodes,
  });
  const products = response.data;
  yield put({
    type: SAVE_PRODUCTS_SUCCESS,
    payload: { payload, products },
  });
}

export function* getProductsRandom() {
  const response = yield call(apiService.getRandomProducts);
  const products = response.data;

  yield put({
    type: GET_PRODUCTS_RANDOM_SUCCESS,
    payload: { products },
  });
}

export function* saveProductsWatcher() {
  yield takeLatest(SAVE_PRODUCTS_REQUEST, saveProducts);
}

export function* getProductsRandomWatcher() {
  yield takeLatest(GET_PRODUCTS_RANDOM_REQUEST, getProductsRandom);
}

export function* saveProductsSaga() {
  yield all([call(saveProductsWatcher), call(getProductsRandomWatcher)]);
}
