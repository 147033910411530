export const ADD_BRAND_ACTIVE_FILTER = "ADD_BRAND_ACTIVE_FILTER";
export const REMOVE_BRAND_ACTIVE_FILTER = "REMOVE_BRAND_ACTIVE_FILTER";
export const SET_PRICE_ACTIVE_FILTER = "SET_PRICE_ACTIVE_FILTER";
export const SET_STOCK_ACTIVE_FILTER = "SET_STOCK_ACTIVE_FILTER";

export const SEARCH_GUEST_REQUEST = "SEARCH_GUEST_REQUEST";
export const SEARCH_GUEST_SUCCESS = "SEARCH_GUEST_SUCCESS";
export const SEARCH_GUEST_FAILURE = "SEARCH_GUEST_FAILURE";

export const NEXT_PAGE_FILTERS = "NEXT_PAGE_FILTERS";
export const PREV_PAGE_FILTERS = "PREV_PAGE_FILTERS";

export const SET_ORDER = "SET_ORDER";

export const SET_NEXT_PAGE_FILTERS = "SET_NEXT_PAGE_FILTERS";
export const SET_PREV_PAGE_FILTERS = "SET_PREV_PAGE_FILTERS";
export const SET_FIRST_PAGE_FILTERS = "SET_FIRST_PAGE_FILTERS";
export const SET_LAST_PAGE_FILTERS = "SET_LAST_PAGE_FILTERS";

export const SET_CHANGED_FILTERS = "SET_CHANGED_FILTERS";

export const PRICE_TYPE = "PRICE";
export const BRAND_TYPE = "BRAND";
export const CATEGORY_TYPE = "CATEGORY";
export const NAME_TYPE = "NAME";
export const STOCK_TYPE = "STOCK";
