import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import { GET_PRODUCT_REQUEST, GET_PRODUCT_SUCCESS } from "./product.constants";

export function* getProduct({ payload }) {
  const { data: product } = yield call(apiService.getProduct, payload);
  yield put({
    type: GET_PRODUCT_SUCCESS,
    payload: { product },
  });
}

export function* getProductWatcher() {
  yield takeLatest(GET_PRODUCT_REQUEST, getProduct);
}

export function* productSaga() {
  yield all([call(getProductWatcher)]);
}
