import {
  ADD_TO_CART,
  ADD_TO_CART_USER_REQUEST,
  ADD_TO_CART_USER_SUCCESS,
  ADD_TO_WISHLIST_REQUEST,
  ADD_TO_WISHLIST_SOLO_CART_SUCCESS,
  DELETE_FROM_WISHLIST_CART_SUCCESS,
  DELETE_FROM_WISHLIST_REQUEST,
  DELETE_FROM_WISHLIST_SUCCESS,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
  REMOVE_FROM_CART,
  SET_SHOW_CART,
  UPDATE_CART_PRICE,
  UPDATE_CART_QUANTITY_REQUEST,
  UPDATE_CART_QUANTITY_SUCCESS,
} from "./cart.constants";

const INITIAL_STATE = {
  cartProducts: [],
  wishList: [],
  cartLength: 0,
  isLoading: false,
  cartLoading: false,
  showCart: false,
};
export const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SHOW_CART:
      return {
        ...state,
        showCart: action.payload,
      };
    case ADD_TO_WISHLIST_REQUEST:
    case DELETE_FROM_WISHLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CART_REQUEST:
    case GET_WISHLIST_REQUEST:
      return {
        ...state,
        cartLoading: true,
      };
    case ADD_TO_CART_USER_REQUEST:
      return {
        ...state,
        isLoading: action.payload.code || action.payload.productCode,
      };
    case UPDATE_CART_QUANTITY_REQUEST:
      return {
        ...state,
        isLoading:
          action.payload.product.code || action.payload.product.productCode,
      };
    case UPDATE_CART_QUANTITY_SUCCESS:
    case DELETE_FROM_WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_TO_CART_USER_SUCCESS:
    case ADD_TO_CART:
      const foundProduct = state.cartProducts.find((product) => {
        if (product.name) return action.payload.name === product.name;
        else return action.payload.name === product.productName;
      });

      const index = state.cartProducts.indexOf(foundProduct);
      const newCartProducts = [...state.cartProducts];
      if (foundProduct)
        newCartProducts[index] = {
          ...foundProduct,
          id: Math.random() * 1000,
          price:
            foundProduct.price || foundProduct.price === 0
              ? foundProduct.price
              : foundProduct.currentPrice,
          oldPrice: Number(foundProduct.oldPrice),
          quantity: action.payload.quantityToAdd
            ? foundProduct.quantity + action.payload.quantityToAdd
            : foundProduct.quantity + 1,
        };
      else
        newCartProducts.push({
          ...action.payload,
          price: Number(action.payload.price),
          oldPrice: Number(action.payload.oldPrice),
          quantity: action.payload.quantityToAdd || 1,
        });

      return {
        ...state,
        cartLength: action.payload.quantityToAdd
          ? action.payload.quantityToAdd + state.cartLength
          : state.cartLength + 1,
        cartProducts: [...newCartProducts],
        isLoading: false,
      };
    case REMOVE_FROM_CART:
      let foundProductToRemove;
      const payloadName = action.payload.name || action.payload.productName;

      foundProductToRemove = state.cartProducts.find((product) => {
        if (product.productName) return payloadName === product.productName;
        else return payloadName === product.name;
      });
      const indexToRemove = state.cartProducts.indexOf(foundProductToRemove);

      let newCartProductsRemoved = [...state.cartProducts];

      if (
        !action.payload.permanent &&
        foundProductToRemove &&
        foundProductToRemove.quantity !== 1
      )
        newCartProductsRemoved[foundProductToRemove] = {
          ...foundProductToRemove,
          quantity: foundProductToRemove.quantity--,
        };
      else {
        if (newCartProductsRemoved[indexToRemove].productName)
          newCartProductsRemoved = newCartProductsRemoved.filter(
            (product) => product.productName !== payloadName
          );
        else
          newCartProductsRemoved = newCartProductsRemoved.filter(
            (product) => product.name !== payloadName
          );
      }
      return {
        ...state,
        cartLength: action.payload.permanent
          ? state.cartLength - foundProductToRemove.quantity
          : state.cartLength - 1,
        cartProducts: [...newCartProductsRemoved],
        isLoading: false,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        cartLength: action.payload.reduce(
          (acc, product) => product.quantity + acc,
          0
        ),
        cartProducts: [...state.cartProducts, ...action.payload],
        isLoading: false,
        cartLoading: false,
      };
    case GET_WISHLIST_SUCCESS:
      return {
        ...state,
        wishList: action.payload,
        isLoading: false,
        cartLoading: false,
      };
    case UPDATE_CART_PRICE:
      const allProducts = [...state.cartProducts];
      const currentProduct = allProducts.filter(
        (pr) => pr.code === action.payload.code
      );
      currentProduct.forEach((product) => {
        const oldPriceStrings = String(action.payload.oldPrice).split(".");
        const priceStrings = String(action.payload.price).split(".");

        product.numberOfStars1 = action.payload.numberOfStars1;
        product.numberOfStars2 = action.payload.numberOfStars2;
        product.numberOfStars3 = action.payload.numberOfStars3;
        product.numberOfStars4 = action.payload.numberOfStars4;
        product.numberOfStars5 = action.payload.numberOfStars5;
        product.price = Number(priceStrings[0]);
        product.priceSup = priceStrings[1] ? priceStrings[1] : "0";
        product.oldPrice = Number(oldPriceStrings[0]);
        product.oldPriceSup = oldPriceStrings[1] ? oldPriceStrings[1] : "0";
        product.weight = action.payload.weight;
      });
      return {
        ...state,
        cartProducts: allProducts,
      };
    case ADD_TO_WISHLIST_SOLO_CART_SUCCESS:
      return {
        ...state,
        wishList: [...state.wishList, action.payload],
        isLoading: false,
      };
    case DELETE_FROM_WISHLIST_CART_SUCCESS:
      return {
        ...state,
        wishList: state.wishList.filter((wish) => {
          if (wish.code) return wish.code !== action.payload;
          if (wish.productCode) return wish.productCode !== action.payload;
        }),
        isLoading: false,
      };
    default:
      return state;
  }
};
