import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styles from "./footer.module.scss";
import VisaPNG from "../../images/payment_visa.png";
import FacebookPNG from "../../images/facebook.png";
import InstagramPNG from "../../images/instagram.png";
import YoutubePNG from "../../images/youtube-logotype.png";
import { Link } from "react-router-dom";
import ANPC from "../../images/a6cd04a1-c104-4f3d-9f13-ed75d7437686.jpeg";
import onlineANPC from "../../images/9522c1b5-0f7c-45df-bbbd-d08008847cc2.jpeg";

const Footer = () => {
  return (
    <div
      className="col-12"
      style={{
        background: "#484B51 0% 0% no-repeat padding-box",
        border: "1px solid #707070",
      }}
    >
      <div className={styles.footer__container}>
        <div className={styles.top_footer}>
          <ul className={styles.footer_chunk + " " + styles.links}>
            <p>DESPRE ROMSTOCK</p>
            <Link
              to="/terms"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Termeni și condiții</li>
            </Link>
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Politica de confidențialitate</li>
            </Link>
            <a
              href="/cookie"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Politica privind cookies</li>
            </a>
            <Link
              to="/contact"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Contact</li>
            </Link>
          </ul>
          <ul className={styles.footer_chunk}>
            <p>URMĂRIȚI-NE</p>
            <a
              href="https://www.facebook.com/Romstock-109330394317335"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookPNG}
                alt="social-romstock-facebook-share"
                className={styles.icon_facebook}
              />
            </a>
            <a
              href="https://www.instagram.com/romstock.ro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramPNG}
                alt="social-romstock-instagram"
                className={styles.icon_instagram}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCt94vGRi13FJn5g4zkJXIxA/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={YoutubePNG}
                alt="social-romstock-youtube"
                className={styles.icon_instagram}
              />
            </a>
          </ul>
          <ul className={styles.footer_chunk}>
            <p>CONTACT</p>
            <li>
              <a
                style={{ textDecoration: "none", color: "#DDDADAB2" }}
                href="tel:+40 748 886 806"
              >
                +40 758 258 888
              </a>
            </li>
            <li>vanzari@romstock.ro</li>
          </ul>
          <ul className={styles.footer_chunk}>
            <p>MODALITĂȚI DE PLATĂ</p>
            <div className={styles.payment}>
              <img
                src={VisaPNG}
                alt="payment-visa-icon"
                className={styles.visa}
              />
            </div>
            <div className={styles.anpc}>
              <a
                href="https://anpc.ro/ce-este-sal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ANPC}
                  alt="anpc-icon"
                />
              </a>
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={onlineANPC}
                  alt="online-anpc-icon"
                />
              </a>
            </div>
          </ul>
        </div>

        <div className={styles.footer_mobile}>
          <ul className={styles.footer_chunk}>
            <p>DESPRE ROMSTOCK</p>
            <Link
              to="/terms"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Termeni și condiții</li>
            </Link>
            <Link
              to="/privacy-policy"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Politica de confidențialitate</li>
            </Link>
            <a
              href="/cookie"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Politica privind cookies</li>
            </a>
            <Link
              to="/contact"
              style={{ textDecoration: "none" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <li>Contact</li>
            </Link>
          </ul>
          <ul className={styles.footer_chunk}>
            <p>URMĂRIȚI-NE</p>
            <a
              href="https://www.facebook.com/Romstock-109330394317335"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={FacebookPNG}
                alt="social-romstock-facebook-share"
                className={styles.icon_facebook}
              />
            </a>
            <a
              href="https://www.instagram.com/romstock.ro/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={InstagramPNG}
                alt="social-romstock-instagram"
                className={styles.icon_instagram}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCt94vGRi13FJn5g4zkJXIxA/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={YoutubePNG}
                alt="social-romstock-youtube"
                className={styles.icon_instagram}
              />
            </a>
          </ul>
          <ul className={styles.footer_chunk}>
            <p>CONTACT</p>
            <li>+40 758 258 888</li>
            <li>vanzari@romstock.ro</li>
          </ul>
          <ul style={{ display: "block" }} className={styles.footer_chunk}>
            <p>MODALITĂȚI DE PLATĂ</p>
            <div className={styles.payment}>
              <img
                src={VisaPNG}
                alt="payment-visa-icon"
                className={styles.visa}
              />
            </div>
            <div className={styles.anpc}>
              <a
                href="https://anpc.ro/ce-este-sal/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={ANPC}
                  alt="anpc-icon"
                />
              </a>
              <a
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={onlineANPC}
                  alt="online-anpc-icon"
                />
              </a>
            </div>
          </ul>
        </div>
        <div
          style={{
            color: "#DDDADA",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <h6 style={{ width: "100%", textAlign: "center" }}>
            {new Date().getFullYear()} &#169; RomStock. All rights reserved.
            Designed by Pantech <span style={{ color: "red" }}>. </span>
          </h6>
          {/*<h6 style={{ width: "40%", textAlign: "end" }}> </h6>*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
