import {
  ORDER_RAMBURS_REQUEST,
  ORDER_RAMBURS_SUCCESS,
  ORDER_RAMBURS_FAILURE,
  ORDER_BT_REQUEST,
  ORDER_BT_SUCCESS,
  ORDER_BT_FAILURE,
  ORDER_INFO_REQUEST,
  ORDER_INFO_SUCCESS,
  ORDER_INFO_FAILURE,
  BT_STATUS_REQUEST,
  BT_STATUS_SUCCESS,
  BT_STATUS_FAILURE,
  VOUCHER_INFO_REQUEST,
  VOUCHER_INFO_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  VOUCHER_INFO_ERROR,
} from "./order.constants";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  voucherError: null,
  order: null,
  response: null,
  status: null,
  info: null,
  voucher: null,
  details: null,
};

export const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ORDER_RAMBURS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_RAMBURS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        order: action.payload.order,
        error: null,
      };
    case ORDER_RAMBURS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ORDER_BT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_BT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        order: action.payload.order,
        response: action.payload.response,
        error: null,
      };
    case ORDER_BT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case BT_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BT_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        isLoading: false,
      };
    case BT_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ORDER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        info: action.payload.info,
        // error: null
      };
    case ORDER_INFO_FAILURE:
      return {
        ...state,
        // error: action.payload,
        isLoading: false,
      };
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        details: action.payload.details,
      };
    case VOUCHER_INFO_REQUEST:
      return {
        ...state,
      };
    case VOUCHER_INFO_SUCCESS:
      return {
        ...state,
        voucher: action.payload.voucher,
      };
    case VOUCHER_INFO_ERROR:
      return {
        ...state,
        voucherError: action.payload.message,
      };
    default:
      return state;
  }
};
