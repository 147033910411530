import {
  GET_COMMENTS_REQUEST,
  NEXT_PAGE_COMMENTS,
  PREV_PAGE_COMMENTS,
  SAVE_COMMENT_REQUEST,
} from "./comments.constants";

export const getCommentsRequest = (payload) => {
  return {
    type: GET_COMMENTS_REQUEST,
    payload,
  };
};

export const nextPage = () => {
  return {
    type: NEXT_PAGE_COMMENTS,
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE_COMMENTS,
  };
};

export const saveCommentRequest = (payload) => {
  return {
    type: SAVE_COMMENT_REQUEST,
    payload,
  };
};
