import React, { useMemo } from "react";
import "./CartPage.scss";
import { Button, Spinner } from "react-bootstrap";
import StarRatings from "react-star-ratings/build/star-ratings";
import { Link } from "react-router-dom";
import SEO from "../../components/SEO/SEO";
import {
  replaceNameSingleProduct,
  encodeProductCode,
} from "../../utilities/helpers";

const CartPage = ({
  products,
  cartLength,
  isWishList,
  updateCartQuantityRequest,
  addToCart,
  removeFromCart,
  user,
  cartProducts,
  addToCartLogged,
  deleteFromWishlist,
  isLoading,
  cartLoading,
  addNotification,
}) => {
  const totalPrice = useMemo(() => {
    const productsSum = products.reduce((acc, current) => {
      if (!current) return acc;
      const price =
        (current.price || current.price === 0) && !current.currentPrice
          ? (current.price +
              Number(current.priceSup) /
                Math.pow(10, parseFloat(String(current.priceSup).length))) *
            current.quantity
          : current.currentPrice * current.quantity;
      const sumAcc = Number(acc) + Number(price);
      return sumAcc;
    }, 0);

    return productsSum;
  }, [products]);

  const totalReducedPrice = useMemo(() => {
    let sum = 0;
    products.forEach((current) => {
      if (!current) return;
      const oldPrice =
        !current.oldPriceSup && !current.oldPriceSup !== 0
          ? current.oldPrice * current.quantity
          : (Number(current.oldPrice) +
              Number(current.oldPriceSup) /
                Math.pow(10, parseFloat(String(current.oldPriceSup).length))) *
            current.quantity;
      sum = sum + oldPrice;
    });
    return totalPrice.toFixed(2) - sum;
  }, [products]);

  const getStars = (product) => {
    if (
      product.numberOfStars1 +
        product.numberOfStars2 +
        product.numberOfStars3 +
        product.numberOfStars4 +
        product.numberOfStars5 !==
      0
    ) {
      const number =
        (product.numberOfStars1 +
          product.numberOfStars2 * 2 +
          product.numberOfStars3 * 3 +
          product.numberOfStars4 * 4 +
          product.numberOfStars5 * 5) /
        (product.numberOfStars1 +
          product.numberOfStars2 +
          product.numberOfStars3 +
          product.numberOfStars4 +
          product.numberOfStars5);
      return Math.round(number * 100) / 100;
    } else return 0;
  };

  if (cartLoading)
    return (
      <div className="general-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  return (
    <>
      <SEO
        title="Coșul meu"
        description=""
        robots="noindex, follow"
        canonical="https://romstock.ro/my-cart"
        openGraph={{
          url: "https://www.romstock.ro/my-cart",
          type: "website",
          image: "https://romstock.ro/logo192.png",
          description: "Cosul tau te asteapta. Finalizeaza cumparaturile!",
        }}
      />
      <div className="my-cart">
        <h1>{isWishList ? "Favorite" : "Coșul meu"}</h1>
        <div className="my-cart_products">
          {!products.length ? (
            <p>
              {isWishList ? "Lista de favorite este goală." : "Coșul este gol."}
            </p>
          ) : (
            <div className="my-cart_cards">
              {products.map((product, i) => {
                return (
                  <div className="my-cart_card">
                    <div className="my-cart_details">
                      <div className="my-cart_details_image-container">
                        <label
                          className={
                            product.stockStatus === "OUT_OF_STOCK" ||
                            product.stockStatus === "indisponibil"
                              ? "out-of-stock"
                              : product.stockStatus === "REDUCED_STOCK"
                              ? "reduced-stock"
                              : "availability"
                          }
                        >
                          {product.stockStatus === "OUT_OF_STOCK" ||
                          product.stockStatus === "indisponibil"
                            ? "indisponibil"
                            : product.stockStatus === "REDUCED_STOCK"
                            ? "stoc redus"
                            : "în stoc"}
                        </label>
                        <img
                          className="my-cart_image"
                          src={
                            product.image ||
                            product.productImage ||
                            product.imageUrl
                          }
                          alt={(product.name || product.productName) + "-icon"}
                        />
                      </div>
                      <div className="my-cart_details_product-info">
                        <Link
                          to={
                            "/" +
                            (product.name
                              ? replaceNameSingleProduct(product.name)
                              : replaceNameSingleProduct(product.productName)) +
                            "/" +
                            (product.code
                              ? encodeProductCode(product.code)
                              : encodeProductCode(product.productCode))
                          }
                        >
                          <p className="my-cart_details_product-info-name">
                            {product.name || product.productName}
                          </p>
                        </Link>
                        <StarRatings
                          rating={getStars(product)}
                          starRatedColor="#E6B923"
                          numberOfStars={5}
                          name="rating"
                          starDimension="15px"
                          starSpacing="1px"
                        />
                        <div className="my-cart_guarantee">
                          <p>Garanție:</p>
                          <ul>
                            <li>
                              {product.legalGuarantee} luni persoane juridice
                            </li>
                            <li>
                              {product.commercialGuarantee} luni persoane fizice
                            </li>
                          </ul>
                        </div>
                        <p className="my-cart_code">
                          Cod produs: {product.code || product.productCode}
                        </p>
                      </div>
                    </div>
                    <div className="my-cart_details-price">
                      <div style={{ justifyContent: "center" }}>
                        <div className="my-cart_details-price_quantity">
                          <h4>{i === 0 && "Cantitate"}</h4>

                          {!isWishList ? (
                            isLoading === product.code ||
                            isLoading === product.productCode ? (
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <p>
                                <span
                                  onClick={() => {
                                    const payload = {
                                      product,
                                      quantity: product.quantity - 1,
                                    };
                                    if (user)
                                      updateCartQuantityRequest(payload);
                                    else removeFromCart(product);
                                  }}
                                >
                                  -
                                </span>
                                <span>{product.quantity}</span>
                                <span
                                  onClick={() => {
                                    const payload = {
                                      product,
                                      quantity: product.quantity + 1,
                                    };
                                    if (user)
                                      updateCartQuantityRequest(payload);
                                    else addToCart(product);
                                  }}
                                >
                                  +
                                </span>
                              </p>
                            )
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </div>
                      <div style={{ justifyContent: "center" }}>
                        <div className="my-cart_details-price_price">
                          <h4>{i === 0 && "Preț"}</h4>
                          {(product.price &&
                            !product.currentPrice &&
                            Number(product.oldPrice).toFixed(0) -
                              product.price !==
                              0) ||
                            (product.currentPrice &&
                              Number(product.oldPrice).toFixed(0) -
                                product.currentPrice !==
                                0) ||
                            (product.productPrice &&
                              product.oldPrice.toFixed(0) -
                                product.productPrice !==
                                0 && (
                                <p className="my-cart_details-price_price-reduced">
                                  {product.oldPriceSup
                                    ? (product.oldPrice +
                                        Number(product.oldPriceSup) /
                                          Math.pow(
                                            10,
                                            parseFloat(
                                              String(product.oldPriceSup).length
                                            )
                                          )) *
                                      product.quantity
                                    : product.oldPrice * product.quantity}
                                  <span>
                                    {product.type ||
                                      product.productType ||
                                      "RON"}
                                  </span>
                                </p>
                              ))}
                          {(product.price || product.price === 0) &&
                          !product.currentPrice ? (
                            <p>
                              {(
                                (Number(product.price) +
                                  Number(product.priceSup) /
                                    Math.pow(
                                      10,
                                      parseFloat(
                                        String(product.priceSup).length
                                      )
                                    )) *
                                (product.quantity ? product.quantity : 1)
                              ).toFixed(2)}
                              <span>{product.type || " RON"}</span>
                            </p>
                          ) : (
                            <p>
                              {product.currentPrice ||
                              product.currentPrice === 0
                                ? (
                                    Number(product.currentPrice) *
                                    (product.quantity || 1)
                                  ).toFixed(2)
                                : (Number(product.productPrice) *
                                  product.quantity
                                    ? product.quantity
                                    : 1
                                  ).toFixed(2)}{" "}
                              RON
                            </p>
                          )}
                        </div>
                      </div>
                      {isWishList && (
                        <>
                          <Button
                            className="orange-button"
                            onClick={() => {
                              if (
                                product.stockStatus !== "OUT_OF_STOCK" &&
                                product.stockStatus !== "indisponibil"
                              ) {
                                let ok = false;
                                const code =
                                  product.productCode || product.code;
                                cartProducts.forEach((cartProduct) => {
                                  if (
                                    cartProduct.code == code ||
                                    cartProduct.productCode == code
                                  ) {
                                    updateCartQuantityRequest({
                                      product: { ...product },
                                      quantity: cartProduct.quantity + 1,
                                    });
                                    ok = true;
                                  }
                                });
                                if (!ok) addToCartLogged(product);
                              } else {
                                addNotification({
                                  boldText:
                                    "Produsul nu poate fi adaugat in cos",
                                  text: "pentru ca este indisponibil!",
                                });
                              }
                            }}
                          >
                            Adaugă în coș
                          </Button>
                          <Button
                            className="black-button"
                            style={{ marginTop: "1em" }}
                            onClick={() => deleteFromWishlist({ product })}
                          >
                            Șterge din favorite
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {!isWishList && products.length && (
            <div className="my-cart_total">
              <div className="my-cart_finish">
                <h3>Total</h3>
                <p>
                  Număr produse <span>{cartLength}</span>
                </p>
                <p>
                  Reducere <span>{totalReducedPrice.toFixed(2)} RON</span>
                </p>
                <h5>
                  Preț total{" "}
                  <span>{products && totalPrice.toFixed(2)} RON</span>
                </h5>
                <Button
                  className="orange-button"
                  onClick={() => {
                    let ok = true;
                    for (let i = 0; i < products.length; i++) {
                      if (
                        products[i].stockStatus === "OUT_OF_STOCK" ||
                        products[i].stockStatus === "indisponibil"
                      ) {
                        ok = false;
                        break;
                      }
                    }

                    if (!ok) {
                      addNotification({
                        boldText: "Nu se poate finaliza comanda!",
                        text: "Exista produse care nu sunt in stoc!",
                      });
                    } else {
                      window.location.assign("/order-completion");
                      window.scrollTo(0, 0);
                    }
                  }}
                >
                  Finalizare comandă
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CartPage;
