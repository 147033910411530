import { compose } from "redux";
import { connect } from "react-redux";
import CartPage from "./CartPage";
import {
  addToCart,
  removeFromCart,
  updateCartQuantityRequest,
} from "../../redux/cart/cart.actions";
import {
  removeNotification,
  addNotification,
} from "../../redux/product/product.actions";

const mapStateToProps = (state) => ({
  products: state.cart.cartProducts,
  cartLength: state.cart.cartLength,
  user: state.registerAccount.user,
  notifications: state.product.notifications,
  isLoading: state.cart.isLoading,
  cartLoading: state.cart.cartLoading,
});
const mapDispatchToProps = (dispatch) => ({
  addToCart: (payload) => dispatch(addToCart(payload)),
  removeFromCart: (payload) => dispatch(removeFromCart(payload)),
  updateCartQuantityRequest: (payload) =>
    dispatch(updateCartQuantityRequest(payload)),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
});
export const MyCart = compose(
  connect(mapStateToProps, mapDispatchToProps)(CartPage)
);
