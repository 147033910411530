import {
  SEARCH_PRODUCTS_REQUEST,
  SET_LAST_PAGE_A,
  SET_PREV_PAGE_A,
  SET_FIRST_PAGE_A,
  SET_NEXT_PAGE_A,
  NEXT_PAGE_A,
  PREV_PAGE_A,
} from "./search.constants";

export const searchProductsRequest = (payload) => {
  return {
    type: SEARCH_PRODUCTS_REQUEST,
    payload,
  };
};

export const nextPageA = () => {
  return {
    type: NEXT_PAGE_A,
  };
};

export const prevPageA = () => {
  return {
    type: PREV_PAGE_A,
  };
};

export const setNextPageA = () => {
  return {
    type: SET_NEXT_PAGE_A,
  };
};

export const setPrevPageA = () => {
  return {
    type: SET_PREV_PAGE_A,
  };
};

export const setFirstPageA = () => {
  return {
    type: SET_FIRST_PAGE_A,
  };
};

export const setLastPageA = () => {
  return {
    type: SET_LAST_PAGE_A,
  };
};
