import React, { Component } from "react";
import "./LoginForm.scss";
import { Formik, Field, ErrorMessage } from "formik";
import { Button, Form, Row, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginRequest } from "../../../redux/register/register.actions";
import SEO from "../../../components/SEO/SEO";
import { URL } from "../../../utilities/constants";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordRevealed: false,
    };

    this.togglePassword = this.togglePassword.bind(this);
  }

  togglePassword() {
    this.setState({
      isPasswordRevealed: !this.state.isPasswordRevealed,
    });
  }
  render() {
    return (
      <div>
        <SEO
          title="Login"
          description="Intra acum in contul tau Romstock. Introdu adresa de email si parola si esti pregatit de cumparaturi."
          robots="index, follow"
          canonical="https://romstock.ro/login"
          openGraph={{
            url: "https://www.romstock.ro/login",
            type: "website",
            image: "https://romstock.ro/logo192.png",
            description: "Intra in contul tau RomStock",
          }}
        />
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Adresa de email este obligatorie";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Adresa de email este invalidă";
            }
            if (!values.password) {
              errors.password = "Parola este obligatorie";
            }
            return errors;
          }}
          onSubmit={(values) => {
            this.props.login(values);
          }}
        >
          {(props) => (
            <div id="container-style" className="inner-bg container">
              <h1 className="title-style">Intră în cont</h1>
              <Row className="line-style">
                {/* <label style={{ fontWeight: "bolder" }}> _________ </label> */}
                <div
                  className="div-line"
                  style={{
                    width: "20%",
                    height: "2px",
                    backgroundColor: "#E04C35",
                  }}
                ></div>
              </Row>
              <div className="form-box">
                <Form className="center-block" onSubmit={props.handleSubmit}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <span className="label-group">
                            <i className="fa fa-user"></i>
                            <label htmlFor="email">Cont</label>
                          </span>
                        </td>
                        <td>
                          <span>
                            <Field
                              type="text"
                              name="email"
                              className="form-control input-style"
                              placeholder="email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="form-group col-md-12 warning-message"
                            />
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ paddingTop: "2%" }}>
                          <span className="label-group">
                            <i className="fa fa-key" aria-hidden="true"></i>
                            <label htmlFor="password">Parolă</label>
                          </span>
                        </td>
                        <td style={{ paddingTop: "2%" }}>
                          <span>
                            <div style={{ position: "relative" }}>
                              <Field
                                type={
                                  this.state.isPasswordRevealed
                                    ? "text"
                                    : "password"
                                }
                                name="password"
                                className="form-control input-style"
                                placeholder="parolă"
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  left: "93%",
                                  top: "6px",
                                }}
                                onClick={() => this.togglePassword()}
                                ref={this.isPasswordRevealed}
                              >
                                <span className="eye_span">
                                  {this.state.isPasswordRevealed ? (
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </span>
                              </span>
                            </div>
                            <ErrorMessage
                              name="password"
                              component="div"
                              className="form-group warning-message"
                            />
                            {this.props.error && (
                              <div className="form-group warning-message">
                                {this.props.error}
                              </div>
                            )}
                          </span>
                        </td>
                      </tr>
                      {this.props.isLoading ? (
                        <tr className="login-spinner">
                          <td></td>
                          <td>
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td></td>
                            <td className="forgot-pass">
                              <label
                                onClick={() =>
                                  window.open(URL + "/forgot-password")
                                }
                              >
                                Ați uitat parola?
                              </label>
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td className="buttons-td">
                              <Row className="buttons-row">
                                <Button
                                  variant="secondary"
                                  className="btn-fill center-block button-style"
                                  type="button"
                                >
                                  <Link
                                    to="/register"
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                    }}
                                  >
                                    Creează cont
                                  </Link>
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="btn-fill center-block button-style"
                                  type="submit"
                                >
                                  Autentificare
                                </Button>
                              </Row>
                            </td>
                          </tr>
                        </>
                      )}
                      {/* TO DO: Login with Google and Facebook */}

                      {/* <tr>
                                                <td></td>
                                                <td className="logo-row">
                                                    <span>
                                                        <label>Sau loghează-te cu:
                                                            <Image className="logo-style" alt="login-google-logo" src={google}></Image>
                                                            <Image className="logo-style" alt="login-facebook-logo" src={facebook}></Image>
                                                        </label>
                                                    </span>
                                                </td>
                                            </tr> */}
                    </tbody>
                  </table>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.registerAccount.error,
  isLoading: state.registerAccount.isLoading,
});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(loginRequest(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
