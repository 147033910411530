import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  SEARCH_GUEST_REQUEST,
  SEARCH_GUEST_SUCCESS
} from "./filters.constants";

export function* getGuestProducts({ payload, options }) {
  const { filters, sortType, currentPage, isLogged, cancelToken } = payload;
  const body = options.changeFilters ? [filters[filters.length - 1]] : filters;

  const {
    data: { products, brands, priceRangeStart, priceRangeEnd, numberOfPages },
  } = isLogged
    ? yield call(
        apiService.getLoggedProducts,
        { filters: body, sortType },
        cancelToken,
        currentPage
      )
    : !isLogged
    ? yield call(
        apiService.getGuestProducts,
        { filters: body, sortType },
        cancelToken,
        currentPage
      )
    : "";
  if (options.changeFilters)
    yield put({
      type: SEARCH_GUEST_SUCCESS,
      payload: {
        products,
        brands,
        priceRange: { min: priceRangeStart, max: priceRangeEnd },
        numberOfPages,
        shouldChangeFilters: true,
      },
    });
  else
    yield put({
      type: SEARCH_GUEST_SUCCESS,
      payload: { products, numberOfPages, shouldChangeFilters: false },
    });
}

export function* getGuestProductsWatcher() {
  yield takeLatest(SEARCH_GUEST_REQUEST, getGuestProducts);
}

export function* filtersSaga() {
  yield all([call(getGuestProductsWatcher)]);
}
