import {
  SEARCH_PRODUCTS_REQUEST,
  SEARCH_PRODUCTS_SUCCESS,
  SET_LAST_PAGE_A,
  SET_PREV_PAGE_A,
  SET_FIRST_PAGE_A,
  SET_NEXT_PAGE_A,
  NEXT_PAGE_A,
  PREV_PAGE_A,
} from "./search.constants";

const INITIAL_STATE = {
  products: [],
  isLoading: false,
  currentPageNumber: 1,
  numberOfPages: null,
};

export const searchReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        currentPageNumber: state.currentPageNumber,
      };
    case SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload.products,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
      };
    case NEXT_PAGE_A:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber + 1,
      };
    case PREV_PAGE_A:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber - 1,
      };
    case SET_NEXT_PAGE_A:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber + 2,
      };
    case SET_PREV_PAGE_A:
      return {
        ...state,
        currentPageNumber: state.currentPageNumber - 2,
      };
    case SET_FIRST_PAGE_A:
      return {
        ...state,
        currentPageNumber: 1,
      };
    case SET_LAST_PAGE_A:
      return {
        ...state,
        currentPageNumber: state.numberOfPages,
      };
    default:
      return state;
  }
};
