import React, { useMemo } from "react";
import ImageGallery from "react-image-gallery";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import "react-image-gallery/styles/scss/image-gallery.scss";
import { connect } from "react-redux";
import "./ProductDetails.scss";
import DetailsCard from "../DetailsCard/DetailsCard.jsx";
import QuantityCard from "../QuantityCard/QuantityCard";
import { removeNotification } from "../../../redux/product/product.actions";
import { ScrollTo } from "react-scroll-to";
import Notification from "../../../components/Notification/Notification";
import {
  addToWishlist,
  deleteFromWishlist,
} from "../../../redux/cart/cart.actions";
import SEO from "../../../components/SEO/SEO";
import { Link } from "react-router-dom";
import { replaceCategory } from "../../../utilities/helpers";
import Benefits from "../Benefits/Benefits";
import BenefitsMobile from "../Benefits/BenefitsMobile";
import { addNotification } from "../../../redux/product/product.actions";

const ProductDetails = ({
  product,
  notifications,
  removeNotification,
  clickShowDetails,
  addToWishlist,
  deleteFromWishlist,
  wishList,
  isWishlistLoading,
  user,
  addNotification,
}) => {
  const isInWishlist = useMemo(() => {
    if (!product) return false;

    const productName = product.name ? product.name : product.productName;
    return wishList.some((listItem) => {
      if (listItem.name) return listItem.name === productName;
      else return listItem.productName === productName;
    });
  }, [wishList.length]);

  const getImages = () => {
    let firstImage = " " + product.imageUrl;
    let newArray = [];
    if (product.otherImageUrls != null) {
      let imagesArray = product.otherImageUrls.split(",").slice(1);
      newArray = [firstImage].concat(imagesArray);
    } else {
      newArray = [firstImage];
    }

    let images = [];
    for (let i = 0; i < newArray.length; i++) {
      images.push({
        original: newArray[i].trim(),
        thumbnail: newArray[i].trim(),
      });
    }
    return images;
  };

  const getStars = () => {
    if (
      product.numberOfStars1 +
        product.numberOfStars2 +
        product.numberOfStars3 +
        product.numberOfStars4 +
        product.numberOfStars5 !=
      0
    ) {
      const number =
        (product.numberOfStars1 +
          product.numberOfStars2 * 2 +
          product.numberOfStars3 * 3 +
          product.numberOfStars4 * 4 +
          product.numberOfStars5 * 5) /
        (product.numberOfStars1 +
          product.numberOfStars2 +
          product.numberOfStars3 +
          product.numberOfStars4 +
          product.numberOfStars5);
      return Math.round(number * 100) / 100;
    }
    return 5;
  };

  const getBestRating = () => {
    if (product.numberOfStars5 != 0) {
      return 5;
    } else if (product.numberOfStars4 != 0) {
      return 4;
    } else if (product.numberOfStars3 != 0) {
      return 3;
    } else if (product.numberOfStars2 != 0) {
      return 2;
    } else if (product.numberOfStars1 != 0) {
      return 1;
    }
    return 5;
  };

  const getWorstRating = () => {
    if (product.numberOfStars1 != 0) {
      return 1;
    } else if (product.numberOfStars2 != 0) {
      return 2;
    } else if (product.numberOfStars3 != 0) {
      return 3;
    } else if (product.numberOfStars4 != 0) {
      return 4;
    } else if (product.numberOfStars5 != 0) {
      return 5;
    }
    return 1;
  };

  const getLink = (index) => {
    let categoryArray = product.category.split("::");
    const link = categoryArray.reduce((acc, currentCategory, i) => {
      const currentAcc = acc + (i == 0 ? "" : "_") + currentCategory;
      if (i <= index) return currentAcc;

      return acc + "";
    }, "");
    return link;
  };

  if (!product) {
    return (
      <div className="products-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return (
      <>
        <SEO
          title={product.name}
          description={product.description.substring(0, 100)}
          robots="index, follow"
          canonical={"https://www.romstock.ro" + window.location.pathname}
          openGraph={{
            url: "https://www.romstock.ro" + window.location.pathname,
            type: "product",
            image: product.imageUrl,
            description: "Cumpara " + product.name + " de pe RomStock",
          }}
        />
        <div className="main-div-product">
          <div className="filters_notifications sticky">
            {notifications.map((notification) => {
              return (
                <Notification
                  text={notification.text}
                  boldText={notification.boldText}
                  onClick={() => removeNotification(notification.id)}
                />
              );
            })}
          </div>
          <ol
            id="products-list"
            itemScope
            itemType="https://schema.org/BreadcrumbList"
          >
            {String(product.category)
              .split("::")
              .map((category, index) => {
                const link = getLink(index);
                return (
                  <li
                    key={index}
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    <Link
                      to={{ pathname: "/products/" + replaceCategory(link) }}
                      itemProp="item"
                    >
                      <span itemProp="name">
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </span>
                    </Link>
                    <meta itemProp="position" content={index + 1} />
                  </li>
                );
              })}
          </ol>
          <h1 className="title-product" itemProp="name">
            {product.brand !== "-" ? product.brand + " -" : null} {product.name}
          </h1>
          <label
            itemProp="availability"
            href="https://schema.org/InStock"
            className={
              product.stockStatus === "OUT_OF_STOCK"
                ? "out-of-stock"
                : product.stockStatus === "REDUCED_STOCK"
                ? "reduced-stock"
                : "availability"
            }
          >
            {product.stockStatus === "REDUCED_STOCK"
              ? "stoc redus"
              : product.stockStatus === "OUT_OF_STOCK"
              ? "indisponibil"
              : "în stoc"}
          </label>
          {/* MOBILE */}
          <div
            className="label-mobile"
            itemProp="aggregateRating"
            itemScope
            itemType="https://schema.org/AggregateRating"
          >
            <label> Nota clienților: </label>
            <label style={{ color: "#058BFF" }}>
              (<span itemProp="ratingValue">{getStars()}</span>
              <span style={{ display: "none" }} itemProp="reviewCount">
                {1 +
                  product.numberOfStars1 +
                  product.numberOfStars2 +
                  product.numberOfStars3 +
                  product.numberOfStars4 +
                  product.numberOfStars5}
              </span>
              <span style={{ display: "none" }} itemProp="bestRating">
                {getBestRating()}
              </span>
              <span style={{ display: "none" }} itemProp="worstRating">
                {getWorstRating()}
              </span>
              )
            </label>
          </div>

          <Row style={{ marginBottom: "5%", width: "100%" }}>
            <Col md={5}>
              <span style={{ display: "none" }} itemProp="image">
                {product.imageUrl}
              </span>
              <ImageGallery items={getImages()} />
            </Col>
            <Col md={7}>
              <Row>
                <Col md={6} style={{ paddingRight: "0", marginRight: "0" }}>
                  <DetailsCard clickShowDetails={clickShowDetails} />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <QuantityCard />
                  <Benefits />
                  {/* MOBILE */}
                  <Row className="mobile-btn">
                    {isWishlistLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <>
                        <Button
                          className="wishlist-btn"
                          onClick={() => {
                            if (!isInWishlist) {
                              if (user) {
                                addToWishlist({ product });
                                addNotification({
                                  text: "a fost adăugat în Wishlist!",
                                  boldText: product.name,
                                });
                              } else {
                                addNotification({
                                  text: "Pentru a putea adăuga produse în Wishlist, te rugăm să te autentifici!",
                                });
                              }
                            } else {
                              deleteFromWishlist({ product });
                              addNotification({
                                text: "a fost scos din Wishlist!",
                                boldText: product.name,
                              });
                            }
                          }}
                        >
                          <i className="fa fa-heart-o" aria-hidden="true"></i>
                          {!isInWishlist
                            ? "Adaugă în wishlist"
                            : "Scoate din wishlist"}
                        </Button>
                        <ScrollTo>
                          {({ scroll }) => (
                            <Button
                              className="details-btn"
                              onClick={() => {
                                clickShowDetails(true);
                                scroll({ y: 1000, smooth: true });
                              }}
                            >
                              Detalii produs
                            </Button>
                          )}
                        </ScrollTo>
                      </>
                    )}
                    <BenefitsMobile />
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
};
const mapDispatchToProps = (dispatch) => ({
  removeNotification: (payload) => dispatch(removeNotification(payload)),
  addToWishlist: (payload) => dispatch(addToWishlist(payload)),
  deleteFromWishlist: (payload) => dispatch(deleteFromWishlist(payload)),
  addNotification: (payload) => dispatch(addNotification(payload)),
});
const mapStateToProps = (state) => ({
  clicks: state.product.clicks,
  product: state.product.product,
  notifications: state.product.notifications,
  wishList: state.cart.wishList,
  isWishlistLoading: state.cart.isLoading,
  isLoading: state.product.isLoading,
  user: state.registerAccount.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
