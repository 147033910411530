import {
  DECREASE_ITEM,
  INCREMENT_ITEM,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  RESET_ITEM,
} from "./product.constants";

const INITIAL_STATE = {
  product: null,
  clicks: 1,
  isLoading: false,
  notifications: [],
};

export const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ITEM:
      return {
        ...state,
        clicks: 1,
      };
    case DECREASE_ITEM:
      return {
        ...state,
        clicks: state.clicks > 0 ? state.clicks - 1 : 0,
      };
    case INCREMENT_ITEM:
      return {
        ...state,
        clicks: state.clicks + 1,
      };
    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        isLoading: true,
        clicks: 1,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        isLoading: false,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: Math.random() * 1000,
            boldText: action.payload.boldText,
            text: action.payload.text,
          },
        ],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
