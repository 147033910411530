import React from "react";
import { Row, Col } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import Avatar from "react-avatar";
import "./ReviewCard.scss";

const ReviewCard = ({ name, rating, date, title, content }) => {
  return (
    <div
      className="review-card-container"
      itemProp="review"
      itemScope
      itemType="https://schema.org/Review"
    >
      <Row>
        {/* WEB */}
        <Col md={2} className="web">
          <Avatar
            name={name}
            size="80"
            maxInitials={2}
            color="#B9BDC3"
            fgColor="#484B51"
            round="50%"
            textSizeRatio={4}
          />
        </Col>
        <Col md={4} sm={6} className="web">
          <Row className="name-class" itemProp="author">
            {name}
          </Row>
          <Row itemProp="ratingValue">
            <StarRatings
              rating={rating}
              starRatedColor="#E6B923"
              numberOfStars={5}
              name="rating"
            />
          </Row>
          <meta itemProp="datePublished" content={date} />
          <Row className="date-class">{date}</Row>
        </Col>

        {/* MOBILE */}
        <div className="mobile">
          <Col xs={7} style={{ display: "inline-table", width: "max-content" }}>
            <Row className="name-class">{name}</Row>
            <Row className="date-class">{date}</Row>
          </Col>
          <Col>
            <StarRatings
              rating={rating}
              starRatedColor="#E6B923"
              numberOfStars={5}
              name="rating"
            />
          </Col>
        </div>

        <Col>
          <Row className="title-class" itemProp="name">
            {title}
          </Row>
          <Row className="content-class" itemProp="reviewBody">
            {content}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ReviewCard;
