import {
  GET_PROMOTION_CATEGORIES_REQUEST,
  GET_PROMOTION_CATEGORIES_SUCCESS,
  GET_PROMOTION_PRODUCTS_REQUEST,
  GET_PROMOTION_PRODUCTS_SUCCESS,
} from "./promotions.constants";
import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";

export function* getProductsPromotion({ payload }) {
  const { currentPage, category } = payload;
  const {
    data: { products, numberOfPages },
  } = yield call(apiService.getPromotionProducts, currentPage, category);
  yield put({
    type: GET_PROMOTION_PRODUCTS_SUCCESS,
    payload: { products, numberOfPages },
  });
}

export function* getCategoriesPromotion() {
  const { data } = yield call(apiService.getPromotionCategories);
  yield put({
    type: GET_PROMOTION_CATEGORIES_SUCCESS,
    payload: { categories: data },
  });
}

export function* getProductsPromotionWatcher() {
  yield takeLatest(GET_PROMOTION_PRODUCTS_REQUEST, getProductsPromotion);
}

export function* getCategoriesPromotionWatcher() {
  yield takeLatest(GET_PROMOTION_CATEGORIES_REQUEST, getCategoriesPromotion);
}

export function* promotionSaga() {
  yield all([
    call(getProductsPromotionWatcher),
    call(getCategoriesPromotionWatcher),
  ]);
}
