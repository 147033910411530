import { takeLatest, put, all, call } from "redux-saga/effects";
import apiService from "../../service/apiService";
import {
  ORDER_RAMBURS_REQUEST,
  ORDER_RAMBURS_SUCCESS,
  ORDER_RAMBURS_FAILURE,
  ORDER_BT_REQUEST,
  ORDER_BT_SUCCESS,
  ORDER_BT_FAILURE,
  ORDER_INFO_REQUEST,
  ORDER_INFO_SUCCESS,
  BT_STATUS_REQUEST,
  BT_STATUS_SUCCESS,
  BT_STATUS_FAILURE,
  VOUCHER_INFO_SUCCESS,
  VOUCHER_INFO_REQUEST,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  VOUCHER_INFO_GUEST_REQUEST,
  VOUCHER_INFO_ERROR,
} from "./order.constants";
import { LOCAL_STORAGE_REFERRER } from "../../utilities/constants";

export function* makeBTPayment({ payload }) {
  const {
    voucherCode,
    currency,
    cartItems,
    deliveryAddressId,
    billingAddressId,
    deliveryAddress,
    billingAddress,
    isLogged,
    deliveryType,
    referrer,
  } = payload;
  try {
    const response = isLogged
      ? yield call(apiService.postLoggedBTPayment, {
          voucherCode,
          currency,
          cartItems,
          deliveryAddressId,
          billingAddressId,
          deliveryAddress,
          billingAddress,
          deliveryType,
          referrer,
        })
      : yield call(apiService.postBTPayment, {
          voucherCode,
          currency,
          cartItems,
          deliveryAddressId,
          billingAddressId,
          deliveryAddress,
          billingAddress,
          deliveryType,
          referrer,
        });

    if (response) {
      yield put({
        type: ORDER_BT_SUCCESS,
        payload: { response },
      });
      localStorage.removeItem("persist:root");
      localStorage.removeItem(LOCAL_STORAGE_REFERRER);
      window.location.href = response.data.formUrl;
    }
  } catch (err) {
    yield put({
      type: ORDER_BT_FAILURE,
      payload: "Plata online cu cardul a eșuat, încercați din nou!",
    });
  }
}

export function* getBTStatus({ payload }) {
  const { orderId, isLogged } = payload;
  const status = isLogged
    ? yield call(apiService.getLoggedStatusBTPayment, orderId)
    : yield call(apiService.getStatusBTPayment, orderId);
  try {
    yield put({
      type: BT_STATUS_SUCCESS,
      payload: { status },
    });
  } catch (err) {
    yield put({
      type: BT_STATUS_FAILURE,
      payload: "Comanda nu a putut fi înregistrată, încercați din nou!",
    });
  }
}

export function* makeRambursPayment({ payload }) {
  const {
    voucherCode,
    currency,
    cartItems,
    deliveryAddressId,
    billingAddressId,
    deliveryAddress,
    billingAddress,
    isLogged,
    deliveryType,
    referrer,
  } = payload;
  try {
    const response = isLogged
      ? yield call(apiService.postLoggedRambursPayment, {
          voucherCode,
          currency,
          cartItems,
          deliveryAddressId,
          billingAddressId,
          deliveryAddress,
          billingAddress,
          deliveryType,
          referrer,
        })
      : yield call(apiService.postRambursPayment, {
          voucherCode,
          currency,
          cartItems,
          deliveryAddressId,
          billingAddressId,
          deliveryAddress,
          billingAddress,
          deliveryType,
          referrer,
        });
    if (response.status === 200) {
      yield put({
        type: ORDER_RAMBURS_SUCCESS,
        payload: { payload },
      });
      localStorage.removeItem("persist:root");
      localStorage.removeItem(LOCAL_STORAGE_REFERRER);
      window.location.assign(
        `/confirmation-ramburs?orderNumber=${response.data}`
      );
    }
  } catch (err) {
    if (err.response.status === 500 || err.response.status === 400) {
      yield put({
        type: ORDER_RAMBURS_FAILURE,
        payload: "Plata ramburs la curier a eșuat, încercați din nou!",
      });
    }
  }
}

export function* getInfo({ payload }) {
  const { data: info } = yield call(apiService.getOrdersInfo, payload);
  yield put({
    type: ORDER_INFO_SUCCESS,
    payload: { info },
  });
}

export function* getDetails({ payload }) {
  const { orderNumber, isLogged } = payload;
  const { data: details } = isLogged
    ? yield call(apiService.getLoggedOrderDetails, orderNumber)
    : yield call(apiService.getOrderDetails, orderNumber);
  yield put({
    type: ORDER_DETAILS_SUCCESS,
    payload: { details },
  });
}

export function* getVoucher({ payload }) {
  try {
    const { data: voucher } = yield call(apiService.getVoucher, payload);
    yield put({
      type: VOUCHER_INFO_SUCCESS,
      payload: { voucher },
    });
  } catch (err) {
    yield put({
      type: VOUCHER_INFO_ERROR,
      payload: { message: "Voucher-ul nu este valid sau a fost folosit!" },
    });
  }
}

export function* getVoucherGuest({ payload }) {
  try {
    const { data: voucher } = yield call(
      apiService.getVoucherGuest,
      payload.voucherCode,
      payload.email
    );
    yield put({
      type: VOUCHER_INFO_SUCCESS,
      payload: { voucher },
    });
  } catch (err) {
    yield put({
      type: VOUCHER_INFO_ERROR,
      payload: { message: "Voucher-ul nu este valid sau a fost folosit!" },
    });
  }
}

export function* makeBTPaymentWatcher() {
  yield takeLatest(ORDER_BT_REQUEST, makeBTPayment);
}

export function* getBTStatusWatcher() {
  yield takeLatest(BT_STATUS_REQUEST, getBTStatus);
}

export function* makeRambursPaymentWatcher() {
  yield takeLatest(ORDER_RAMBURS_REQUEST, makeRambursPayment);
}

export function* getInfoWatcher() {
  yield takeLatest(ORDER_INFO_REQUEST, getInfo);
}

export function* getDetailsWatcher() {
  yield takeLatest(ORDER_DETAILS_REQUEST, getDetails);
}

export function* getVoucherWatcher() {
  yield takeLatest(VOUCHER_INFO_REQUEST, getVoucher);
}

export function* getVoucherGuestWatcher() {
  yield takeLatest(VOUCHER_INFO_GUEST_REQUEST, getVoucherGuest);
}

export function* orderSaga() {
  yield all([
    call(makeRambursPaymentWatcher),
    call(makeBTPaymentWatcher),
    call(getBTStatusWatcher),
    call(getInfoWatcher),
    call(getVoucherWatcher),
    call(getDetailsWatcher),
    call(getVoucherGuestWatcher),
  ]);
}
