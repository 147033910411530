import {
  GET_ADMIN_ORDERS_REQUEST,
  GET_ADMIN_ORDER_DETAILS_REQUEST,
  RESET_ORDER_DETAILS,
  ADD_ADMIN_ORDER,
  VERIFY_ROLE_REQUEST,
  VERIFY_ROLE_SUCCESS,
  SET_NEXT_PAGE_ADMIN,
  SET_FIRST_PAGE_ADMIN,
  SET_LAST_PAGE_ADMIN,
  SET_PREV_PAGE_ADMIN,
  NEXT_PAGE_ADMIN,
  PREV_PAGE_ADMIN,
  EDIT_PRODUCT_REQUEST,
} from "./admin.constants";

export const getAdminOrdersRequest = (payload) => {
  return {
    type: GET_ADMIN_ORDERS_REQUEST,
    payload,
  };
};

export const getAdminOrderDetailsRequest = (payload) => {
  return {
    type: GET_ADMIN_ORDER_DETAILS_REQUEST,
    payload,
  };
};

export const resetOrderDetails = () => {
  return {
    type: RESET_ORDER_DETAILS,
  };
};

export const addAdminOrder = (payload) => {
  return {
    type: ADD_ADMIN_ORDER,
    payload,
  };
};

export const editProductRequest = (payload) => {
  return {
    type: EDIT_PRODUCT_REQUEST,
    payload,
  };
};

export const verifyRole = () => {
  return {
    type: VERIFY_ROLE_REQUEST,
  };
};

export const verifyRoleSuccess = () => {
  return {
    type: VERIFY_ROLE_SUCCESS,
  };
};

export const nextPage = () => {
  return {
    type: NEXT_PAGE_ADMIN,
  };
};

export const prevPage = () => {
  return {
    type: PREV_PAGE_ADMIN,
  };
};

export const setNextPage = () => {
  return {
    type: SET_NEXT_PAGE_ADMIN,
  };
};

export const setPrevPage = () => {
  return {
    type: SET_PREV_PAGE_ADMIN,
  };
};

export const setFirstPage = () => {
  return {
    type: SET_FIRST_PAGE_ADMIN,
  };
};

export const setLastPage = () => {
  return {
    type: SET_LAST_PAGE_ADMIN,
  };
};
