import {
  DECREASE_ITEM,
  INCREMENT_ITEM,
  GET_PRODUCT_REQUEST,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "./product.constants";

export const decreaseItem = (payload) => {
  return {
    type: DECREASE_ITEM,
    payload,
  };
};

export const incrementItem = (payload) => {
  return {
    type: INCREMENT_ITEM,
    payload,
  };
};

export const getProductRequest = (payload) => {
  return {
    type: GET_PRODUCT_REQUEST,
    payload,
  };
};

export const addNotification = (payload) => {
  return {
    type: ADD_NOTIFICATION,
    payload,
  };
};

export const removeNotification = (payload) => {
  return {
    type: REMOVE_NOTIFICATION,
    payload,
  };
};
