import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, robots, canonical, openGraph }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>
        {"RomStock - " +
          (title.charAt(0).toUpperCase() +
            title.substring(1, title.length).toLowerCase())}
      </title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charset="UTF-8" />
      <meta name="robots" content={robots} />
      <meta property="og:url" content={openGraph.url} />
      <meta property="og:image" content={openGraph.image} />
      <meta property="og:description" content={openGraph.description} />
      <meta property="og:locale" content="ro_RO" />
      <meta property="og:site_name" content="RomStock" />
      <link rel="canonical" href={canonical} />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default SEO;
