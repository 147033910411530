import React from "react";
import Img from "react-cool-img";
import image404 from "../../images/404.png";
import "./Page404.scss";

const Page404 = () => {
  return (
    <>
      <div className="container-not-found">
        <Img className="image404" src={image404} alt={"image404"} cache />
      </div>
    </>
  );
};

export default Page404;
