import React from "react";
import styles from "./SpecificationsTable.module.scss";

const SpecificationsTable = ({ specifications }) => {
  return (
    <div className={styles.main_div_specifications}>
      <table className={styles.table_specifications}>
        <tr>
          <th>
            <h6>Nume</h6>
          </th>
          <th style={{ margin: "2%" }}>
            <h6>Valoare</h6>
          </th>
        </tr>
        {specifications.map((specification) => (
          <tr>
            <td>{specification.specificationName}</td>
            <td style={{ margin: "2%" }}>{specification.specificationValue}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export default SpecificationsTable;
