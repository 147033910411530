import React from "react";
import styles from "../../general.module.scss";
import "./Confirmation.scss";
import { Link } from "react-router-dom";

const OrderRambursError = () => {
  return (
    <div className={styles.container}>
      {
        <div className="container col-md-8 col-12 confirmation_container">
          <h1>Comanda ta nu a fost înregistrată.</h1>
          <h3>Plata ramburs la curier a eșuat, încercați din nou!</h3>
          <Link
            to="/my-cart"
            style={{ color: "white", textDecoration: "none" }}
          >
            <button className="button">Coșul meu</button>
          </Link>
        </div>
      }
    </div>
  );
};
export default OrderRambursError;
