import {
  ADD_TO_CART,
  ADD_TO_CART_USER_REQUEST,
  ADD_TO_WISHLIST_REQUEST,
  DELETE_CART_REQUEST,
  DELETE_FROM_WISHLIST_REQUEST,
  GET_CART_REQUEST,
  GET_WISHLIST_REQUEST,
  REMOVE_FROM_CART,
  SET_SHOW_CART,
  UPDATE_CART_QUANTITY_REQUEST,
  UPDATE_GUEST_CART_REQUEST,
} from "./cart.constants";

export const addToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload,
  };
};

export const removeFromCart = (payload) => {
  return {
    type: REMOVE_FROM_CART,
    payload,
  };
};

export const addToWishlist = (payload) => {
  return {
    type: ADD_TO_WISHLIST_REQUEST,
    payload,
  };
};

export const deleteFromWishlist = (payload) => {
  return {
    type: DELETE_FROM_WISHLIST_REQUEST,
    payload,
  };
};
export const getCart = () => {
  return {
    type: GET_CART_REQUEST,
  };
};
export const getWishlistRequest = () => {
  return {
    type: GET_WISHLIST_REQUEST,
  };
};
export const addToCartLogged = (payload) => {
  return {
    type: ADD_TO_CART_USER_REQUEST,
    payload,
  };
};

export const updateCartQuantityRequest = (payload) => {
  return {
    type: UPDATE_CART_QUANTITY_REQUEST,
    payload,
  };
};

export const deleteCartRequest = (payload) => {
  return {
    type: DELETE_CART_REQUEST,
    payload,
  };
};

export const setShowCart = (payload) => {
  return {
    type: SET_SHOW_CART,
    payload,
  };
};

export const updateGuestCartRequest = (payload) => {
  return {
    type: UPDATE_GUEST_CART_REQUEST,
    payload,
  };
};
