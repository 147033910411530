import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link as RouterLink } from "react-router-dom";
import "./Home.scss";
import FooterIcons from "../../components/FooterIcons/FooterIcons";
import Brand1 from "../../images/Home/Brand1.jpg";
import Brand2 from "../../images/Home/Brand2.png";
import Brand3 from "../../images/Home/Brand3.png";
import Brand4 from "../../images/Home/Brand4.png";
import brand1 from "../../images/logo_jos/VULCAN.png";
import brand2 from "../../images/logo_jos/1024px-Toshiba_logo.png";
import brand3 from "../../images/logo_jos/nou_jvc.png";
import brand4 from "../../images/logo_jos/lahti.png";
import brand5 from "../../images/logo_jos/kruger.jpg";
import brand6 from "../../images/logo_jos/MEGA.png";
import brand7 from "../../images/logo_jos/nou_peiying.png";
import brand8 from "../../images/logo_jos/POLONIA.png";
import brand9 from "../../images/logo_jos/PROLINE.png";
import brand10 from "../../images/logo_jos/nou_teesa.png";
import brand11 from "../../images/logo_jos/tryton.png";
import CarouselHeader from "./CarouselHeader/CarouselHeader";
import Img from "react-cool-img";
import {
  randomProductsRequest,
  saveProductsRequest,
} from "../../redux/home/home.actions";
import { connect } from "react-redux";
import SEO from "../../components/SEO/SEO";
import { removeNotification } from "../../redux/product/product.actions";
import acumulatori from "../../images/Home/icons/acumulatori.png";
import gradinarit from "../../images/Home/icons/gradinarit.png";
import protectie from "../../images/Home/icons/protectie.png";
import telefoane from "../../images/Home/icons/telefoane.png";
import unelteElectrice from "../../images/Home/icons/unelte-electrice.png";
import ProductsCarousel from "../../components/ProductsCarousel/ProductsCarousel";

const Home = ({
  randomProductsRequest,
  saveProductsRequest,
  products,
  notifications,
  removeNotification,
}) => {
  useEffect(() => {
    randomProductsRequest();
  }, []);

  const categoriiPopulare = [
    {
      image: acumulatori,
      text: "Acumulatori",
      link: "electrice,-surse-de-energie-si-iluminat_surse-de-energie_acumulatori_sla",
    },
    {
      image: gradinarit,
      text: "Gradinarit",
      link: "gradinarit",
    },
    {
      image: protectie,
      text: "Protectie",
      link: "protectie",
    },
    {
      image: telefoane,
      text: "Telefoane",
      link: "tablete-si-telefoane_telefoane-mobile-si-accesori",
    },
    {
      image: unelteElectrice,
      text: "Unelte Electrice",
      link: "electrice",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  if (products)
    return (
      <>
        <SEO
          title="Acasă"
          description="Peste 12.000 de produse in stoc. Casa si gradina, electronice si echipamente de protectie. O gama variata la preturi mici, accesibile."
          robots="index, follow"
          canonical="https://romstock.ro"
          openGraph={{
            url: "https://www.romstock.ro",
            type: "website",
            image: "https://romstock.ro/logo192.png",
            description:
              "Peste 12.000 de produse in stoc. Casa si gradina, electronice si echipamente de protectie. O gama variata la preturi mici, accesibile.",
          }}
        />

        <CarouselHeader />
        <div className="main-div-home">
          {/* <img className="eggs-left" src={eggsImageLeft}></img> */}
          {/* <img className="eggs-right" src={eggsImageRight}></img> */}

          <h2 className="label-title label-weight-bold ">Categorii populare</h2>
          <div className="categorii-populare row">
            {categoriiPopulare.map((c, index) => {
              return (
                <Col key={index} lg={2} sm={4} xs={6} className="col-categorii">
                  <RouterLink
                    style={{ display: "flex", flexDirection: "column" }}
                    to={`/products/${c.link}`}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <Img
                      style={{
                        margin: "auto",
                        height: "112px",
                        marginBottom: "7%",
                      }}
                      src={c.image}
                      alt={c.text + "-icon"}
                      cache
                    />
                    <label className="text-cat">{c.text}</label>
                  </RouterLink>
                </Col>
              );
            })}
          </div>

          <h1 className="label-title label-weight-light">
            Descoperă produsele săptămânii
          </h1>
          <h2 className="label-subtitle">Recomandate pentru tine</h2>
          <ProductsCarousel products={products.products.weeklyPromotions} />

          <h2 className="label-title label-weight-bold ">Protecție</h2>
          <h6 className="see-more">
            <a href="/products/protectie">vezi mai multe</a>
          </h6>
          <ProductsCarousel products={products.products.tools} />

          <div className="easter">
            {/* <img className="grass" src={grassImage}></img> */}
            <h2
              className="label-title label-weight-bold"
              // style={{ color: "#06B200" }}
            >
              Aparate de Bucătărie
            </h2>
            <h6 className="see-more">
              <a href="/products/aparate-de-bucatarie">vezi mai multe</a>
            </h6>
            <div className="carousel-container">
              <ProductsCarousel products={products.products.electronics} />
            </div>
          </div>

          <h2 className="label-title label-weight-bold ">
            Descoperă brandurile
          </h2>
          <div className="descopera-brand">
            <Row style={{ justifyContent: "center" }}>
              <div>
                <RouterLink
                  to={`/products/all?brand=PROLINE`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Img src={Brand1} cache alt="proline-brand-icon" />
                </RouterLink>
              </div>
              <div>
                <RouterLink
                  to={`/products/all?brand=TRYTON`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Img src={Brand2} cache alt="tryton-brand-icon" />
                </RouterLink>
              </div>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <div>
                <RouterLink
                  to={`/products/all?brand=MEGA`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Img src={Brand3} cache alt="mega-brand-icon" />
                </RouterLink>
              </div>
              <div>
                <RouterLink
                  to={`/products/all?brand=LAHTI.PRO`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <Img src={Brand4} cache alt="lathi-pro-brand-icon" />
                </RouterLink>
              </div>
            </Row>
          </div>
        </div>
        <div className="carrousel-div">
          <Row
            style={{
              justifyContent: "center",
              width: "-webkit-fill-available",
              marginRight: "0",
              marginLeft: "0",
            }}
          >
            <label className="brands">Branduri</label>
          </Row>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            className="carousel-brands"
            arrows={false}
          >
            <RouterLink
              to={`/products/all?brand=TOSHIBA`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand2} alt="toshiba-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=JVC`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand3} alt="jvc-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=LAHTI.PRO`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand4} alt="lahti-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=KRUGER%20MATZ`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand5} alt="kruger-matz-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=MEGA`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand6} alt="mega-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=PEIYING`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand7} alt="peiying-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=POLONIA`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand8} alt="polonia-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=PROLINE`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand9} alt="proline-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=TEESA`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand10} alt="teesa-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=TRYTON`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand11} alt="tryton-brand-logo" />
            </RouterLink>
            <RouterLink
              to={`/products/all?brand=VULCAN`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <Image src={brand1} alt="vulcan-brand-logo" />
            </RouterLink>
          </Carousel>
        </div>
        <FooterIcons />
      </>
    );

  return null;
};

const mapDispatchToProps = (dispatch) => ({
  randomProductsRequest: () => dispatch(randomProductsRequest()),
  saveProductsRequest: (payload) => dispatch(saveProductsRequest(payload)),
  removeNotification: (payload) => dispatch(removeNotification(payload)),
});

const mapStateToProps = (state) => ({
  products: state.home.products,
  notifications: state.product.notifications,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
