import {
  ADD_TO_WISHLIST_SOLO_SUCCESS,
  DELETE_FROM_WISHLIST_SUCCESS,
} from "../cart/cart.constants";
import {
  ADD_BRAND_ACTIVE_FILTER,
  NEXT_PAGE_FILTERS,
  PREV_PAGE_FILTERS,
  REMOVE_BRAND_ACTIVE_FILTER,
  SEARCH_GUEST_REQUEST,
  SEARCH_GUEST_SUCCESS,
  SET_ORDER,
  SET_PRICE_ACTIVE_FILTER,
  SET_STOCK_ACTIVE_FILTER,
  SET_LAST_PAGE_FILTERS,
  SET_FIRST_PAGE_FILTERS,
  SET_NEXT_PAGE_FILTERS,
  SET_PREV_PAGE_FILTERS,
  PRICE_TYPE,
  STOCK_TYPE,
  SET_CHANGED_FILTERS,
} from "./filters.constants";

const INITIAL_STATE = {
  brandActiveFilters: [],
  stockActiveFilter: { filterType: STOCK_TYPE, value: null },
  priceActiveFilter: { filterType: PRICE_TYPE, value: null },

  brandFilters: [],
  priceFilter: { min: 0, max: 0 },
  products: [],
  currentPageProduct: 1,
  numberOfPages: null,
  isLoading: false,
  order: "DISCOUNT",
  changedPage: false,
  changedFilterList: false,
  justErasedAllFilters: false,
};

export const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_BRAND_ACTIVE_FILTER:
      return {
        ...state,
        changedFilterList: true,
        brandActiveFilters: Array.from(
          new Set([...state.brandActiveFilters, action.payload])
        ),
        justErasedAllFilters: false,
      };
    case REMOVE_BRAND_ACTIVE_FILTER:
      return {
        ...state,
        changedFilterList: true,
        brandActiveFilters: state.brandActiveFilters.filter(
          (filter) => filter !== action.payload
        ),
        justErasedAllFilters: false,
      };
    case SET_PRICE_ACTIVE_FILTER:
      return {
        ...state,
        changedFilterList: true,
        priceActiveFilter: { filterType: PRICE_TYPE, value: action.payload },
        justErasedAllFilters: false,
      };
    case SET_STOCK_ACTIVE_FILTER:
      return {
        ...state,
        changedFilterList: true,
        stockActiveFilter: { filterType: STOCK_TYPE, value: action.payload },
        justErasedAllFilters: false,
      };

    case SEARCH_GUEST_REQUEST:
      return {
        ...state,
        isLoading: true,
        changedFilterList: false,
        currentPageProduct: action.payload.currentPageProduct
          ? action.payload.currentPageProduct
          : state.currentPageProduct,
      };
    case SEARCH_GUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload.products,
        brandFilters: action.payload.brands
          ? action.payload.brands
          : state.brandFilters,
        priceFilter: action.payload.priceRange
          ? action.payload.priceRange
          : state.priceFilter,
        numberOfPages: action.payload.numberOfPages
          ? action.payload.numberOfPages
          : state.numberOfPages,
        changedPage: false,
        brandActiveFilters: action.payload.shouldChangeFilters
          ? []
          : state.brandActiveFilters,
        priceActiveFilter: action.payload.shouldChangeFilters
          ? { filterType: PRICE_TYPE, value: null }
          : state.priceActiveFilter,
        stockActiveFilter: action.payload.shouldChangeFilters
          ? { filterType: STOCK_TYPE, value: null }
          : state.stockActiveFilter,
        justErasedAllFilters: action.payload.shouldChangeFilters,
      };
    case NEXT_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: state.currentPageProduct + 1,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case PREV_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: state.currentPageProduct - 1,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case SET_NEXT_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: state.currentPageProduct + 2,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case SET_PREV_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: state.currentPageProduct - 2,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case SET_FIRST_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: 1,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case SET_LAST_PAGE_FILTERS:
      return {
        ...state,
        currentPageProduct: state.numberOfPages,
        changedPage: true,
        justErasedAllFilters: false,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
        justErasedAllFilters: false,
      };
    case ADD_TO_WISHLIST_SOLO_SUCCESS:
      const newProducts = state.products.map((product) => {
        if (product.code === action.payload.code)
          return { ...product, isInWishlist: true };
        else return product;
      });
      return {
        ...state,
        products: newProducts,
      };
    case DELETE_FROM_WISHLIST_SUCCESS:
      const deletedNewProducts = state.products.map((product) => {
        if (product.code === action.payload.code)
          return { ...product, isInWishlist: false };
        else return product;
      });
      return {
        ...state,
        products: deletedNewProducts,
      };
    case SET_CHANGED_FILTERS:
      return {
        ...state,
        justErasedAllFilters: action.payload,
      };
    default:
      return state;
  }
};
